import { faArrowRotateBack } from '@fortawesome/pro-light-svg-icons'
import { NoFilters } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/ModalConfigColumnsFilters'
import ModalConfigColumnsFiltersComponentsRenderer from '../../../../../../../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/ModalConfigColumnsFilters/ModalConfigColumnsFiltersComponents'
import { ConfigColumnsColumn } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/types'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import DropdownVerticalSortList from '../../../../../../../../../baseComponents/Sorts/DropdownVerticalSortList'
import { DropdownVerticalSortItem } from '../../../../../../../../../baseComponents/Sorts/DropdownVerticalSortList/types'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import Checkbox from '../../../../../../../../../forms/Checkbox'
import TwinForm from '../../../../../../../../../forms/TwinForm'
import { SelectedColumnsAndFilters } from '../../../../../../../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { SearchableCustomerName } from '../subcomponents'
import { useCallback } from 'react'
import { TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'

interface FilterCampaignCustomersProps extends FilterCampaignCustomersLogicProps {
    onSave: () => Promise<void>
    columns: ConfigColumnsColumn[]
    tmpColumns: SelectedColumnsAndFilters
}

const FilterCampaignCustomers: React.FC<FilterCampaignCustomersProps> = ({ columns, tmpColumns, onSave, ...logic }) => {
    const { t } = useTwinTranslation()
    const { changeChecked, changeOrder, changeSingleField, resetSelected } = useFilterCampaignCustomersLogic({ ...logic })
    const firstColumn: JSX.Element[] = []
    const secondColumn: DropdownVerticalSortItem[] = []
    const premiumColumns: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && item.searchableComponent) {
            if (item.haveModule !== false) {
                const index = Object.keys(tmpColumns.filters).indexOf(item.id)
                const selected = index !== -1
                firstColumn.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={selected} /></div>)
                if (selected) {
                    secondColumn.push({
                        name: item.simpleLabel || item.label as any,
                        id: item.id,
                        order: index + 1,
                        component: item.dataKey === 'id' ? <SearchableCustomerName changeSingleField={changeSingleField} {...item} value={tmpColumns.filters[item.id]} label={t('customer', 'Cliente')} extraComponentData={item.searchableComponent.extraComponentData} /> : <ModalConfigColumnsFiltersComponentsRenderer changeSingleField={changeSingleField} {...item} value={tmpColumns.filters[item.id]} component={item.searchableComponent.component} extraComponentData={item.searchableComponent.extraComponentData} ExtraLeftHeader={item.searchableComponent.extraComponentData.ExtraLeftHeader} />
                    })
                }
            } else {
                premiumColumns.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={true} readOnly={true} /></div>)
            }
        }
    }
    secondColumn.sort((a, b) => a.order - b.order)
    if (secondColumn[secondColumn.length - 1] && secondColumn[secondColumn.length - 1].id !== 'id') {
        secondColumn[secondColumn.length - 1].component = (<ModalConfigColumnsFiltersComponentsRenderer {...secondColumn[secondColumn.length - 1].component.props} isLast={true} />)
    }
    let secondColumnRender: JSX.Element | null = <DropdownVerticalSortList items={secondColumn} setItems={(setItemsFake) => changeOrder(setItemsFake(secondColumn))} />
    if (!secondColumn.length) {
        secondColumnRender = <NoFilters />
    }
    return (
        <TwinForm beforeSubmitHandler={() => { onSave(); return false }} className='flex flex-col flex-auto filter_campaign_customer_step'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <h4>{t('selectColumnsToFilterCampaignCustomers', 'Selecciona las columnas para filtrar los clientes de tu campaña')}</h4>,
                button: t('viewCustomers', 'Ver clientes')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit '>
                        <div className='w-fit max-w-300'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='filtersToShow'>Mostrar filtros</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='uncheckAll'>Desmarcar todo</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                        </div>
                        <div className='border-r border-gray-D6 w-1 mx-35' ></div>
                        <div className='flex flex-col min-h-full flex-auto w-1'>
                            <h4 className='mb-20'><TwinTrans transKey='orderAndConfigureSelectedFilters'>Ordena y configura los filtros seleccionados</TwinTrans></h4>
                            {secondColumnRender}
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface FilterCampaignCustomersLogicProps {
    setTmpColumns: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
}

const useFilterCampaignCustomersLogic = ({ setTmpColumns }: FilterCampaignCustomersLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp) => {
            let tmpCopy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const item = tmpCopy.filters[itemKey]
            if (item) {
                delete tmpCopy.filters[itemKey]
            } else {
                tmpCopy.filters = { ...tmpCopy.filters, [itemKey]: {} }
            }
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    const changeOrder = useCallback((items: DropdownVerticalSortItem[]) => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            let filters: SelectedColumnsAndFilters['filters'] = {}
            for (const item of items) {
                filters[item.id] = copy.filters[item.id] || {}
            }
            return { ...copy, filters }
        })
    }, [setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const myColumns = { ...copy, filters: {} }
            return myColumns
        })
    }, [setTmpColumns])

    const changeSingleField = useCallback((id: string, value: TwinDictionary) => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const myColumns = { ...copy, filters: { ...copy.filters, [id]: value } }
            return myColumns
        })
    }, [setTmpColumns])

    return { setTmpColumns, changeChecked, changeOrder, resetSelected, changeSingleField }
}

export default FilterCampaignCustomers