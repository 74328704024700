import { useCallback, useEffect, useState } from 'react'
import { TwinDictionary } from '../../globals/dictionary'
import { CampaignModelType } from '@teinor/erp/types/company/customer/campaign'
import { twinFetchPostJSON } from '../../globals/data'

interface NotSelectedCampaignCustomerProps{
    id: string
}
export const useNotSelectedCampaignCustomer = ({ id }: NotSelectedCampaignCustomerProps) => {
     const [campaigns, setCampaigns] = useState<TwinDictionary | null>(null)
        const CustomerId = id
        const getCampaigns = useCallback(async () => {
            const result = await twinFetchPostJSON('/api/app/customer/campaign/getAllCampaigns', { })
            const selected = await twinFetchPostJSON('/api/app/customer/campaign/customer/getAllCampaignCustomers', { where: { CustomerId} })
            if(result && selected){
                setCampaigns(filterNotSelectedCampaigns(result, selected))
            }
        }, [setCampaigns, CustomerId])
    
        useEffect(() => {
            getCampaigns()
        }, [getCampaigns])

    return { campaigns }
}

const filterNotSelectedCampaigns = (campaigns: CampaignModelType[], selectedCampaigns: TwinDictionary[]) => {
    const result: TwinDictionary = {}
    for (const campaign of campaigns) {
        let isNotSelected = true
        for (const selected of selectedCampaigns) {
            if (parseInt(String(campaign.id)) === parseInt(String(selected.Campaign.id))) {
                isNotSelected = false
            }
        }
        if (isNotSelected) {
            result[campaign.id] = campaign
        }
    }
    return result
}