import { useCallback } from 'react'
import { CustomerProjectModelTypeExtended } from '../types'
import TwinComment, { CreateTwinComment } from '../../../../baseComponents/TwinComment'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import PermissionChecker from '../../../../baseComponents/PermissionChecker'
import { getUserPermissions } from '../../../../utils/reducers/getters'

interface CustomerProjectCommentTabProps {
    customerProjectData: CustomerProjectModelTypeExtended
    getCustomerProject: () => Promise<void>
}

const CustomerProjectCommentTab: React.FC<CustomerProjectCommentTabProps> = ({ customerProjectData, getCustomerProject }) => {
    const renderThis: JSX.Element[] = []
    const customerProjectComments = customerProjectData?.CustomerProjectComments || []
    const { deleteCustomerProjectComment, updateCustomerProjectComment } = useCustomerProjectCommentTabLogic({ getCustomerProject })
    
    const userPermissions = getUserPermissions(['customer.projects.main', 'customer.profile.projects'])

    customerProjectComments.sort((a, b) => {
        return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
    })

    for (const key in customerProjectComments) {
        const myComment = customerProjectComments[key]
        const parseDate = new Date(myComment?.createdAt).toLocaleString()
        renderThis.push(<TwinComment key={key} deleteComment={deleteCustomerProjectComment} updateComment={updateCustomerProjectComment} comment={myComment.message} posArr={parseInt(key)} fieldComment='message' title={customerProjectData?.name} subtitle={parseDate} photo={myComment?.Employee?.profile_image} downloadFileURL='/api/app/customer/customerProject/customerProjectComment/getCustomerProjectCommentInstanceFile' {...myComment} EmployeeId={myComment?.Employee?.id || 0} />)
    }
    return (
        <div className='flex flex-col mt-25 h-screen flex-auto customer_project_activity_tab'>
            <div className='flex flex-col flex-auto overflow-auto h-1 gap-20 relative customer_project_activity_tab_inner'>
                {renderThis}
            </div>
            <PermissionChecker userPermissions={userPermissions} permission={'update'}>
                <CreateTwinComment createURL='/api/app/customer/customerProject/customerProjectComment/createCustomerProjectComment' imagePath={['customer', String(customerProjectData.CustomerId), 'customerProjects', String(customerProjectData.id)]} idParent={String(customerProjectData.id) || ''} fieldIdParent='CustomerProjectId' myOnSubmit={getCustomerProject} fieldName='message'  />

            </PermissionChecker>

        </div>
    )
}

interface CustomerProjectCommentTabLogicProps {
    getCustomerProject: () => Promise<void>
}

const useCustomerProjectCommentTabLogic = ({ getCustomerProject }: CustomerProjectCommentTabLogicProps) => {

    const deleteCustomerProjectComment = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectComment/deleteCustomerProjectComment', { id })
        if(result){
            await getCustomerProject()
        }
    }, [getCustomerProject])

    const updateCustomerProjectComment = useCallback(async (id: number, message: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectComment/updateCustomerProjectComment', { id, message })
        if(result){
            await getCustomerProject()
        }
    }, [getCustomerProject])

    return { deleteCustomerProjectComment, updateCustomerProjectComment }
}

export default CustomerProjectCommentTab