import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { EditRow, DeleteRow, CustomRenderBoolean } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { CustomerShopModelType } from '@teinor/erp/types/company/customer/customerShop'
import ModalCEContactB2BShop from './ModalCEContactB2BShop'


interface ContactB2BShopsProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const ContactB2BShops: React.FC<ContactB2BShopsProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('workingCenters', 'Centros de trabajo'),
        newText: t('newWorkingCenter', 'Nuevo centro de trabajo'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableContactB2BShops} CreateModalComponent={ModalCEContactB2BShop} EditModalComponent={ModalCEContactB2BShop} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id }} />
    )
}
interface TableContactB2BShopsProps extends TableComponentBaseScreenInnerProps { }

const TableContactB2BShops: React.FC<TableContactB2BShopsProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerShopModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'address',
            dataKey: 'address',
            label: t('address', 'Dirección'),
            sortable: true,
        },
        {
            id: 'city',
            dataKey: 'city',
            label: t('city', 'Ciudad'),
            sortable: true,
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'isMain',
            dataKey: 'isMain',
            label: t('isMainWC', 'Centro principal'),
            width: 150,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={75} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable columns={columns} name='contactB2BShops' getDataFrom='/api/app/customer/customerShop/getAllCustomerShops'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData,
            })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams}
        />
    )
}

export default ContactB2BShops