import { useCallback } from 'react'
import { CampaignCustomerModelTypeExtended } from '../types'
import { getUserPermissions } from '../../../utils/reducers/getters'
import TwinComment, { CreateTwinComment } from '../../../baseComponents/TwinComment'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import PermissionChecker from '../../../baseComponents/PermissionChecker'

interface CampaignCustomerCommentTabProps {
    campaignCustomerData: CampaignCustomerModelTypeExtended
    getCampaignCustomer: () => Promise<void>
}

const CampaignCustomerCommentTab: React.FC<CampaignCustomerCommentTabProps> = ({ campaignCustomerData, getCampaignCustomer }) => {
    const renderThis: JSX.Element[] = []
    const campaignCustomerComments = campaignCustomerData?.CampaignCustomerComments || []
    const { deleteCampaignCustomerComment, updateCampaignCustomerComment } = useCampaignCustomerCommentTabLogic({ getCampaignCustomer })
    const userPermissions = getUserPermissions(['customer.campaigns.inner', 'customer.profile.campaign'])

    campaignCustomerComments.sort((a, b) => {
        return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
    })

    for (const key in campaignCustomerComments) {
        const myComment = campaignCustomerComments[key]
        const parseDate = new Date(myComment?.createdAt).toLocaleString()
        renderThis.push(<TwinComment key={key} deleteComment={deleteCampaignCustomerComment} updateComment={updateCampaignCustomerComment} comment={myComment.message} posArr={parseInt(key)} fieldComment='message' title={myComment.Employee?.fullname_short || ''} subtitle={parseDate} photo={myComment?.Employee?.profile_image} {...myComment} downloadFileURL='/api/app/customer/campaign/customer/comment/getCampaignCustomerCommentInstanceFile' EmployeeId={myComment.Employee?.id || 0} />)
    }
    return (
        <div className='flex flex-col mt-25 h-screen flex-auto campaign_customer_activity_tab'>
            <div className='flex flex-col flex-auto overflow-auto h-1 gap-20 relative campaign_customer_activity_tab_inner'>
                {renderThis}
            </div>
            <PermissionChecker userPermissions={userPermissions} permission={'update'}>
                <CreateTwinComment createURL='/api/app/customer/campaign/customer/comment/createCampaignCustomerComment' imagePath={['customer', String(campaignCustomerData.CustomerId), 'campaignCustomers', String(campaignCustomerData.id)]} idParent={String(campaignCustomerData?.id) || ''} fieldIdParent='CampaignCustomerId' myOnSubmit={getCampaignCustomer} fieldName='message' />
            </PermissionChecker>
        </div>
    )
}
interface CampaignCustomerCommentTabLogicProps {
    getCampaignCustomer: () => Promise<void>
}

const useCampaignCustomerCommentTabLogic = ({ getCampaignCustomer }: CampaignCustomerCommentTabLogicProps) => {
    const deleteCampaignCustomerComment = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/deleteCampaignCustomerComment', { id })
        if (result) {
            await getCampaignCustomer()
        }
    }, [getCampaignCustomer])

    const updateCampaignCustomerComment = useCallback(async (id: number, message: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/updateCampaignCustomerComment', { id, message })
        if (result) {
            await getCampaignCustomer()
        }
    }, [getCampaignCustomer])

    return { deleteCampaignCustomerComment, updateCampaignCustomerComment }
}

export default CampaignCustomerCommentTab