
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import WCalOvertimeEditModalAdmin from '../../../../../../../specificComponents/WCal/WCalOvertimeEditModalAdmin'

interface ModalEditWCalOvertimeRequestsProps extends ModalEditComponentProps {}

const ModalEditWCalOvertimeRequests: React.FC<ModalEditWCalOvertimeRequestsProps> = ({ userPermissions, setOpened, allRowData, onSubmit }) => {
    return (
        <WCalOvertimeEditModalAdmin setOpened={setOpened} onSubmit={onSubmit} allRowData={allRowData} userPermissions={userPermissions || undefined} />
    )
}

export default ModalEditWCalOvertimeRequests