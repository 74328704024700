import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import useIsLoading from '../../../../../../../../../utils/hooks/useIsLoading'
import FormRenderer from '../../../../../../../../../forms/FormRenderer'
import TwinForm from '../../../../../../../../../forms/TwinForm'
import PermissionChecker from '../../../../../../../../../baseComponents/PermissionChecker'
import { ButtonOrLoader } from '../../../../../../../../../baseComponents/Button'
import TwinComment, { CreateTwinComment } from '../../../../../../../../../baseComponents/TwinComment'
import { CustomerCommentOnNotesTy } from '../../../../../../../../../specificComponents/Customers/types'
import { useCustomerNotesCommentsPropsTypes } from '../../../../../../../../../utils/hooks/customer/useCustomerNotesCommentsPropsTypes'

type CustomerNotesProps = ComponentWithPermissions & CustomerNotesLogicProps & {
    basicData: CustomerModelType | null
}

const CustomerNotes: React.FC<CustomerNotesProps> = ({ basicData, userPermissions, ...logic }) => {
    const { t } = useTwinTranslation()
    const { myOnSubmit, endLoading, startLoading, loading, customerNotesComments, CustomerId, getCustomerNotesComments, setCustomerNotesComments } = useCustomerNotesLogic({ ...logic })
    const { commentsPropsByType } = useCustomerNotesCommentsPropsTypes({ setCustomerNotesComments })
    const fields: FormRender<CustomerModelType> = [
        {
            cols: 1,
            title: t('notesCustomer', 'Notas del Cliente'),
            elements: [
                {
                    name: 'notes',
                    component: 'TwinEditor',
                    defaultValue: basicData?.notes || '',
                    className: 'h-300'
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        }
    ]
    const renderThis: JSX.Element[] = []
    if (customerNotesComments) {   
        for (const key in customerNotesComments) {
            const comment = customerNotesComments[key]
            const typeComment = 'CustomerProjectId' in comment ? 'customerProject' : 'CampaignCustomerId' in comment ? 'campaignCustomer' : 'customerNote'
            const parseDate = new Date(comment?.createdAt).toLocaleString()
            renderThis.push(<TwinComment key={key} comment={comment.message || ''} posArr={parseInt(key)} fieldComment='message' subtitle={parseDate} photo={comment?.Employee?.profile_image} {...comment} {...commentsPropsByType[typeComment]} title={commentsPropsByType[typeComment].title(comment) || ''} />)
        }
    }
    return (
        <div className='grid grid-cols-2 gap-30 flex-auto'>
            <div className='flex flex-col'>
                <div className='regular18 mb-17'>
                    <TwinTrans transKey='customerComments'>Comentarios del cliente</TwinTrans>
                </div>
                <div className='flex flex-col gap-20 overflow-auto flex-auto pr-5 h-1'>{renderThis}</div>
                <PermissionChecker userPermissions={userPermissions} permission={'update'}>
                    <CreateTwinComment createURL={'/api/app/customer/customerNote/createCustomerNote'} imagePath={['customer', String(CustomerId), 'customerNotes']} idParent={CustomerId || ''} fieldIdParent='CustomerId' myOnSubmit={getCustomerNotesComments} fieldName='message' />
                </ PermissionChecker>
            </div>
            <TwinForm action='/api/app/customer/updateCustomer' onSubmit={myOnSubmit} beforeSubmitHandler={startLoading} onError={endLoading} className=''>
                <FormRenderer sections={fields} antiUnsaveTrigger={true} />
                <PermissionChecker userPermissions={userPermissions} permission='update'>
                    <div className='flex justify-end items-center mt-40'>
                        <ButtonOrLoader textButton={t('save', 'Guardar')} buttonIsDisabled={loading} />
                    </div>
                </PermissionChecker>
            </TwinForm>
        </div>
    )
}
interface CustomerNotesLogicProps {
    getData: () => Promise<void>
}

const useCustomerNotesLogic = ({ getData }: CustomerNotesLogicProps) => {
    const [customerNotesComments, setCustomerNotesComments] = useState<CustomerCommentOnNotesTy[] | null>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const { id } = useParams()

    const myOnSubmit = useCallback(() => {
        endLoading()
        getData()
    }, [endLoading, getData])

    const getCustomerNotesComments = useCallback(async () => {
        const result: CustomerCommentOnNotesTy[] = await twinFetchPostJSON('/api/app/customer/customerNote/getAllCustomerNotesAndOtherComments', { 'CustomerId': id })
        if (result) {
            result.sort((a, b) => (b.createdAt || 0) > (a.createdAt || 0) ? 1 : -1)
            setCustomerNotesComments(result)
        }
    }, [id])

    useEffect(() => {
        getCustomerNotesComments()
    }, [getCustomerNotesComments])

    return { startLoading, endLoading, loading, myOnSubmit, customerNotesComments, CustomerId: id, getCustomerNotesComments, setCustomerNotesComments }
}

export default CustomerNotes