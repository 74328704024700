import { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { WCalSpecialDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalSpecial/WCalSpecialDay'
import { flushSync } from 'react-dom'
import { ButtonOrLoader } from '../../../../../../../../baseComponents/Button'
import { ModalSmallMedium } from '../../../../../../../../baseComponents/Modal'
import { ModalOpenedSetTy } from '../../../../../../../../baseComponents/Modal/types'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import twinFetchPost from '../../../../../../../../utils/globals/data'
import { checkIfDailyHoursIsSmallerOrEqual, checkSchedulesAllOk } from '../../../../../../../../utils/globals/schedule'
import useIsLoading from '../../../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { changeErrorMessage } from '../../../../../../../../utils/reducers/reduxDispatch'
import { WCalScheduleTyAndError } from '../../../WorkingCalendars/SingleWorkingCalendar/Tabs/ScheduleWorkingCalendar/ModalEditScheduleWorkingCalendar/types'
import ScheduleNewSpecialDay from './ScheduleNewSpecialDay'
import TwinForm from '../../../../../../../../forms/TwinForm'
import { TwinDictionary } from '../../../../../../../../utils/globals/dictionary'
import CustomError from '../../../../../../../../baseComponents/CustomError'

interface ModalCreateWCSpecialDaysProps extends ModalCreateWCSpecialDaysLogicProps {

}

const ModalCreateWCSpecialDays: React.FC<ModalCreateWCSpecialDaysProps> = ({ setOpened, getData }) => {
    const { t } = useTwinTranslation()
    const { getDefaultDateTwinCalendar, getDefaultRandomColorWCSpecialDays, id, scheduleSpecialDay, setScheduleSpecialDay, endLoading, loading, checkAllOk, error } = useModalCreateWCSpecialDaysLogic({ getData, setOpened })
    const fields: FormRender<WCalSpecialDayModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'day',
                    label: t('day', 'Día'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                    required: true,
                    defaultActiveStartDate: getDefaultDateTwinCalendar(),
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColorWithMargin',
                    defaultValue: getDefaultRandomColorWCSpecialDays()
                },
                {
                    name: 'WCalSpecialId',
                    value: id,
                    component: 'InputHidden'
                }
            ]
        },
    ]
    return (
        <ModalSmallMedium className='flex flex-col' opened={true} setOpened={setOpened}>
            <div className=' modal_negative_margin flex flex-auto flex-col px-30'>
                <h1 className='mb-17'>
                    <TwinTrans transKey='createWorkingCalendarSpecialDays'>
                        Crear día especial
                    </TwinTrans>
                </h1>
                {error ? <CustomError textError={error} className='my-20' /> : null}
                <TwinForm action='' beforeSubmitHandler={(vals)=>{ checkAllOk(vals); return false}} onError={endLoading} className='flex-auto flex flex-col h-1 justify-between'>
                    <div className='overflow-auto flex flex-col flex-auto'>
                        <FormRenderer sections={fields} className='grid_mincontent flex-initial' />
                        <ScheduleNewSpecialDay {...{setScheduleSpecialDay, scheduleSpecialDay}} />
                    </div>
                    <div className='mt-30 ml-auto flex'>
                        <ButtonOrLoader buttonIsDisabled={loading} textButton={t('create', 'Crear')} />
                    </div>
                </TwinForm>
            </div>
        </ModalSmallMedium>
    )
}

interface ModalCreateWCSpecialDaysLogicProps {
    setOpened: ModalOpenedSetTy
    getData: () => void
}

const useModalCreateWCSpecialDaysLogic = ({ getData, setOpened }: ModalCreateWCSpecialDaysLogicProps) => {
    const {t} = useTwinTranslation()
    const [scheduleSpecialDay, setScheduleSpecialDay] = useState<WCalScheduleTyAndError>({ workingTime: [[540, 600]], flexible: false, maxDayMins: 0, minDayMins: 0, errors: [], overtimePosition: [], dailyHours: 0 })
    const [error, setError] = useState<string | null>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const { id, year } = useParams()

    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const errorDailyHours = t('conflictInDailyHoursCheckNotExceedTotalDay', 'Hay un conflicto en las horas diarias. Revisa que no superen el total de cada día.')
    const alreadyExistsError = t('specialDayAlreadyExists', 'Ya hay un día especial creado en esa fecha')

    const yearParsed = parseInt(year || '0')
    const getDefaultDateTwinCalendar = useCallback(() => {
        const currentDate = new Date()
        const defaultDate = new Date(yearParsed, currentDate.getMonth(), currentDate.getDate())
        return defaultDate
    }, [yearParsed])

    const getDefaultRandomColorWCSpecialDays = useCallback(() => {
        const colors = [
            '#6299E3',
            '#90BDDA',
            '#F8A7C1',
            '#AECB6C',
            '#F88754',
            '#F5AE56',
            '#D891EE',
            '#21B37D'
        ]
        const index = Math.floor(Math.random() * colors.length)
        return colors[index]
    }, [])

    const checkAllOk = useCallback(async(values: TwinDictionary) => {
        startLoading()
        await flushSync(async () => {
            let hasError = false
            let checkedSchedules: WCalScheduleTyAndError | false = false
            await setScheduleSpecialDay((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                const myDaySchedule = copyOld
                myDaySchedule.errors = []
                const response = checkSchedulesAllOk(myDaySchedule)
                if (response.hasError) {
                    hasError = true
                }
                if (hasError) {
                    changeErrorMessage(errorHours)
                    endLoading()
                }
                if (!hasError) {
                    checkedSchedules = { ...copyOld }
                }
                return { ...copyOld }
            })
            if (hasError) {
                setError(errorHours)
                return false
            }
            if (checkedSchedules) {
                const checkDailyHours = checkIfDailyHoursIsSmallerOrEqual(scheduleSpecialDay)
                if (checkDailyHours.hasError) {
                    const modal = document.querySelector('.modal_schedule_container ');
                    if (modal) {
                        modal.scrollTop = 0
                    }
                    setError(errorDailyHours)
                    endLoading()
                    return false
                } else {
                    setError(null)
                    values['schedule'] = JSON.stringify(checkedSchedules)
                    const response = await twinFetchPost('/api/app/workingCalendar/workingCalendarSpecial/createWorkingCalendarSpecialDay', values)
                    if (response.status === 200) {
                        setOpened(null)
                        getData()
                    } else {
                        const responseJSON = await response.json()
                        if (responseJSON.errors[0].msg === 'Special Day already exists'){
                            changeErrorMessage(alreadyExistsError)
                        }
                        endLoading()
                    }
                }
            }
        })
        return false
    }, [setError, errorHours, setScheduleSpecialDay, endLoading, setOpened, getData, startLoading, alreadyExistsError, errorDailyHours, scheduleSpecialDay])

    return { getDefaultDateTwinCalendar, getDefaultRandomColorWCSpecialDays, id, scheduleSpecialDay, setScheduleSpecialDay, startLoading, endLoading, loading, checkAllOk, error }
}


export default ModalCreateWCSpecialDays