import { ConnectedProps, connect } from 'react-redux'
import { AppLayoutWithoutMenu } from '../../baseComponents/AppLayout'
import CreateRoutering from '../../baseComponents/CreateRoutering'
import { cleanRoutes } from '../../baseComponents/CreateRoutering/functions'
import { Routes } from '../../baseComponents/CreateRoutering/types'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../utils/reducers'
import { useCallback, useEffect, useState } from 'react'
import TwinIcon from '../../baseComponents/TwinIcon'
import TwinTrans from '../../baseComponents/TwinTrans'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { getAndChangeCompany, getAndChangeConfig } from '../erp/components/ErpLoader/functions'
import { twinCountries } from '../../utils/globals/countries'
import { connectToSocket } from '../../utils/globals/socket'
import QrTimeControl from './screens/QrTimeControl'
import LoadingSpinner from '../../baseComponents/LoaderDecider/LoadingSpinner'


export const QrRoutesPaths = (): Routes => {
    const { t } = useTwinTranslation()
    return [
        {
            link: '/',
            text: t('loginQr', 'Inicio sesión QR'),
            component: QrTimeControl,
            modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full'],
            permissions: 'generateQRCodes',
            childs: [
                {
                    link: '/:invent',
                    text: t('generateQr', 'Generar QR'),
                    component: QrTimeControl,
                    modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full'],
                    permissions: 'generateQRCodes'
                }
            ]
        }
    ]
}

export interface ErpRoutesProps extends ReduxErpRoutesConnector {

};

const QrRoutes: React.FC<ErpRoutesProps> = ({ groupPermissions, modulesBuyed, modulesDemo, }) => {
    const routes = QrRoutesPaths()
    const {loaded} = useQrRoutesLogic()

    if(!loaded) {
        return <LoadingScreen/>
    }

    const routesClean = cleanRoutes(routes, groupPermissions, modulesBuyed, modulesDemo)
    return <AppLayoutWithoutMenu routes={routesClean}>
        <CreateRoutering routes={routesClean}></CreateRoutering>
        {!groupPermissions?.permissions.generateQRCodes && !groupPermissions?.isRoleAdmin
            ? <div className='regular22 text-red-BA m-auto'>
                <TwinIcon icon={faExclamationTriangle} className='mr-10 h-25' />
                <TwinTrans transKey='dontHavePermissions'>No tienes permisos</TwinTrans>
            </div> 
        : null}
    </AppLayoutWithoutMenu>
}

const useQrRoutesLogic = () => {
    const [loaded, setLoaded] = useState<boolean>(false)
    const loadCompany = useCallback(async () => {
        await Promise.all([
            getAndChangeCompany(),
            getAndChangeConfig(),
            twinCountries(),
            connectToSocket()
        ])
        setLoaded(true)
    }, [setLoaded])

    useEffect(() => {
        loadCompany()
    }, [loadCompany])

    return { loaded }
}

const mapQrRoutesConnector = (state: AllReduxAppPayloads) => ({ groupPermissions: state.user?.extraData.groupPermissions, modulesBuyed: state.company?.modulesBuyed, modulesDemo: state.company?.modulesDemo })
export const qrRoutesConnector = connect(mapQrRoutesConnector, null, null, { context: GlobalContext })
export type ReduxErpRoutesConnector = ConnectedProps<typeof qrRoutesConnector>

const QrRoutesConnector = qrRoutesConnector(QrRoutes)

const QrRoutesLoader: React.FC = () => {
    return (<QrRoutesConnector />)
}

const LoadingScreen: React.FC = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='screen_loader'>
            <h1>{t('loading', 'Cargando...')}</h1>
            <LoadingSpinner />
        </div>
    )
}

export default QrRoutesLoader