import ModalCreateEdit from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs } from '../../../../../../../utils/reducers/getters'
import { useCallback, useEffect, useState } from 'react'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import useCreateCustomerAndAddress from '../../../../../../../utils/hooks/customer/useCreateCustomerAndAddress'
import { CustomerModelTypeAndAddress } from '../../../../../../../specificComponents/Customers/types'
import { CustomerGroupPayload } from '../../../../../../../utils/reducers/customers/customerGroups'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import withLoading from '../../../../../../../utils/hoc/withLoading'

type ModalCreateContactB2CProps = ModalComponentProps & ReduxModalCreateContactB2C  & { }

const ModalCreateContactB2C: React.FC<ModalCreateContactB2CProps> = ({ customerGroups, ...rest }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { dictCustomerOrigins, createAddress, handleShowCreateAddress } = useMocalCreateContactLogic()

    const { addressFields } = useCreateCustomerAndAddress({ createAddress })
    const fields: FormRender<CustomerModelTypeAndAddress> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'CustomerOriginId',
                    label: t('origin', 'Origen'),
                    component: 'CustomSelectWithSearchBar',
                    items: dictCustomerOrigins || {},
                },
                {
                    name: 'customer_type',
                    component: 'InputHidden',
                    value: 3
                },
                {
                    name: 'createAddress',
                    component: 'CheckboxMargin',
                    label: t('createAddress', 'Crear dirección'),
                    value: String(createAddress),
                    onChange: handleShowCreateAddress
                }
            ]
        },
        ...addressFields,
        {
            cols: 1,
            title: t('customerType', 'Tipo de cliente'),
            elements: [
                {
                    name: 'customersForParse',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: dictionaryComplexFromJsonArr(customerGroups?.B2CContactGroups || []),
                },
            ]
        }
    ]   
     return (
        <ModalCreateEdit fields={fields} url={'/api/app/customer/createCustomer'} translations={{
             title: t('createLeadB2C', 'Crear Cliente Potencial'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

const useMocalCreateContactLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)
    const [createAddress, setCreateAddress] = useState(false)

    const handleShowCreateAddress = useCallback(() => {
        setCreateAddress((old) => !old)
    }, [setCreateAddress])


    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins, handleShowCreateAddress, createAddress }
}
const modalCreateContactB2CDispatch = {
    setCustomerGroups: (payload: CustomerGroupPayload) => ({ type: 'CHANGE_CUSTOMER_GROUP', payload }),
}

export type ReduxModalCreateContactB2C = ConnectedProps<typeof ModalCreateContactB2CConnect>
const mapModalCreateContactB2CConnector = (state: AllReduxPayloads) => ({ customerGroups: state.customerGroups })
const ModalCreateContactB2CConnect = connect(mapModalCreateContactB2CConnector, modalCreateContactB2CDispatch)

const modalCreateContactB2CConnectLoading = withLoading(ModalCreateContactB2C, [{ fetchUrl: '/api/app/customer/customerGroup/getAllCustomersGroup', propName: 'customerGroups', setFunctionName: 'setCustomerGroups' }])

export default ModalCreateContactB2CConnect(modalCreateContactB2CConnectLoading);