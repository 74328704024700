import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomer from './ModalCreateCustomer'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import withLoading from '../../../../../../utils/hoc/withLoading'
import { getAllClientManagerEmployees, getConfigParam } from '../../../../../../utils/reducers/getters'
import { useMemo } from 'react'

interface CustomersProps extends ComponentWithPermissions {}

const Customers: React.FC<CustomersProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customers', 'Clientes'),
        newText: t('newCustomer', 'Nuevo cliente'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomersConnect} CreateModalComponent={ModalCreateCustomer} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableCustomersProps = TableComponentProps & ReduxTableCustomers & {}

const TableCustomers: React.FC<TableCustomersProps> = ({ setTableInstance, employees }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const myEmployees = useMemo(() => getConfigParam('showOnlyClientManagerEmployees') === 'on' ? getAllClientManagerEmployees() || {} : employees || {}, [employees])
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            },
            searchKey: 'Customer.code'
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.name'
        },
        {
            id: 'surname',
            dataKey: 'surname',
            label: t('surname', 'Apellidos'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('surname', 'Apellidos') }
            },
            searchKey: 'Customer.surname'
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.email'
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            },
            searchKey: 'Customer.phone'
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('mobile', 'Móvil') }
            },
            searchKey: 'Customer.mobile'
        },
        {
            id: 'CustomerOriginId',
            dataKey: 'CustomerOriginId',
            label: t('origin', 'Origen'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerOrigin?.name} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('origin', 'Origen') }
            },
            searchKey: 'CustomerOrigin.name',
            hideColumnByDefault: true
        },
        {
            id: 'ResponsibleEmployeeId',
            dataKey: 'ResponsibleEmployeeId',
            label: t('ourManager', 'Nuestro gestor'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.ResponsibleEmployee?.fullname_short} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: myEmployees, label: t('ourManager', 'Nuestro gestor'), fieldName: 'fullname_short' }
            },
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTableLink  createRowDataLink={[location, ['$id', 'main']]} name='customers' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{ 'customer_type': customerTypes['B2C'] }}  setTableInstance={setTableInstance} />
    )
}
const tableCustomersDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

type ReduxTableCustomers = ConnectedProps<typeof tableCustomersConnect>
const mapTableCustomersConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const tableCustomersConnect = connect(mapTableCustomersConnector, tableCustomersDispatch)
const tableCustomersLoading = withLoading(TableCustomers, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])
const TableCustomersConnect = tableCustomersConnect(tableCustomersLoading)
export default Customers