import { useCallback, useMemo } from 'react'
import { CustomSelectBase } from '../../../forms/CustomSelect'
import { CustomSelectSectionSearchbarDropDown, CustomSelectSectionsProps } from '../../../forms/CustomSelect/CustomSelectSections'
import { DisplayTextFuncTy } from '../../../forms/CustomSelect/types'
import { Modify } from '../../../utils/globals/types'
import { CustomerPayload } from '../../../utils/reducers/customers/customer'
import { parseSearchCustomerProjectToSections } from '../SearcherCustomerProject/functions'
import useCustomerTypesDict from '../../../utils/hooks/customer/useCustomerTypesDict'

type CustomSelectSectionCustomersProps = Modify<CustomSelectSectionsProps, CustomSelectSectionCustomersLogicProps & {
    items?: never
}>


const CustomSelectSectionCustomers: React.FC<CustomSelectSectionCustomersProps> = ({customers, ...rest}) => {
    const { customersSectionItems, displayTextFunc } = useCustomSelectSectionCustomersLogic({ customers })
    return (
        <CustomSelectBase {...rest} items={customersSectionItems.customersSectionItems} DropDownComponent={CustomSelectSectionSearchbarDropDown} displayTextFunc={displayTextFunc} />
    )
}

interface CustomSelectSectionCustomersLogicProps {
    customers: CustomerPayload
}

const useCustomSelectSectionCustomersLogic = ({ customers }: CustomSelectSectionCustomersLogicProps) => {
    const { dictCustomerTypeTrans } = useCustomerTypesDict()
    const customersSectionItems = useMemo(() => {
        return parseSearchCustomerProjectToSections(customers, dictCustomerTypeTrans)
    }, [customers, dictCustomerTypeTrans])

    const displayTextFunc: DisplayTextFuncTy = useCallback((_items, value) => {
        return customersSectionItems.indexCustomers[value]?.text || customersSectionItems.indexCustomers[value as any]
    }, [customersSectionItems.indexCustomers])

    return { displayTextFunc, customersSectionItems }
}

export default CustomSelectSectionCustomers