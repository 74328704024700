import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { VirtualTableListingStateLess } from '../../../TwinTable/VirtualTableListing'
import { CustomRenderDate } from '../../../TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { TaskModelType } from '@teinor/erp/types/company/task'
import TwinIcon from '../../../TwinIcon'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { changeBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'
import { CustomRenderTaskCustomerAndProject } from '../../../../specificComponents/Task/TaskScreenTable/Subcomponents'


interface DSBMyTasksTableProps extends TableMyTasksProps {
    title: string
}

const DSBMyTasksTable: React.FC<DSBMyTasksTableProps> = ({ title, start_date, end_date }) => {
    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <h3 className='pb-15'>{title}</h3>
            <TableMyTasks start_date={start_date} end_date={end_date} />
        </div>
    )
}

interface TableMyTasksProps extends TableMyTasksLogicProps {

}

const TableMyTasks: React.FC<TableMyTasksProps> = ({ start_date, end_date }) => {
    const { t } = useTwinTranslation()
    const { data, handleOpenTask } = useTableMyTasksLogic({ start_date, end_date })
    const columns: ColumnTableSchema<TaskModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'CustomerId',
            dataKey: 'CustomerId',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskCustomerAndProject allRowData={allRowData} id={'main'} />,
        },
        {
            id: 'start_date',
            dataKey: 'end_date',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'end_date',
            dataKey: 'end_date',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'id',
            dataKey: 'id',
            label: '',
            customRender: (parameterValue) => <TwinIcon icon={faChevronRight} onClick={() => handleOpenTask(parseInt(String(parameterValue)))} className='cursor-pointer hover:text-green-43' size='lg' />,
            width: 50
        }
    ]

    return (
        <div className='flex-auto'>
            <VirtualTableListingStateLess tableData={data} rowHeight={48} headerHeight={48} name='DSBMyTasksTableDashboard' columns={columns} setSelectedColumnsAndFilters={() => { }} updateEmployeeParameter={false} />
        </div>
    )
}

interface TableMyTasksLogicProps {
    start_date: Date
    end_date: Date
}

const useTableMyTasksLogic = ({ start_date, end_date }: TableMyTasksLogicProps) => {
    const [data, setData] = useState<TaskModelType[] | null>(null)

    const handleOpenTask = useCallback(async(id: number) => {
        await changeBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + id, extraData: { id, opened: true } })
    }, [])
    
    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getMyTasksForWidgets', {
            start_date,
            end_date,
            whereToParse: { deleted: false }
        })
        if (result) {
            setData(result)
        }
    }, [setData, start_date, end_date])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleOpenTask }
}

export default DSBMyTasksTable