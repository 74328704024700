import { CustomerAddressModelType } from '@teinor/erp/types/company/customer/customerAddress'
import { useParams } from 'react-router'
import ModalCreateEdit from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../../../../utils/globals/countries'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useCountryState from '../../../../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getDictionaryCountries } from '../../../../../../../../../../utils/reducers/getters'

interface ModalCEContactB2BAddressProps extends ModalEditComponentProps {}

const ModalCEContactB2BAddress: React.FC<ModalCEContactB2BAddressProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { id } = useParams()
    const { t } = useTwinTranslation()
    const { country, setCountry } = useCountryState({ defaultCountry: allRowData?.country })
    const fields: FormRender<CustomerAddressModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('nameAddress', 'Nombre de la dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'name_to',
                    label: t('recipientName', 'Nombre del receptor'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname_to',
                    label: t('recipientSurname', 'Apellido del receptor'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
            ],
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postalCode', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    required: true,
                    value: allRowData?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    required: true,
                    value: allRowData?.state
                },
                {
                    name: 'lat',
                    label: t('latitude', 'Latitud'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'long',
                    label: t('longitude', 'Longitud'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
            ]
        },
        {
            cols: 2,
            title: t('contatInformation', 'Información  de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
            ]
        }
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'fiscal_address',
            label: t('fiscalAddress', 'Dirección Fiscal'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<ModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerAddress/updateCustomerAddress'} translations={{ title: t('editAddress', 'Editar dirección'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />)
    }

    return (<ModalCreateEdit fields={fields} url={'/api/app/customer/customerAddress/createCustomerAddress'} translations={{
        title: t('createAddress', 'Crear dirección'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCEContactB2BAddress