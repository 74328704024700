import { WCalOvertimeModelType, WCalOvertimeStatusTypeInversed, WCalOvertimeSubtypeInversed } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { useState, useCallback, useEffect } from 'react'
import { ButtonOrLoader } from '../../../../../../../../../../baseComponents/Button'
import LoadingSpinner from '../../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalMedium } from '../../../../../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../../../../baseComponents/PermissionChecker'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import CustomRenderSchedules from '../../../../../../../../../../specificComponents/WCal/schedule/CustomRenderSchedules'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDate, displayFormatedHourFromMins } from '../../../../../../../../../../utils/globals/date'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import useEditDeleteModal from '../../../../../../../../../../utils/hooks/useEditDeleteModal'
import useDictRequestOvertime from '../../../../../../../../../../utils/hooks/useDictRequestOvertime'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { changeSuccessMessage, changeErrorMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'
import ModalSmallFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import withLoading from '../../../../../../../../../../utils/hoc/withLoading'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../../../../utils/reducers'
import { EmployeeAbsenceTypePayload } from '../../../../../../../../../../utils/reducers/company/employeeAbsenceTypes'
import { EmployeesPayload } from '../../../../../../../../../../utils/reducers/company/employees'
import { returnClassOfSpanTemplate } from '../../../../../../../../../../utils/globals/tailwind'
import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'

type ModalEmployeeNotificationOvertimeProps = ModalEmployeeNotificationOvertimeLogicProps & ReduxEmployees & {
}

const ModalEmployeeNotificationOvertime: React.FC<ModalEmployeeNotificationOvertimeProps> = ({ userPermissions, setOpened, allRowData, onSubmit, employees }) => {
    const { manageWCalOvertimeStatus, data, openModal, setOpenModal, type } = useModalEmployeeNotificationOvertimeLogic({ allRowData, setOpened, onSubmit })
    const { t } = useTwinTranslation()
    const { requestStatusOvertimeTypesDic, overtimePreferences} = useDictRequestOvertime()

    const statusButtons: TwinDictionary = {
        'accepted': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(3)} textButton={t('accept', 'Aceptar')} buttonIsDisabled={false} />,
        'deniedByEmployee': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(6)} textButton={t('deny', 'Denegar')} buttonIsDisabled={false} className='button_red' />,
    }
    const renderThis: JSX.Element[] = []
    if (parseInt(String(data?.status)) === WCalOvertimeStatusTypeInversed['pending'] && parseInt(String(data?.subtype)) === WCalOvertimeSubtypeInversed['adminToUser']) {
        renderThis.push(statusButtons['accepted'])
        renderThis.push(statusButtons['deniedByEmployee'])
    }

    if (!data) {
        return (
            <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalMedium>
        )
    }

    const fields: FormRender<WCalOvertimeModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.EmployeeId ? employees?.[data?.EmployeeId].fullname_short : '-',
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'AdminEmployeeId',
                    component: 'TextField',
                    value: allRowData?.EmployeeTrigger? allRowData?.EmployeeTrigger?.fullname_short : '-',
                    label: t('ManageBy', 'Gestionado por')
                },
                {
                    name: 'day',
                    component: 'TextField',
                    label: t('day', 'Día'),
                    value: displayDate(new Date(data?.day || ''))
                },
                {
                    name: 'schedule',
                    component: 'Custom',
                    render: () => data?.schedule ? <CustomRenderSchedules schedule={data?.schedule} /> : <div>No hay</div>
                },
                {
                    name: 'overtimeMins',
                    component: 'TextField',
                    label: t('total', 'Total'),
                    value: displayFormatedHourFromMins(data?.overtimeMins || 0),
                },
                {
                    name: 'status',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: data?.status ? requestStatusOvertimeTypesDic[data?.status].name : '-'
                },
                {
                    name: 'approvedDate',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de aprobación'),
                    value: data?.approvedDate ? displayDate(new Date(data?.approvedDate)) : '-'
                },
                {
                    name: 'paymentDate',
                    component: 'TextField',
                    label: t('paymentDate', 'Fecha de pago'),
                    value: data?.paymentDate ? displayDate(new Date(data?.paymentDate)) : '-'
                },
                {
                    name: 'overtimePreferences',
                    component: 'TextField',
                    label: t('preferences', 'Preferencias'),
                    value: data?.overtimePreferences || data?.overtimePreferences === 0 ? overtimePreferences[data.overtimePreferences].reason : '-'
                },
                {
                    name: 'reason',
                    component: 'TextField',
                    label: t('reason', 'Justificación'),
                    value: data?.reason || '-',
                    className: returnClassOfSpanTemplate(2),
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    return (
        <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <div className='mb-25'>
                <h2><TwinTrans transKey='manageOvertime'>Gestionar horas extra</TwinTrans></h2>
            </div>
            <div className={'flex flex-auto h-1'}>
                <div className='overflow-auto flex flex-auto flex-col notFlexAutoFormRender'>
                    {fields.length ?
                        <FormRenderer sections={fields} className='grid_mincontent'>
                        </FormRenderer>
                        : null}
                </div>
            </div>
            <div className='mt-30 ml-auto flex gap-10'>
                <PermissionChecker userPermissions={userPermissions!} permission={'update'}>
                    {renderThis}
                </PermissionChecker>
            </div>
            {openModal?.type === 'edit' && type  && <ModalApproveOrDenyOvertime setOpened={() => setOpenModal(null)} allRowData={openModal.allRowData} onSubmit={(res, send) => { onSubmit(res, send); setOpened(null) }} type={type} />}
        </ModalMedium>
    )
}

interface ModalEmployeeNotificationOvertimeLogicProps extends ModalEditComponentProps { }

const useModalEmployeeNotificationOvertimeLogic = ({ allRowData }: ModalEmployeeNotificationOvertimeLogicProps) => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [data, setData] = useState<WCalOvertimeModelType | null>(null)
    const [type, setType] = useState <'approved' | 'denied' | null>(null)

    const manageWCalOvertimeStatus = useCallback(async (status: number) => {
        setOpenModal({ type: 'edit', allRowData: allRowData ? allRowData : [] })
        setType((status === 3 ? 'approved' : 'denied'))
    }, [allRowData, setOpenModal, setType])

    const getOvertimeData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeOvertime/getWCalOvertimeInstanceByEmployee', { id: allRowData?.SubId })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.SubId])

    useEffect(() => {
        getOvertimeData()
    }, [getOvertimeData])

    return { manageWCalOvertimeStatus, data, openModal, setOpenModal, getOvertimeData, type }
}
interface ModalApproveOrDenyOvertimeProps extends ModalApproveOrDenyOvertimeLogicProps {
    type: 'approved' | 'denied'
}

const ModalApproveOrDenyOvertime: React.FC<ModalApproveOrDenyOvertimeProps> = ({ onSubmit, setOpened, allRowData, type }) => {
    const { t } = useTwinTranslation()
    const { handleOvertimeStatus } = useModalApproveOrDenyOvertimeLogic({ onSubmit, allRowData, setOpened })
    if (type === 'approved') {
        return (<ModalSmallFilled translations={{
            title: t('acceptOvertime', 'Aceptar horas extra'),
            subtitle: t('sureAcceptOvertime', '¿Estás seguro de querer aceptar las horas extra?'),
            buttonAccept: t('accept', 'Aceptar'),
        }} onAccept={() => handleOvertimeStatus(1)} opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} />)
    } else {
        return (<ModalDeleteFilled translations={{
            title: t('denyOvertime', 'Denegar horas extra'),
            subtitle: t('sureDenyOvertime', '¿Estás seguro de querer denegar las horas extra?'),
            buttonAccept: t('deny', 'Denegar'),
        }} onAccept={() => handleOvertimeStatus(3)} opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} />)
    }
}

interface ModalApproveOrDenyOvertimeLogicProps extends ModalEditComponentProps {}

const useModalApproveOrDenyOvertimeLogic = ({ onSubmit, setOpened, allRowData }: ModalApproveOrDenyOvertimeLogicProps) => {
    const { t } = useTwinTranslation()

    const message = t('successfullyCompleted', 'Realizado correctamente')

    const handleOvertimeStatus = useCallback(async (status: number) => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeOvertime/manageOvertimeRequestByEmployee', {
            id: allRowData?.SubId,
            status,
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.SubId, setOpened, onSubmit, message])

    return { handleOvertimeStatus }
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees, employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)
const employeesConnectLoading = withLoading(ModalEmployeeNotificationOvertime, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])
const ModalEmployeeNotificationOvertimeConnect = employeesConnect(employeesConnectLoading)

export default ModalEmployeeNotificationOvertimeConnect