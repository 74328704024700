import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import ModalEditTaskBagOfHoursConnect from '../../../../../../../../../../specificComponents/ModalEditTaskBagOfHours'

interface ModalUserNotificationsBagOfHoursProps extends ModalEditComponentProps {}

const ModalUserNotificationsBagOfHours: React.FC<ModalUserNotificationsBagOfHoursProps> = ({ userPermissions, setOpened, allRowData }) => {
    return (
        <ModalEditTaskBagOfHoursConnect allRowData={{ ...allRowData, id: allRowData?.SubId }} userPermissions={userPermissions} setOpened={setOpened} onSubmit={() => setOpened(null)} />
    )
}


export default ModalUserNotificationsBagOfHours