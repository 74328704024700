import GenerateTimeControlQr from '../../../../specificComponents/WCal/GenerateTimeControlQr'

interface QrTimeControlProps { }

const QrTimeControl: React.FC<QrTimeControlProps> = () => {
    return (
        <div className='landing_qr py-45 px-40 flex flex-auto big-layout flex-col'>
            <GenerateTimeControlQr />
        </div>
    )
}

export default QrTimeControl