
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../../../../baseComponents/PermissionChecker'
import WCalOvertimeEditModalAdmin from '../../../../../../../../../../specificComponents/WCal/WCalOvertimeEditModalAdmin'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'

interface ModalAdminNotificationOvertimeProps extends ModalEditComponentProps {
}

const ModalAdminNotificationOvertime: React.FC<ModalAdminNotificationOvertimeProps> = ({ setOpened, allRowData, onSubmit }) => {
    const userPermissions = getUserPermissions('WCal.overtime')
    return (
        <PermissionChecker userPermissions={userPermissions} permission={'read'}>
            <WCalOvertimeEditModalAdmin setOpened={setOpened} onSubmit={onSubmit} allRowData={{ id: allRowData?.SubId }} userPermissions={userPermissions || undefined} />
        </PermissionChecker>
    )
}

export default ModalAdminNotificationOvertime