import { useParams } from 'react-router'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { CustomRenderBoolean, CustomRenderString, CustomRenderStringAndColorNull, EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { CustomerProjectTypePayload } from '../../../../../../../../../utils/reducers/customers/customerProjectTypes'
import ModalEditCustomerProject from '../../../../../../../../../specificComponents/Customers/ModalEditCustomerProject'
import ModalCreateCustomerProject from '../../../../../../../../../specificComponents/Customers/ModalCreateCustomerProject'

interface ContactB2BProjectsProps extends ComponentWithPermissions {
}

const ContactB2BProjects: React.FC<ContactB2BProjectsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('projects', 'Proyectos'),
        newText: t('addProject', 'Añadir un proyecto'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableContactProjectsConnect} extraWhereParams={{ 'CustomerId': id }} pageData={pageData} CreateModalComponent={ModalCreateCustomerProject} EditModalComponent={ModalEditCustomerProject} userPermissions={userPermissions} />
    )
}

type TableContactProjectsProps = TableComponentBaseScreenInnerProps & ReduxCustomerProjectTypes & {
}

const TableContactProjects: React.FC<TableContactProjectsProps> = ({ setTableInstance, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CustomerProjectModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'CustomerProject.name'
        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'CustomerProjectType',
            dataKey: 'CustomerProjectTypeId',
            label: t('projectType', 'Tipo de proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProjectType?.name} />
        },
         {
            id: 'CurrentCProjectStatusId',
            dataKey: 'CurrentCProjectStatusId',
            label: t('status', 'Estado'),
             customRender: (_parameterValue, allRowData) => <CustomRenderStringAndColorNull value={allRowData?.CurrentCProjectStatus?.name} color={allRowData?.CurrentCProjectStatus?.color} />,
             sortable: true,
             sortKey: 'CurrentCProjectStatus.order'
        },
        {
            id: 'active',
            dataKey: 'active',
            label: t('active', 'Activo'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
            hideColumnByDefault: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: {
                    items: {
                        'true': { name: t('actived', 'Activados') },
                        'false': { name: t('disabled', 'Desactivados') },
                        'all': { name: t('all', 'Todos') }
                    }, label: t('active', 'Activo')
                }
            },
            searchKey: 'CustomerProject.active',
            generalSearchable: false
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='ContactProjects' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} getDataFrom='/api/app/customer/customerProject/getAllCustomerProjects'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

const customerProjectTypesDispatch = {
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
}

export type ReduxCustomerProjectTypes = ConnectedProps<typeof customerProjectTypesConnect>
const mapCustomerProjectTypesConnector = (state: AllReduxPayloads) => ({ customerProjectTypes: state.customerProjectTypes })
const customerProjectTypesConnect = connect(mapCustomerProjectTypesConnector, customerProjectTypesDispatch)

const customerProjectTypesConnectLoading = withLoading(TableContactProjects, [{ fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }])

const TableContactProjectsConnect = customerProjectTypesConnect(customerProjectTypesConnectLoading)

export default ContactB2BProjects