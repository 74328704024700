import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { VirtualTableListingStateLess } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { Fragment, useMemo, useState } from 'react'
import { ButtonPrimary } from '../../../../../../../../../baseComponents/Button'
import SearchBar from '../../../../../../../../../baseComponents/SearchBar'
import { modalCreateCampaignFilterCustomers } from './functions'

interface TablePreselectedCustomersProps extends TablePreselectedCustomersLogicProps {
    openModalConfirmCustomers: () => void
    deletePreselectedCustomer: (pos: number) => void
}

const TablePreselectedCustomers: React.FC<TablePreselectedCustomersProps> = ({ preselectedCustomers, openModalConfirmCustomers, deletePreselectedCustomer }) => {
    const { t } = useTwinTranslation()
    const { searchValue, setSearchValue, filteredCustomers} = useTablePreselectedCustomersLogic({ preselectedCustomers })
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) =>deletePreselectedCustomer(allRowData?.rowIndex)} />
        }
    ]
    return (
        <Fragment>
            <div className='flex flex-auto flex-col'>
                <h2 className='text-18 text-gray-51'>
                    <TwinTrans transKey='matchCustomersNotAlreadyInCampaign'>Clientes que coinciden y no pertenecen a la campaña</TwinTrans>
                </h2>
                <SearchBar className='search_bar_table my-20' placeholder={t('searchDots', 'Buscar...')} searchValue={searchValue} onChange={(value) => setSearchValue(value)} />
                <div className='flex-auto'>
                    <VirtualTableListingStateLess tableData={filteredCustomers} rowHeight={48} headerHeight={48} name='CampaignCustomerSelector' columns={columns} setSelectedColumnsAndFilters={() => {}} />
                    {!preselectedCustomers?.length? 
                        <div className='mt-64 my-auto'>
                            <TwinTrans transKey='noCoincidences'>No hay coincidencias</TwinTrans>
                        </div>
                        : null
                    }
                </div>
            </div>
            <ButtonPrimary className='mt-75 ml-auto' onClick={openModalConfirmCustomers}>
                <TwinTrans transKey='addCustomers'>Añadir clientes</TwinTrans>
            </ButtonPrimary>
        </Fragment>
    )
}

interface TablePreselectedCustomersLogicProps {
    preselectedCustomers: CustomerModelType[]
}

const useTablePreselectedCustomersLogic = ({ preselectedCustomers}: TablePreselectedCustomersLogicProps) => {
    const [searchValue, setSearchValue] = useState<string>('')

    const filteredCustomers = useMemo(() => modalCreateCampaignFilterCustomers(preselectedCustomers, searchValue), [preselectedCustomers, searchValue])

    return { searchValue, setSearchValue, filteredCustomers }
}

export default TablePreselectedCustomers