import { WCalOvertimeModelAdd } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getEmployees } from '../../../../../../../utils/reducers/getters'
import { ModalCreateEditMediumCustomLoading } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalOpenedSetTy } from '../../../../../../../baseComponents/Modal/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'
import { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import twinFetchPost from '../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { OvertimeScheduleErrorsTy, ScheduleOvertime } from '../../../../../../../specificComponents/WCal/schedule/ScheduleOvertime'
import { checkSchedulesAllOk } from '../../../../../../../utils/globals/schedule'
import { changeErrorMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'

type ModalCreateWCalOvertimeRequestsProps = ModalEditComponentProps & ModalCreateWCalOvertimeRequestsLogicProps & {}

const ModalCreateWCalOvertimeRequests: React.FC<ModalCreateWCalOvertimeRequestsProps> = ({ setOpened, ...logic }) => {
    const { t } = useTwinTranslation()
    const employees = getEmployees()
    const { handleBeforeSubmit, schedule, setSchedule, loading } = useModalCreateWCalOvertimeRequestsLogic({ setOpened, ...logic })
    const fields: FormRender<WCalOvertimeModelAdd> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'CustomSelect',
                    label: t('employee', 'Empleado'),
                    items: employees || {},
                    required: true,
                    fieldName: 'fullname_short'
                },
                {
                    name: 'day',
                    label: t('day', 'Día'),
                    required: true,
                    component: 'InputCalendarStateFull'
                },
                {
                    name: 'reason',
                    component: 'TextArea',
                    label: t('reason', 'Justificación'),
                    parentClassName: returnClassOfSpanTemplate(2),
                },
                {
                    name: 'status',
                    component: 'CustomSelect',
                    label: t('approvalStatus', 'Estado de la aprobación'),
                    className: returnClassOfSpanTemplate(2),
                    value: '0',
                    items: {
                        0: {
                            name: t('sendRequestToEmployee', 'Mandar solicitud al empleado')
                        },
                        1: {
                            name: t('markAsOvertimeApproved', 'Marcar horas extra como aprobadas')
                        },
                        4: {
                            name: t('markAsOvertimePaid', 'Marcar horas extra como pagadas')
                        },
                        5: {
                            name: t('markAsOvertimeOnHolidays', 'Marcar horas extra como devueltas en vacaciones')
                        }
                    }
                },
            ]
        }
    ]
    return (
        <ModalCreateEditMediumCustomLoading fields={fields} url='' translations={{
            title: t('createOvertimeByManager', 'Crear horas extra'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} setOpened={setOpened} onSubmit={() => { }} beforeSubmit={(vals) => { handleBeforeSubmit(vals); return false }} className='notFlexAutoFormRender' loading={loading}>
            <ScheduleOvertime schedule={schedule} setSchedule={setSchedule} />
        </ModalCreateEditMediumCustomLoading>
    )
}

interface ModalCreateWCalOvertimeRequestsLogicProps {
    onSubmit: OnSubmit
    setOpened: ModalOpenedSetTy
}

const useModalCreateWCalOvertimeRequestsLogic = ({ setOpened, onSubmit }: ModalCreateWCalOvertimeRequestsLogicProps) => {
    const [schedule, setSchedule] = useState<OvertimeScheduleErrorsTy>({ workingTime: [], errors: [] })
    const { endLoading, loading, startLoading } = useIsLoading()
    const { t } = useTwinTranslation()
    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const errorHoursEmpty = t('notSelectedAnSchedule', 'No has seleccionado ningún horario.')
    const messages = JSON.stringify({
        'Already have a request for this day': t('alreadyHaveARequestForThisDay', 'Ya hay una solicitud para este día'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
        'Overtime exceeded the allowed hours': t('employeeOvertimeExceeded', 'Has excedido las horas extras del empleado para este año'),
        'Working Calendar is required': t('thisEmployeeNotHaveAnAssignedWorkingCalendar', 'Este empleado no tiene un calendario laboral asignado'),
    })
    const handleBeforeSubmit = useCallback(async (values: TwinDictionary) => {
        await flushSync(async () => {
            startLoading()
            if (schedule.workingTime.length) {
                let hasError = false
                let checkedSchedule: OvertimeScheduleErrorsTy | false = false
                await setSchedule((old) => {
                    const copyOld = JSON.parse(JSON.stringify(old))
                    const myDaySchedule = copyOld
                    myDaySchedule.errors = []
                    const response = checkSchedulesAllOk(myDaySchedule)
                    if (response.hasError) {
                        hasError = true
                    }
                    if (hasError) {
                        changeErrorMessage(errorHours)
                    }
                    if (!hasError) {
                        checkedSchedule = { ...copyOld }
                    }
                    return { ...copyOld }
                })
                if (hasError) {
                    changeErrorMessage(errorHours)
                    endLoading()
                    return false
                }
                if (checkedSchedule) {
                    values['schedule'] = JSON.stringify(schedule.workingTime)
                }
            } else {
                changeErrorMessage(errorHoursEmpty)
                endLoading()
                return false
            }
            const result = await twinFetchPost('/api/app/workingCalendar/workingCalendarOvertime/createWCalOvertimeByAdmin', values)
            if (result.status === 200) {
                setOpened(null)
                onSubmit(result, {})
                endLoading()
            } else {
                const responseJSON = await result.json()
                const messagesParsed = JSON.parse(messages)
                if (responseJSON.errors[0].msg && messagesParsed[responseJSON.errors[0].msg]) {
                    changeErrorMessage(messagesParsed[responseJSON.errors[0].msg])
                }
                endLoading()
            }
        })
        return false
    }, [schedule, startLoading, endLoading, errorHours, setOpened, errorHoursEmpty, onSubmit, messages])

    return { handleBeforeSubmit, schedule, setSchedule, loading }
}


export default ModalCreateWCalOvertimeRequests

