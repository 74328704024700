import { WCalOvertimeModelType, WCalOvertimeStatusTypeInversed, WCalOvertimeSubtypeInversed } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { useState, useCallback, useEffect } from 'react'
import { ButtonOrLoader } from '../../../baseComponents/Button'
import LoadingSpinner from '../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalMedium } from '../../../baseComponents/Modal'
import ModalDeleteFilled from '../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../baseComponents/PermissionChecker'
import TwinTrans from '../../../baseComponents/TwinTrans'
import FormRenderer from '../../../forms/FormRenderer'
import { FormRender } from '../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { displayDate, displayFormatedHourFromMins } from '../../../utils/globals/date'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import useEditDeleteModal from '../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { getUserInfo } from '../../../utils/reducers/getters'
import { UserModel } from '../../../utils/reducers/globals/user'
import { changeSuccessMessage, changeErrorMessage } from '../../../utils/reducers/reduxDispatch'
import CustomRenderSchedules from '../schedule/CustomRenderSchedules'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../utils/reducers'
import { EmployeeAbsenceTypePayload } from '../../../utils/reducers/company/employeeAbsenceTypes'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import useDictRequestOvertime from '../../../utils/hooks/useDictRequestOvertime'
import { returnClassOfSpanTemplate } from '../../../utils/globals/tailwind'
import CustomError from '../../../baseComponents/CustomError'


type WCalOvertimeEditModalAdminProps = WCalOvertimeEditModalAdminLogicProps & ReduxEmployees & {
}

const WCalOvertimeEditModalAdmin: React.FC<WCalOvertimeEditModalAdminProps> = ({ userPermissions, setOpened, allRowData, onSubmit, employees}) => {
    const { manageWCalOvertimeStatus, data, openModal, setOpenModal, user} = useWCalOvertimeEditModalAdminLogic({ allRowData, setOpened, onSubmit })
    const { t } = useTwinTranslation()
    const { requestStatusOvertimeTypesDic, overtimePreferences } = useDictRequestOvertime()
    let warningText = t('employeeNotAcceptedOvertimeYet', 'El empleado aún no ha aceptado las horas extras')

    const statusButtons: TwinDictionary = {
        'accepted': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(1)} textButton={t('approve', 'Aprobar')} buttonIsDisabled={false} />,
        'deniedByAdmin': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(3)} textButton={t('deny', 'Denegar')} buttonIsDisabled={false} className='button_red' />,
        'canceled': <ButtonOrLoader onClick={() => setOpenModal({ type: 'edit', allRowData: allRowData ? allRowData : [] })} textButton={t('cancel', 'Cancelar')} buttonIsDisabled={false} className='button_red' />,
        'paid': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(4)} textButton={t('markAsPaid', 'Marcar como pagadas')} buttonIsDisabled={false} className='' />,
        'createdHolidays': <ButtonOrLoader onClick={() => manageWCalOvertimeStatus(5)} textButton={t('onHolidays', 'Devuelto en vacaciones')} buttonIsDisabled={false} className='button_createdHolidays' />
    }

    const renderThis: JSX.Element[] = []
    if (parseInt(String(data?.status)) === WCalOvertimeStatusTypeInversed['pending']) {
        if (parseInt(String(data?.subtype)) === WCalOvertimeSubtypeInversed['userToAdmin']) {
            renderThis.push(statusButtons['deniedByAdmin'])
        } else {
            renderThis.push(statusButtons['canceled'])
        }
        renderThis.push(statusButtons['accepted'])
        renderThis.push(statusButtons['paid'])
        renderThis.push(statusButtons['createdHolidays'])
    } else if (parseInt(String(data?.status)) === WCalOvertimeStatusTypeInversed['accepted']) {
        renderThis.push(statusButtons['canceled'])
        renderThis.push(statusButtons['paid'])
        renderThis.push(statusButtons['createdHolidays'])
    } else if (parseInt(String(data?.status)) === WCalOvertimeStatusTypeInversed['paid'] || parseInt(String(data?.status)) === WCalOvertimeStatusTypeInversed['createdHolidays']) {
        renderThis.push(statusButtons['canceled'])
    }

    if (!data || !userPermissions) {
        return (
            <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalMedium>
        )
    }

    const fields: FormRender<WCalOvertimeModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.EmployeeId ? employees?.[data?.EmployeeId].fullname_short : '-',
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'EmployeeRequestId',
                    component: 'TextField',
                    value: data?.EmployeeRequestId ? employees?.[data?.EmployeeRequestId].fullname_short : '',
                    label: t('requestedBy', 'Solitado por')
                },
                {
                    name: 'AdminEmployeeId',
                    component: 'TextField',
                    value: data.subtype === 0 ? (data?.AdminEmployeeId ? employees?.[data?.AdminEmployeeId].fullname_short : '-') : employees?.[data?.EmployeeId].fullname_short,
                    label: t('ManageBy', 'Gestionado por')
                },
                {
                    name: 'day',
                    component: 'TextField',
                    label: t('day', 'Día'),
                    value: displayDate(new Date(data?.day || ''))
                },
                {
                    name: 'schedule',
                    component: 'Custom',
                    render: () => data?.schedule ? <CustomRenderSchedules schedule={data?.schedule} /> : <div>No hay</div>
                },
                {
                    name: 'overtimeMins',
                    component: 'TextField',
                    label: t('total', 'Total'),
                    value: displayFormatedHourFromMins(data?.overtimeMins || 0),
                },
                {
                    name: 'status',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: requestStatusOvertimeTypesDic[data?.status!].name
                },
                {
                    name: 'approvedDate',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de aprobación'),
                    value: data?.approvedDate ? displayDate(new Date(data?.approvedDate)) : '-'
                },
                {
                    name: 'paymentDate',
                    component: 'TextField',
                    label: t('paymentDate', 'Fecha de pago'),
                    value: data?.paymentDate ? displayDate(new Date(data?.paymentDate)) : '-'
                },
                {
                    name: 'overtimePreferences',
                    component: 'TextField',
                    label: t('preferences', 'Preferences'),
                    value: data?.overtimePreferences || data?.overtimePreferences === 0 ? overtimePreferences[data?.overtimePreferences].reason : '-'
                },
                {
                    name: 'reason',
                    component: 'TextField',
                    label: t('reason', 'Justificación'),
                    value: data?.reason || '-',
                    className: returnClassOfSpanTemplate(2),
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    return (
        <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <div className='mb-25'>
                <h2><TwinTrans transKey='manageOvertime'>Gestionar horas extra</TwinTrans></h2>
                {parseInt(String(data?.status)) === 0 && parseInt(String(data?.subtype)) === 0 ? <div className='mt-10'><CustomError textError={warningText} className='mt-5' /></div> : null}
            </div>
            <div className={'flex flex-auto h-1'}>
                <div className='overflow-auto flex flex-auto flex-col notFlexAutoFormRender'>
                    {fields.length ?
                        <FormRenderer sections={fields} className='grid_mincontent'>
                        </FormRenderer>
                        : null}
                </div>
            </div>
            <div className='mt-30 ml-auto flex gap-10'>
                <PermissionChecker userPermissions={userPermissions!} permission={'update'}>
                    {renderThis}
                </PermissionChecker>
            </div>
            {openModal?.type === 'edit' && <ModalCancelOvertimeRequest setOpened={() => setOpenModal(null)} allRowData={openModal.allRowData} onSubmit={(res, send) => { onSubmit(res, send); setOpened(null) }} user={user} />}
        </ModalMedium>
    )
}

interface WCalOvertimeEditModalAdminLogicProps extends ModalEditComponentProps { }

const useWCalOvertimeEditModalAdminLogic = ({ allRowData, setOpened, onSubmit }: WCalOvertimeEditModalAdminLogicProps) => {
    const { t } = useTwinTranslation()
    const { openModal, setOpenModal } = useEditDeleteModal()
    const message = t('successfullyCompleted', 'Realizado correctamente')
    const [data, setData] = useState<WCalOvertimeModelType | null>(null)
    const user = getUserInfo()

    const manageWCalOvertimeStatus = useCallback(async (status: number) => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarOvertime/manageOvertimeRequestByAdmin', {
            id: allRowData?.id,
            status,
            AdminEmployeeId: user?.id
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message, user?.id])

    const getOvertimeData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarOvertime/getWCalOvertimeInstance', { id: allRowData?.id })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getOvertimeData()
    }, [getOvertimeData])

    return { manageWCalOvertimeStatus, data, openModal, setOpenModal, getOvertimeData, user }
}

interface ModalCancelOvertimeRequestProps extends ModalCancelOvertimeRequestLogicProps { }

const ModalCancelOvertimeRequest: React.FC<ModalCancelOvertimeRequestProps> = ({ onSubmit, setOpened, allRowData, user }) => {
    const { cancelOvertime } = useModalCancelOvertimeRequestLogic({ onSubmit, setOpened, allRowData, user })
    const { t } = useTwinTranslation()

    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={cancelOvertime} translations={{
            title: t('sureCancelWCalOvertimeRequest', '¿Seguro que quieres cancelar estas horas extra?'),
            subtitle: t('onceCanceledOvertimeNotRecoverM', 'Una vez canceladas no podrás recuperarlas'),
            buttonAccept: t('cancel', 'Cancelar'),
            buttonCancel: t('return', 'Volver')
        }} />
    )
}
interface ModalCancelOvertimeRequestLogicProps extends ModalEditComponentProps {
    user: UserModel | undefined
}

const useModalCancelOvertimeRequestLogic = ({ onSubmit, setOpened, allRowData, user }: ModalCancelOvertimeRequestLogicProps) => {
    const { t } = useTwinTranslation()

    const message = t('successfullyCompleted', 'Realizado correctamente')

    const cancelOvertime = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarOvertime/manageOvertimeRequestByAdmin', {
            id: allRowData?.id,
            status: 2,
            AdminEmployeeId: user?.id
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message, user?.id])

    return { cancelOvertime }
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees, employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)
const employeesConnectLoading = withLoading(WCalOvertimeEditModalAdmin, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])
const WCalOvertimeEditModalAdminConnect = employeesConnect(employeesConnectLoading)

export default WCalOvertimeEditModalAdminConnect