import {faArrowTurnDownRight, faComment, faUser } from '@fortawesome/pro-light-svg-icons'
import { CampaignCustomerActivityDataTy, CampaignCustomerActivityTy } from './types'
import { CampaignCustomerModelTypeExtended } from '../types'
import { CampaignCustomerTabsInfoHeader, CampaignCustomerTabsInfoHeaderProps } from '../CampaignCustomerComponents'
import TwinIcon, { TwinIconProp } from '../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinCommentBox } from '../../../baseComponents/TwinComment'

interface CampaignCustomerActivityTabProps {
    campaignCustomerData: CampaignCustomerModelTypeExtended
    getCampaignCustomer: () => Promise<void>
}

const CampaignCustomerActivityTab: React.FC<CampaignCustomerActivityTabProps> = ({ campaignCustomerData, getCampaignCustomer }) => {
    const renderThis: JSX.Element[] = []
    const campaignCustomerActivityData: CampaignCustomerActivityDataTy[] = []

    const campaignCustomerComments = campaignCustomerData?.CampaignCustomerComments || []
    for (const element of campaignCustomerComments) {
        campaignCustomerActivityData.push({
            type: 'comment',
            data: element,
            date: element.createdAt
        })
    }

    const campaignCustomerStatusHistories = campaignCustomerData?.CampaignCustomerStatusHistories || []
    for (const element of campaignCustomerStatusHistories) {
        campaignCustomerActivityData.push({
            type: 'status',
            data: element,
            date: element.createdAt
        })
    }

    campaignCustomerActivityData.sort((a, b) => {
        return new Date(b.date) > new Date(a.date) ? 1 : -1
    })

    campaignCustomerActivityData.push({
        type: 'createdAt',
        data: {},
        date: campaignCustomerData.createdAt
    })

    for (const key in campaignCustomerActivityData) {
        const element = campaignCustomerActivityData[key]
        renderThis.push(<CampaignCustomerActivityItem item={element} getData={getCampaignCustomer} key={key} />)
    }
    return (
        <div className='grid mt-25 flex-auto overflow-auto campaign_customer_activity_tab'>
            <div className='campaign_customer_activity_line_parent'>
                <div className='campaign_customer_activity_line'></div>
            </div>
            <div className='flex flex-col flex-auto gap-20 campaign_customer_activity_tab_inner'>
                {renderThis}
            </div>
        </div>
    )
}

interface CampaignCustomerComponentProps {
    item: CampaignCustomerActivityDataTy
    getData: () => Promise<void>
}

interface CampaignCustomerActivityItemProps {
    item: CampaignCustomerActivityDataTy
    getData: () => Promise<void>
}

const CampaignCustomerActivityItem: React.FC<CampaignCustomerActivityItemProps> = ({ item, ...rest }) => {
    const componentsTypeDict: Record<CampaignCustomerActivityTy, React.FC<CampaignCustomerComponentProps>> = {
        'createdAt': CampaignCustomerCreatedCampaignComponent,
        'comment': CampaignCustomerCommentComponent,
        'status': CampaignCustomerHistoryComponent,
    }
    const Component = componentsTypeDict[item.type]
    return (
        <Component item={item} {...rest} />
    )
}

const CampaignCustomerCreatedCampaignComponent: React.FC<CampaignCustomerComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CampaignCustomerActivityComponent nameTrigger='' icon={faUser} headerText={t('addedCustomerToCampaign', 'Se ha añadido el cliente a la campaña')} date={item.date} showImage={false} tooltipName='creationProject' />
    )
}

const CampaignCustomerHistoryComponent: React.FC<CampaignCustomerComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CampaignCustomerActivityComponent id={item.data.EmployeeId} icon={faArrowTurnDownRight} mainColor={item?.data?.CampaignStatus?.color} headerText={t('changedStatusToSm', 'Cambio de estado a')} image={item?.data?.Employee?.profile_image} date={item.data.createdAt} secondText={item?.data?.CampaignStatus?.name} tooltipName={'statusChangedEmployeeActivityTab'} nameTrigger={item?.data?.Employee?.fullname_short} />
    )
}

const CampaignCustomerCommentComponent: React.FC<CampaignCustomerComponentProps> = ({ item, getData }) => {
    const { deleteCampaignCustomerComment, updateCampaignCustomerComment, } = useCampaignCustomerCommentComponentLogic({ getData })
    const { t } = useTwinTranslation()
    return (
        <CampaignCustomerActivityComponent id={item.data.id} icon={faComment} tooltipName={'commentEmployeeActivityTab'} headerText={t('newComment', 'Nuevo comentario')} image={item.data.Employee?.profile_image} date={item.date} nameTrigger={item.data.Employee.fullname_short} >
            <TwinCommentBox id={item.data.id} downloadFileURL='/api/app/customer/campaign/customer/comment/getCampaignCustomerCommentInstanceFile' comment={item.data.message || ''} deleteComment={deleteCampaignCustomerComment} posArr={item.data.id} fieldComment='message' updateComment={updateCampaignCustomerComment} EmployeeId={item.data?.Employee?.id} file_url={item?.data?.file_url} />
        </CampaignCustomerActivityComponent>
    )
}

interface CampaignCustomerCommentComponentLogicProps {
    getData: () => Promise<void>
}

const useCampaignCustomerCommentComponentLogic = ({ getData }: CampaignCustomerCommentComponentLogicProps) => {

    const deleteCampaignCustomerComment = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/deleteCampaignCustomerComment', { id })
        if (result) {
            await getData()
        }
    }, [getData])
    const updateCampaignCustomerComment = useCallback(async (id: number, message: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/updateCampaignCustomerComment', { id, message })
        if (result) {
            await getData()
        }
    }, [getData])
    return { deleteCampaignCustomerComment, updateCampaignCustomerComment }
}

interface CampaignCustomerActivityComponentProps extends CampaignCustomerTabsInfoHeaderProps {
    icon: TwinIconProp
    mainColor?: string
    children?: React.ReactNode
    id?: number
    nameTrigger: string
    tooltipName: string
}

const CampaignCustomerActivityComponent: React.FC<CampaignCustomerActivityComponentProps> = ({ mainColor, icon, children, ...header }) => {
    return (
        <div className='flex flex-col flex-auto gap-10 relative campaign_customer_activity_component'>
            <div className='flex items-center'>
                <div className=' flex items-center rounded-full px-8 py-15 border bg-white mr-14 campaign_customer_activity_component_inner' style={{ borderColor: mainColor || '#D6D6D6' }}>
                    <TwinIcon icon={icon} className='w-16 h-16 ' style={{ color: mainColor || '#43BAA5' }}></TwinIcon>
                </div>
                <CampaignCustomerTabsInfoHeader {...header} />
            </div>
            {
                children ?
                    <div className='flex flex-col flex-auto ml-48'>
                        {children}
                    </div>
                    : null
            }
        </div>
    )
}

export default CampaignCustomerActivityTab