import { CPChecklistSectionModelType } from '@teinor/erp/types/company/customer/customerProject/cPChecklist/cPChecklistSection'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import CPChecklistTask from '../CPChecklistTask'
import { SetCustomerProjectDataTy } from '../../types'
import { permissionCheck } from '../../../../../baseComponents/PermissionChecker/function'
import { getUserPermissions } from '../../../../../utils/reducers/getters'
import ChecklistSection from '../../../../../baseComponents/Checklist/ChecklistSection'

interface CPChecklistSectionProps extends CPChecklistSectionLogicProps {
    addSection: () => void
}

const CPChecklistSection: React.FC<CPChecklistSectionProps> = ({ section, addSection, ...logic }) => {
    const { updateNameSection, addCPChecklistTask, deleteCPChecklistSection, userPermission } = useCPChecklistSectionLogic({ section, ...logic })
    const renderThis: JSX.Element[] = []
    if (section.CPChecklistTasks?.length) {
        for (const key in section.CPChecklistTasks) {
            const task = section.CPChecklistTasks[key]
            renderThis.push(<CPChecklistTask key={task.id} posTask={parseInt(key)} task={task} addTask={addCPChecklistTask} {...logic} />)
        }
    }
    return (
        <div className='mb-30'>
            <ChecklistSection section={section} userPermission={userPermission} onChangeSection={updateNameSection} onDeleteSection={deleteCPChecklistSection} onAddTask={addCPChecklistTask} addSection={addSection} />
            {renderThis.length ?
                <div className='ml-16'>
                    <div className='border-b border-gray-EE' />
                    {renderThis}
                </div>
                :
                null
            }
        </div>
    )
}

interface CPChecklistSectionLogicProps {
    posChecklist: number
    posSection: number
    CustomerProjectId: number
    section: CPChecklistSectionModelType
    setCustomerProjectData: SetCustomerProjectDataTy
    refChecklistSection: React.MutableRefObject<any>
}

const useCPChecklistSectionLogic = ({ section, setCustomerProjectData, posChecklist, posSection, refChecklistSection, CustomerProjectId }: CPChecklistSectionLogicProps) => {
    const userPermission = getUserPermissions('customer.projects.checklists')

    const handleChangeNameSection = useCallback((value: string) => {
        setCustomerProjectData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            const checklist = copyOld.CPChecklists[posChecklist]
            checklist.CPChecklistSections[posSection].name = value
            return { ...copyOld }
        })
    }, [setCustomerProjectData, posChecklist, posSection])

    const updateNameSection = useCallback(async (value: string) => {
        if (value !== section.name && permissionCheck(userPermission, 'update')) {
            handleChangeNameSection(value)
            await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/updateCPChecklistSection', { id: section.id, name: value, CPChecklistId: section.CPChecklistId })
        }
    }, [section.id, section.name, section.CPChecklistId, handleChangeNameSection, userPermission,])

    const addCPChecklistTask = useCallback(async () => {
        if (permissionCheck(userPermission, 'update')) {
            const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/task/createCPChecklistTask', { CPChecklistSectionId: section.id, name: '', CustomerProjectId })
            if (result) {
                setCustomerProjectData((old) => {
                    if (!old) {
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    const checklist = copyOld.CPChecklists[posChecklist]
                    const mySection = checklist.CPChecklistSections[posSection]
                    if (!mySection?.CPChecklistTasks) {
                        mySection.CPChecklistTasks = []
                    }
                    mySection.CPChecklistTasks.push({ ...result })
                    return { ...copyOld }
                })
                setTimeout(() => {
                    refChecklistSection.current?.children[posSection]?.lastChild?.lastChild?.getElementsByClassName('input_change_checklist_task_name')[0]?.getElementsByClassName('rectangular_textarea')[0]?.children[0].focus()
                }, 100)
            }
        }

    }, [section.id, setCustomerProjectData, posChecklist, posSection, userPermission, CustomerProjectId, refChecklistSection])

    const deleteCPChecklistSection = useCallback(async () => {
        if (permissionCheck(userPermission, 'delete')) {
            const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/deleteCPChecklistSection', { id: section.id, CustomerProjectId })
            if (result) {
                setCustomerProjectData((old) => {
                    if (!old) {
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    const checklist = copyOld.CPChecklists[posChecklist]
                    checklist.CPChecklistSections.splice(posSection, 1)
                    return { ...copyOld }
                })
            }
        }
    }, [section.id, setCustomerProjectData, posChecklist, posSection, userPermission, CustomerProjectId])

    return { updateNameSection, addCPChecklistTask, deleteCPChecklistSection, userPermission }
}
export default CPChecklistSection