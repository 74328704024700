import { Fragment } from 'react'
import { RenderBalanceHours, RenderDateAndWeekDay, RenderEntryOutWork, RenderEntryOutWorkPermitted, RenderHoursToWork, RenderIconBalanceHours, RenderIconBalanceHoursPermitted, RenderIconShowLocationTimetables, RenderOvertimeHours, RenderWorkedHours, RenderWorkingCenterEntry, RenderWorkingTime } from './Subcomponents'
import { timeControlEmployeeMonthRowRender } from './functions'
import { EmployeeJourneyTyExtended, EmployeTTShowPermittedTimeInversedKeys } from '../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { TableComponentProps } from '../../../baseComponents/TwinTable/BaseScreenTable/types'
import { VirtualTableListingStateLess } from '../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import useEditDeleteModal from '../../../utils/hooks/useEditDeleteModal'
import { ModalShowTimetablesLocation } from './Subcomponents/ModalShowTimetablesLocation'
import { getConfigParam, getModulesBuyed } from '../../../utils/reducers/getters'
import { haveModuleBuyed } from '../../../utils/globals'

type TableControlMonthWorkProps = TableComponentProps & {
    data: EmployeeJourneyTyExtended[] | null
    showPermittedTime?: EmployeTTShowPermittedTimeInversedKeys
    haveOvertimeHours: boolean
}

const TableControlMonthWork: React.FC<TableControlMonthWorkProps> = ({ data, showPermittedTime, haveOvertimeHours }) => {
    const { t } = useTwinTranslation()

    const permittedTime = showPermittedTime === 'efective_hours' ? true : false
    const {openModal, setOpenModal} = useEditDeleteModal()
    const punchInWithWorkingCenter = getConfigParam('punchInWithWorkingCenter') === 'on'
    const haveWorkingCalendarFullModule = haveModuleBuyed(['working_calendar_full'], getModulesBuyed())
    let extraColumns: ColumnTableSchema<EmployeeJourneyTyExtended> = []
    let extraOvertimeColumn: ColumnTableSchema<EmployeeJourneyTyExtended> = []
    if (punchInWithWorkingCenter) {
        extraColumns.push({
            id: 'WorkingCenter_name',
            dataKey: 'timetables',
            label: t('workingCenter', 'Centro de trabajo'),
            customRender: (parameterValue, allRowData) => <RenderWorkingCenterEntry {...{ allRowData }} permittedHours={permittedTime} />,
            minWidth: 180,
        })
    }
    if (haveWorkingCalendarFullModule || haveOvertimeHours) {
        extraOvertimeColumn.push({
            id: 'totalOvertimeMins',
            dataKey: 'totalOvertimeMins',
            label: t('OvertimeShort', 'H. extra'),
            headerClassName: 'flex justify-center items-center',
            className: 'flex justify-center items-center',
            customRender: (parameterValue, allRowData) => <RenderOvertimeHours  {...{ allRowData }} />,
            width: 100
        },)
    }
    const columns: ColumnTableSchema<EmployeeJourneyTyExtended> = [
        {
            id: 'day',
            dataKey: 'date',
            label: t('day', 'Día'),
            customRender: (parameterValue) => <RenderDateAndWeekDay date={parameterValue} />,
            width: 115
        },
        {
            id: 'workingTime',
            dataKey: 'journey',
            label: t('workingTime', 'Horario'),
            customRender: (parameterValue, allRowData) => <RenderWorkingTime {...{ allRowData }} />,
            minWidth: 290,
        },
    ]
    if(permittedTime){
        columns.push(...[
            {
                id: 'workingTime_permitted_start',
                dataKey: 'journey',
                label: t('entry', 'Entrada'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWorkPermitted entry={true} {...{ allRowData }} />,
                width: 80
            },
            {
                id: 'workingTime_permitted_exit',
                dataKey: 'journey',
                label: t('exit', 'Salida'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWorkPermitted entry={false} {...{ allRowData }} />,
                width: 80
            },
            ...extraColumns,
            {
                id: 'workedHours_permitted',
                dataKey: 'timetables',
                label: t('workedHours', 'Horas trabajadas'),
                customRender: (parameterValue, allRowData) => <RenderWorkedHours  {...{ allRowData, permittedTime }} />,
                width: 150
            },
            {
                id: 'hoursToWork',
                dataKey: 'journey',
                label: t('baseHours', 'Horas base'),
                customRender: (parameterValue, allRowData) => <RenderHoursToWork {...{ allRowData }} />,
                width: 120
            },
            ...extraOvertimeColumn,
            {
                id: 'balance_permitted',
                dataKey: 'timetables',
                label: t('balance', 'Balance'),
                headerClassName: 'flex justify-center items-center',
                className: 'flex justify-center items-center',
                customRender: (parameterValue, allRowData) => <RenderBalanceHours  {...{ allRowData, permittedTime }} />,
                width: 90
            },
            {
                id: 'icons_permitted',
                dataKey: 'timetables',
                label: '',
                customRender: (parameterValue, allRowData) => <RenderIconBalanceHoursPermitted {...{ allRowData }} />,
                minWidth: 210,
            },
        ] as ColumnTableSchema<EmployeeJourneyTyExtended>)
    }else{
        columns.push(...[
            {
                id: 'workingTime_start',
                dataKey: 'journey',
                label: t('entry', 'Entrada'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={true} {...{ allRowData }} />,
                width: 80
            },
            {
                id: 'workingTime_exit',
                dataKey: 'journey',
                label: t('exit', 'Salida'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={false} {...{ allRowData }} />,
                width: 80
            },
            ...extraColumns,
            {
                id: 'workedHours',
                dataKey: 'timetables',
                label: t('workedHours', 'Horas trabajadas'),
                customRender: (parameterValue, allRowData) => <RenderWorkedHours  {...{ allRowData, permittedTime }} />,
                width: 150
            },
            {
                id: 'hoursToWork',
                dataKey: 'journey',
                label: t('baseHours', 'Horas base'),
                customRender: (parameterValue, allRowData) => <RenderHoursToWork {...{ allRowData }} />,
                width: 120
            },
            ...extraOvertimeColumn,
            {
                id: 'balance',
                dataKey: 'timetables',
                label: t('balance', 'Balance'),
                headerClassName: 'flex justify-center items-center',
                className: 'flex justify-center items-center',
                customRender: (parameterValue, allRowData) => <RenderBalanceHours  {...{ allRowData, permittedTime }} />,
                width: 90
            },
            {
                id: 'icons',
                dataKey: 'timetables',
                label: '',
                customRender: (parameterValue, allRowData) => <RenderIconBalanceHours {...{ allRowData }} />,
                minWidth: 210,
            },
        ] as ColumnTableSchema<EmployeeJourneyTyExtended>)
    }
    columns.push(...[
        {
            id: 'icon_timetables',
            dataKey: 'date',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <RenderIconShowLocationTimetables date={parameterValue} setOpenModal={setOpenModal} allRowData={allRowData} />
        },] as ColumnTableSchema<EmployeeJourneyTyExtended>)

    return (
        <Fragment>
            <div className={'flex flex-col flex-auto h-1'}>
                <div className='flex-auto'>
                    <VirtualTableListingStateLess {...{ columns: columns }} setSelectedColumnsAndFilters={() => { }} tableData={data || []} headerHeight={48} name='monthWorkDays' rowRenderer={timeControlEmployeeMonthRowRender} />
                </div>
            </div>
            {openModal?.type === 'edit' ? <ModalShowTimetablesLocation setOpenModal={setOpenModal} data={data} openModal={openModal}/> : null}
        </Fragment>
    )
}

export default TableControlMonthWork