import { FormRender } from '../../../forms/FormRenderer/types'
import { CustomerModelTypeAndAddress } from '../../../specificComponents/Customers/types'
import { getDictionaryStates } from '../../globals/countries'
import { getDictionaryCountries } from '../../reducers/getters'
import useCountryState from '../useCountry'
import useTwinTranslation from '../useTwinTranslation'

interface CreateCustomerAddressProps {
    createAddress: boolean
}

const useCreateCustomerAndAddress = ({ createAddress }: CreateCustomerAddressProps) => {
    const { country, setCountry } = useCountryState({ defaultCountry: '' })
    const {t} = useTwinTranslation()
    const addressFields:  FormRender<CustomerModelTypeAndAddress> =[]
    if(createAddress){
        addressFields.push(
        {
            cols: 2,
            elements: [
            {
                name: 'nameAddress',
                label: t('nameAddress', 'Nombre de la dirección'),
                component: 'InputWithLabelMargin',
                type: 'text',
                required: true
            },
            {
                name: 'name_to',
                label: t('recipientName', 'Nombre del receptor'),
                component: 'InputWithLabelMargin',
                type: 'text',
                required: true
            },
            {
                name: 'surname_to',
                label: t('recipientSurname', 'Apellido del receptor'),
                component: 'InputWithLabelMargin',
                type: 'text'
            },
        ],
    },
    {
        cols: 2,
            title: t('address', 'Dirección'),
                elements: [
                    {
                        name: 'address',
                        label: t('address', 'Dirección'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'additional_address',
                        label: t('additional_address', 'Dirección adicional'),
                        component: 'InputWithLabelMargin',
                        type: 'text'
                    },
                    {
                        name: 'postal_code',
                        label: t('postalCode', 'Código Postal'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'city',
                        label: t('city', 'Ciudad'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'country',
                        label: t('country', 'País'),
                        component: 'CustomSelectWithSearchBar',
                        items: getDictionaryCountries(),
                        required: true,
                        value: '',
                        onChange: (value: string) => setCountry(value)
                    },
                    {
                        name: 'state',
                        label: t('state', 'Provincia'),
                        component: 'CustomSelectWithSearchBar',
                        sortItems: true,
                        items: getDictionaryStates(country),
                        required: true,
                        value: ''
                    },
                    {
                        name: 'lat',
                        label: t('latitude', 'Latitud'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                    {
                        name: 'long',
                        label: t('longitude', 'Longitud'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                ]
            }
        )
    }
    return { addressFields }
}


export default useCreateCustomerAndAddress