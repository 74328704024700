import { IconDefinition } from '@fortawesome/fontawesome-common-types/index'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Modify } from '@teinor/erp'
import TwinToolTip from '../TwinToolTip'

export type TwinIconProp = IconDefinition

type TwinIconProps = Modify<FontAwesomeIconProps, {
    icon: TwinIconProp
}>

const TwinIcon: React.FC<TwinIconProps> = ({icon,...rest}) => {
    return (
        <FontAwesomeIcon icon={icon as any} {...rest} />
    )
}

export interface TwinIconToolTipProps {
    message: string
    id: string
    children?: React.ReactNode
    icon?: TwinIconProp
    classNameIcon?: string
    onClick?: () => void
    className?: string
    subRef?: any
}

export const TwinIconToolTip: React.FC<TwinIconToolTipProps> = ({ message, id, children, icon, onClick, classNameIcon, className, subRef }) => {
    return (
        <div ref={subRef} className={'relative ' + (className || '')}>
            <TwinToolTip id={id} place='top' content={message} />
            <div>
                {children}
                {icon ? <TwinIcon data-tooltip-id={id} className={'cursor-pointer px-1 ' + (classNameIcon || '')} icon={icon} onClick={onClick} /> : null}
            </div>
        </div>
    )
}


export default TwinIcon