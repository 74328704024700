import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import GenerateTimeControlQr from '../../../../../../specificComponents/WCal/GenerateTimeControlQr'


interface GenerateQrCodesProps extends ComponentWithPermissions { }

const GenerateQrCodes: React.FC<GenerateQrCodesProps> = () => {
    return (
        <div className='py-45 px-40 flex flex-auto big-layout'>
            <WhiteBox className='flex flex-col flex-auto px-40 py-50'>
                <GenerateTimeControlQr/>
            </WhiteBox>
        </div>
    )
}

export default GenerateQrCodes