import { useState, useCallback, useEffect } from 'react'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { SetCustomerProjectDataTy } from '../../types'
import { faCopy, faPlus } from '@fortawesome/pro-light-svg-icons'
import PermissionChecker from '../../../../../baseComponents/PermissionChecker'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { UserPermissionsSingle } from '../../../../../baseComponents/CreateRoutering/types'
import { CPChecklistModelType } from '@teinor/erp/types/company/customer/customerProject/cPChecklist'
import { ButtonAddChecklistSectionBase } from '../../../../../baseComponents/Checklist/ButtonAddChecklistSection'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import { ButtonPrimary } from '../../../../../baseComponents/Button'
import CustomSelect from '../../../../../forms/CustomSelect'

interface ButtonsAddSectionCopyTChecklistProps {
    addSection: () => void
    userPermission: UserPermissionsSingle | false
    posChecklist: number
    myChecklist: CPChecklistModelType
    setCustomerProjectData: SetCustomerProjectDataTy
}

const ButtonsAddSectionCopyTChecklist: React.FC<ButtonsAddSectionCopyTChecklistProps> = ({userPermission, addSection, posChecklist, myChecklist, setCustomerProjectData}) => {
    const { opened, handleOpen } = useButtonsAddSectionCopyTChecklistLogicProps()
    const {t} = useTwinTranslation()
    return (
        <PermissionChecker userPermissions={userPermission} permission='update'>
            {opened?
                <CopyTChecklist posChecklist={posChecklist} myChecklist={myChecklist} setCustomerProjectData={setCustomerProjectData} handleOpen={handleOpen} />
            :
                null
            }
            <div className='flex items-center w-full'>
                <ButtonAddChecklistSectionBase className='w-full ' buttonText={t('addSection', 'Añadir sección')} icon={faPlus} addSection={addSection} />
                <ButtonAddChecklistSectionBase className='w-full border-white border' buttonText={t('copyList', 'Copiar lista')} icon={faCopy} addSection={handleOpen} />
            </div>
        </PermissionChecker>
    )
}

const useButtonsAddSectionCopyTChecklistLogicProps = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const handleOpen = useCallback(() => {
        setOpened((old) => {
            if (!old) {
                return true
            }
            return null
        })
    }, [setOpened])
    return { opened, handleOpen }
}


interface CopyTChecklistProps extends CopyTChecklistLogicProps {}

const CopyTChecklist: React.FC<CopyTChecklistProps> = ({...logic}) => {
    const { dictTChecklist, copyTChecklistId, handleCopyTChecklistId, copyCPList } = useCopyTChecklistLogic({...logic})
    const {t} = useTwinTranslation()
    return (
        <div className='flex items-end justify-center'>
            <CustomSelect className='w-200' label={t('copyListFromTemplate', 'Copiar lista de una plantilla')} items={dictTChecklist || {}} value={copyTChecklistId} onChange={handleCopyTChecklistId} />
            <ButtonPrimary className='ml-10' onClick={copyCPList}>
                <TwinTrans transKey='copyList'>Copiar lista</TwinTrans>
            </ButtonPrimary>
        </div>
    )
}

interface CopyTChecklistLogicProps {
    handleOpen: () => void
    posChecklist: number
    myChecklist: CPChecklistModelType
    setCustomerProjectData: SetCustomerProjectDataTy
}

const useCopyTChecklistLogic = ({ posChecklist, myChecklist, setCustomerProjectData, handleOpen }: CopyTChecklistLogicProps) => {
    const [copyTChecklistId, setCopyTChecklistId] = useState<number>(0)
    const [dictTChecklist, setDictTChecklist] = useState<TwinDictionary | null>(null)

    const copyCPList = useCallback(async () => {
        const res = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/copyTemplateToExistingCPChecklist', { TChecklistId: parseInt(String(copyTChecklistId)), CPChecklistId: myChecklist.id })
        if (res) {
            setCustomerProjectData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.CPChecklists[posChecklist] = res
                return { ...copyOld }
            })
            handleOpen()
        }
    }, [setCustomerProjectData, copyTChecklistId, posChecklist, myChecklist.id, handleOpen])

    const getTChecklists = useCallback(async () => {
        const tChecklists = await twinFetchPostJSON('/api/app/config/templateChecklist/getAllTChecklists', {})
        setDictTChecklist(dictionaryComplexFromJsonArr(tChecklists))
    }, [setDictTChecklist])

    const handleCopyTChecklistId = useCallback((value: number) => {
        setCopyTChecklistId(value)
    }, [setCopyTChecklistId])

    useEffect(() => {
        getTChecklists()
    }, [getTChecklists])

    return { dictTChecklist, handleCopyTChecklistId, copyTChecklistId, copyCPList }
}


export default ButtonsAddSectionCopyTChecklist