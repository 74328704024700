import { LittleModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { CampaignCustomerModelType } from '@teinor/erp/types/company/customer/campaign/campaignCustomer'
import { useParams } from 'react-router'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { useNotSelectedCampaignCustomer } from '../../../../../../../../../../utils/hooks/customer/useNotSelectedCampaignCustomer'

interface ModalCreateCustomerB2BCampaignProps extends ModalEditComponentProps {}

const ModalCreateCustomerB2BCampaign: React.FC<ModalCreateCustomerB2BCampaignProps> = ({ userPermissions, ...rest}) => {
    const {t} = useTwinTranslation()
    const { id } = useParams()
    const { campaigns } = useNotSelectedCampaignCustomer({id: id || ''})
    const fields: FormRender<CampaignCustomerModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'CampaignId',
                    label: t('campaign', 'Campaña'),
                    component: 'CustomSelectWithSearchBar',
                    items: campaigns || {},
                    required: true
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                }
            ]
        }
    ]
    return (
        <LittleModalCreateEdit fields={fields} translations={{
            title: t('addCustomerToCampaign', 'Añadir cliente a la campaña'),
            button: t('add', 'Añadir')
        }} haveButtonPermissions={permissionCheck(userPermissions|| false, 'create')} url={'/api/app/customer/campaign/customer/createCampaignCustomer'} {...rest} />
    )
}

export default ModalCreateCustomerB2BCampaign