import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { VirtualTableListingStateLess } from '../../../TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString, CustomRenderStringAndColorNull } from '../../../TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { CampaignModelType } from '@teinor/erp/types/company/customer/campaign'
import { getUserInfo } from '../../../../utils/reducers/getters'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import TwinIcon from '../../../TwinIcon'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import ModalEditCustomerProject from '../../../../specificComponents/Customers/ModalEditCustomerProject'
import useEditDeleteModal from '../../../../utils/hooks/useEditDeleteModal'

interface DSBMyCustomerProjectsTableProps extends TableMyCustomerProjectsProps {
    title: string
}

const DSBMyCustomerProjectsTable: React.FC<DSBMyCustomerProjectsTableProps> = ({ title, start_date, end_date }) => {
    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <h3 className='pb-15'>{title}</h3>
            <TableMyCustomerProjects start_date={start_date} end_date={end_date} />
        </div>
    )
}

interface TableMyCustomerProjectsProps extends TableMyCustomerProjectsLogicProps { }

const TableMyCustomerProjects: React.FC<TableMyCustomerProjectsProps> = ({ start_date, end_date }) => {
    const { t } = useTwinTranslation()
    const { data, openModal, setOpenModal } = useTableMyCustomerProjectsLogic({ start_date, end_date })
    const columns: ColumnTableSchema<CustomerProjectModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            customRender: (parameterValue) => <CustomRenderString value={parameterValue} />,
        },
        {
            id: 'CustomerName',
            dataKey: 'Customer',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.Customer?.name || ''} />,
        },
        {
            id: 'CurrentCProjectStatusId',
            dataKey: 'CurrentCProjectStatusId',
            label: t('status', 'Estado'),
            customRender: (_parameterValue, allRowData) => <CustomRenderStringAndColorNull value={allRowData?.CurrentCProjectStatus?.name} color={allRowData?.CurrentCProjectStatus?.color} />,
        },
        {
            id: 'limit_date',
            dataKey: 'limit_date',
            label: t('limitDate', 'Fecha límite'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'id',
            dataKey: 'id',
            label: '',
            customRender: (parameterValue) => <TwinIcon icon={faChevronRight} onClick={() => { setOpenModal({ type: 'edit', allRowData: { id: parameterValue } }) }} className='cursor-pointer hover:text-green-43' size='lg' />,
            width: 50
        }
    ]

    return (
        <div className='flex-auto'>
            <VirtualTableListingStateLess tableData={data} rowHeight={48} headerHeight={48} name='DSBMyCustomerProjectsTableDashboard' columns={columns} setSelectedColumnsAndFilters={() => { }} updateEmployeeParameter={false} />
            {openModal?.type === 'edit' ?
                <ModalEditCustomerProject allRowData={openModal.allRowData} setOpened={() => setOpenModal(null)} onSubmit={() => { }} />
                : null
            }
        </div>
    )
}

interface TableMyCustomerProjectsLogicProps {
    start_date: Date
    end_date: Date
}

const useTableMyCustomerProjectsLogic = ({ start_date, end_date }: TableMyCustomerProjectsLogicProps) => {
    const [data, setData] = useState<CampaignModelType[] | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()

    const user = getUserInfo()
    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjectsComplete', {
            where: {
                'CustomerProject.limit_date': {
                    '$betweenN': [start_date, end_date]
                },
                'Employees->CustomerProjectEmployee.EmployeeId': user?.Employee?.id
            }
        })
        if (result) {
            setData(result)
        }
    }, [setData, start_date, end_date, user?.Employee?.id])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, openModal, setOpenModal }
}

export default DSBMyCustomerProjectsTable