import { getAllPossiblePermissions, GroupPermissionsComputed, PermissionThreeKeys, whatCanYouDo, whatCanYouDoInversed, WhatCanYouDoInversedKeys, WhatCanYouDoKeys } from '@teinor/erp/permissions'
import { ErpModules, ErpSingleModule } from '@teinor/erp/modules'
import { RouteChild, Routes, UserPermissionsObject, UserPermissionsSingle } from './types'
import { haveModuleBuyed } from '../../utils/globals'
import NullComponent from '../NullComponent'

export const joinAndRemoveDuplicatedBars = (parentsLink: string, elementLink: string) => {
    const startLink = parentsLink + elementLink
    const startLinkSplited = startLink.split('/')
    const arrayForResult: string[] = []
    for (const sublink of startLinkSplited) {
        const isNotEmptySpace = sublink.length
        if (isNotEmptySpace) {
            arrayForResult.push(sublink)
        }
    }
    return '/'+ arrayForResult.join('/')
}

export const cleanRoutes = (routes: Routes, groupPermissions?: GroupPermissionsComputed, modulesBuyed?: ErpModules, modulesDemo?: ErpModules, moduleInDemo?: ErpSingleModule) => {
    const routeParsed : Routes = []
    if ((!modulesBuyed || modulesBuyed?.length === 0) && (!modulesDemo || modulesDemo?.length === 0) && !moduleInDemo) {
        return routeParsed
    }
    for (const route of routes) {
        if (checkIfHaveToAppendRoute(route, groupPermissions, modulesBuyed)) {
            let myDemoModule = moduleInDemo || false
            if (route?.modules?.length && modulesDemo?.length) {
                if (!myDemoModule) {
                    for (const element of route?.modules) {
                        myDemoModule = modulesDemo?.includes(element) ? element : (moduleInDemo || false)
                        if(myDemoModule){
                            break
                        }
                    }
                }
            }
            if (route.childs) {
                route.childs = cleanRoutes(route.childs, groupPermissions, modulesBuyed, modulesDemo, myDemoModule || undefined)
            }
            if (route.childs?.length || route.component) {
                routeParsed.push({
                    ...route,
                    component: route.component || NullComponent,
                    moduleInDemo: myDemoModule || undefined
                })
            }
        }
    }
    return routeParsed
}

export const checkIfHaveToAppendRoute = (route: RouteChild, groupPermissions?: GroupPermissionsComputed, modulesBuyed?: ErpModules) => {
    if (!haveModuleBuyed(route.modules, modulesBuyed)) {
        return false
    }
    if (!haveRoutePermissions(route, groupPermissions)) {
        return false
    }
    return true
}

const haveRoutePermissions = (route: RouteChild, groupPermissions?: GroupPermissionsComputed): boolean => {
    if (!('permissions' in route)) {
        return true
    }
    if (groupPermissions && route.permissions) {
        if (Array.isArray(route.permissions)) {
            let permissions: UserPermissionsObject = {}
            for (const permission of route.permissions) {
                const myPermission = getMaxPermissionByGroupPermissions(permission, groupPermissions)
                if (myPermission.n > 0) {
                    permissions[permission] = myPermission
                }
            }
            route.componentPermissions = permissions
            if (Object.keys(permissions).length) {
                return true
            }
        } else {
            route.componentPermissions = getMaxPermissionByGroupPermissions(route.permissions, groupPermissions)
            if (route.componentPermissions.n > 0) {
                return true
            }
        }
    }
    return false
}

export const getMaxPermissionByGroupPermissions = (permission: PermissionThreeKeys, groupPermissions: GroupPermissionsComputed): UserPermissionsSingle => {
        if (groupPermissions.isRoleAdmin) {
            return { permission: 'delete', n: 4 }
        }
        let major = 0 as WhatCanYouDoInversedKeys
        const allPossibleRoutePermissions = getAllPossiblePermissions(permission)
        for (const permission of allPossibleRoutePermissions) {
            const mypermission = groupPermissions.permissions[permission] as WhatCanYouDoKeys
            const myPermissionN = whatCanYouDo[mypermission] as WhatCanYouDoInversedKeys
            if (myPermissionN > major) {
                major = myPermissionN
            }
        }
        const majorPermission = whatCanYouDoInversed[major] as WhatCanYouDoKeys
        return { permission: majorPermission, n: major }
}