import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, CustomRenderDate, CustomRenderDisplayHoursMinsSecsBySecs, CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import ModalCreateTaskBagOfHours from './ModalCreateTaskBagOfHours'
import { CustomerPayload } from '../../../../../../utils/reducers/customers/customer'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import ModalEditTaskBagOfHours from '../../../../../../specificComponents/ModalEditTaskBagOfHours'


interface TaskBagOfHoursProps extends ComponentWithPermissions { }

const TaskBagOfHours: React.FC<TaskBagOfHoursProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('bagsOfHours', 'Bolsas de horas'),
        newText: t('newBagOfHours', 'Nueva bolsa de horas'),
    }

    return (
        <BaseScreenTable TableComponent={TableTaskBagOfHoursConnect} CreateModalComponent={ModalCreateTaskBagOfHours} EditModalComponent={ModalEditTaskBagOfHours} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableTaskBagOfHoursProps = TableComponentProps & ReduxCustomer & {}

const TableTaskBagOfHours: React.FC<TableTaskBagOfHoursProps> = ({ userPermissions, setTableInstance, setOpened, customers }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<TaskBagOfHoursModelType & CustomerProjectModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'TaskBagOfHours.name'
        },
        {
            id: 'customer',
            dataKey: 'CustomerId',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProject?.Customer?.name} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: customers?.all || {} , label: t('customer', 'Cliente') }
            },
            searchSingleKey: 'CustomerProject.Customer.id',
            searchKey: 'CustomerProject.Customer.name'
        },
        {
            id: 'customerProject',
            dataKey: 'CustomerProject',
            label: t('project', 'Proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProject?.name || ''} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('project', 'Proyecto') }
            },
            searchKey: 'CustomerProject.name',
            sortKey: 'CustomerProject.name',
        },
        {
            id: 'timeLimit',
            dataKey: 'timeLimit',
            label: t('timeLimit', 'Tiempo límite'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs value={parseInt(parameterValue)} />,
            sortable: true
        },
        {
            id: 'remainingTime',
            dataKey: 'remainingTime',
            label: t('remainingTime', 'Tiempo restante'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs className={parseInt(parameterValue)<=0? 'text-red-BA': ''}  value={parseInt(parameterValue)} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelect',
                extraComponentData: {
                    label: t('timeFilter', 'Filtro de tiempo'), items: {
                        'exh': { name: t('exhausteds', 'Agotadas') },
                        '20less': { name: t('less20Remaining', 'Menos 20% restante') },
                        'all': { name: t('all', 'Todos') }
                    }
                }
            },
            generalSearchable: false,
            sortable: true
        },
        {
            id: 'expireDate',
            dataKey: 'expireDate',
            label: t('expireDate', 'Fecha de vencimiento'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'active',
            dataKey: 'active',
            label: t('active', 'Activo'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
            hideColumnByDefault: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: {
                    items: {
                        'true': { name: t('actived', 'Activados') },
                        'false': { name: t('disabled', 'Desactivados') },
                        'all': { name: t('all', 'Todos') }
                    }, label: t('active', 'Activo') }
            },
            searchKey: 'TaskBagOfHours.active',
            generalSearchable: false
        },
        {
            id: 'sharing',
            dataKey: 'sharing',
            label: t('share', 'Compartida'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='taskBagOfHours' columns={columns} getDataFrom='/api/app/task/bagOfHours/getAllTaskBagsOfHours' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

const customersDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

export type ReduxCustomer = ConnectedProps<typeof customersConnect>
const mapCustomersConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const customersConnect = connect(mapCustomersConnector, customersDispatch)

const customersConnectLoading = withLoading(TableTaskBagOfHours, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])

const TableTaskBagOfHoursConnect = customersConnect(customersConnectLoading)

export default TaskBagOfHours