import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import MainContactB2CProfile from './Tabs/MainContactB2CProfile'
import ContactB2CAddresses from './Tabs/ContactB2CAddresses'
import ContactB2COtherInformation from './Tabs/ContactB2COtherInformation'
import ContactB2CBanks from './Tabs/ContactB2CBanks'
import ContactB2CContactsPersons from './Tabs/ContactB2CContactsPersons'
import ContactB2CProjects from './Tabs/ContactB2CProjects'
import ContactB2CGdpr from './Tabs/ContactB2CGdpr'
import ContactB2CFiles from './Tabs/ContactB2CFiles'
import ContactB2CBagOfHours from './Tabs/ContactB2CBagOfHours'
import ContactB2CTasks from './Tabs/ContactB2CTasks'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import ContactB2CNotes from './Tabs/ContactB2CNotes'
import ContactB2CCampaigns from './Tabs/ContactB2CCampaigns'

interface SingleContactB2CProps extends  ComponentWithPermissions {}

const SingleContactB2C: React.FC<SingleContactB2CProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: { id: params.id }, url: '/api/app/customer/getCustomerInstance', variableToCurrentPage: 'name' })
    const { dictCustomerOrigins } = useSingleContactB2CLogic()

    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainContactB2CProfile, text: t('general', 'General'), permission: 'customer.profile.main', extraProps: { dictCustomerOrigins}},
        'other-information': { component: ContactB2COtherInformation, text: t('otherInformation', 'Otra información'), permission: 'customer.profile.main' },
        'address': { component: ContactB2CAddresses, text: t('addresses', 'Direcciones'), permission: 'customer.profile.address' },
        'bank': { component: ContactB2CBanks, text: t('banks', 'Bancos'), permission: 'customer.profile.bank' },
        'contacts': { component: ContactB2CContactsPersons, text: t('contacts', 'Contactos'), permission: 'customer.profile.contactPerson' },
        'projects': { component: ContactB2CProjects, text: t('projects', 'Proyectos'), permission: 'customer.profile.projects' },
        'bagsOfHours': { component: ContactB2CBagOfHours, text: t('bagsOfHours', 'Bolsas de horas'), permission: 'customer.profile.bagOfHours', modules: ['tasks'] },
        'tasks': { component: ContactB2CTasks, text: t('tasks', 'Tareas'), permission: 'customer.profile.tasks', modules: ['tasks'] },
        'campaigns': { component: ContactB2CCampaigns, text: t('campaigns', 'Campaigns'), permission: 'customer.profile.campaign', modules: ['tasks'] },
        'notes': { component: ContactB2CNotes, text: t('notes', 'Notas'), permission: 'customer.profile.notes' },
        'files': { component: ContactB2CFiles, text: t('files', 'Ficheros'), permission: 'customer.profile.files' },
        'gdpr': { component: ContactB2CGdpr, text: t('gdpr', 'RGPD'), permission: 'customer.profile.gdpr' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}



const useSingleContactB2CLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)

    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins }
}

export default SingleContactB2C