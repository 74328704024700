import { useCallback } from 'react'
import { TChecklistTaskModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist/tChecklistSection/tChecklistTask'
import { TChecklistModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getUserPermissions } from '../../../../../../../utils/reducers/getters'
import ChecklistTask from '../../../../../../../baseComponents/Checklist/ChecklistTask'
import { ChangeChecklistTaskTy } from '../../../../../../../baseComponents/Checklist/types'

interface TChecklistTaskProps extends TChecklistTaskLogicProps {
    addTask: ()=>void
 }

const TChecklistTask: React.FC<TChecklistTaskProps> = ({ task, addTask, ...res}) => {
    const { updateTChecklistTask, deleteTChecklistTask, userPermission } = useTChecklistTaskLogic({ task, ...res })
    return (
        <ChecklistTask task={task} userPermission={userPermission} onChangeTask={updateTChecklistTask} onDeleteTask={deleteTChecklistTask} addTask={addTask} />
    )
}

interface TChecklistTaskLogicProps {
    posSection: number
    posTask: number
    task: TChecklistTaskModelType
    setDataChecklist: React.Dispatch<React.SetStateAction<TChecklistModelType>>
}

const useTChecklistTaskLogic = ({ posSection, posTask, task, setDataChecklist }: TChecklistTaskLogicProps) => {
    const userPermission = getUserPermissions('config.customerProject.templateChecklist')

    const handleTChecklistTask: ChangeChecklistTaskTy = useCallback((field, value) => {
        setDataChecklist((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            const mySection = copyOld.TChecklistSections[posSection]
            mySection.TChecklistTasks[posTask][field] = value
            return { ...copyOld }
        })
    }, [posTask, posSection, setDataChecklist])

    const updateTChecklistTask: ChangeChecklistTaskTy = useCallback(async (field, value) => {
        if (value !== task?.[field]) {
            handleTChecklistTask(field, value)
            await twinFetchPostJSON('/api/app/config/templateChecklist/section/task/updateTChecklistTask', { id: task.id, [field]: value })
        }
    }, [task, handleTChecklistTask])

    const deleteTChecklistTask = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/config/templateChecklist/section/task/deleteTChecklistTask', { id: task.id })
        if (result) {
            setDataChecklist((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                if (copyOld.TChecklistSections && copyOld.TChecklistSections.length) {
                    const mySection = copyOld.TChecklistSections[posSection]
                    mySection.TChecklistTasks.splice(posTask, 1)
                }
                return { ...copyOld }
            })
        }
    }, [task.id, setDataChecklist, posTask, posSection])
    return { updateTChecklistTask, deleteTChecklistTask, userPermission }
}

export default TChecklistTask