import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { VirtualTableListingStateLess } from '../../../TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString, CustomRenderStringAndColorNull } from '../../../TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { CampaignCustomerModelType } from '@teinor/erp/types/company/customer/campaign/campaignCustomer'
import useEditDeleteModal from '../../../../utils/hooks/useEditDeleteModal'
import TwinIcon from '../../../TwinIcon'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import ModalEditCampaignCustomer from '../../../../specificComponents/ModalEditCampaignCustomer'
import { getUserPermissions } from '../../../../utils/reducers/getters'

interface DSBAllCampaignsTableProps extends TableAllCampaignsProps {
    title: string
}

const DSBAllCampaignsTable: React.FC<DSBAllCampaignsTableProps> = ({ title, start_date, end_date }) => {
    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <h3 className='pb-15'>{title}</h3>
            <TableAllCampaigns start_date={start_date} end_date={end_date} />
        </div>
    )
}

interface TableAllCampaignsProps extends TableAllCampaignsLogicProps {}

const TableAllCampaigns: React.FC<TableAllCampaignsProps> = ({ start_date, end_date }) => {
    const { t } = useTwinTranslation()
    const { data, openModal, setOpenModal } = useTableAllCampaignsLogic({ start_date, end_date })
    const columns: ColumnTableSchema<CampaignCustomerModelType> = [
        {
            id: 'CampaignName',
            dataKey: 'Campaign',
            label: t('name', 'Nombre'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.Campaign?.name || ''} />,
        },
        {
            id: 'CustomerName',
            dataKey: 'Customer',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.Customer?.name || ''} />,
        },
         {
            id: 'CurrentCampaignStatus',
            dataKey: 'CurrentCampaignStatus',
            label: t('status', 'Estado'),
             customRender: (_parameterValue, allRowData) => <CustomRenderStringAndColorNull value={allRowData?.CurrentCampaignStatus?.name} color={allRowData?.CurrentCampaignStatus?.color} />,
        },
        {
            id: 'date',
            dataKey: 'date',
            label: t('limitDate', 'Fecha límite'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'id',
            dataKey: 'id',
            label: '',
            customRender: (parameterValue) => <TwinIcon icon={faChevronRight} onClick={() => { setOpenModal({ type: 'edit', allRowData: { id: parameterValue } }) }} className='cursor-pointer hover:text-green-43' size='lg' />,
            width: 50
        }
    ]
    return (
        <div className='flex-auto'>
            <VirtualTableListingStateLess tableData={data} rowHeight={48} headerHeight={48} name='DSBAllCampaignsTableDashboard' columns={columns} setSelectedColumnsAndFilters={() => { }} updateEmployeeParameter={false} />
            {openModal?.type === 'edit' ?
                <ModalEditCampaignCustomer allRowData={openModal.allRowData} setOpened={() => setOpenModal(null)} onSubmit={() => { }} userPermissions={getUserPermissions('customer.campaigns.inner') || {n: 1, permission: 'read'}} />
                : null
            }
        </div>
    )
}

interface TableAllCampaignsLogicProps {
    start_date: Date
    end_date: Date
}

const useTableAllCampaignsLogic = ({ start_date, end_date }: TableAllCampaignsLogicProps) => {
    const [data, setData] = useState<TaskModelType[] | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/getAllCampaignCustomers', { 
            where: {
                date: { 
                    '$betweenN': [start_date, end_date]
                }
            }
        })
        if (result) {
            setData(result)
        }
    }, [setData, start_date, end_date])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, openModal, setOpenModal }
}

export default DSBAllCampaignsTable