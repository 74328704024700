import { FormRender } from '../../../forms/FormRenderer/types'
import { CustomerModelTypeShopAndAddress } from '../../../specificComponents/Customers/types'
import { getDictionaryStates } from '../../globals/countries'
import { getDictionaryCountries } from '../../reducers/getters'
import useCountryState from '../useCountry'
import useTwinTranslation from '../useTwinTranslation'

interface CreateCustomerAddressProps {
    createWorkingCenter: boolean
}

const useCreateCustomerB2BWCAndAddress = ({ createWorkingCenter }: CreateCustomerAddressProps) => {
    const { country, setCountry } = useCountryState({ defaultCountry: '' })
    const { t } = useTwinTranslation()
    const workingCenterFields: FormRender<CustomerModelTypeShopAndAddress> = []
    if (createWorkingCenter) {
        workingCenterFields.push(
            {
                cols: 2,
                title: t('workingCenter', 'Centro de trabajo'),
                elements: [
                    {
                        name: 'nameWorkingCenter',
                        label: t('name', 'Nombre'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'responsible',
                        label: t('responsible', 'Responsable'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'address',
                        label: t('address', 'Dirección'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'additional_address',
                        label: t('additional_address', 'Dirección adicional'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                    {
                        name: 'postal_code',
                        label: t('postal_code', 'Código Postal'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'city',
                        label: t('city', 'Ciudad'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'country',
                        label: t('country', 'País'),
                        component: 'CustomSelectWithSearchBar',
                        items: getDictionaryCountries(),
                        value: '',
                        onChange: (value: string) => setCountry(value),
                        required: true
                    },
                    {
                        name: 'state',
                        label: t('state', 'Provincia'),
                        component: 'CustomSelectWithSearchBar',
                        sortItems: true,
                        items: getDictionaryStates(country),
                        value: '',
                        required: true
                    },
                    {
                        name: 'phone',
                        label: t('phone', 'Teléfono'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                    {
                        name: 'mobile',
                        label: t('mobile', 'Móvil'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                    {
                        name: 'email',
                        label: t('email', 'Email'),
                        component: 'InputWithLabelMargin',
                        type: 'text',
                    },
                    {
                        name: 'createAddressToo',
                        label: t('createAddressToo', 'También crear dirección'),
                        component: 'CheckboxMargin',
                    },
                ]
            }
        )
    }
    return { workingCenterFields }
}


export default useCreateCustomerB2BWCAndAddress