import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { TwinAutoSizer } from '../../../baseComponents/TwinTable/Adapters'
import './textarea.sass'

export type TextAreaProps = TextAreaLogicProps & {
    value?: string
    className?: string
    name?: string
    label?: string
    placeholder?: string
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
    readOnly?: boolean
    subRef?: any
    maxRows?: number
    required?: boolean
    parentClassName?: string
}

const TextArea: React.FC<TextAreaProps> = ({ label, value, className, onChange, placeholder, onBlur, subRef, maxRows = 6, parentClassName, ...rest }) => {
    const { myOnChange, changeWidth, isLoaded, setFocusedTrue, myOnBlur } = useTextAreaLogic({ onChange, onBlur })
    return (
        <div className={'flex flex-col w-fill ' + (parentClassName || '') }>
            <TwinAutoSizer disableHeight={true} onResize={changeWidth} >
                {({ width }) => {
                    return (
                        <div className={'flex flex-auto flex-col justify-end items-start custom_textarea_parent ' + (className || '')} style={{ width }}>
                            <span>{label} {rest.required ? '*' : ''}</span>
                            {isLoaded === 1 && width > 10 ? <TextareaAutosize ref={subRef} onBlur={myOnBlur} onFocus={setFocusedTrue} onChange={myOnChange} placeholder={placeholder} maxRows={maxRows} className={'custom_textarea'} value={value} {...rest} /> : null}
                        </div>
                    )
                }}
            </TwinAutoSizer>
        </div>

    )
}

export interface RectangularTextAreaProps extends TextAreaProps {
    subRef?: any
}

export const RectangularTextArea: React.FC<RectangularTextAreaProps> = ({ label, value, onChange, placeholder, onBlur, className, subRef, onShiftEnter, maxRows = 6, ...rest }) => {
    const { myOnChange, isLoaded, changeWidth, myOnBlur, setFocusedTrue, myOnShiftEnter } = useTextAreaLogic({ onChange, onBlur, onShiftEnter })
    return (
        <div className='flex flex-col w-fill'>
            <TwinAutoSizer disableHeight={true} onResize={changeWidth}>
            {({ width }) => {
                return (
                    <div className={'rectangular_textarea_parent flex flex-col justify-end items-start border border-gray-D6 ' + (className || '')} style={{ width }}>
                        <span>{label}</span>
                        <div className='w-full rectangular_textarea flex justify-center flex-col'>
                            {isLoaded === 1 && width > 10 ? <TextareaAutosize ref={subRef} onBlur={myOnBlur} onKeyDownCapture={myOnShiftEnter} onFocus={setFocusedTrue} onChange={myOnChange} placeholder={placeholder} maxRows={maxRows} className={'resize-none border-0 w-full h-0 p-0'} value={value} {...rest} /> : null}
                        </div>
                    </div>

                )
                }}
            </TwinAutoSizer>
        </div>
    )
}

interface TextAreaLogicProps {
    onShiftEnter?: () => void
    onChange?: (value: string) => void
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement> | undefined
}

const useTextAreaLogic = ({ onChange, onBlur, onShiftEnter }: TextAreaLogicProps) => {
    const [isLoaded, setIsLoaded] = useState(0)
    const [isFocused, setIsFocused] = useState(false)

    const myOnShiftEnter: React.KeyboardEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault()
            e.stopPropagation()
            onShiftEnter?.()
        }
    }, [onShiftEnter])

    const myOnChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e.currentTarget.value)
    }, [onChange])

    const myOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        setIsFocused(false)
        onBlur?.(e)
    }, [onBlur])

    const setFocusedTrue = useCallback(() => {
        setIsFocused(true)
    }, [setIsFocused])

    const changeWidth = useCallback(() => {
        if (!isFocused){
            setIsLoaded(0)
        }
    }, [isFocused])

    useLayoutEffect(() => {
        if (isLoaded === 0) {
            setIsLoaded(1)
        }
    }, [setIsLoaded, isLoaded])

    return { myOnChange, changeWidth, isLoaded, myOnBlur, setFocusedTrue, myOnShiftEnter }
}

type TextAreaDebounceProps = TextAreaProps & TextAreaDebounceLogicProps & {}

export const TextAreaDebounce: React.FC<TextAreaDebounceProps> = ({ value, onChange, ...rest }) => {
    const { changeMyValue, myValue, ref, myOnBlur } = useTextAreaDebounceLogic({ value, onChange })
    return (
        <TextArea {...rest} value={myValue} onChange={changeMyValue} subRef={ref} onBlur={myOnBlur}/>
    )
}
interface TextAreaDebounceLogicProps {
    value: string
    onChange: (value: string) => void
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
}

const useTextAreaDebounceLogic = ({ value, onChange, onBlur }: TextAreaDebounceLogicProps) => {
    const [myValue, setMyValue] = useState(value)
    const [haveToTrigger, setHaveToTrigger] = useState<boolean>(false)
    const ref = useRef<any>(null)
    const changeMyValue = useCallback((changedValue: string) => {
        setHaveToTrigger(true)
        setMyValue(changedValue)
    }, [setMyValue, setHaveToTrigger])

    const myOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if(onBlur){
            onBlur(e)
        }else {
            const val = e.currentTarget.value
            onChange(val)
        }
    }, [onBlur, onChange])

    useEffect(() => {
        if (haveToTrigger !== false) {
            let id = setInterval(() => {
                if (ref.current) {
                    if (ref.current.value === myValue) {
                        onChange(ref.current.value);
                        setHaveToTrigger(false)
                    }
                } else {
                    onChange(ref.current.value);
                    setHaveToTrigger(false)
                }
            }, 1000)
            return () => clearInterval(id)
        }
    }, [haveToTrigger, onChange, myValue])


    useEffect(() => {
        setMyValue(value)
    }, [value])

    return { myValue, changeMyValue, ref, myOnBlur }
}

type RectangularTextAreaDebounceProps = RectangularTextAreaProps & TextAreaDebounceLogicProps & {}

export const RectangularTextAreaDebounce: React.FC<RectangularTextAreaDebounceProps> = ({ value, onChange, onBlur, ...rest }) => {
    const { changeMyValue, myValue, ref, myOnBlur } = useTextAreaDebounceLogic({ value, onChange })
    return (
        <RectangularTextArea {...rest} value={myValue} onChange={changeMyValue} subRef={ref} onBlur={myOnBlur} />
    )
}


export default TextArea