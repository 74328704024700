import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import CustomError from '../../../../../../../../../../baseComponents/CustomError'
import { DisplayStatusWithoutIcon } from '../../../../../../../../../../baseComponents/Displays/DisplayStatus'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { VirtualTableListingStateLess } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { CustomRenderDate } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { BeforeSubmitHandler } from '../../../../../../../../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useStatusRequestTypes from '../../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import useDictHolidayTypes from '../../../../../../../../../../utils/hooks/wCalHolidayBag/useDictHolidayTypes'

interface ModalCEEmployeeHolidaysBagProps extends ModalEditComponentProps {}

interface WCalHolidayBagModelTypeExtended extends WCalHolidayBagModelType {
    error?: boolean
}

const ModalCEEmployeeHolidaysBag: React.FC<ModalCEEmployeeHolidaysBagProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { id } = useParams()
    const { handleBeforeSubmit, errors } = useModalCEEmployeeHolidaysBagLogic()
    const {dictHolidayTypesDays} = useDictHolidayTypes()
    const fields: FormRender<WCalHolidayBagModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'error',
                    component: 'Custom',
                    render: () => <CustomError textError={errors} className='mb-10' />,
                    extraProps: {
                        errors
                    },
                },
                {
                    name: 'name',
                    component: 'InputWithLabelMargin',
                    label: t('name', 'Nombre'),
                    required: true
                },
                {
                    name: 'from',
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                    label: t('availableFrom', 'Disponible desde'),
                },
                {
                    name: 'to',
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                    label: t('expires', 'Caduca'),
                },
                {
                    name: 'originalDays',
                    component: 'InputWithLabelMarginIntegerStateFull',
                    label: t('originalDays', "Días iniciales"),
                    required: true
                },
                {
                    name: 'leftDays',
                    component: 'InputWithLabelMarginHalfFloatStateFull',
                    label: t('leftDays', 'Días restantes'),
                },
                {
                    name: 'holidayType',
                    label: t('daysType', 'Tipo de días'),
                    component: 'CustomSelect',
                    items: dictHolidayTypesDays,
                    value: allRowData?.holidayType || 0,
                    required: true
                },
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: id
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
            ]
        }
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<MediumModalCreateEdit fields={parsedFields} beforeSubmit={handleBeforeSubmit} url='/api/app/workingCalendar/workingCalendarHolidayBag/updateWorkingCalendarHolidayBag' translations={{ title: t('editHolidaysBag', 'Editar bolsa de vacaciones'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} className='notFlexAutoFormRender'{...rest} >
            <TableDetailHolidays id={allRowData?.id || 0} />
        </MediumModalCreateEdit>)
    }
    return (
        <MediumModalCreateEdit fields={fields} beforeSubmit={handleBeforeSubmit} url='/api/app/workingCalendar/workingCalendarHolidayBag/createWorkingCalendarHolidayBagMultipleEmployees' translations={{
            title: t('addHolidaysBag', 'Añadir bolsa de vacaciones'),
            button: t('add', 'Añadir')
        }} haveButtonPermissions={true} {...rest} />
    )
}

const useModalCEEmployeeHolidaysBagLogic = () => {
    const [errors, setErrors] = useState<false | string>(false)
    const { t } = useTwinTranslation()
    const error = t('wrongDateRange', 'El rango de fecha es incorrecto')
    const handleBeforeSubmit: BeforeSubmitHandler = useCallback((values: any) => {
        const fromDateParsed = new Date(values.from)
        const toDateParsed = new Date(values.to)
        if (toDateParsed < fromDateParsed) {
            setErrors(error)
            return false
        }
        return values
    }, [setErrors, error])
    return { handleBeforeSubmit, errors}
}

interface TableDetailHolidaysProps extends TableDetailLogicProps { }

const TableDetailHolidays: React.FC<TableDetailHolidaysProps> = ({ id }) => {
    const { t } = useTwinTranslation()
    const { holidayBagInstance } = useTableDetailLogic({ id })
    const columns: ColumnTableSchema<WCalHolidayUsedDayModelType> = [
        {
            id: 'from',
            dataKey: 'from',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            minWidth: 150
        },
    ]
    const usedDays = holidayBagInstance?.WCalHolidayUsedDays

    return (
        <div className='mb-20 mt-25 flex flex-col flex-auto'>
            <div className='justify-between items-center mb-20'>
                <h2 className='text-18 text-gray-51'>
                    <TwinTrans transKey='usedDaysRequiredDetailHolidayBag'>Detalle de las vacaciones solicitadas</TwinTrans>
                </h2>
            </div>
            <div className='flex-auto h-1 overflow-auto'>
                <VirtualTableListingStateLess tableData={usedDays || []} rowHeight={48} headerHeight={48} name='HolidayRequestDetail' columns={columns} setSelectedColumnsAndFilters={() => { }} />
            </div>
        </div>
    )
}


interface TableDetailLogicProps {
    id: number
}

const useTableDetailLogic = ({ id }: TableDetailLogicProps) => {
    const [holidayBagInstance, setHolidayBagInstance] = useState<WCalHolidayBagModelType | null>(null)

    const getHolidayBag = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getWorkingCalendarHolidayBagInstance', {
            id: id
        })
        if (result) {
            setHolidayBagInstance(result)
        }
    }, [setHolidayBagInstance, id])

    useEffect(() => {
        getHolidayBag()
    }, [getHolidayBag])


    return { holidayBagInstance }
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

export default ModalCEEmployeeHolidaysBag