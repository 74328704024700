import CustomerAdresses from './Tabs/CustomerAddresses'
import MainCustomerProfile from './Tabs/MainCustomerProfile'
import CustomerBank from './Tabs/CustomerBanks'
import { useLocation, useParams } from 'react-router'
import CustomerGdpr from './Tabs/CustomerGdpr'
import CustomerContactPersons from './Tabs/CustomerContactsPersons'
import CustomerNotes from './Tabs/CustomerNotes'
import CustomerOtherInformation from './Tabs/CustomerOtherInformation'
import CustomerFiles from './Tabs/CustomerFiles'
import CustomerShops from './Tabs/CustomerShops'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import CustomerProjects from './Tabs/CustomerProjects'
import CustomerBagOfHours from './Tabs/CustomerBagOfHours'
import CustomerTasks from './Tabs/CustomerTasks'
import { useCallback, useEffect, useState } from 'react'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import CustomerCampaigns from './Tabs/CustomerCampaigns'

interface SingleCustomerProps extends ComponentWithPermissions { }


const SingleCustomer: React.FC<SingleCustomerProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const {t} = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: { id: params.id }, url: '/api/app/customer/getCustomerInstance', variableToCurrentPage: 'name' })
    const { dictCustomerOrigins } = useSingleCustomerLogic()
    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainCustomerProfile, text: t('general', 'General'), permission: 'customer.profile.main', extraProps: { dictCustomerOrigins}},
        'other-information': { component: CustomerOtherInformation, text: t('otherInformation', 'Otra información'), permission: 'customer.profile.main'},
        'address': { component: CustomerAdresses, text: t('addresses', 'Direcciones'), permission: 'customer.profile.address' },
        'bank': { component: CustomerBank, text: t('banks', 'Bancos'), permission: 'customer.profile.bank' },
        'contacts': { component: CustomerContactPersons, text: t('contacts', 'Contactos'), permission: 'customer.profile.contactPerson' },
        'shops': { component: CustomerShops, text: t('workingCenters', 'Centros de trabajo'), permission: 'customer.profile.shops' },
        'projects': { component: CustomerProjects, text: t('projects', 'Proyectos'), permission: 'customer.profile.projects' },
        'bagsOfHours': { component: CustomerBagOfHours, text: t('bagsOfHours', 'Bolsas de horas'), permission: 'customer.profile.bagOfHours', modules: ['tasks'] },
        'tasks': { component: CustomerTasks, text: t('tasks', 'Tareas'), permission: 'customer.profile.tasks', modules: ['tasks'] },
        'campaigns': { component: CustomerCampaigns, text: t('campaigns', 'Campañas'), permission: 'customer.profile.campaign', modules: ['tasks'] },
        'notes': { component: CustomerNotes, text: t('notes', 'Notas'), permission: 'customer.profile.notes'},
        'files': { component: CustomerFiles, text: t('files', 'Ficheros'), permission: 'customer.profile.files' },
        'gdpr': { component: CustomerGdpr, text: t('gdpr', 'RGPD'), permission: 'customer.profile.gdpr' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}

const useSingleCustomerLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)

    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins }
}

export default SingleCustomer