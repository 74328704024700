import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateContactB2CContactPerson from './ModalCreateContactB2CContactPerson'
import ModalEditContactB2CContactPerson from './ModalEditContactB2CContactPerson'
import ModalDeleteContactB2CContactPerson from './ModalDeleteContactB2CContactPerson'

interface ContactB2CContactsPersonsProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const ContactB2CContactsPersons: React.FC<ContactB2CContactsPersonsProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('contactsPerson', 'Personas de contacto'),
        newText: t('addContactPerson', 'Nueva persona de contacto'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableContactB2CContactsPersons} CreateModalComponent={ModalCreateContactB2CContactPerson} EditModalComponent={ModalEditContactB2CContactPerson} DeleteModalComponent={ModalDeleteContactB2CContactPerson} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id, active: true }} />
    )
}
interface TableContactB2CContactsPersonsProps extends TableComponentBaseScreenInnerProps { }

const TableContactB2CContactsPersons: React.FC<TableContactB2CContactsPersonsProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerContactPersonModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'position',
            dataKey: 'position',
            label: t('position', 'Cargo'),
            sortable: true,
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable columns={columns} name='customersContactPersons' getDataFrom='/api/app/customer/customerContactPerson/getAllCustomerContactPersons'
             onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default ContactB2CContactsPersons