import { SetStateAction, useCallback } from 'react'
import { DisplayStatusWithoutIcon } from '../../../../baseComponents/Displays/DisplayStatus'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { displayFormatedHourFromMins } from '../../../../utils/globals/date'
import TableSchedule from '../TableSchedule'
import useDictRequestOvertime from '../../../../utils/hooks/useDictRequestOvertime'


export type OvertimeScheduleErrorsTy = {
    workingTime: [number, number][]
    errors: string[]
}

export interface ScheduleOvertimeProps extends ScheduleOvertimeLogicProps {
    schedule: OvertimeScheduleErrorsTy
}

export const ScheduleOvertime: React.FC<ScheduleOvertimeProps> = ({ schedule, setSchedule }) => {
    const { onChange } = useScheduleOvertimeLogic({ setSchedule })
    return (
        <div>
            <div className='regular20 my-20'>
                <TwinTrans transKey='schedule'>Horario</TwinTrans>
            </div>
            <TableSchedule scheduleTime={schedule.workingTime} errors={schedule.errors} onChange={onChange} />
        </div>
    )
}


interface ScheduleOvertimeLogicProps {
    setSchedule: React.Dispatch<SetStateAction<OvertimeScheduleErrorsTy>>
}

const useScheduleOvertimeLogic = ({ setSchedule }: ScheduleOvertimeLogicProps) => {
    const onChange = useCallback((scheduleTime: [number, number][]) => {
        setSchedule((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            return { ...copy, workingTime: scheduleTime }
        })
    }, [setSchedule])
    return { onChange }
}

interface RenderOvertimeStatusProps {
    value: string
}

export const RenderOvertimeStatus: React.FC<RenderOvertimeStatusProps> = ({ value }) => {
    const {requestStatusOvertimeTypesDic} = useDictRequestOvertime()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={requestStatusOvertimeTypesDic[parsedValue].colorText} text={requestStatusOvertimeTypesDic[parsedValue].name} />
}
interface RenderOvertimeScheduleProps {
    value: string
}

export const RenderOvertimeSchedule: React.FC<RenderOvertimeScheduleProps> = ({ value }) => {
    const parsedSchedule = JSON.parse(value || '[]')
    const renderThis: JSX.Element[] = []
    for (const element of parsedSchedule) {
        renderThis.push(<div>
            <span><TwinTrans transKey='fromWithSpace'>De </TwinTrans></span>
             {displayFormatedHourFromMins(element[0])}
            <span> <TwinTrans transKey='aTo'>a</TwinTrans> </span>
            {displayFormatedHourFromMins(element[1])}
        </div>)
    }

    return (<div className='flex flex-col'>{renderThis}</div>)
}