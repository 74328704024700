import { faPlus, faShoppingBag, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { SetStateAction, useCallback, useState } from 'react'
import { ButtonOrLoader } from '../../../../../baseComponents/Button'
import TwinIcon, { TwinIconToolTip } from '../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { InputCalendarWithHoursStateFull } from '../../../../../forms/Input/InputCalendarWithHours'
import { InputHourMinSecUnlimitedStateFull } from '../../../../../forms/Input/InputHourMinSec/InputHourMinSecUnlimited'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { displayFormatedHourMinSec, getHoursMinSecFromSecs } from '../../../../../utils/globals/date'
import { SetEditDeleteModalTy } from '../../../../../utils/hooks/useEditDeleteModal'
import useIsLoading from '../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { getUserInfo } from '../../../../../utils/reducers/getters'
import { addAntiUnsaveElement, clearAntiUnsaveElement } from '../../../../../utils/reducers/reduxDispatch'
import { TaskWorkLogModelTypeExt } from '../../types'
import { ModalTaskInvertedTimeBaseProps, TempTaskInvertedTimeTy } from '../types'
import { sumAllSecsWorkLogs, addOriginalWorkedSecsTaskWorkLog } from './functions'

interface ModalTaskListInvertedTimeProps extends ModalTaskListInvertedTimeLogicProps {}

const ModalTaskListInvertedTime: React.FC<ModalTaskListInvertedTimeProps> = ({ setOpenModalInvertedTime, data }) => {
    const { handleUpdateTask, loading, tempInvertedTime, setTempInvertedTime } = useModalTaskListInvertedTimeLogic({ setOpenModalInvertedTime, data})
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col flex-auto h-1'>
            <h2>
                <TwinTrans transKey='timeSpentInTask'>
                    Tiempo gastado en la tarea
                </TwinTrans>
                : <span className='text-green-21'>{displayFormatedHourMinSec(getHoursMinSecFromSecs(tempInvertedTime.invertedTime))}</span>
            </h2>
            <TableWorkLogs taskWorkLogs={tempInvertedTime?.taskWorkLogs} {...{setOpenModalInvertedTime, setTempInvertedTime }} />
            <ButtonOrLoader className='mt-auto' onClick={handleUpdateTask} buttonIsDisabled={loading} textButton={t('save', 'Guardar')} />
        </div>
    )
}

interface ModalTaskListInvertedTimeLogicProps extends ModalTaskInvertedTimeBaseProps { }

const useModalTaskListInvertedTimeLogic = ({ data, setOpenModalInvertedTime, }: ModalTaskListInvertedTimeLogicProps) => {
    const [tempInvertedTime, setTempInvertedTime] = useState<TempTaskInvertedTimeTy>({ invertedTime: sumAllSecsWorkLogs(data.TaskWorkLogs || []), taskWorkLogs: addOriginalWorkedSecsTaskWorkLog(data.TaskWorkLogs || [])} )
    const { startLoading, endLoading, loading } = useIsLoading()
    const userInfo = getUserInfo()
    const EmployeeId = userInfo?.Employee?.id

    const handleUpdateTask = useCallback(async () => {
        startLoading()
        const myTaskWorkLogs: TaskWorkLogModelTypeExt[] = []
        for (const workLog of tempInvertedTime?.taskWorkLogs) {
            if(workLog.EmployeeId ===EmployeeId && workLog.endDate){
                myTaskWorkLogs.push(workLog)
            }
        }
        const result = await twinFetchPostJSON('/api/app/task/workLog/updateTaskWorkLogs', { TaskId: data?.id, taskWorkLogs: myTaskWorkLogs })

        if (result) {
            await clearAntiUnsaveElement()
            setOpenModalInvertedTime(null)
        }
        endLoading()
    }, [setOpenModalInvertedTime, tempInvertedTime?.taskWorkLogs, endLoading, startLoading, data?.id, EmployeeId])
    return { handleUpdateTask, loading, tempInvertedTime, setTempInvertedTime }
}


interface TableWorkLogProps {
    taskWorkLogs: TaskWorkLogModelTypeExt[]
    setOpenModalInvertedTime: SetEditDeleteModalTy
    setTempInvertedTime: React.Dispatch<SetStateAction<TempTaskInvertedTimeTy>>
}

const TableWorkLogs: React.FC<TableWorkLogProps> = ({ taskWorkLogs, setOpenModalInvertedTime, ...rest}) => {
    const renderThis: JSX.Element[] = []
    if (taskWorkLogs?.length){
        for (const key in taskWorkLogs) {
            const taskWorkLog = taskWorkLogs[key]
            if (!taskWorkLog.deleted) {
                renderThis.push(< TaskWorkLogRow posArr={parseInt(key)} key={taskWorkLog.id} {...{ taskWorkLog, ...rest }} />)
            }
        }

    }
    return (
        <div className='mt-20 flex flex-col h-1 flex-auto overflow-y-auto mb-20'>
            {taskWorkLogs?.length ?
                <div className='flex items-center table_work_log_row bg-gray-F7 p-10 px-14 medium14'>
                    <div><TwinTrans transKey='employee'>Empleado</TwinTrans></div>
                    <div><TwinTrans transKey='date'>Fecha</TwinTrans></div>
                    <div><TwinTrans transKey='hours'>Horas</TwinTrans></div>
                    <div></div>
                    <div></div>
                </div>
                : null
            }
            {renderThis}
            <div className='bg-gray-F7 p-10 px-14 medium14 flex items-center justify-center cursor-pointer' onClick={()=>setOpenModalInvertedTime({type: 'create', allRowData: {}})}>
                <TwinIcon icon={faPlus} className='mr-10' />
                <TwinTrans transKey='addTime'>Añadir tiempo</TwinTrans>
            </div>
        </div>
    )
}




interface TaskWorkLogRowProps extends TaskWorkLogRowLogicProps {
    taskWorkLog: TaskWorkLogModelTypeExt
}

const TaskWorkLogRow: React.FC<TaskWorkLogRowProps> = ({ taskWorkLog, posArr, ...rest }) => {
    const { handleUpdateTaskWorkLog, removeTaskWorkLog } = useTaskWorkLogRowLogic({ posArr, ...rest})
    const userInfo = getUserInfo()
    const EmployeeId = userInfo?.Employee?.id
    const readOnly = EmployeeId !== taskWorkLog.EmployeeId || !taskWorkLog.endDate
    return (
        <div className={'flex items-center table_work_log_row p-10 px-14 border-b border-gray-F7 ' + (readOnly ? 'readonly' : '')}>
            <div>
                {taskWorkLog?.Employee?.fullname_short}
            </div>
            <div className='pr-10'>
                <InputCalendarWithHoursStateFull readOnly={readOnly} label='' value={new Date(taskWorkLog.startDate)} onChange={(value) => handleUpdateTaskWorkLog(value.toDateString(), 'startDate')} />
            </div>
            <div className='pr-10'>
                <InputHourMinSecUnlimitedStateFull readOnly={readOnly} label='' value={String(taskWorkLog.workedSecs)} onFinalChange={(value) => handleUpdateTaskWorkLog(value, 'workedSecs')} />
            </div>
            <TwinIconToolTip message={taskWorkLog?.TaskBagOfHours?.name || ''} id={'taskWorkLog' + taskWorkLog.id} icon={taskWorkLog.TaskBagOfHoursId ?faShoppingBag : undefined} className='max-w-300' />
            <div className='text-right'>
                {EmployeeId === taskWorkLog.EmployeeId ? <TwinIcon className='cursor-pointer hover:text-red-BA' icon={faTrashCan} onClick={removeTaskWorkLog} /> : null}
            </div>
        </div>
    )
}
interface TaskWorkLogRowLogicProps {
    posArr: number
    setTempInvertedTime: React.Dispatch<SetStateAction<TempTaskInvertedTimeTy>>
}

const useTaskWorkLogRowLogic = ({ posArr, setTempInvertedTime }: TaskWorkLogRowLogicProps) => {
    const handleUpdateTaskWorkLog = useCallback(async (value: string | number, field: 'startDate' | 'workedSecs') => {
        setTempInvertedTime((old)=>{
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld.taskWorkLogs?.[posArr]?.[field] !== undefined) {
                copyOld.taskWorkLogs[posArr][field] = value
            }
            return { ...copyOld, invertedTime: sumAllSecsWorkLogs(copyOld.taskWorkLogs)}
        })
    }, [setTempInvertedTime, posArr])

    const removeTaskWorkLog = useCallback(async() => {
        setTempInvertedTime((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if(copyOld.taskWorkLogs?.[posArr]){
                copyOld.taskWorkLogs[posArr] = { ...copyOld.taskWorkLogs[posArr], deleted: true }
            }
            return { ...copyOld, invertedTime: sumAllSecsWorkLogs(copyOld.taskWorkLogs) }
        })
        await addAntiUnsaveElement(['inverted_time', { delete: true }])

    }, [setTempInvertedTime, posArr])

    return { handleUpdateTaskWorkLog, removeTaskWorkLog }
}

export default ModalTaskListInvertedTime