import { CustomerModelType } from '@teinor/erp/types/company/customer';
import { stringMatchWithSearch } from '../../../../../../../../../utils/globals/search';

export const modalCreateCampaignFilterCustomers = (preselectedCustomers : CustomerModelType[], searchValue: string) => {
    const result: CustomerModelType[] = []
    for (const customer of preselectedCustomers) {
        if (stringMatchWithSearch(searchValue, customer.name) || stringMatchWithSearch(searchValue, String(customer.id)) || stringMatchWithSearch(searchValue, String(customer.code))) {
        result.push(customer)
        }
    }
    return result
}