import TwinIcon from '../../../TwinIcon'
import UserIcons from './UserIcons'
import { Routes } from '../../../CreateRoutering/types'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../Modal/types'
import { LeftMenuPrintItemsConnect } from '../LeftMenuComponents'

interface IconMenuBarProps {
    routes: Routes
    opened: ModalOpenedTy
    setOpened: ModalOpenedSetTy
}

const IconMenuBar: React.FC<IconMenuBarProps> = ({ routes, opened, setOpened }) => {
    return (
        <div className='w-70 flex flex-col items-center bg-gray-33 text-white py-15'>
            {!opened ? <TwinIcon icon={faBars} className={'cursor-pointer text-white opacity-50 pt-12 h-27'} onClick={() => setOpened(true)} /> : <img className='menu_bar_logo' src='/logo-white.png'  alt='' />}
            <LeftMenuPrintItemsConnect routes={routes}/>
            <UserIcons routes={routes} />
        </div>
    )
}

export default IconMenuBar