import { useCallback } from 'react'
import { CustomerCommentOnNotesTy } from '../../../specificComponents/Customers/types'
import { twinFetchPostJSON } from '../../globals/data'
import useTwinTranslation from '../useTwinTranslation'
import { TwinDictionary } from '../../globals/dictionary'


interface CustomerNotesPropsTypesProps {
    setCustomerNotesComments: React.Dispatch<React.SetStateAction<CustomerCommentOnNotesTy[] | null>>
}

export const useCustomerNotesCommentsPropsTypes = ({ setCustomerNotesComments}: CustomerNotesPropsTypesProps) => {
    const {t} = useTwinTranslation()
    const deleteCommentArr = useCallback((posArr: number) => {
        setCustomerNotesComments((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.splice(posArr, 1)
            return [...copyOld]
        })
    }, [setCustomerNotesComments])

    const deleteCustomerProjectComment = useCallback(async (id: number, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectComment/deleteCustomerProjectComment', { id })
        if (result) {
            deleteCommentArr(posArr)
        }
    }, [deleteCommentArr])

    const deleteCustomerNote = useCallback(async (id: number, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerNote/deleteCustomerNote', { id })
        if (result) {
            deleteCommentArr(posArr)
        }
    }, [deleteCommentArr])
    const deleteCampaignCustomerComment = useCallback(async (id: number, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/deleteCampaignCustomerComment', { id })
        if (result) {
            deleteCommentArr(posArr)
        }
    }, [deleteCommentArr])

    const updateCommentArr = useCallback((posArr: number, message: string) => {
        setCustomerNotesComments((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld[posArr].message = message
            return [...copyOld]
        })
    }, [setCustomerNotesComments])

    const updateCustomerNote = useCallback(async (id: number, message: string, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerNote/updateCustomerNote', { id, message })
        if (result) {
            updateCommentArr(posArr, message)
        }
    }, [updateCommentArr])

    const updateCustomerProjectComment = useCallback(async (id: number, message: string, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectComment/updateCustomerProjectComment', { id, message })
        if (result) {
            updateCommentArr(posArr, message)
        }
    }, [updateCommentArr])
    const updateCampaignCustomerComment = useCallback(async (id: number, message: string, posArr: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/comment/updateCampaignCustomerComment', { id, message })
        if (result) {
            updateCommentArr(posArr, message)
        }
    }, [updateCommentArr])


    const commentsPropsByType = {
        'customerProject': {
            'title': (myComment: TwinDictionary) => myComment.Employee?.fullname_short + ' - ' + t('project', 'Proyecto') + ': ' + myComment?.CustomerProject?.name,
            'downloadFileURL': '/api/app/customer/customerProject/customerProjectComment/getCustomerProjectCommentInstanceFile',
            'deleteComment': deleteCustomerProjectComment ,
            'updateComment': updateCustomerProjectComment
        },
        'customerNote': {
            'title': (myComment: TwinDictionary) => myComment.Employee?.fullname_short,
            'downloadFileURL': '/api/app/customer/customerNote/getCustomerNoteInstanceFile',
            'deleteComment': deleteCustomerNote ,
            'updateComment': updateCustomerNote
        },
        'campaignCustomer': {
            'title': (myComment: TwinDictionary) => myComment.Employee?.fullname_short + ' - ' + t('campaign', 'Campaña') + ': ' + myComment?.CampaignCustomer?.Campaign?.name,
            'downloadFileURL': '/api/app/customer/campaign/customer/comment/getCampaignCustomerCommentInstanceFile',
            'deleteComment': deleteCampaignCustomerComment ,
            'updateComment': updateCampaignCustomerComment
        }
    }
    return {commentsPropsByType }
}

