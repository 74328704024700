import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderDateHours, CustomRenderString, CustomRenderStringAndColorNull, EditRow } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../../../../utils/reducers/getters'
import { connect, ConnectedProps } from 'react-redux'
import { CustomerPayload } from '../../../../../../../utils/reducers/customers/customer'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import { CampaignCustomerModelType } from '@teinor/erp/types/company/customer/campaign/campaignCustomer'
import { useParams } from 'react-router'
import { useCallback, useEffect, useState } from 'react'
import ModalCreateCampaignCustomers from './ModalCreateCampaignCustomers'
import ModalEditCampaignCustomer from '../../../../../../../specificComponents/ModalEditCampaignCustomer'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { CustomRenderCustomer } from '../../../../../../../specificComponents/Customers/Subcomponents'

interface SingleCampaignProps extends ComponentWithPermissions {}

const SingleCampaign: React.FC<SingleCampaignProps> = () => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('customersCampaign', 'Clientes de la campaña'),
        newText: t('addCustomers', 'Añadir clientes'),
    }
    const updatePermissions = getUserPermissions('customer.campaigns.main')

    return (
        <BaseScreenTable TableComponent={TableCampaignCustomersConnect} pageData={pageData} CreateModalComponent={ModalCreateCampaignCustomers} EditModalComponent={MyModalEditCampaignCustomer} userPermissions={updatePermissions || {n: 1, permission: 'read'}} />
    )
}

type TableCampaignCustomersProps = TableComponentBaseScreenInnerProps & ReduxCustomerAndCampaigns & {
}

const TableCampaignCustomers: React.FC<TableCampaignCustomersProps> = ({ setTableInstance, setOpened, customers }) => {
    const { t } = useTwinTranslation()
    const { CampaignId, dictCampaignStatus } = useTableCampaignCustomersLogic()

    const columns: ColumnTableSchema<CampaignCustomerModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75,
            sortable: true,
        },
        {
            id: 'customer',
            dataKey: 'CustomerId',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderCustomer allRowData={allRowData} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: customers?.all || {}, label: t('customer', 'Cliente') }
            },
            searchKey: 'Customer.name',
            sortKey: 'Customer.id'
        },
        {
            id: 'customerPhone',
            dataKey: 'CustomerId',
            label: t('phone', 'Teléfono'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData.Customer.phone} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            },
            searchKey: 'Customer.phone'
        },
        {
            id: 'customerEmail',
            dataKey: 'CustomerId',
            label: t('email', 'Email'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData.Customer.email} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('email', 'Email') }
            },
            searchKey: 'Customer.email'
        },
        {
            id: 'CurrentCampaignStatusId',
            dataKey: 'CurrentCampaignStatusId',
            label: t('status', 'Estado'),
            customRender: (_parameterValue, allRowData) => <CustomRenderStringAndColorNull value={allRowData?.CurrentCampaignStatus?.name} color={allRowData?.CurrentCampaignStatus?.color} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelect',
                extraComponentData: { items: dictCampaignStatus || {}, label: t('status', 'Estado') }
            },
            sortable: true,
        },
        {
            id:'ResponsibleEmployeeId',
            dataKey: 'Customer',
            label: t('ourManager', 'Nuestro gestor'),
            customRender: (parameterValue, allRowData) =><CustomRenderString value={allRowData?.Customer?.ResponsibleEmployee?.fullname_short} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.ResponsibleEmployee.fullname_short'
        },
        {
            id: 'date',
            dataKey: 'date',
            label: t('limitDate', 'Fecha límite'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
            sortable: true
        },
        {
            id: 'dateLastAction',
            dataKey: 'dateLastAction' as any,
            label: t('dateLastAction', 'Fecha última acción'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
            sortable: true,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CampaignCustomers' columns={columns} getDataFrom='/api/app/customer/campaign/customer/getAllCampaignCustomers' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })}
        setTableInstance={setTableInstance} extraWhereParams={{'CampaignCustomer.CampaignId': CampaignId}} />
    )
}

const useTableCampaignCustomersLogic = () => {
    const [dictCampaignStatus, setDictCampaignStatus] = useState<TwinDictionary | null>(null)
    const { id } = useParams()
    const CampaignId = id
    const getCampaignStatus = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/getAllCampaignStatus', { where: {CampaignId}})
        if(result){
            setDictCampaignStatus(dictionaryComplexFromJsonArr(result))
        }
    }, [CampaignId, setDictCampaignStatus])

    useEffect(() => {
        getCampaignStatus()
    }, [getCampaignStatus])
    return { dictCampaignStatus, CampaignId }
}

interface MyModalEditCampaignCustomerProps extends ModalEditComponentProps {}

const MyModalEditCampaignCustomer: React.FC<MyModalEditCampaignCustomerProps> = ({...all}) => {
    return (
        <ModalEditCampaignCustomer {...all} userPermissions={getUserPermissions('customer.campaigns.inner') || {n: 1, permission: 'read'}}/>
    )
}

const customersAndCampaignsDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

export type ReduxCustomerAndCampaigns = ConnectedProps<typeof customersAndCampaignsConnect>
const mapCustomersAndCampaignsConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const customersAndCampaignsConnect = connect(mapCustomersAndCampaignsConnector, customersAndCampaignsDispatch)
const customersAndCampaignsConnectLoading = withLoading(TableCampaignCustomers, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])
const TableCampaignCustomersConnect = customersAndCampaignsConnect(customersAndCampaignsConnectLoading)

export default SingleCampaign