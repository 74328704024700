import { WeekDays } from '@teinor/erp/types/company/WCalParent/WCal'
import { checkIsBiggerThanSmaller } from '../../app/erp/screens/Staff/WorkingCalendarParent/WorkingCalendars/SingleWorkingCalendar/Tabs/ScheduleWorkingCalendar/ModalEditScheduleWorkingCalendar/functions'
import { ScheduleForModalEdit, WCalScheduleTyAndError } from '../../app/erp/screens/Staff/WorkingCalendarParent/WorkingCalendars/SingleWorkingCalendar/Tabs/ScheduleWorkingCalendar/ModalEditScheduleWorkingCalendar/types'

export const checkSchedulesAllOk = (myDaySchedule: WCalScheduleTyAndError) => {
    myDaySchedule.errors = []
    let hasError = false
    const workingTime = myDaySchedule.workingTime
    if (workingTime.length) {
        for (const pos in workingTime) {
            const hourDayRow = workingTime[pos]
            const prevRow = workingTime[parseInt(pos) - 1]
            const nextRow = workingTime[parseInt(pos) + 1]
            if (prevRow && prevRow.length) {
                if (!checkIsBiggerThanSmaller(hourDayRow[0], prevRow[1])) {
                    myDaySchedule.errors.push((parseInt(pos) - 1) + '-1')
                    myDaySchedule.errors.push(pos + '-0')
                    hasError = true
                }
            }
            if (!checkIsBiggerThanSmaller(hourDayRow[1], hourDayRow[0])) {
                myDaySchedule.errors.push(pos + '-0')
                myDaySchedule.errors.push(pos + '-1')
                hasError = true
            }
            if (nextRow && nextRow.length) {
                if (!checkIsBiggerThanSmaller(nextRow[0], hourDayRow[1])) {
                    myDaySchedule.errors.push(pos + '-1')
                    myDaySchedule.errors.push((parseInt(pos) + 1) + '-0')
                    hasError = true
                }
            }
        }
    }
    return { hasError }
}

export const checkIfDailyHoursIsSmallerOrEqualArr = (mySchedule: ScheduleForModalEdit) => {
    let hasError = false
    if (mySchedule) {
        for (const key in mySchedule) {
            const myDaySchedule = mySchedule[key as WeekDays]
            if(checkIfDailyHoursIsSmallerOrEqual(myDaySchedule).hasError){
                hasError = true
            }
        }
    }
    return { hasError }
}

export const checkIfDailyHoursIsSmallerOrEqual = (mySchedule: WCalScheduleTyAndError) => {
    let hasError = false
    if (mySchedule) {
        const workingTime = mySchedule.workingTime
        let totalWorkingTime = 0
        if (workingTime.length) {
            for (const hour of workingTime) {
                totalWorkingTime = totalWorkingTime + (hour[1] - hour[0])
            }
        }
        if (mySchedule.dailyHours && totalWorkingTime < mySchedule.dailyHours) {
            hasError = true
        }
    }
    return { hasError }
}