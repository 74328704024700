import ModalDeleteFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteCustomerProjectTypeProps extends ModalEditComponentProps { }

const ModalDeleteCustomerProjectType: React.FC<ModalDeleteCustomerProjectTypeProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerProject/customerProjectTypes/updateCustomerProjectType',
        data: {
            id: allRowData?.id,
            active: false
        }
    })

    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureUnactiveCustomerProjectType', '¿Seguro que quieres desactivar este tipo de proyecto?'),
            subtitle: t('onceUnactiveYouCanRecover', 'Una vez desactivado podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteCustomerProjectType