import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { RectangularTextAreaDebounce } from '../../../../../../../forms/Input/TextArea'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { faArrowLeftRotate, faPencil } from '@fortawesome/pro-light-svg-icons'
import TwinEditor from '../../../../../../../forms/TwinEditor'
import CustomSelectSectionCustomers from '../../../../../../../specificComponents/Customers/CustomSelectSectionCustomers'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import TaskRepositoryAnswers from './TaskRepositoryAnswers'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { displayDateTime } from '../../../../../../../utils/globals/date'
import { ModalBig } from '../../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { RowData } from '../../../../../../../baseComponents/TwinTable/types'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'
import { TaskRepositoryModelType } from '@teinor/erp/types/company/task/taskRepository'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import './taskRepository.sass'
import { CustomChangeFunction } from '../../../../../../../forms/Input/types'
import { CustomerPayload } from '../../../../../../../utils/reducers/customers/customer'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'

type ModalEditTaskRepositoryProps = ModalEditComponentProps & ReduxModalEditTaskRepository & {}

const ModalEditTaskRepository: React.FC<ModalEditTaskRepositoryProps> = ({ allRowData, setOpened, onSubmit, customers, userPermissions }) => {
    const {t, tVars} = useTwinTranslation()
    const { loading, updateData, isEditing, handleChangeField, data, getTaskRepository, handleEditing } = useTaskRepositoryLogic({allRowData, onSubmit })
    if(!data){
        return (
            <ModalBig opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col '}>
                <LoadingSpinner />
            </ModalBig>

        )
    }
    const date = new Date(data.createdAt)
    const options = { 'interpolation': { 'escapeValue': false }, taskRepositoryId: data.id, createdDate: displayDateTime(date) }
    return (
        <ModalBig opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col '}>
            <div className='modal_negative_margin flex flex-auto flex-col '>
                <div className='overflow-auto flex flex-auto flex-col h-1 pr-10'>
                    <div className='flex items-center justify-between my-20'>
                        <TaskRepositoryTitle id={data.id} value={data.title} onChange={(value) => handleChangeField(value, 'title')} readOnly={!isEditing} />
                        <PermissionChecker permission='update' userPermissions={userPermissions || false}>
                            <TwinIcon icon={isEditing ? faArrowLeftRotate : faPencil} className='ml-10 cursor-pointer ' size={'xl'} onClick={() => handleEditing(isEditing)} />
                        </PermissionChecker>
                    </div>
                    <CustomSelectSectionCustomers className='mb-20 w-200' customers={customers} label={t('customer', 'Cliente')} value={data?.CustomerId} onChange={(value) => handleChangeField(value, 'CustomerId')} readOnly={!isEditing} />
                    <div className='flex items-center mb-15'>
                        <span className='regular16 '>
                            <TwinTrans transKey='description'>Descripción</TwinTrans>
                        </span>
                    </div>
                    <TwinEditor defaultValue={data?.description} readOnly={!isEditing} onChange={(value) => handleChangeField(value, 'description')} className='px-10'/>
                    {isEditing ? 
                        <ButtonOrLoader className='mt-20' buttonIsDisabled={loading} textButton={t('save', 'Guardar')} onClick={updateData} />
                        : null
                    }
                    <TaskRepositoryAnswers TaskRepositoryId={String(data?.id) || ''} answers={data?.TaskRepositoryAnswers as any || []} {...{ getTaskRepository }} userPermissions={userPermissions || {n: 1, permission: 'read'}} />
                    <div className='mt-auto'>
                        <span className='link12'>{data?.Employee?.fullname_short} </span>
                        <span className='label12'>{tVars('createdTaskRepositoryLog', 'ha creado el tema #{{taskRepositoryId}} - {{createdDate}}', options)}</span>
                    </div>
                </div>
            </div>
        </ModalBig>

    )
}

interface TaskRepositoryLogicProps {
    onSubmit: OnSubmit
    allRowData?: RowData
}
const useTaskRepositoryLogic = ({ allRowData, onSubmit }: TaskRepositoryLogicProps) => {
    const [data, setData] = useState<TaskRepositoryModelType | null>(null)
    const [isEditing, setIsEditing] = useState(false)
    const { startLoading, endLoading, loading } = useIsLoading()
   
    const getTaskRepository = useCallback(async () => {
        const res = await twinFetchPostJSON('/api/app/task/repository/getTaskRepositoryInstance', {id: allRowData?.id})
        if(res){
            setData(res)
        }
    }, [allRowData?.id])

    const handleChangeField = useCallback((value: any, field: string) => {
        setData((old)=>{
            if(!old){
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            return {...copyOld, [field]: value}
        })
    }, [setData])

    const handleEditing = useCallback(async (value: boolean) => {
        setIsEditing((old) => !old)
        if (value) {
            getTaskRepository()
        }
    }, [setIsEditing, getTaskRepository])


    const updateData = useCallback(async () => {
        startLoading()
        await twinFetchPostJSON('/api/app/task/repository/updateTaskRepository', {...data})
        endLoading()
        setIsEditing(false)
        onSubmit?.({} as any, {})
    }, [startLoading, endLoading, data, setIsEditing, onSubmit])
    
    useEffect(() => {
        getTaskRepository()
    }, [getTaskRepository])

    return { loading, updateData, isEditing, handleChangeField, data, getTaskRepository, handleEditing }
}

interface TaskRepositoryTitleProps {
    id: number
    value: string
    onChange: CustomChangeFunction
    readOnly: boolean
}

const TaskRepositoryTitle: React.FC<TaskRepositoryTitleProps> = ({ id, value, onChange, readOnly }) => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex items-center regular18 flex-auto'>
            <span className='mr-10 text-green-43'>#{id}</span>
            <RectangularTextAreaDebounce label='' placeholder={t('title', 'Título')} className='flex-auto input_change_task_repository_title' {...{value, onChange, readOnly}} />
        </div >
    )
}

const modalEditTaskRepositoryDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

export type ReduxModalEditTaskRepository = ConnectedProps<typeof modalEditTaskRepositoryConnect>
const mapModalEditTaskRepositoryConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const modalEditTaskRepositoryConnect = connect(mapModalEditTaskRepositoryConnector, modalEditTaskRepositoryDispatch)
const modalEditTaskRepositoryConnectLoading = withLoading(ModalEditTaskRepository, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])
const ModalEditTaskRepositoryConnect = modalEditTaskRepositoryConnect(modalEditTaskRepositoryConnectLoading)

export default ModalEditTaskRepositoryConnect