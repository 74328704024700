import { faCircleCheck, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { CampaignStatusModelType } from '@teinor/erp/types/company/customer/campaign/campaignStatus'
import { CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ComponentWithPermissions } from '../../baseComponents/CreateRoutering/types'
import { EditCardTabsHorizontalWithoutLinks } from '../../baseComponents/EditCardTabs'
import { ModalWithCloseButton } from '../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../baseComponents/PermissionChecker'
import { permissionCheck } from '../../baseComponents/PermissionChecker/function'
import TwinIcon, { TwinIconToolTip } from '../../baseComponents/TwinIcon'
import { RowData } from '../../baseComponents/TwinTable/types'
import CustomSelectColor from '../../forms/CustomSelect/CustomSelectColor'
import { InputCalendarWithHoursStateLess } from '../../forms/Input/InputCalendarWithHours'
import { OnSubmit } from '../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../utils/globals/data'
import { displayDate } from '../../utils/globals/date'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../utils/globals/dictionary'
import useEditDeleteModal from '../../utils/hooks/useEditDeleteModal'
import { ComponentTabObject } from '../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import CampaignCustomerActivityTab from './CampaignCustomerActivityTab'
import CampaignCustomerCommentTab from './CampaignCustomerCommentTab'
import ModalDeleteCampaignCustomer from './ModalDeleteCampaignCustomer'
import { CampaignCustomerModelTypeExtended, SetCampaignCustomerDataTy } from './types'
import CustomSelect from '../../forms/CustomSelect'
import './modaleditcampaigncustomer.sass'

type ModalEditCampaignCustomerProps = ModalEditComponentProps  & { }

const ModalEditCampaignCustomer: React.FC<ModalEditCampaignCustomerProps> = ({ allRowData, setOpened, onSubmit, userPermissions }) => {
    const { campaignCustomerData, getCampaignCustomer, setCampaignCustomerData } = useModalEditCampaignCustomerLogic({ allRowData })
    if (!campaignCustomerData) {
        return null
    }

    return (
        <ModalWithCloseButton size='modal_medium' opened={true} setOpened={setOpened} className='flex flex-col modal_edit_campaign_customer' onClickOut={false} showCloseButton={false} setOpenedReturn={setOpened}>
            <CampaignMain getCampaignCustomer={getCampaignCustomer} allRowData={allRowData || {}} campaignCustomerData={campaignCustomerData} setCampaignCustomerData={setCampaignCustomerData} setOpened={setOpened} onSubmit={onSubmit} userPermissions={userPermissions} />
        </ModalWithCloseButton>
    )
}

interface ModalEditCampaignCustomerLogicProps {
    allRowData?: RowData
}

const useModalEditCampaignCustomerLogic = ({ allRowData }: ModalEditCampaignCustomerLogicProps) => {
    const [campaignCustomerData, setCampaignCustomerData] = useState<CampaignCustomerModelTypeExtended | null>(null)
    const id = allRowData?.id
    const getCampaignCustomer = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/getCampaignCustomerInstanceComplete', { id })
        if (result) {
            setCampaignCustomerData(() => {
                const dictCampaignStatusHistory = dictionaryComplexFromJsonArr(result?.CampaignCustomerStatusHistories, 'CampaignStatusId', undefined, true)
                return {
                    ...result,
                    campaignStatusesHistoryDict: dictCampaignStatusHistory
                }
            })
        }
    }, [setCampaignCustomerData, id])


    useEffect(() => {
        getCampaignCustomer()
    }, [getCampaignCustomer])

    return { campaignCustomerData, getCampaignCustomer, setCampaignCustomerData }
}

interface CampaignMainProps extends CampaignMainLogicProps {
    allRowData: RowData
    setCampaignCustomerData: SetCampaignCustomerDataTy
    getCampaignCustomer: () => Promise<void>
}

const CampaignMain: React.FC<CampaignMainProps> = ({ allRowData, campaignCustomerData, setCampaignCustomerData, getCampaignCustomer, userPermissions, ...logic}) => {
    const { t } = useTwinTranslation()
    const { setTabActive, tabActive, parsedLink, openModal, setOpenModal, campaignStatuses, myOnSubmitDeleteCampaign } = useCampaignMainLogic({ campaignCustomerData, ...logic })

    const disableMssg = t('deleteCampaign', 'Eliminar campaña')
    const tabs: ComponentTabObject = {
        'activity': { component: CampaignCustomerActivityTab, text: t('activity', 'Actividad') },
        'comments': { component: CampaignCustomerCommentTab, text: t('comments', 'Comentarios') },
    }
    return (
        <div className='modal_edit_campaign_customer_title flex-auto flex flex-col'>
            <div className='flex items-center'>
                <Link to={parsedLink}><h2 className='cursor-pointer text-gray-51 hover:text-green-21'>{campaignCustomerData?.Customer?.name}</h2></Link>
                <PermissionChecker userPermissions={userPermissions || false} permission={'delete'}>
                    <TwinIconToolTip id='deleteCampaign' message={disableMssg} classNameIcon='h-20 hover:text-red-BA' className='ml-auto' icon={faTrashCan} onClick={() => setOpenModal({ type: 'delete', allRowData: {} })} />
                </PermissionChecker>
                {openModal?.type === 'delete' ?
                    <ModalDeleteCampaignCustomer onSubmit={myOnSubmitDeleteCampaign} id={campaignCustomerData?.id} setOpened={() => setOpenModal(null)} />
                    : null}
            </div>
            <CampaignCustomerFirstHeader campaignCustomerData={campaignCustomerData} getCampaignCustomer={getCampaignCustomer} myCampaignCustomerStatusesDict={campaignStatuses.myCampaignStatusesDict} onSubmit={logic.onSubmit} userPermissions={userPermissions || {n: 1, permission: 'read'}} setCampaignCustomerData={setCampaignCustomerData} />
            <CampaignCustomerStatusBar CurrentCampaignStatusId={campaignCustomerData.CurrentCampaignStatusId || 0} campaignStatusesHistoryDict={campaignCustomerData.campaignStatusesHistoryDict} myCampaignCustomerStatusesArr={campaignStatuses.myCampaignStatusesArr || []} />
            <EditCardTabsHorizontalWithoutLinks tabs={tabs} tab={tabActive} onClick={setTabActive} extraComponentData={{ campaignCustomerData, getCampaignCustomer, setCampaignCustomerData }} className='campaign_customer_tabs mt-34 flex-auto flex flex-col' />
        </div>
    )
}

interface CampaignMainLogicProps extends ModalEditComponentProps {
    campaignCustomerData: CampaignCustomerModelTypeExtended
}

const useCampaignMainLogic = ({ campaignCustomerData, onSubmit, setOpened }: CampaignMainLogicProps) => {
    const [tabActive, setTabActive] = useState('activity')
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [campaignStatuses, setCampaignStatuses] = useState<{
        myCampaignStatusesDict: TwinDictionary
        myCampaignStatusesArr: CampaignStatusModelType[]
    }>({myCampaignStatusesDict : {}, myCampaignStatusesArr : []})

    const typeCustomer: CustomerTypesInversedKeys = campaignCustomerData?.Customer?.customer_type || 0
    const parsedLink = useMemo(() => {
        const dictTypeCustomerLink: Record<CustomerTypesInversedKeys, string> = {
            0: 'customers',
            1: 'customersB2B',
            2: 'contactsB2B',
            3: 'contactsB2C'
        }
        return '/customer/' + dictTypeCustomerLink[typeCustomer] + '/' + campaignCustomerData?.Customer?.id + '/main'
    },
    [typeCustomer, campaignCustomerData?.Customer?.id])

    const getCampaignStatuses = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/getAllCampaignStatus', { where: { CampaignId: campaignCustomerData.CampaignId } })
        if (result) {
            setCampaignStatuses({
                myCampaignStatusesArr: result,
                myCampaignStatusesDict: dictionaryComplexFromJsonArr(result)
            })
        }
    }, [setCampaignStatuses, campaignCustomerData.CampaignId])


    const myOnSubmitDeleteCampaign: OnSubmit= useCallback(async (res) => {
        onSubmit(res, {})
        setOpened(null) 
    }, [onSubmit, setOpened])

    useEffect(() => {
        getCampaignStatuses()
    }, [getCampaignStatuses])

    return { tabActive, setTabActive, campaignStatuses, parsedLink, openModal, setOpenModal, myOnSubmitDeleteCampaign }
}

type CampaignCustomerFirstHeaderProps = CampaignCustomerFirstHeaderLogicProps & ComponentWithPermissions & {
    myCampaignCustomerStatusesDict: TwinDictionary
    campaignCustomerData: CampaignCustomerModelTypeExtended
}

const CampaignCustomerFirstHeader: React.FC<CampaignCustomerFirstHeaderProps> = ({ userPermissions, campaignCustomerData, myCampaignCustomerStatusesDict, getCampaignCustomer, onSubmit, setCampaignCustomerData }) => {
    const { t } = useTwinTranslation()
    const { handleOnChangeCampaignCustomerLimitDate, handleOnChangeCampaignCustomerStatus } = useCampaignCustomerFirstHeaderLogic({ getCampaignCustomer, id: campaignCustomerData.id, onSubmit, setCampaignCustomerData })
    const hasUpdatePermissions = permissionCheck(userPermissions, 'update')
    return (
        <div className='mt-18 flex items-start justify-between'>
            <div className='grid flex-auto gap-50 grid-cols-3'>
                <InputCalendarWithHoursStateLess label={t('limitDate', 'Fecha límite')} value={campaignCustomerData?.date ? new Date(campaignCustomerData?.date) : undefined} onChange={handleOnChangeCampaignCustomerLimitDate} readOnly={!hasUpdatePermissions} onDeleteDate={() => handleOnChangeCampaignCustomerLimitDate('')} />
                <CustomSelectColor items={myCampaignCustomerStatusesDict} value={campaignCustomerData?.CurrentCampaignStatusId} label={t('changeStatus', 'Cambiar de estado')} onChange={handleOnChangeCampaignCustomerStatus} readOnly={!hasUpdatePermissions} />
                <CustomSelect readOnly={true} label={t('ourManager', 'Nuestro gestor')} items={{ [campaignCustomerData?.Customer?.ResponsibleEmployeeId || 0]: { name: campaignCustomerData?.Customer?.ResponsibleEmployee?.fullname_short}}} value={campaignCustomerData?.Customer?.ResponsibleEmployeeId} />
            </div>
        </div>
    )
}

interface CampaignCustomerFirstHeaderLogicProps {
    setCampaignCustomerData: SetCampaignCustomerDataTy
    getCampaignCustomer: () => Promise<void>
    onSubmit: OnSubmit
    id?: number
}

const useCampaignCustomerFirstHeaderLogic = ({ getCampaignCustomer, id, onSubmit, setCampaignCustomerData }: CampaignCustomerFirstHeaderLogicProps) => {
    
    const handleOnChangeCampaignCustomerLimitDate = useCallback(async (value: any) => {
        const res = await twinFetchPostJSON('/api/app/customer/campaign/customer/updateCampaignCustomer', {
            id,
            date: value
        })
        setCampaignCustomerData((old)=>{
            const copyOld = JSON.parse(JSON.stringify(old))
            return {...copyOld, date: value}
        })
        onSubmit(res, {})
    }, [id, onSubmit, setCampaignCustomerData])

    const handleOnChangeCampaignCustomerStatus = useCallback(async (CurrentCampaignStatusId: string) => {
        const res = await twinFetchPostJSON('/api/app/customer/campaign/customer/updateCampaignCustomerCurrentStatus', {
            id,
            CurrentCampaignStatusId
        })
        getCampaignCustomer()
        onSubmit(res, {})
    }, [id, getCampaignCustomer, onSubmit])

    return { handleOnChangeCampaignCustomerLimitDate, handleOnChangeCampaignCustomerStatus }
}

interface CampaignCustomerStatusBarProps {
    CurrentCampaignStatusId: number
    campaignStatusesHistoryDict: TwinDictionary
    myCampaignCustomerStatusesArr: CampaignStatusModelType[]
}

const CampaignCustomerStatusBar: React.FC<CampaignCustomerStatusBarProps> = ({ CurrentCampaignStatusId, myCampaignCustomerStatusesArr, campaignStatusesHistoryDict }) => {
    const renderThis: JSX.Element[] = []
    let lastDate: null | Date = null
    let isColored = CurrentCampaignStatusId ? true : false
    for (const element of myCampaignCustomerStatusesArr) {
        const finishedDate = campaignStatusesHistoryDict[element.id]?.finishedDate
        let displayDateDate = finishedDate ? new Date(finishedDate) : null
        if (!lastDate && displayDateDate) {
            lastDate = displayDateDate
        }
        if (displayDateDate && lastDate && displayDateDate < lastDate) {
            displayDateDate = null
        } else if (displayDateDate) {
            lastDate = displayDateDate
        }
        const isCurrentStatus = CurrentCampaignStatusId === element.id
        if (isCurrentStatus) {
            const createdAt = campaignStatusesHistoryDict[element.id]?.createdAt
            displayDateDate = new Date(createdAt)
        }
        renderThis.push(
            <CampaignStatusBox name={element.name} displayDate={isColored && displayDateDate ? displayDate(displayDateDate) : ''} color={isColored ? element.color : '#D9D9D9'} hasIcon={!isCurrentStatus} key={element.id} />
        )
        if (isCurrentStatus) {
            isColored = false
        }
    }
    return (
        <div className='mt-23 flex gap-2 overflow-auto'>
            {renderThis}
        </div>
    )
}

interface CampaignStatusBoxProps {
    displayDate?: string
    name: string
    color?: string
    hasIcon?: boolean
}

const CampaignStatusBox: React.FC<CampaignStatusBoxProps> = ({ displayDate, name, color, hasIcon }) => {
    return (
        <div className='flex flex-col campaign_customer_status_box p-10 bg-gray-F7 border-t-4' style={{ borderColor: color }}>
            <span className='text-gray-51 text-14 twin_elipsis'>{name}</span>
            {displayDate ? 
                <div className='flex justify-between items-center h-24'>
                    <span className='text-gray-51 text-12 font-light mr-40'>{displayDate}</span>
                    {hasIcon ? 
                        <TwinIcon icon={faCircleCheck} className='text-green-43 w-20 h-20'></TwinIcon>  
                        : null
                    }
                </div> 
                : null
            }
        </div>
    )
}

export default ModalEditCampaignCustomer