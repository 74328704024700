import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'

interface ModalCancelRequestEmployeeAbsenceProps extends ModalEditComponentProps {}

const ModalCancelRequestEmployeeAbsence: React.FC<ModalCancelRequestEmployeeAbsenceProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/employee/employeeAbsence/cancelMyAbsenceRequest',
        data: {
            id: allRowData?.id
        }
    })

    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureCancelEmployeeAbsence', '¿Seguro que quieres cancelar esta ausencia?'),
            subtitle: t('onceCanceledNotRecoverM', 'Una vez cancelado no podrás recuperarlo'),
            buttonAccept: t('cancel', 'Cancelar'),
            buttonCancel: t('return', 'Volver')
        }} />
    )
}

export default ModalCancelRequestEmployeeAbsence