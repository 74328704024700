import { WCalOvertimeModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import { TwinDictionary } from '../../../../../../utils/globals/dictionary'
import { CustomRenderDate, CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import withLoading from '../../../../../../utils/hoc/withLoading'
import { displayFormatedHourFromMins } from '../../../../../../utils/globals/date'
import ModalCreateWCalOvertimeRequests from './ModalCreateWCalOvertimeRequests'
import ModalEditWCalOvertimeRequests from './ModalEditWCalOvertimeRequests'
import { RenderOvertimeSchedule, RenderOvertimeStatus } from '../../../../../../specificComponents/WCal/schedule/ScheduleOvertime'
import useDictRequestOvertime from '../../../../../../utils/hooks/useDictRequestOvertime'


interface WorkingCalendarOvertimeRequestsProps extends ComponentWithPermissions { }

const WorkingCalendarOvertimeRequests: React.FC<WorkingCalendarOvertimeRequestsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('overtime', 'Horas extra'),
        newText: t('newOvertime', 'Nuevas horas extra'),
    }
    return (
        <BaseScreenTable TableComponent={TableWorkingCalendarOvertimeRequestsBagConnect} pageData={pageData} userPermissions={userPermissions} EditModalComponent={ModalEditWCalOvertimeRequests} CreateModalComponent={ModalCreateWCalOvertimeRequests} />
    )
}

type TableWorkingCalendarOvertimeRequestsProps = TableComponentProps & ReduxEmployees & {}

const TableWorkingCalendarOvertimeRequests: React.FC<TableWorkingCalendarOvertimeRequestsProps> = ({ userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const {requestStatusOvertimeTypesDic} = useDictRequestOvertime()

    const columns: ColumnTableSchema<WCalOvertimeModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75,
            sortable: true
        },
        {
            id: 'EmployeeIdRequest',
            dataKey: 'EmployeeId',
            label: t('employee', 'Empleado'),
            searchKey: 'Employee.fullname_short',
            searchSingleKey: 'EmployeeId',
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees as TwinDictionary, label: t('employee', 'Empleado'), fieldName: 'fullname_short' }
            },
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData.Employee.fullname_short || ''} />
        },
        {
            id: 'EmployeeRequestId',
            dataKey: 'EmployeeRequestId',
            label: t('requestedBy', 'Solicitado por'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={(employees?.[parameterValue as any]?.fullname_short || '')} />
        },
        {
            id: 'day',
            dataKey: 'day',
            label: t('date', 'Fecha'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'schedule',
            dataKey: 'schedule',
            label: t('schedule', 'Horario'),
            sortable: true,
            customRender: (parameterValue) => <RenderOvertimeSchedule value={parameterValue} />
        },
        {
            id: 'overtimeMins',
            dataKey: 'overtimeMins',
            label: t('hours', 'Hours'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderString value={displayFormatedHourFromMins(parseInt(parameterValue))} />
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderOvertimeStatus value={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: requestStatusOvertimeTypesDic, label: t('status', 'Estado') }
            },
        },
        {
            id: 'approvedDate',
            dataKey: 'approvedDate',
            label: t('approvedDate', 'Fecha de aprobación'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'paymentDate',
            dataKey: 'paymentDate',
            label: t('paymentDate', 'Fecha de pago'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='WorkingCalendarOvertimeRequests' columns={columns} getDataFrom='/api/app/workingCalendar/workingCalendarOvertime/getAllWCalOvertimes' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(TableWorkingCalendarOvertimeRequests, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const TableWorkingCalendarOvertimeRequestsBagConnect = employeesConnect(employeesConnectLoading)

export default WorkingCalendarOvertimeRequests