import { WCalHolidayUsedDayModelType, WCalHolidayUsedDayTypeKeysInversed } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { useCallback, useEffect, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../../../../../baseComponents/Button'
import { DisplayStatusWithoutIcon } from '../../../../../../../../../../baseComponents/Displays/DisplayStatus'
import { ModalMedium } from '../../../../../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../../../../baseComponents/PermissionChecker'
import { VirtualTableListingStateLess } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { WCalHolidayUsedDayModelTypeExtended } from '../../../../../../../../../../specificComponents/WCal/WCalHolidayUsedDay/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../../../../utils/globals/date'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useIsLoading from '../../../../../../../../../../utils/hooks/useIsLoading'
import useStatusRequestTypes from '../../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'
import CustomRenderWCalHalfDayForM, { CustomRenderWCalHalfDayForTable } from '../../../../../../../../../../specificComponents/WCal/WCalHolidayUsedDay/CustomRenderWCalHalfDay'
import useDictHolidayTypes from '../../../../../../../../../../utils/hooks/wCalHolidayBag/useDictHolidayTypes'

interface ModalAdminNotificationsHolidayUsedDaysProps extends ModalAdminNotificationsHolidayUsedDaysLogicProps {
}

const ModalAdminNotificationsHolidayUsedDays: React.FC<ModalAdminNotificationsHolidayUsedDaysProps> = ({ setOpened, allRowData, onSubmit }) => {
    const { t, tVars } = useTwinTranslation()
    const status = useStatusRequestTypes()
    const { dictHolidayTypes } = useDictHolidayTypes()
    const { data, manageRequestedHolidays, loading } = useModalAdminNotificationsHolidayUsedDaysLogic({ allRowData, setOpened, onSubmit })
    const statusButtons: TwinDictionary = {
        'approved': <ButtonOrLoader key='approved' onClick={() => manageRequestedHolidays('approved')} textButton={t('approve', 'Aprobar')} buttonIsDisabled={loading} />,
        'denied': <ButtonOrLoader key='denied' onClick={() => manageRequestedHolidays('denied')} textButton={t('deny', 'Denegar')} buttonIsDisabled={loading} className='button_red' />,
    }
    const renderThis: JSX.Element[] = []
    if (data?.approved === 0) {
        renderThis.push(statusButtons['approved'])
        renderThis.push(statusButtons['denied'])
    }  else {
        renderThis.push(<div key='requestManaged'><TwinTrans transKey={'requestManaged'}>Esta solicitud ya ha sido gestionada</TwinTrans></div>)
    }
    const extraColumns: any[] = []
    if (data?.totalDaysInvested) {
        extraColumns.push({
            name: 'totalDaysInvested',
            component: 'TextField',
            label: t('daysInvested', 'Días invertidos'),
            value: tVars('investedDaysHolidayTypes', '{{totalDaysInvested}} días {{holidayType}}', { totalDaysInvested: data?.totalDaysInvested, holidayType: dictHolidayTypes[data?.WCalHolidayBag?.holidayType || 0].name.toLowerCase() })
        })
    }

    const fields: FormRender<WCalHolidayUsedDayModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    component: 'TextField',
                    label: t('holidayBag', 'Bolsa de vacaciones'),
                    value: data?.WCalHolidayBag?.name,
                },
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.WCalHolidayBag?.Employee?.fullname_short,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'from',
                    component: 'TextField',
                    label: t('from', 'Desde'),
                    value: displayDate(new Date(data?.from || ''))
                },
                {
                    name: 'to',
                    component: 'TextField',
                    label: t('to', 'Hasta'),
                    value: displayDate(new Date(data?.to || '')),
                },
                ...extraColumns,
                {
                    name: 'isHalfDay',
                    component: 'Custom',
                    extraProps: { value: data?.isHalfDay },
                    render: () => <CustomRenderWCalHalfDayForM value={data?.isHalfDay} />,
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'approvedDate',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de aprobación'),
                    value: data?.approvedDate ? displayDate(new Date(data?.approvedDate)) : ''
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    const userPermission = getUserPermissions('WCal.holiday.usedDays')
    if (userPermission) {
        const parsedFields = valueOrDefaultValue(fields, userPermission, allRowData)
        return (
            <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <div className='mb-25'>
                    <h2><TwinTrans transKey='notificationInfo'>Información de la notificación</TwinTrans></h2>
                </div>
                <div className={'flex flex-auto h-1'}>
                    <div className='overflow-auto flex flex-auto flex-col notFlexAutoFormRender'>
                        {fields.length ?
                            <FormRenderer sections={parsedFields} className='grid_mincontent'>
                            </FormRenderer>
                            : ''}
                        <MatchHolidays data={data || undefined} />
                    </div>
                </div>
                <div className='mt-30 ml-auto flex gap-15 edit_holiday_requests_footer'>
                <PermissionChecker userPermissions={userPermission} permission={'update'}>
                        {renderThis}
                </PermissionChecker>
                </div>
            </ModalMedium>
        )
    }
    return null
}

interface ModalAdminNotificationsHolidayUsedDaysLogicProps extends ModalEditComponentProps{
}

const useModalAdminNotificationsHolidayUsedDaysLogic = ({ allRowData, setOpened, onSubmit }: ModalAdminNotificationsHolidayUsedDaysLogicProps) => {
    const {endLoading, loading, startLoading} = useIsLoading()
    const { t } = useTwinTranslation()
    const [data, setData] = useState<WCalHolidayUsedDayModelTypeExtended | null>(null)
    const message = t('successfullyCompleted', 'Realizado correctamente')
    const manageRequestedHolidays = useCallback(async (status: WCalHolidayUsedDayTypeKeysInversed) => {
        startLoading()
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/manageHolidayRequest', {
            id: data?.id,
            approved: status === 'approved' ? 1 : 2
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
        endLoading()
    }, [data?.id, setOpened, onSubmit, message, startLoading, endLoading])

    const getUsedDaysData = useCallback(async() => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getWCalHolidayBagUsedDaysInstanceForManage', { id: allRowData?.SubId })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.SubId ])

    useEffect(() => {
        getUsedDaysData()
    }, [getUsedDaysData])
    return { manageRequestedHolidays, data, loading }
}


interface MatchHolidaysProps {
    data?: WCalHolidayUsedDayModelTypeExtended
}

const MatchHolidays: React.FC<MatchHolidaysProps> = ({ data }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<WCalHolidayUsedDayModelType> = [
        {
            id: 'from',
            dataKey: 'from',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('to', 'Hasta'),
            customRender: (parameterValue, allRowData) => <CustomRenderWCalHalfDayForTable endDate={parameterValue} isHalfDay={allRowData.isHalfDay}/>,
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            label: t('employee', 'Empleado'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.WCalHolidayBag?.Employee?.fullname_short} />,
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            width: 220
        },
    ]
    return (
        <div className='h-300'>
            <h2 className='text-18 text-gray-51 mb-10'>
                <TwinTrans transKey='matchHolidays'>Vacaciones que coinciden</TwinTrans>
            </h2>
            <VirtualTableListingStateLess tableData={data?.matchHolidays as any || []} rowHeight={48} headerHeight={48} name='HolidayMatchDetail' columns={columns} setSelectedColumnsAndFilters={() => { }} />
        </div>
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

export default ModalAdminNotificationsHolidayUsedDays