import { ModalMedium } from '../../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { CustomRenderDate, CustomRenderString } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { VirtualTableListingStateLess } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { WCalOvertimeModelTypeExtended } from '..'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { displayFormatedHourFromMins } from '../../../../../../../utils/globals/date'
import { OvertimeScheduleErrorsTy, RenderOvertimeSchedule, RenderOvertimeStatus, ScheduleOvertime } from '../../../../../../../specificComponents/WCal/schedule/ScheduleOvertime'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import TwinForm from '../../../../../../../forms/TwinForm'
import FormRenderer from '../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { WCalOvertimeModelAdd, WCalOvertimeModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { flushSync } from 'react-dom'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../../../../../baseComponents/Modal/types'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import ModalSmallFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import { checkSchedulesAllOk } from '../../../../../../../utils/globals/schedule'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'
import useDictRequestOvertime from '../../../../../../../utils/hooks/useDictRequestOvertime'
import { getNextOrder } from '../../../../../../../baseComponents/TwinTable/functions'
import { TableConfiguration } from '../../../../../../../baseComponents/TwinTable/types'
import ModalDeleteFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'


type ModalOvertimeDetailProps = ModalEditComponentProps & ModalOvertimeDetailLogicProps & {
    totalHours?: number
    totalOvertimeMins?: number
}

const ModalOvertimeDetail: React.FC<ModalOvertimeDetailProps> = ({ setOpened, allRowData, totalHours, year, totalOvertimeMins, onSubmit }) => {
    const { overtime, getOvertimeData } = useModalOvertimeDetailLogic({ year })
    return (
        <ModalMedium className={'h-full modal_medium flex flex-col'} opened={true} setOpened={setOpened}>
            <div className='modal_negative_margin flex-auto flex-col flex h-1'>
                <div className='overflow-auto flex flex-col flex-auto pr-30'>
                    <RequestOvertimeSection totalOvertimeMins={totalOvertimeMins} totalHours={totalHours} defaultModalRequestValue={allRowData?.requestingOvertime} setOpened={setOpened} onSubmit={(res) => { onSubmit(res, {}); getOvertimeData() }} />
                    {overtime ? <OvertimeDetailTable overtime={overtime} getData={getOvertimeData} /> : <LoadingSpinner />}
                </div>
            </div>
        </ModalMedium>
    )
}

interface ModalOvertimeDetailLogicProps {
    year: number
}

const useModalOvertimeDetailLogic = ({ year }: ModalOvertimeDetailLogicProps) => {
    const [overtime, setOvertime] = useState<WCalOvertimeModelType[] | null>(null)
    const getOvertimeData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeOvertime/getAllWCalOvertimesByEmployeeByYear', { year })
        if (result) {
            setOvertime(result)
        } 
    }, [setOvertime, year])

    useEffect(() => {
        getOvertimeData()
    }, [getOvertimeData])

    return { overtime, getOvertimeData }
}


interface RequestOvertimeSectionProps extends RequestOvertimeSectionLogicProps {
    totalHours?: number
    setOpened: ModalOpenedSetTy
    totalOvertimeMins?: number
    onSubmit: OnSubmit
}

const RequestOvertimeSection: React.FC<RequestOvertimeSectionProps> = ({ totalHours, defaultModalRequestValue, setOpened,  totalOvertimeMins, onSubmit }) => {
    const { tVars } = useTwinTranslation()
    const { requestHolidayOpened, setRequestHolidayOpened } = useRequestOvertimeSectionLogic({ defaultModalRequestValue })
    return (
        <div className='mt-30'>
            <div className='flex mb-10'>
                <h1 className='text-22 text-gray-51'>
                    <TwinTrans transKey='overtime'>Horas extra</TwinTrans>
                </h1>
                <span className='ml-auto text-16 text-green-21 cursor-pointer' onClick={() => setRequestHolidayOpened((old) => !old)}><TwinTrans transKey='requestOvertime'>Solicitar horas extra</TwinTrans></span>
            </div>
            <span className='text-gray-51 light16'>{tVars('youHaveXhoursRemaining', 'Te quedan {{overtimeHours}} horas extra disponibles ', { overtimeHours: displayFormatedHourFromMins((totalHours || 0) - (totalOvertimeMins || 0)) })}</span>
            {requestHolidayOpened ? <CreateRequestOvertimeByEmployee setOpened={setOpened} onSubmit={onSubmit} /> : null}
        </div>
    )
}

interface RequestOvertimeSectionLogicProps {
    defaultModalRequestValue: boolean
}

const useRequestOvertimeSectionLogic = ({ defaultModalRequestValue }: RequestOvertimeSectionLogicProps) => {
    const [requestHolidayOpened, setRequestHolidayOpened] = useState<boolean>(defaultModalRequestValue)
    return { requestHolidayOpened, setRequestHolidayOpened }
}

interface CreateRequestOvertimeByEmployeeProps extends CreateRequestOvertimeByEmployeeLogicProps { }


const CreateRequestOvertimeByEmployee: React.FC<CreateRequestOvertimeByEmployeeProps> = ({ setOpened, ...logic }) => {
    const { handleBeforeSubmit, schedule, setSchedule, loading } = useCreateRequestOvertimeByEmployeeLogic({ setOpened, ...logic })
    const { t } = useTwinTranslation()
    const { overtimePreferences} = useDictRequestOvertime()

    const fields: FormRender<WCalOvertimeModelAdd> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'day',
                    label: t('day', 'Día'),
                    required: true,
                    component: 'InputCalendarStateFull'
                },
                {
                    name: 'overtimePreferences',
                    component: 'CustomSelect',
                    label: t('preferences', 'Preferencias'),
                    items: overtimePreferences
                },
                {
                    name: 'reason',
                    component: 'TextArea',
                    label: t('reason', 'Justificación'),
                    parentClassName: returnClassOfSpanTemplate(2),
                },
            ]
        }
    ]
    return (
        <TwinForm className='mt-22 border-solid border-b border-gray-D6' action='' beforeSubmitHandler={(vals) => { handleBeforeSubmit(vals); return false }} onSubmit={() => {}}>
            <FormRenderer sections={fields}/>
            <ScheduleOvertime schedule={schedule} setSchedule={setSchedule} />
            <ButtonOrLoader textButton={t('request', 'Solicitar')} buttonIsDisabled={loading} className='ml-auto my-20' />
        </TwinForm>)
}

interface CreateRequestOvertimeByEmployeeLogicProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}

const useCreateRequestOvertimeByEmployeeLogic = ({ setOpened, onSubmit}: CreateRequestOvertimeByEmployeeLogicProps) => {
    const [schedule, setSchedule] = useState<OvertimeScheduleErrorsTy>({ workingTime: [], errors: [] })
    const { endLoading, loading, startLoading } = useIsLoading()
    const { t } = useTwinTranslation()
    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const errorHoursEmpty = t('notSelectedAnSchedule', 'No has seleccionado ningún horario.')
    const messages = JSON.stringify({
        'Already have a request for this day': t('alreadyHaveARequestForThisDay', 'Ya hay una solicitud para este día'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
        'Overtime exceeded the allowed hours': t('overtimeExceeded', 'Has excedido las horas extras para este año')
    })
    const message = t('successfullyCompleted', 'Realizado correctamente')

    const handleBeforeSubmit = useCallback(async (values: TwinDictionary) => {
        await flushSync(async () => {
            startLoading()
            if (schedule.workingTime.length) {
                let hasError = false
                let checkedSchedule: OvertimeScheduleErrorsTy | false = false
                await setSchedule((old) => {
                    const copyOld = JSON.parse(JSON.stringify(old))
                    const myDaySchedule = copyOld
                    myDaySchedule.errors = []
                    const response = checkSchedulesAllOk(myDaySchedule)
                    if (response.hasError) {
                        hasError = true
                    }
                    if (hasError) {
                        changeErrorMessage(errorHours)
                    }
                    if (!hasError) {
                        checkedSchedule = { ...copyOld }
                    }
                    return { ...copyOld }
                })
                if (hasError) {
                    changeErrorMessage(errorHours)
                    endLoading()
                    return false
                }
                if (checkedSchedule) {
                    values['schedule'] = JSON.stringify(schedule.workingTime)
                }
            } else {
                changeErrorMessage(errorHoursEmpty)
                endLoading()
                return false
            }

            const result = await twinFetchPost('/api/app/employee/employeeOvertime/createWCalOvertimeByEmployee', values)
            if (result.status === 200) {
                setOpened(null)
                onSubmit(result, {})
                endLoading()
                changeSuccessMessage(message)
            } else {
                const responseJSON = await result.json()
                const messagesParsed = JSON.parse(messages)
                if (responseJSON.errors[0].msg && messagesParsed[responseJSON.errors[0].msg]) {
                    changeErrorMessage(messagesParsed[responseJSON.errors[0].msg])
                }
                endLoading()
            }
        })
        return false
    }, [schedule, startLoading, endLoading, errorHours, setOpened, errorHoursEmpty, messages, message, onSubmit])

    return { handleBeforeSubmit, schedule, setSchedule, loading }
}



interface OvertimeDetailTableProps extends OvertimeDetailTableLogicProps {
    overtime: WCalOvertimeModelType[]
}

const OvertimeDetailTable: React.FC<OvertimeDetailTableProps> = ({ overtime, getData }) => {
    const { t } = useTwinTranslation()
    const {requestStatusOvertimeTypesDic} = useDictRequestOvertime()
    const { openApproveOrDenyModal, setOpenApproveOrDenyModal, handleOpenApproveOrDenyOvertime, handleChangeStatusOvertime, selectedOvertime, setSelectedOvertime, headerChanges, tableConfiguration, parsedTableData } = useOvertimeDetailTableLogic({getData})
    if (!overtime) {
        return <LoadingSpinner/>
    }

    const columns: ColumnTableSchema<WCalOvertimeModelTypeExtended> = [
        {
            id: 'day',
            dataKey: 'day',
            label: t('date', 'Fecha'),
            sortable: true,
            width: 120,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'schedule',
            dataKey: 'schedule',
            label: t('schedule', 'Horario'),
            customRender: (parameterValue) => <RenderOvertimeSchedule value={parameterValue} />
        },
        {
            id: 'overtimeMins',
            dataKey: 'overtimeMins',
            label: t('hours', 'Hours'),
            width: 80,
            customRender: (parameterValue) => <CustomRenderString value={displayFormatedHourFromMins(parseInt(parameterValue))} />
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderOvertimeStatus value={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: requestStatusOvertimeTypesDic, label: t('status', 'Estado') }
            },
        },
        {
            id: 'approve',
            dataKey: 'status',
            label: '',
            width: 40,
            customRender: (parameterValue, allRowData) => {
                if (parseInt(parameterValue) === 0 && allRowData.subtype === 0) {
                    return (
                        <div className='cursor-pointer' onClick={() => handleOpenApproveOrDenyOvertime({ id: allRowData.id, type: 'approved'})}>
                            <TwinIcon icon={faCheck} color='#218679' className='w-20 h-20' />
                        </div>)
                } else {
                    return <div></div>
                }
            }
        },
        {
            id: 'deny',
            dataKey: 'status',
            label: '',
            width: 40,
            customRender: (parameterValue, allRowData) => {
                if (parseInt(parameterValue) === 0 && allRowData.subtype === 0) {
                    return (
                        <div className='cursor-pointer' onClick={() => handleOpenApproveOrDenyOvertime({ id: allRowData.id, type: 'denied'})}>
                            <TwinIcon icon={faXmark} color='#BA435D' className='w-20 h-20' />
                        </div>)
                } else {
                    return <div></div>
                }
            }
        },
    ]
    const parsedData = parsedTableData(overtime)
    return (
        <div className='mb-20 mt-25 flex flex-col flex-auto'>
            <div className='justify-between items-center mb-20'>
                <h2 className='text-18 text-gray-51'>
                    <TwinTrans transKey='overtimeRequiredDetail'>Detalle de las horas extra solicitadas</TwinTrans>
                </h2>
            </div>
            <div className='min-h-200 flex-auto h-1 overflow-auto'>
                <VirtualTableListingStateLess tableData={parsedData} rowHeight={48} headerHeight={48} name='OvertimeRequestDetail' columns={columns} setSelectedColumnsAndFilters={() => {}} headerChanges={headerChanges} tableConfiguration={tableConfiguration} />
            </div>
            {openApproveOrDenyModal && selectedOvertime ? <ModalAcceptOrDenyOvertime setOpened={setOpenApproveOrDenyModal} onSubmit={() => { } } setSelectedOvertimeId={setSelectedOvertime} selectedOvertimeId={selectedOvertime} handleChangeStatusOvertime={handleChangeStatusOvertime} type={selectedOvertime.type}/>: null}
        </div>
    )
}

interface SelectedOvertimeValues {
    id: number,
    type: 'approved' | 'denied'
}

interface OvertimeDetailTableLogicProps {
    getData: () => Promise<void>
}

const useOvertimeDetailTableLogic = ({ getData }: OvertimeDetailTableLogicProps) => {
    const [openApproveOrDenyModal, setOpenApproveOrDenyModal] = useState<ModalOpenedTy>(null)
    const [selectedOvertime, setSelectedOvertime] = useState<SelectedOvertimeValues | null>(null)
    const [tableConfiguration, setTableConfiguration] = useState<TableConfiguration>({
        generalSearchbar: '',
        order: {},
        offset: 0,
        singleFieldsSearch: {},
        petitionNumber: 0
    })

    const changeOrder = useCallback((fieldName: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            const newOrder = getNextOrder(fieldName, copyOld.order, 'desc')
            return {
                ...copyOld,
                offset: 0,
                order: { ...newOrder }
            }
        })
    }, [setTableConfiguration])

    const changeSingleField = useCallback((fieldName: string, value: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset: 0,
                singleFieldsSearch: {
                    ...copyOld.singleFieldsSearch,
                    [fieldName]: value
                }
            }
        })
    }, [setTableConfiguration])

    const handleOpenApproveOrDenyOvertime = useCallback((values: SelectedOvertimeValues) => {
        setSelectedOvertime(values)
        setOpenApproveOrDenyModal(true)
    }, [setOpenApproveOrDenyModal, setSelectedOvertime])

    const handleChangeStatusOvertime = useCallback(async (type: 'approved' | 'denied') => {
        let myStatus = type === 'approved' ? 1 : 3
        await twinFetchPost('/api/app/employee/employeeOvertime/manageOvertimeRequestByEmployee', { id: selectedOvertime?.id, status: myStatus })
        getData()
    }, [selectedOvertime, getData])

    const parsedTableData = useCallback((data: any[]) => {
        const myData = JSON.parse(JSON.stringify(data))
        let parsedData = []
        if (tableConfiguration.singleFieldsSearch.status) {
            for (const element of myData) {
                if (parseInt(element.status) === parseInt(tableConfiguration.singleFieldsSearch.status)) {
                    parsedData.push(element)
                }
            }
        } else {
            parsedData = myData
        }
        if (tableConfiguration.order.day) {
            const sortTypeIsDesc = tableConfiguration.order.day === 'desc'
            parsedData.sort((a: any, b: any) => {
                return sortTypeIsDesc ? new Date(b.day) > new Date(a.day) ? 1 : -1 : new Date(b.day) < new Date(a.day) ? 1 : -1
            })
        }
        return parsedData
    }, [tableConfiguration])

    return { openApproveOrDenyModal, setOpenApproveOrDenyModal, handleOpenApproveOrDenyOvertime, handleChangeStatusOvertime, selectedOvertime, setSelectedOvertime, headerChanges: { changeOrder, changeSingleField }, tableConfiguration, parsedTableData }
}

interface ModalApproveOrDenyOvertimeProps extends ModalEditComponentProps { 
    setSelectedOvertimeId: React.Dispatch<SetStateAction<SelectedOvertimeValues | null>>
    selectedOvertimeId: SelectedOvertimeValues | null
    handleChangeStatusOvertime: (type: 'approved' | 'denied') => Promise<void>
    type: 'approved' | 'denied'
}

const ModalAcceptOrDenyOvertime: React.FC<ModalApproveOrDenyOvertimeProps> = ({ setOpened, setSelectedOvertimeId, handleChangeStatusOvertime, type }) => {
    const { t } = useTwinTranslation()
    if (type === 'approved') {
        return (<ModalSmallFilled translations={{
            title: t('acceptOvertime', 'Aceptar horas extra'),
            subtitle: t('sureAcceptOvertime', '¿Estás seguro de querer aceptar las horas extra?'),
            buttonAccept: t('accept', 'Aceptar'),
        }} onAccept={() => { handleChangeStatusOvertime(type);  setOpened(null) }} opened={true} setOpened={setOpened} onCancel={() => setSelectedOvertimeId(null)}/>)
    } else {
        return (<ModalDeleteFilled translations={{
            title: t('denyOvertime', 'Denegar horas extra'),
            subtitle: t('sureDenyOvertime', '¿Estás seguro de querer denegar las horas extra?'),
            buttonAccept: t('deny', 'Denegar'),
        }} onAccept={() => { handleChangeStatusOvertime(type); setOpened(null) }} opened={true} setOpened={setOpened} onCancel={() => setSelectedOvertimeId(null)} />)
    }
}




export default ModalOvertimeDetail