import { faPencil, faXmark } from '@fortawesome/pro-light-svg-icons'
import { ModalMedium } from '../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import TChecklistSection from './TChecklistSection'
import { TChecklistModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { OnSubmit } from '../../../../../../forms/TwinForm/types'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import { ButtonDisabledOrLoader } from '../../../../../../baseComponents/Button'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { RectangularTextArea } from '../../../../../../forms/Input/TextArea'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { getUserPermissions } from '../../../../../../utils/reducers/getters'
import ButtonAddChecklistSection from '../../../../../../baseComponents/Checklist/ButtonAddChecklistSection'
import './modalEditTChecklist.sass'

interface ModalEditTChecklistProps extends ModalEditTChecklistLogicProps { }

const ModalEditTChecklist: React.FC<ModalEditTChecklistProps> = ({ allRowData, ...rest }) => {
    const { onCloseModal, data } = useModalEditTChecklistLogic({ allRowData, ...rest })
    if (!data) {
        return null
    }
    return (
        <ModalMedium opened={true} setOpened={onCloseModal} onClickOut={false} className='flex flex-col '>
            <TChecklistTitle data={data} onSubmit={rest.onSubmit} />
            <div className='flex flex-col flex-auto h-1 overflow-auto checklist_negative_margin'>
                <Checklist checklist={data} />
            </div>
        </ModalMedium>
    )
}

interface ModalEditTChecklistLogicProps extends ModalEditComponentProps { }

const useModalEditTChecklistLogic = ({ setOpened, onSubmit, allRowData }: ModalEditTChecklistLogicProps) => {
    const [data, setData] = useState<TChecklistModelType | null>(null)

    const onCloseModal = useCallback(() => {
        setOpened(null)
        onSubmit({} as any, [])
    }, [setOpened, onSubmit])

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/config/templateChecklist/getTChecklistComplete', { id: allRowData?.id })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getData()
    }, [getData])

    return { onCloseModal, data }
}

interface TChecklistTitleProps extends TChecklistTitleLogicProps { }

const TChecklistTitle: React.FC<TChecklistTitleProps> = ({ data, ...logic }) => {
    const { loading, opened, handleOpenEditTitle, setTitle, updateNameTChecklist, title } = useTChecklistTitleLogic({ data, ...logic })
    const { t } = useTwinTranslation()
    if (opened) {
        return (
            <div className='flex items-center mb-10'>
                <RectangularTextArea className='w-full input_change_tchecklist_name rounded-md' value={title} onChange={setTitle} />
                <ButtonDisabledOrLoader className='ml-10' onClick={updateNameTChecklist} loading={loading} buttonIsDisabled={title === '' || data?.name === title} textButton={t('save', 'Guardar')} />
                <TwinIcon className='ml-10 cursor-pointer hover:text-red-BA' icon={faXmark} onClick={handleOpenEditTitle} />
            </div>
        )
    }
    return (
        <div className='flex items-center ml-16 mb-10'>
            <h2>{title}</h2>
            <TwinIcon className='ml-10 cursor-pointer hover:text-green-43' icon={faPencil} onClick={handleOpenEditTitle} />
        </div>
    )
}

interface TChecklistTitleLogicProps {
    data: TChecklistModelType
    onSubmit: OnSubmit
}

const useTChecklistTitleLogic = ({ data, onSubmit }: TChecklistTitleLogicProps) => {
    const [opened, setOpened] = useState(false)
    const [title, setTitle] = useState(data?.name || '')
    const { startLoading, endLoading, loading } = useIsLoading()
    const userPermission = getUserPermissions('config.customerProject.templateChecklist')

    const handleOpenEditTitle = useCallback(() => {
        if (permissionCheck(userPermission, 'update')) {
            setOpened((old) => !old)
        }
    }, [userPermission])

    const updateNameTChecklist = useCallback(async () => {
        startLoading()
        const res = await twinFetchPostJSON('/api/app/config/templateChecklist/updateTChecklist', { name: title, id: data?.id })
        endLoading()
        onSubmit(res, {})
        setOpened(false)
    }, [startLoading, endLoading, title, onSubmit, data?.id])

    return { loading, opened, setTitle, setOpened, updateNameTChecklist, title, handleOpenEditTitle }
}


interface ChecklistProps extends ChecklistLogicProps {
}

const Checklist: React.FC<ChecklistProps> = ({ checklist }) => {
    const { addSection, dataChecklist, setDataChecklist, refChecklistSection } = useChecklistLogic({ checklist })
    const renderThis: JSX.Element[] = []
    if (dataChecklist?.TChecklistSections) {
        for (const key in dataChecklist.TChecklistSections) {
            const section = dataChecklist.TChecklistSections[key]
            renderThis.push(<TChecklistSection key={section.id} posSection={parseInt(key)} {...{ section, setDataChecklist, addSection, refChecklistSection }} />)
        }
    }
    const userPermission = getUserPermissions('config.customerProject.templateChecklist')
    return (
        <div ref={refChecklistSection}>
            {renderThis}
            <ButtonAddChecklistSection addSection={addSection} userPermission={userPermission} />
        </div>
    )
}

interface ChecklistLogicProps {
    checklist: TChecklistModelType
}

const useChecklistLogic = ({ checklist }: ChecklistLogicProps) => {
    const [dataChecklist, setDataChecklist] = useState<TChecklistModelType>(checklist)
    const refChecklistSection = useRef<any>(null)

    const addSection = useCallback(async () => {
        const res = await twinFetchPostJSON('/api/app/config/templateChecklist/section/createTChecklistSection', { TChecklistId: checklist.id, name: '' })
        if (res) {
            setDataChecklist((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                if (!copyOld?.TChecklistSections) {
                    copyOld.TChecklistSections = []
                }
                copyOld?.TChecklistSections.push({ ...res, TChecklistTasks: [] })
                return { ...copyOld }
            })
            setTimeout(() => {
                refChecklistSection.current?.children[dataChecklist.TChecklistSections.length || 0]?.getElementsByClassName('input_change_checklist_section_name')[0]?.getElementsByClassName('rectangular_textarea')[0]?.children[0]?.focus()
            }, 100)
        }
    }, [checklist.id, dataChecklist.TChecklistSections.length])

    return { dataChecklist, setDataChecklist, addSection, refChecklistSection }
}


export default ModalEditTChecklist