import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinFileSystem from '../../../../../../../../../baseComponents/TwinFileSystem'

interface ContactB2BFilesProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const ContactB2BFiles: React.FC<ContactB2BFilesProps> = ({ userPermissions, basicData }) =>  {
    if (basicData?.id) {
        return (<TwinFileSystem subFolder={'/' + basicData.id + '/customerFolder'} type='customer' userPermissions={userPermissions}></TwinFileSystem>)
    }
    return null
}

export default ContactB2BFiles