import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { Fragment, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ButtonPrimary } from '../../../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { ModalOpenedTy } from '../../../../../../../../../baseComponents/Modal/types'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { addDictionaryExtraLine, TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { getActiveLangs, getAllClientManagerEmployees, getConfigParam } from '../../../../../../../../../utils/reducers/getters'
import ModalContactChangeType from './ModalContactB2BChangeType'


type MainContactB2BProfileProps = ComponentWithPermissions & ReduxMainContactB2BProfile & {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
    extraProps?: TwinDictionary
}

interface CustomerModelTypeExtended extends CustomerModelType {
    transform_to_client: string
}

const MainContactB2BProfile: React.FC<MainContactB2BProfileProps> = ({ basicData, userPermissions, getData, extraProps, employees }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { opened, setOpened } = useMainContactB2BProfileLogic()
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getConfigParam('showOnlyClientManagerEmployees') === 'on' ? getAllClientManagerEmployees() || {} : employees || {}))), [employees])


    const fields: FormRender<CustomerModelTypeExtended> = [
        {
            cols: 2,
            title: t('generalInformation', 'Información general'),
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'Nombre fiscal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: basicData?.type_id_number,
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'customer_card',
                    label: t('customerCard', 'Tarjeta de cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'responsable_name',
                    label: t('responsableName', 'Nombre del responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'ResponsibleEmployeeId',
                    label: t('ourManager', 'Nuestro gestor'),
                    component: 'CustomSelectWithSearchBar',
                    items: myEmployees || {},
                    fieldName: 'fullname_short',
                    sortItems: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Datos de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'CustomerOriginId',
                    label: t('origin', 'Origen'),
                    component: 'CustomSelectWithSearchBar',
                    items: extraProps?.dictCustomerOrigins || {},
                },
                {
                    name: 'newsletter',
                    label: t('newsletter', 'Newsletter'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)

    const extraButtons = [
        <ButtonPrimary key='transformToClient' className='regular14 ml-10' onClick={() => setOpened(true)}><TwinTrans transKey='saveAndTransformToCustomerB2B'>Guardar y Transformar a cliente B2B</TwinTrans></ButtonPrimary>
    ]
    return (
        <Fragment>
            <SingleFormLayout action='/api/app/customer/updateCustomer' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} extraButtons={extraButtons}>
            </SingleFormLayout>
            {opened ? <ModalContactChangeType setOpened={() => setOpened(null)} onSubmit={() => { }} allRowData={basicData || {}} /> : null}
        </Fragment>
    )
}

const useMainContactB2BProfileLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    return { opened, setOpened }
}


const mainContactB2BProfileDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxMainContactB2BProfile = ConnectedProps<typeof mainContactB2BProfileConnect>
const mapMainContactB2BProfileTypesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const mainContactB2BProfileConnect = connect(mapMainContactB2BProfileTypesConnector, mainContactB2BProfileDispatch)

const mainContactB2BProfileConnectLoading = withLoading(MainContactB2BProfile, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateMainContactB2BProfileConnect = mainContactB2BProfileConnect(mainContactB2BProfileConnectLoading)


export default ModalCreateMainContactB2BProfileConnect