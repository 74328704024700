import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { TaskModelType } from '@teinor/erp/types/company/task'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { CustomRenderDateHours } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getAllTaskTypes, getCustomers, getEmployees, getGroups, getTaskCFields } from '../../../../utils/reducers/getters'
import { CustomRenderTaskName } from '../../TaskScreenTable/Subcomponents'
import TaskTable from '../../TaskScreenTable/TaskTable'
import { useTaskScreenTableLogic } from '../../TaskScreenTable'
import { SingleTaskInner, useSingleTaskLogic } from '../../SingleTask'
import { useCallback } from 'react'
import { changeErrorMessage, changeSuccessMessage } from '../../../../utils/reducers/reduxDispatch'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../utils/reducers'
import { TaskCFieldPayload } from '../../../../utils/reducers/company/taskCFields'

interface SectionCustomerTasksProps extends ReduxSectionCustomerTasks{
    CustomerId: string
    selectedTaskId: number | null
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

export const SectionCustomerTasks: React.FC<SectionCustomerTasksProps> = ({ CustomerId, selectedTaskId, setSelectedTaskId }) => {
    const { t } = useTwinTranslation()
    const name = 'tableCustomerTasks'
    const customers = getCustomers()
    const customer = customers?.all?.[parseInt(CustomerId)]?.name
    const columns: ColumnTableSchema<TaskModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('nº', 'Nº'),
            customRender: (parameterValue) => <div className='text-green-43'>#{parameterValue}</div>,
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            minWidth: 120,
            hideColumnByDefault: true,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskName id={String(allRowData?.id)} name={allRowData?.name} />,
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('createdAt', 'Fecha de creación'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
        },
        {
            id: 'openTask',
            dataKey: 'id',
            label: '',
            width: 30,
            customRender: (parameterValue) => <div onClick={() => setSelectedTaskId(parseInt(parameterValue))}><TwinIcon icon={faChevronRight} className='h-20 w-20 text-gray-B2' /></div>,
        },
    ]

    const { setSelectedColsFiltersHeader, selectedColsFiltersHeader } = useTaskScreenTableLogic({ name, limitPath: '', floatingTasks: true, columns })

    const headerTableWhereParams = {
        generalSearchBar: '',
        start_date: '',
        end_date: '',
        finished: 1,
        assigned: 1,
        visibles: 2
    }

    if (selectedTaskId) {
        return <SectionCustomerTasksSingleTask selectedTaskId={selectedTaskId} setSelectedTaskId={setSelectedTaskId} />
    }
    
    return (
        <div className='flex flex-col flex-auto taskDoubleTable pr-30'>
            <h2><TwinTrans transKey='taskOf'>Tareas de </TwinTrans><span>{customer}</span></h2>
            <TaskTable name={name} createRowDataLink={['/task/tasks', ['$id']]} defaultOrder={{ defaultOrderField: 'createdAt', order: 'desc' }} setSelectedColsFiltersHeader={setSelectedColsFiltersHeader} memorizedColumns={columns} selectedColsFiltersHeader={selectedColsFiltersHeader} generalSearchbar='' columns={columns} getDataFrom='/api/app/task/getAllTasks' extraWhereParams={{ 'Task.CustomerId': CustomerId }} listenSocketStr={'getAllTasksCustomer_' + CustomerId} headerTableWhereParams={headerTableWhereParams} floatingTasks={false} simpleDivContainer={true} />
        </div>
    )
}

interface SectionCustomerTasksSingleTaskProps {
    selectedTaskId?: number
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const SectionCustomerTasksSingleTask: React.FC<SectionCustomerTasksSingleTaskProps> = ({ selectedTaskId, setSelectedTaskId }) => {
    const { onInvalidPermissions, onDeletedTask } = useSectionCustomerTasksSingleTaskLogic()
    const { refSubTask, data, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime, setOpenModalInvertedTime } = useSingleTaskLogic({ id: String(selectedTaskId), onInvalidPermissions, onDeletedTask })
    const employees = getEmployees()
    const groups = getGroups()
    const taskCFields = getTaskCFields()
    const taskTypes = getAllTaskTypes()

    if (selectedTaskId && data) {
        return (
            <div className='flex flex-col flex-auto single_task overflow-auto'>
                <SingleTaskInner data={data} setSelectedSubtaskId={setSelectedTaskId} {...{ updateTask, setData, changeDescription, changeName, refSubTask, customerDicts, openModalInvertedTime, setOpenModalInvertedTime, employees, groups, taskCFields, taskTypes }} />
            </div>
        )
    }
    return null
}

const useSectionCustomerTasksSingleTaskLogic = () => {
    const { t } = useTwinTranslation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')

    const onInvalidPermissions = useCallback(() => {
        changeErrorMessage(taskNotAvailable)
    }, [taskNotAvailable])

    const onDeletedTask = useCallback((otherPerson?: boolean) => {
        if (otherPerson) {
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
    }, [taskDeleted, taskHasBeenDeleted])


    return { onInvalidPermissions, onDeletedTask }
}

const sectionCustomerTasksDispatch = {
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

type ReduxSectionCustomerTasks = ConnectedProps<typeof sectionCustomerTasksConnect>
const mapSectionCustomerTasksConnector = (state: AllReduxPayloads) => ({ taskCFields: state.taskCFields })
const sectionCustomerTasksConnect = connect(mapSectionCustomerTasksConnector, sectionCustomerTasksDispatch)

const taskConnectLoading = withLoading(SectionCustomerTasks, [{ fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const SectionCustomerTasksConnect = sectionCustomerTasksConnect(taskConnectLoading)


export default SectionCustomerTasksConnect