import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { SelectedColumnsAndFilters, SingleSelectedFilterTy } from '../../../../../../../../utils/hooks/useConfigColumns'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import Modal, { CloseLateralModalSimple } from '../../../../../../../../baseComponents/Modal'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { getWhereFromFilters } from '../../../../../../../../baseComponents/TwinTable/functions'
import { ColumnTableSchema } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { CustomersForCampaign } from './types'
import TablePreselectedCustomers from './TablePreselectedCustomers'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../../../../../../baseComponents/Modal/types'
import { OnSubmit } from '../../../../../../../../forms/TwinForm/types'
import { CustomerProjectTypePayload } from '../../../../../../../../utils/reducers/customers/customerProjectTypes'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../../utils/reducers'
import withLoading from '../../../../../../../../utils/hoc/withLoading'
import { EmployeesPayload } from '../../../../../../../../utils/reducers/company/employees'
import { CustomerGroupPayload } from '../../../../../../../../utils/reducers/customers/customerGroups'
import FilterCampaignCustomers from './FilterCampaignCustomers'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../../utils/globals/dictionary'
import { SwitchLightInversed } from '../../../../../../../../forms/SwitchLight'
import { getAllCustomerProjectTypes, getEmployees, getCustomerGroups } from '../../../../../../../../utils/reducers/getters'
import ModalConfirmCreateCampaignCustomers from './ModalConfirmCreateCampaignCustomers'

interface ModalCreateCampaignCustomersProps extends ModalEditComponentProps {}

const ModalCreateCampaignCustomers: React.FC<ModalCreateCampaignCustomersProps> = ({setOpened, onSubmit}) => {
    const { id } = useParams()
    const CampaignId = id
    const { columns } = useCampaignCustomerColumns({ CampaignId: CampaignId || '' })
    const { step, tmpColumns, setTmpColumns, onSaveFilters, preselectedCustomers, createCampaignCustomers, deletePreselectedCustomer, onCancel, confirmCustomersModal, setConfirmCustomersModal } = useModalCreateCampaignCustomersLogic({ columns, setOpened, onSubmit })
    return (
        <Modal className='h-full modal_big flex flex-col'  opened={true} setOpened={setOpened} onClickOut={false} >
            <CloseLateralModalSimple onClose={onCancel} />
            <h2 className='mb-25'>
                <TwinTrans transKey='addCustomersToCampaign'>Añadir clientes a la campaña</TwinTrans>
            </h2>
            {step === 0 ?
                <FilterCampaignCustomers setTmpColumns={setTmpColumns} onSave={onSaveFilters} columns={columns} tmpColumns={tmpColumns} />
                : <TablePreselectedCustomers preselectedCustomers={preselectedCustomers} openModalConfirmCustomers={()=>setConfirmCustomersModal(true)} deletePreselectedCustomer={deletePreselectedCustomer}/>
            }
            {confirmCustomersModal ? <ModalConfirmCreateCampaignCustomers numCustomers={preselectedCustomers.length} createCampaignCustomers={createCampaignCustomers} setOpened={() => setConfirmCustomersModal(null)} onSubmit={() => {}} /> : null}
        </Modal>
    )
}

interface ModalCreateCampaignCustomersLogicProps {
    setOpened: ModalOpenedSetTy
    columns: ColumnTableSchema<CustomersForCampaign>
    onSubmit: OnSubmit
}

const useModalCreateCampaignCustomersLogic = ({ columns, setOpened, onSubmit }: ModalCreateCampaignCustomersLogicProps) => {
    const [step, setStep] = useState<0| 1>(0)
    const [confirmCustomersModal, setConfirmCustomersModal] = useState <ModalOpenedTy>(null)
    const [tmpColumns, setTmpColumns] = useState<SelectedColumnsAndFilters>({filters: {}, selectedColumns: {}, order: {}})
    const [preselectedCustomers, setPreselectedCustomers] = useState<CustomerModelType[]>([])
    const {id} = useParams()
    const CampaignId = id
    const myColumnsJustForEffect = JSON.stringify(columns)

    const onCancel = useCallback(() => {
        if (step ===0){
            setOpened(null)
        }else {
            setStep(0)
            setPreselectedCustomers([])
        }
    }, [setStep, setOpened, step, setPreselectedCustomers])

    const getCustomers = useCallback(async () => {
        const myColumns = JSON.parse(myColumnsJustForEffect)
        const myWhere =  {...getWhereFromFilters(tmpColumns.filters, myColumns)}
        if (myWhere['CustomerProjectTypeId'] && tmpColumns?.filters?.CustomerProjectTypeId?.included){
            if (tmpColumns?.filters?.CustomerProjectTypeId?.excluded) {
                myWhere['CustomerProjectTypeId'] = { $notAny: myWhere['CustomerProjectTypeId']['$notIn'] }
            } else {
                myWhere['CustomerProjectTypeId'] = { $any: myWhere['CustomerProjectTypeId']['$in'] }
            }
        }
        if (myWhere['ResponsibleEmployeeId'] && tmpColumns?.filters?.ResponsibleEmployeeId?.excluded){
            myWhere['ResponsibleEmployeeId'] = {
                 $or: {
                    ...myWhere['ResponsibleEmployeeId'], 
                    '$is': null}
            }
        }
        const customers = await twinFetchPostJSON('/api/app/customer/campaign/customer/getAllCustomersNotSelectedByCampaign', {
            where: myWhere,
            CampaignId
        })
        if (customers){
            setPreselectedCustomers(customers)
        }
    }, [CampaignId, setPreselectedCustomers, tmpColumns, myColumnsJustForEffect])

    const onSaveFilters = useCallback(async() => {
        await twinFetchPostJSON('/api/app/customer/campaign/updateCampaign', { id: CampaignId, filter: JSON.stringify(tmpColumns.filters)})
        setStep(1)
        await getCustomers()
    }, [ setStep, tmpColumns, CampaignId, getCustomers])
    
    const getFilters = useCallback(async () => {
        const campaign = await twinFetchPostJSON('/api/app/customer/campaign/getCampaignInstance', { id: CampaignId })
        if(campaign.filter){
            setTmpColumns((old)=>{
                const copyOld = JSON.parse(JSON.stringify(old))
                return {...copyOld, filters: JSON.parse(campaign.filter)}
            })
        }
    }, [CampaignId])

    const deletePreselectedCustomer = useCallback((pos: number) => {
        setPreselectedCustomers((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.splice(pos, 1)
            return [...copyOld]
        })
    }, [setPreselectedCustomers])

    const createCampaignCustomers = useCallback(async () => {
        const campaignCustomers = []
        for (const customer of preselectedCustomers) {
            campaignCustomers.push({CampaignId, CustomerId: customer.id})
        }
        const result = await twinFetchPostJSON('/api/app/customer/campaign/customer/bulkCreateCampaignCustomer', { campaignCustomers })
        if(result){
            setOpened(null)
            setConfirmCustomersModal(null)
            onSubmit?.(result, {})
        }
    }, [CampaignId, preselectedCustomers, setOpened, onSubmit, setConfirmCustomersModal])

    useEffect(() => {
        getFilters()
    }, [getFilters])

    return { CampaignId, step, tmpColumns, setTmpColumns, onSaveFilters, preselectedCustomers, deletePreselectedCustomer, createCampaignCustomers, onCancel, confirmCustomersModal, setConfirmCustomersModal }
}

interface CampaignCustomerColumnsProps {
    CampaignId: string
}

const useCampaignCustomerColumns = ({ CampaignId }: CampaignCustomerColumnsProps) => {
    const { t } = useTwinTranslation()
    const [campaigns, setCampaigns] = useState<TwinDictionary | null>(null)
    
    const memoCustomerProjectTypes = useMemo(() => getAllCustomerProjectTypes() || {}, [])
    const memoEmployees = useMemo(() => getEmployees() || {}, [])
    const memoCustomerTypes = useMemo(() => getCustomerGroups() || { dictionary: {} }, [])

    const getCampaigns = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/campaign/getAllCampaigns', { where: { id: { $not: CampaignId } } })
        if (result) {
            setCampaigns(dictionaryComplexFromJsonArr(result))
        }
    }, [setCampaigns, CampaignId])
    
    useEffect(() => {
        getCampaigns()
    }, [getCampaigns])

    const columns: ColumnTableSchema<CustomersForCampaign> = [
        {
            id: 'CustomerGroupId',
            dataKey: 'CustomerGroupId',
            label: t('customerType', 'Tipo de cliente'),
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: memoCustomerTypes.dictionary, label: t('customerType', 'Tipo de cliente') }
            },
            searchKey: 'CustomerGroups->CustomerGroupRel.CustomerGroupId'
        },
        {
            id: 'id',
            dataKey: 'id',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: {}, label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.id'
        },
        {
            id: 'CustomerProjectTypeId',
            dataKey: 'CustomerProjectTypeId',
            label: t('customerProjectType', 'Tipo de proyecto'),
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: memoCustomerProjectTypes, label: t('customerProjectType', 'Tipo de proyecto'), ExtraLeftHeader: SwitchLightInversedExtraLeft }
            },
        },
        {
            id: 'ResponsibleEmployeeId',
            dataKey: 'ResponsibleEmployeeId',
            label: t('ourManager', 'Nuestro gestor'),
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: memoEmployees, label: t('ourManager', 'Nuestro gestor') }
            }
        },
        {
            id: 'CampaignId',
            dataKey: 'CampaignId',
            label: t('campaigns', 'Campañas'),
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: campaigns || {}, label: t('campaigns', 'Campañas') }
            },
            searchKey: 'CampaignCustomers.CampaignId'
        },
    ]
    return { columns }
}

interface SwitchLightInversedExtraLeftProps extends SwitchLightInversedExtraLeftLogicProps {
}

const SwitchLightInversedExtraLeft: React.FC<SwitchLightInversedExtraLeftProps> = ({value, ...logic}) => {
    const {t} = useTwinTranslation()
    const { changeIncluded } = useSwitchLightInversedExtraLeftLogic({value, ...logic})
    return (<SwitchLightInversed active={value.included || false} onChange={changeIncluded} label={t('allInSelected', 'Todas las seleccionadas')} />)
}

interface SwitchLightInversedExtraLeftLogicProps {
    changeSingleField: (id: string, value: SingleSelectedFilterTy) => void
    value: SingleSelectedFilterTy
    id: string
}

const useSwitchLightInversedExtraLeftLogic = ({changeSingleField, id, value}: SwitchLightInversedExtraLeftLogicProps) => {
    const changeIncluded = useCallback(() => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (copy['included']) {
            delete copy['included']
        } else {
            copy['included'] = true
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])
    
    return { changeIncluded }
}

const modalCreateCampaignCustomersDispatch = {
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setCustomerGroups: (payload: CustomerGroupPayload) => ({ type: 'CHANGE_CUSTOMER_GROUP', payload }),
}

export type ReduxModalCreateCampaignCustomers = ConnectedProps<typeof modalCreateCampaignCustomersConnect>
const mapModalCreateCampaignCustomersConnector = (state: AllReduxPayloads) => ({ customerProjectTypes: state.customerProjectTypes, employees: state.employees, customerGroups: state.customerGroups })
const modalCreateCampaignCustomersConnect = connect(mapModalCreateCampaignCustomersConnector, modalCreateCampaignCustomersDispatch)
const modalCreateCampaignCustomersConnectLoading = withLoading(ModalCreateCampaignCustomers, [{ fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/customer/customerGroup/getAllCustomersGroup', propName: 'customerGroups', setFunctionName: 'setCustomerGroups' }])
const ModalCreateCampaignCustomersConnect = modalCreateCampaignCustomersConnect(modalCreateCampaignCustomersConnectLoading)

export default ModalCreateCampaignCustomersConnect