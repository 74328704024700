import { displayDateWithFormattedHours } from '../../../utils/globals/date'
import { CustomerProjectRenderImageTooltip } from '../../Customers/Subcomponents'

export interface CampaignCustomerTabsInfoHeaderProps {
    image?: string
    secondText?: string
    headerText?: string
    date: string
    showImage?: boolean
    id?: number
    nameTrigger?: string
    tooltipName: string
}

export const CampaignCustomerTabsInfoHeader: React.FC<CampaignCustomerTabsInfoHeaderProps> = ({ date, headerText, secondText, image, showImage = true, id, nameTrigger, tooltipName }) => {
    return (
        <div className='flex items-center gap-12'>
            {showImage ? <CustomerProjectRenderImageTooltip id={id || 0} img={image ?? '/user-png.png'} name={nameTrigger} tooltipName={tooltipName} /> : null}
            <div className='items-center flex'>
                <div className='font-medium'>
                    <span className='mr-3 '>{headerText}</span>
                    {secondText ? <span className='italic' >{secondText}</span> : null}
                </div>
                <span className='text-gray-9D ml-12'>{displayDateWithFormattedHours(new Date(date))}</span>
            </div>
        </div>
    )
}