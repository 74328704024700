import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { CampaignStatusModelType } from '@teinor/erp/types/company/customer/campaign/campaignStatus'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useState } from 'react'
import { MediumModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import VerticalSortList from '../../../../../../../baseComponents/Sorts/VerticalSortList'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { RowData } from '../../../../../../../baseComponents/TwinTable/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { RectangularInput } from '../../../../../../../forms/Input'
import { InputColorSimple } from '../../../../../../../forms/Input/InputColor'
import { SwitchLightInversed } from '../../../../../../../forms/SwitchLight'
import { customerProjectTypeColors } from '../../../../../../../utils/globals/colors'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { CampaignBasicTy, SetCampaignDataty } from './types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import { CampaignModelType } from '@teinor/erp/types/company/customer/campaign'
import './modalCECampaign.sass'

interface ModalCECampaignProps extends ModalEditComponentProps { }

const ModalCECampaign: React.FC<ModalCECampaignProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { campaignData, setCampaignData, handleChangeCampaignData } = useModalCECampaignLogic({ allRowData })

    const fields: FormRender<CampaignModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                    value: campaignData.name,
                    onChange: handleChangeCampaignData
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
                {
                    name: 'campaignStatuses' as any,
                    component: 'InputHidden',
                    value: JSON.stringify(campaignData.CampaignStatuses)
                },
            ]
        },
    ]
        if (allRowData && userPermissions) {
            const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
            return (
                <MediumModalCreateEdit className='notFlexAutoFormRender' {...rest} fields={parsedFields} translations={{
                    title: t('updateCampaign', 'Actualizar campaña'),
                    button: t('save', 'Guardar')
                }} haveButtonPermissions={true} url={'/api/app/customer/campaign/updateCampaign'}>
                    <CampaignStatuses {...{ campaignData, setCampaignData }} />
                </MediumModalCreateEdit>
            )
        }
    return (<MediumModalCreateEdit className='notFlexAutoFormRender' fields={fields} url={'/api/app/customer/campaign/createCampaign'} translations={{
        title: t('createCampaign', 'Crear campaña'),
        button:t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest}>
        <CampaignStatuses {...{ campaignData, setCampaignData }} />
    </MediumModalCreateEdit>)
}

interface ModalCECampaignLogicProps {
    allRowData?: RowData
}

const useModalCECampaignLogic = ({ allRowData }: ModalCECampaignLogicProps) => {
    const [campaignData, setCampaignData] = useState<CampaignBasicTy>({ name: '', CampaignStatuses: [] })

    const handleChangeCampaignData = useCallback((value: string) => {
        setCampaignData((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            return { ...copyOld, name: value }
        })
    }, [setCampaignData])

    const getCampaignComplete = useCallback(async () => {
        if (allRowData?.id) {
            const result = await twinFetchPostJSON('/api/app/customer/campaign/getCampaignComplete', { id: allRowData?.id })
            if (result) {
                setCampaignData(result)
            }
        }
    }, [setCampaignData, allRowData?.id])

    useEffect(() => {
        getCampaignComplete()
    }, [getCampaignComplete])

    return { campaignData, setCampaignData, handleChangeCampaignData }
}

interface CampaignStatusesProps extends CampaignStatusesLogicProps {
    campaignData: CampaignBasicTy
}

const CampaignStatuses: React.FC<CampaignStatusesProps> = ({ campaignData, setCampaignData }) => {
    const { t } = useTwinTranslation()
    const { ableToOrder, setAbleToOrder, myOrderChange } = useCampaignStatusesLogic({ setCampaignData })
    return (
        <div className='flex flex-col flex-auto'>
            <div className='flex items-center justify-between'>
                <h2 className='mb-20'><TwinTrans transKey='campaignStatus'>Estados de la campaña</TwinTrans></h2>
                <SwitchLightInversed active={ableToOrder} label={t('toOrder', 'Ordenar')} onChange={() => setAbleToOrder((old) => !old)} />
            </div>
            {ableToOrder ?
                <VerticalSortList items={campaignData.CampaignStatuses as any} setItems={myOrderChange} />
                :
                <MyCampaignStatusesTable campaignStatuses={campaignData?.CampaignStatuses || []} setCampaignData={setCampaignData} />
            }
        </div>
    )
}

interface CampaignStatusesLogicProps {
    setCampaignData: SetCampaignDataty
}

const useCampaignStatusesLogic = ({ setCampaignData }: CampaignStatusesLogicProps) => {
    const [ableToOrder, setAbleToOrder] = useState<boolean>(false)

    const myOrderChange = useCallback((a: any) => {
        setCampaignData((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            const result = a(copy.CampaignStatuses)
            copy.CampaignStatuses = result
            return { ...copy }
        })
    }, [setCampaignData])

    return { setAbleToOrder, ableToOrder, myOrderChange }
}

interface MyCampaignStatusesTableProps extends MyCampaignStatusesTableLogicProps {
    campaignStatuses: CampaignStatusModelType[]
}

const MyCampaignStatusesTable: React.FC<MyCampaignStatusesTableProps> = ({ campaignStatuses, ...logic }) => {
    const { addCampaignStatusRow, deleteCampaignStatusRow, onChangeCampaignStatus } = useMyCampaignStatusesTableLogic({ ...logic })
    const renderThis: JSX.Element[] = []

    if (campaignStatuses.length) {
        for (const key in campaignStatuses) {
            const campaignStatusData = campaignStatuses[key]
            renderThis.push(<CampaignStatusRow key={campaignStatusData.id} {...{ ...campaignStatusData, deleteCampaignStatusRow, onChangeCampaignStatus }} />)
        }
    }
    return (
        <div className='flex-auto h-full overflow-auto overflow-x-hidden'>
            <div className='w-full table_campaign_status_container overflow-visible'>
                <div className='flex items-center bg-gray-F7 p-10 px-14 medium14 table_campaign_status_row'>
                    <div><TwinTrans transKey='order'>Orden</TwinTrans></div>
                    <div><TwinTrans transKey='name'>Nombre</TwinTrans></div>
                    <div><TwinTrans transKey='color'>Color</TwinTrans></div>
                    <div></div>
                </div>
                {renderThis}
            </div>
            <div className='cursor-pointer text-center my-10 bg-gray-EF py-8 w-full text-gray-51 ' onClick={addCampaignStatusRow}>
                <TwinIcon className='mr-10' icon={faPlus} />
                <TwinTrans transKey='addCampaignStatus'>Añadir estado de campaña</TwinTrans>
            </div>
        </div>
    )
}

interface MyCampaignStatusesTableLogicProps {
    setCampaignData: SetCampaignDataty
}

const useMyCampaignStatusesTableLogic = ({ setCampaignData }: MyCampaignStatusesTableLogicProps) => {
    const colors = customerProjectTypeColors

    const deleteCampaignStatusRow = useCallback((pos: number) => {
        setCampaignData((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.CampaignStatuses.splice(pos, 1)
            let order = 1
            for (const element of copyOld.CampaignStatuses) {
                element.order = order
                order++
            }
            return { ...copyOld }
        })
    }, [setCampaignData])

    const addCampaignStatusRow = useCallback(() => {
        setCampaignData((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            let copyColors = JSON.parse(JSON.stringify(colors))
            if (copyOld?.CampaignStatuses?.length) {
                for (const element of copyOld?.CampaignStatuses) {
                    const parsedColor = element.color.toUpperCase()
                    const alreadySelectedColor = copyColors.indexOf(parsedColor)
                    if (alreadySelectedColor !== -1) {
                        copyColors.splice(alreadySelectedColor, 1)
                    }
                }
            }
            if (!copyColors.length) {
                copyColors = colors
            }
            const order = copyOld.CampaignStatuses.length + 1
            const index = Math.floor(Math.random() * copyColors.length)
            copyOld.CampaignStatuses.push({ name: '', color: colors[index], order, id: nanoid(), isNew: true })
            return { ...copyOld }
        })
    }, [setCampaignData, colors])

    const onChangeCampaignStatus = useCallback((field: string, value: string, pos: number) => {
        setCampaignData((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld.CampaignStatuses[pos]) {
                copyOld.CampaignStatuses[pos][field] = value
            }
            return { ...copyOld }
        })
    }, [setCampaignData])

    return { deleteCampaignStatusRow, onChangeCampaignStatus, addCampaignStatusRow }
}

interface CampaignStatusRowProps extends CampaignStatusModelType {
    onChangeCampaignStatus: (field: string, value: string, pos: number) => void
    deleteCampaignStatusRow: (pos: number) => void
}

const CampaignStatusRow: React.FC<CampaignStatusRowProps> = ({ name, color, order, deleteCampaignStatusRow, onChangeCampaignStatus }) => {
    return (
        <div className='table_campaign_status_row flex items-center border-b border-b-gray-EE px-14 py-5'>
            <div>{order}</div>
            <div>
                <RectangularInput className='bg-white' label='' value={name} onChange={(value) => onChangeCampaignStatus('name', value, order - 1)} />
            </div>
            <div>
                <InputColorSimple value={color} onChange={(value) => onChangeCampaignStatus('color', value, order - 1)} />
            </div>
            <div>
                <TwinIcon className='cursor-pointer hover:text-red-BA' icon={faTrashAlt} onClick={() => deleteCampaignStatusRow(order - 1)} />
            </div>
        </div>
    )
}

export default ModalCECampaign