import { faLayerPlus, faXmark } from '@fortawesome/pro-light-svg-icons'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { RectangularTextAreaDebounce } from '../../../forms/Input/TextArea'
import TwinIcon from '../../TwinIcon'
import PermissionChecker from '../../PermissionChecker'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { UserPermissionsSingle } from '../../CreateRoutering/types'
import { permissionCheck } from '../../PermissionChecker/function'
import '../checklist.sass'

interface ChecklistSectionProps {
    section: TwinDictionary
    userPermission: UserPermissionsSingle | false
    onChangeSection: (value: string ) => void
    onDeleteSection: () => void
    onAddTask: () => void
    addSection: () => void
 }


const ChecklistSection: React.FC<ChecklistSectionProps> = ({ section, userPermission, onDeleteSection, onChangeSection, onAddTask, addSection }) => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex items-center mb-10 regular18'>
            <RectangularTextAreaDebounce label='' placeholder={t('titleSection', 'Título de la sección')} className='flex-auto input_change_checklist_section_name mr-20 rounded-md' value={section?.name || ''} onChange={onChangeSection} readOnly={!permissionCheck(userPermission, 'update')} onBlur={(e) => {
                const val = e.currentTarget.value
                if (val !== section?.name) {
                    onChangeSection(e.currentTarget.value)
                }
            }} onShiftEnter={permissionCheck(userPermission, 'update') ? addSection : undefined } />
            <PermissionChecker userPermissions={userPermission} permission={'update'}>
                <TwinIcon className='cursor-pointer hover:text-green-43 ml-10' icon={faLayerPlus} onClick={onAddTask} />
            </PermissionChecker>
            <PermissionChecker userPermissions={userPermission} permission={'delete'}>
                <TwinIcon className='cursor-pointer hover:text-red-BA ml-20' icon={faXmark} onClick={onDeleteSection} />
            </PermissionChecker>
        </div>
    )
}
export default ChecklistSection