import { faLayerPlus } from '@fortawesome/pro-light-svg-icons'
import { MutableRefObject, useCallback } from 'react'
import { HeaderElementTask } from '..'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { SetTaskDataExtended } from '../../types'
import { TaskLogTypeInversed, TaskLogTypeKeys } from '@teinor/erp/types/company/task/taskLog'
import { getUserInfo } from '../../../../../utils/reducers/getters'

interface HeaderTaskAddSubTaskProps extends HeaderTaskAddSubTaskLogicProps {
    message: string
}

const HeaderTaskAddSubTask: React.FC<HeaderTaskAddSubTaskProps> = ({id, message, ...rest}) => {
    const { createSubtask } = useHeaderTaskAddSubTaskLogic({id, ...rest})
    return (
        <HeaderElementTask id={'addSubTask_' + id} message={message} icon={faLayerPlus} onClick={createSubtask} />
    )
}
interface HeaderTaskAddSubTaskLogicProps {
    id: string
    setData: SetTaskDataExtended
    refSubTask: MutableRefObject<any>
    CustomerProjectId: number
}

const useHeaderTaskAddSubTaskLogic = ({ id, setData, refSubTask, CustomerProjectId }: HeaderTaskAddSubTaskLogicProps) => {

    const createSubtask = useCallback(async () => {
        const userInfo = getUserInfo()
        const EmployeeId = userInfo?.Employee?.id
        const result = await twinFetchPostJSON('/api/app/task/createTask', { TaskId: id, CustomerProjectId: CustomerProjectId || undefined }, { myExtraHeaders: { 'type-update': 'singleTask' }})
        if (result) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.ChildrenTasks.push(result)
                copyOld.TaskLogs.unshift({
                    type: TaskLogTypeInversed['createSubTask'] as TaskLogTypeKeys,
                    TaskId: result.TaskId,
                    EmployeeId,
                    Employee: getUserInfo()?.Employee,
                    createdAt: new Date(),
                    extraData: JSON.stringify({ 'subTaskId': result.id })
                })
                return { ...copyOld }
            })
            setTimeout(() => {
                refSubTask.current?.lastChild?.getElementsByClassName('input_change_subtask_name')[0]?.getElementsByClassName('rectangular_textarea')[0]?.children[0]?.focus()
            }, 200)
        }
    }, [setData, id, refSubTask, CustomerProjectId])
    return { createSubtask }
}

export default HeaderTaskAddSubTask