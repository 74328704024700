import { useLocation, useParams } from 'react-router-dom'
import useTaskColumns from '../../../../../../../../../utils/hooks/useTaskColumns'
import { TasksInnerTab } from '../../../../../../../../../specificComponents/Task/TasksTableDefault'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { GroupPayload } from '../../../../../../../../../utils/reducers/groups/groups'
import { TaskCFieldPayload } from '../../../../../../../../../utils/reducers/company/taskCFields'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import { TaskTableTab } from '../../../../../../../../../specificComponents/Task/TaskDoubleTable'
import { getBasePath } from '../../../../../../../../../utils/globals/link'
import { WorkingCenterModelType } from '@teinor/erp/types/company/workingCenter'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'

type WorkingCenterTasksProps = ComponentWithPermissions & ReduxWorkingCenterTasks & {
    basicData: WorkingCenterModelType | null
}

const WorkingCenterTasks: React.FC<WorkingCenterTasksProps> = ({ userPermissions, basicData }) => {
    const {t, tVars} = useTwinTranslation()
    const { TaskId } = useParams<{ TaskId: string}>()
    const myId = TaskId || ''
    const WorkingCenterId = basicData?.id
    const { baseColumns, columnCustomer, extraColumns, baseColumnsEnd } = useTaskColumns({ id: myId })
    const columnsWC = [
        ...baseColumns,
        ...columnCustomer,
        ...extraColumns,
        ...baseColumnsEnd
    ]
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'tasks')
    const nameWorkingCenter = basicData?.name || ''
    const pageData = {
        title: tVars('tasksOfWorkingCenter', 'Tareas de {{nameWorkingCenter}}', { nameWorkingCenter }), 
        newText: t('newTask', 'Nueva tarea')
    }
    return (<TaskTableTab limitPath='tasks' extraCreateTaskParams={{ WorkingCenterId }} name='workingCenterTasks' getDataFrom='/api/app/task/getAllTasksByWorkingCenter' listenSocketStr={'getAllTasksWorkingCenter_' + WorkingCenterId} createRowDataLink={[basePath, ['$id']]} extraWhereParams={{ WorkingCenterId }} FirstChild={TasksInnerTab} columns={columnsWC} {...{ userPermissions, id: myId, basePath, pageData }} />)
}


const workingCenterTasksDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

type ReduxWorkingCenterTasks = ConnectedProps<typeof workingCenterTasksConnect>
const mapWorkingCenterTasksConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskCFields: state.taskCFields })
const workingCenterTasksConnect = connect(mapWorkingCenterTasksConnector, workingCenterTasksDispatch)

const taskConnectLoading = withLoading(WorkingCenterTasks, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const WorkingCenterTasksConnect = workingCenterTasksConnect(taskConnectLoading)

export default WorkingCenterTasksConnect