import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import TwinBigCalendar from '../../../../../../baseComponents/TwinBigCalendar'
import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import LoadingSpinner from '../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TCEmployeeHolidaysBag from './TCEmployeeHolidaysBag'
import { TimeControlEventsAndHolidayBags } from './types'
import { employeeAbsencesToEvents, overtimeToEvents, timeControlParseFreeDaysToEvents, timeControlParseHolidayBagToEvents } from './functions'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import ModalRequestEmployeeAbsence from './ModalRequestEmployeeAbsence'
import './tcemployeeholidays.sass'
import { ModuleChecker } from '../../../../../../baseComponents/PermissionChecker'
import TimeControlEmployeeOvertime from '../TimeControlEmployeeOvertime'

interface TimeControlEmployeeHolidaysProps {}

const TimeControlEmployeeHolidays: React.FC<TimeControlEmployeeHolidaysProps> = () => {
    const { data, handleOnNavigate, getData, setYear, year } = useTimeControlEmployeeHolidaysLogic()
    const renderThis: JSX.Element[] = []
    const holidayBags = data?.holidayBag || []
    for (const element of holidayBags) {
        renderThis.push(<TCEmployeeHolidaysBag key={element.id} holidayBag={element} getData={getData} />)
    }
    return (
        <div className='mt-40 flex flex-auto tce_holidays'>
            <WhiteBox className='p-25 flex flex-auto flex-col tce_employee_holidays_calendar'>
                <CustomToolBar getData={getData} setYear={setYear} year={year} />
                <TwinBigCalendar date={new Date(`${year}-01-01`)} events={data?.events || []} defaultView='year' onNavigate={handleOnNavigate} className='tce_employee_holidays_calendar_wrapper' />
            </WhiteBox>
            <ModuleChecker modules={['working_calendar_medium', 'working_calendar_full']}>
                <div className='flex flex-col flex-auto ml-40 tc_holidays_container_margin'>
                    <div className='h-1 flex-auto flex flex-col overflow-auto tc_holidays_container_inner'>
                        {data === null ? <LoadingSpinner /> : renderThis}
                        {data !== null ?
                            <ModuleChecker modules={['working_calendar_full']}>
                                <TimeControlEmployeeOvertime year={year} />
                            </ModuleChecker>
                            : null}
                    </div>
                </div>
            </ModuleChecker>
        </div>
    )
}


const useTimeControlEmployeeHolidaysLogic = () => {
    const { t } = useTwinTranslation()
    const freeDaysTitle = t('freeDay', 'Día festivo')
    const pendingText = t('pending', 'Pendiente')
    const overtimeText = t('overtime', 'Horas extra')
    const [data, setData] = useState<TimeControlEventsAndHolidayBags | null>(null)
    const [year, setYear] = useState<number>(new Date().getFullYear())

    const parseResultToData = useCallback((result: EmployeeModelType, ) => {
        const freeDaysEvents = timeControlParseFreeDaysToEvents(result, freeDaysTitle)
        const holidayBagResult = timeControlParseHolidayBagToEvents(result, pendingText)
        const overtimeEvents = overtimeToEvents(result, pendingText, overtimeText)
        const absencesEvents = employeeAbsencesToEvents(result, pendingText)
        const data: TimeControlEventsAndHolidayBags = {
            events: [...freeDaysEvents, ...holidayBagResult.events, ...absencesEvents, ...overtimeEvents],
            holidayBag: holidayBagResult.holidayBag
        }
         return data
    }, [freeDaysTitle, pendingText, overtimeText])

    const getData = useCallback(async () => {
        setData(null)
        const result = await twinFetchPostJSON('/api/app/employee/employeeHoliday/getEmployeeFreeDaysCalendar', { year })
        const parsedData = parseResultToData(result)
        setData(parsedData)
    }, [setData, parseResultToData, year])

    const handleOnNavigate = useCallback((date: any, view: any) => {
        if (view === 'year') {
            setYear(date.getFullYear())
        }
    }, [setYear])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleOnNavigate, getData, year, setYear}
}

interface CustomToolBarProps extends CustomToolBarLogicProps {
    year: number
    getData: () => void
}

const CustomToolBar: React.FC<CustomToolBarProps> = ({year, getData, ...rest}) => {
    const { handleNextYear, handlePrevYear, openModal, setOpenModal } = useCustomToolBarLogic({...rest})
    return (
        <div className='flex items-center custom_calendar_toolbar'>
            <div className='mr-24'>
                <TwinIcon className='cursor-pointer p-3 h-24  hover:text-green-21' icon={faChevronLeft} onClick={handlePrevYear} />
            </div>
            <div className='mr-24'>
                <TwinIcon className='cursor-pointer p-3 h-24 hover:text-green-21' icon={faChevronRight} onClick={handleNextYear} />
            </div>
            <div className='toolbar-label text-left text-gray-51 capitalize'>
                {year}
            </div>
            <ModuleChecker modules={['working_calendar_full']}>
                <div className=' ml-auto mr-10 text-green-43 cursor-pointer' onClick={() => setOpenModal(true)}>
                    <TwinTrans transKey='requestAbsence'>
                        Solicitar ausencia
                    </TwinTrans>
                </div>
            </ModuleChecker>
            {openModal ? <ModalRequestEmployeeAbsence setOpened={setOpenModal} onSubmit={getData} /> : null}
        </div>
    )
}

interface CustomToolBarLogicProps {
    setYear: React.Dispatch<React.SetStateAction<number>>
}

const useCustomToolBarLogic = ({setYear}: CustomToolBarLogicProps) => {
    const [openModal, setOpenModal] = useState<true | null>(null)

    const handlePrevYear = useCallback(() => {
        setYear((old) => old - 1)
    }, [setYear])
    const handleNextYear = useCallback(() => {
        setYear((old) => old + 1)
    }, [setYear])

    return { handleNextYear, handlePrevYear, openModal, setOpenModal }
}



export default TimeControlEmployeeHolidays