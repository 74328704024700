import { CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'
import { TwinDictionary } from '../../globals/dictionary'
import useTwinTranslation from '../useTwinTranslation'
import { ReduxCustomerTypes } from '../../reducers/customers/customer'


const useCustomerTypesDict = () => {
    const { t } = useTwinTranslation()
    const dictCustomerTypeBase: Record<CustomerTypesInversedKeys, TwinDictionary> = {
        0: { name: t('B2C', 'B2C') },
        1: { name: t('B2B', 'B2B') },
        2: { name: t('leadB2B', 'Potencial B2B') },
        3: { name: t('leadB2C', 'Potencial') }
    }
    const dictCustomerTypeTrans: Record<ReduxCustomerTypes, string> = {
        'customers': t('customers', 'Clientes'),
        'customersB2B': t('customersB2B', 'Clientes B2B'),
        'contactsB2B': t('leadsB2B', 'Clientes Potenciales B2B'),
        'contactsB2C': t('leadsB2C', 'Potenciales'),
    }
    const dictCustomerType: TwinDictionary = dictCustomerTypeBase
    return { dictCustomerType, dictCustomerTypeTrans }
}

export default useCustomerTypesDict