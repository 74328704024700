import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { DeleteRow, EditRowNotLink } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { useParams } from 'react-router-dom'
import { useAddDoubleTableGreenRowRender } from '../../../../../../baseComponents/DoubleTable/functions'
import { CampaignModelType } from '@teinor/erp/types/company/customer/campaign'
import ModalCECampaign from './ModalCECampaign'
import ModalDeleteCampaign from './ModalDeleteCampaign'
import { getUserPermissions } from '../../../../../../utils/reducers/getters'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'

interface CampaignsProps extends ComponentWithPermissions { }

const Campaigns: React.FC<CampaignsProps> = () => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('campaigns', 'Campañas'),
        newText: t('newCampaign', 'Nueva campaña'),
    }

    const updatePermissions = getUserPermissions('customer.campaigns.main')

    return (
        <BaseScreenTable TableComponent={TableCampaigns} CreateModalComponent={ModalCECampaign} EditModalComponent={ModalCECampaign} DeleteModalComponent={ModalDeleteCampaign} pageData={pageData} userPermissions={updatePermissions || {'n': 1, permission: 'read'}} />
    )
}

interface TableCampaignsProps extends TableComponentProps { }

const TableCampaigns: React.FC<TableCampaignsProps> = ({ setTableInstance, setOpened, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { CampaignId } = useParams()
    const { rowRenderer } = useAddDoubleTableGreenRowRender(CampaignId || '')
    const columns: ColumnTableSchema<CampaignModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'update')
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]

    return (
        <TwinTableLink createRowDataLink={['/customer/campaigns', ['$id']]} name='Campaigns' columns={columns} getDataFrom='/api/app/customer/campaign/getAllCampaigns' setTableInstance={setTableInstance} rowRenderer={rowRenderer} hideLoadFiltersButton={true} />)
}


export default Campaigns