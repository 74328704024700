import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import LoadingSpinner from '../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { NoProjectsMessage } from '../../../../../../../../../specificComponents/Customers/Subcomponents'
import { TaskTableTab } from '../../../../../../../../../specificComponents/Task/TaskDoubleTable'
import { CustomRenderTaskCustomerProject } from '../../../../../../../../../specificComponents/Task/TaskScreenTable/Subcomponents'
import { TasksInnerTab } from '../../../../../../../../../specificComponents/Task/TasksTableDefault'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../../../utils/globals/dictionary'
import { getBasePath } from '../../../../../../../../../utils/globals/link'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useTaskColumns, { TaskModelColumnsExt } from '../../../../../../../../../utils/hooks/useTaskColumns'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { TaskCFieldPayload } from '../../../../../../../../../utils/reducers/company/taskCFields'
import { GroupPayload } from '../../../../../../../../../utils/reducers/groups/groups'

type ContactB2BTasksProps = ComponentWithPermissions & ReduxContactB2BTasks & {
    basicData: CustomerModelType | null
}

const ContactB2BTasks: React.FC<ContactB2BTasksProps> = ({ userPermissions, basicData }) => {
    const { t, tVars } = useTwinTranslation()
    const { projects } = useContactB2BTasksLogic({ CustomerId: String(basicData?.id) || '' })
    const { baseColumns, baseColumnsEnd, extraColumns } = useTaskColumns({ id: 'main' })
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'tasks')
    if (!projects) {
        return <LoadingSpinner />
    }
    if (projects && !Object.keys(projects).length) {
        return <NoProjectsMessage id={basicData?.id || 0} />
    }
    const columns: ColumnTableSchema<TaskModelColumnsExt> = [
        ...baseColumns,
        {
            id: 'CustomerProjectId',
            dataKey: 'CustomerProjectId',
            label: t('project', 'Proyecto'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskCustomerProject allRowData={allRowData} />,
            width: 200,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: projects || {}, label: t('project', 'Proyecto') }
            },
        },
        ...extraColumns,
        ...baseColumnsEnd,
    ]
    const CustomerProjectId = projects[Object.keys(projects)[0]].id
    const nameCustomer = basicData?.name
    const pageData = {
        title: tVars('tasksOfNameCustomer', 'Tareas de {{nameCustomer}}', { nameCustomer }),
        newText: t('newTask', 'Nueva tarea')
    }
    return (<TaskTableTab limitPath='tasks' extraCreateTaskParams={{ CustomerProjectId }} name='contactB2BTasks' getDataFrom='/api/app/task/getAllTasks' listenSocketStr={'getAllTasksCustomer_' + basicData?.id} createRowDataLink={[basePath, ['$id']]} extraWhereParams={{ 'CustomerProject.CustomerId': basicData?.id }} FirstChild={TasksInnerTab} {...{ columns, userPermissions, id: 'main', basePath, pageData }} />)
}

interface ContactB2BTasksLogicProps {
    CustomerId: string
}

const useContactB2BTasksLogic = ({ CustomerId }: ContactB2BTasksLogicProps) => {
    const [projects, setProjects] = useState<TwinDictionary | null>(null)
    const getCustomerProjects = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjects', { where: { CustomerId } })
        if (result) {
            setProjects(dictionaryComplexFromJsonArr(result))
        }
    }, [setProjects, CustomerId])
    useEffect(() => {
        getCustomerProjects()
    }, [getCustomerProjects])
    return { projects }
}


const contactB2BTasksDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

type ReduxContactB2BTasks = ConnectedProps<typeof contactB2BTasksConnect>
const mapContactB2BTasksConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, customers: state.customers, taskCFields: state.taskCFields })
const contactB2BTasksConnect = connect(mapContactB2BTasksConnector, contactB2BTasksDispatch)
const taskConnectLoading = withLoading(ContactB2BTasks, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const ContactB2BTasksConnect = contactB2BTasksConnect(taskConnectLoading)

export default ContactB2BTasksConnect