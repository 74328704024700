import { DndContext, DragEndEvent, DragOverEvent, KeyboardSensor, PointerSensor, closestCorners, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { faCalendarDays, faChevronLeft, faChevronRight, faComment, faSearch } from '@fortawesome/pro-light-svg-icons'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { ConnectedProps, connect } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { WhiteBox } from '../../../../../../../baseComponents/AppLayout/WhiteBox'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { Where } from '../../../../../../../baseComponents/TwinTable/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import ModalEditCustomerProject from '../../../../../../../specificComponents/Customers/ModalEditCustomerProject'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../utils/globals/date'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { CustomerProjectTypePayload } from '../../../../../../../utils/reducers/customers/customerProjectTypes'
import CustomerProjectFlowHeader from './CustomerProjectFlowHeader'
import { CustomerProjectFlowUpdatedDataForApi, CustomerProjectStatusesDictForFlow } from './types'
import { CustomerProjectRenderImageTooltip } from '../../../../../../../specificComponents/Customers/Subcomponents'
import { listenSocket, unlistenSocket } from '../../../../../../../utils/globals/socket'
import { EmployeesPayload } from '../../../../../../../utils/reducers/company/employees'
import './customerProjectFlow.sass'
import { CustomSelectWithSearchBar } from '../../../../../../../forms/CustomSelect'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import { getBasePath } from '../../../../../../../utils/globals/link'


interface CustomerProjectFlowProps extends CustomerProjectFlowLogicProps { }

const CustomerProjectFlow: React.FC<CustomerProjectFlowProps> = ({customerProjectTypes,  ...logic }) => {
    const renderThis: JSX.Element[] = []
    const { projects, getProjects, handleOnDragEnd, onHandleDragOver, sensors, myProjectTypeId, searchValue, setSearchValue, openModal, setOpenModal, handleModalEditOpened, setSelectedEmployeeId, selectedEmployeeId } = useCustomerProjectFlowLogic({ customerProjectTypes, ...logic })
    const projectType = customerProjectTypes?.[myProjectTypeId]
    if (!projectType) {
        return null
    }
    let statuses: CustomerProjectStatusModelType[] = []
    if (projectType) {
        statuses = projectType.CustomerProjectStatuses || []
        for (const element of statuses) {
            if (element.listing) {
                renderThis.push(
                    <DroppableStatusColumn id={String(element.id)} status={element} projects={projects?.[element.id] || []} key={element.id} handleModalEditOpened={handleModalEditOpened}/>
                )
            }
        }
    }
    
    return (
        <div className='py-36 px-50 flex flex-auto h-1'>
            <WhiteBox className='flex flex-auto flex-col py-40 px-60 w-1'>
                <CustomerProjectFlowSelector customerProjectTypes={customerProjectTypes} ProjectTypeId={myProjectTypeId} {...{ ...logic }} />
                <CustomerProjectFlowHeader statuses={statuses} customerProjectTypeId={myProjectTypeId} getData={getProjects} setSearchValue={setSearchValue} searchValue={searchValue} {...{ selectedEmployeeId, setSelectedEmployeeId}} />
                {!projects ?
                    <LoadingSpinner /> : 
                    <div className='grid gap-10 flex-auto mt-30 overflow-x-auto' style={{ gridTemplateColumns: `repeat(${renderThis.length}, minmax(260px, 290px))` }}>
                        <DndContext
                            sensors={sensors}
                            onDragEnd={handleOnDragEnd}
                            onDragOver={onHandleDragOver}
                            collisionDetection={closestCorners}
                        >
                            {renderThis}
                        </DndContext>
                    </div>
                }
            </WhiteBox>
            {openModal?.type === 'edit' ? <ModalEditCustomerProject setOpened={() => { setOpenModal(null); getProjects() }} onSubmit={() => {}} allRowData={openModal.allRowData} /> : null}
        </div>
    )
}



interface CustomerProjectFlowLogicProps extends ReduxCustomerProjectTypes {}

const useCustomerProjectFlowLogic = ({ customerProjectTypes }: CustomerProjectFlowLogicProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number |null>(null)
    const [projects, setProjects] = useState<CustomerProjectStatusesDictForFlow | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()

    const { id } = useParams()
    const myProjectTypeId = parseInt(String(id))

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { delay: 200, tolerance: 5 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const onHandleDragOver = useCallback((event: DragOverEvent) => {
        const { active, over } = event
        const activeId = active.id
        const overId = parseInt(String(over?.id) || '')
        const activeContainer = parseInt(active?.data?.current?.sortable?.containerId)
        let overContainer = 0
        if (over?.data?.current && 'sortable' in over?.data?.current) {
            overContainer = parseInt(over?.data?.current?.sortable?.containerId)
        } else {
            overContainer = parseInt(over?.data?.current?.columnId)
        }

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return
        }
        setProjects((old) => {
            if (!old) {
                return null
            }
            const copy = JSON.parse(JSON.stringify(old))

            const activeItems = copy[activeContainer]
            const overItems = copy[overContainer]

            if (!copy[overContainer]) {
                copy[overContainer] = []
            }

            const activeIndex = activeItems.findIndex(
                (item: any) => item.id === activeId
            )
            const overIndex = overItems?.findIndex((item: any) => item.id === overId);
            let newIndex: number;
            if (copy && overId in copy) {
                newIndex = (overItems?.length || 0) + 1
            } else {
                const isBelowOverItem =
                    over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top > over.rect.top + over.rect.height;
                const modifier = isBelowOverItem ? 1 : 0;
                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }
            return {
                ...copy,
                [activeContainer]: copy[activeContainer].filter(
                    (item: any) => item.id !== active.id
                ),
                [overContainer]: [
                    ...copy[overContainer].slice(0, newIndex),
                    copy[activeContainer][activeIndex],
                    ...copy[overContainer].slice(newIndex, copy[overContainer].length)
                ]
            }
        })

    }, [setProjects])

    const handleOnDragEnd = useCallback(async (event: DragEndEvent) => {
        const { active, over } = event
        const activeId = active.id
        const overId = parseInt(String(over?.id) || '')
        const activeContainer = parseInt(active?.data?.current?.sortable?.containerId)
        let overContainer = parseInt(over?.data?.current?.sortable?.containerId)
        let customerProjectWithStatus: CustomerProjectFlowUpdatedDataForApi | null = null

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return
        }
        flushSync(async () => {
            await setProjects((old) => {
                if (!old) {
                    return null
                }
                let copy = JSON.parse(JSON.stringify(old))
                const activeIndex = copy[activeContainer].findIndex((item: any) => {
                    return item.id === activeId
                })
                const myOverContainer = copy[overContainer]
                const overIndex = myOverContainer.findIndex(
                    (item: any) => item.id === overId
                )
    
                if (!myOverContainer) {
                    copy[overContainer] = []
                }
    
                if (activeIndex !== overIndex) {
                    copy = { ...copy, [overContainer]: arrayMove(myOverContainer, activeIndex, overIndex) }
                }
                let order = 0
                const originalContainer = parseInt(active?.data?.current?.originalContainer || '0')
                if (originalContainer && overContainer !== originalContainer) {
                    for (const key in copy[originalContainer]) {
                        copy[originalContainer][key].order = order + 1
                        order++
                    }
                }
                order = 0
                for (const key in myOverContainer) {
                    copy[overContainer][key].order = order + 1
                    if ((active.id === copy[overContainer][key].id)) {
                        customerProjectWithStatus = {
                            id: copy[overContainer][key].id,
                            name: copy[overContainer][key].name,
                            order: copy[overContainer][key].order,
                            CurrentCProjectStatusId: overContainer
                        }
                    }
                    copy[overContainer][key].originalContainer = overContainer
                    order++
                }
                return copy
            })
            if (customerProjectWithStatus) {
                await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProjectCurrentStatus', { CurrentCProjectStatusId: overContainer, order: customerProjectWithStatus.order, id: activeId })
            }
        })
    }, [setProjects])

    const getProjects = useCallback(async () => {
        let where: Where = {
            'CustomerProject.CustomerProjectTypeId': myProjectTypeId
        }
        if (searchValue && searchValue.length) {
            where['$or'] = {
                'Customer.name': { $iLike: '%' + searchValue + '%' },
                'CustomerProject.name': { $iLike: '%' + searchValue + '%' },
            }
        }
        if(selectedEmployeeId){
            where['Employees->CustomerProjectEmployee.EmployeeId'] = selectedEmployeeId
        }
        const myProjectStatusDict: CustomerProjectStatusesDictForFlow = {}
        let statusesWhere: number[] = []
        const projectType = customerProjectTypes?.[myProjectTypeId]
        if (projectType) {
            const statuses = projectType.CustomerProjectStatuses || []
            for (const element of statuses) {
                if (element.listing) {
                    statusesWhere.push(element.id)
                    myProjectStatusDict[element.id] = []
                }
            }
        }
        where['CustomerProject.CurrentCProjectStatusId'] = { '$or': statusesWhere }
        if (statusesWhere.length) {
            const result = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjectsComplete', {
                where,
                order: [['order', 'ASC']]
            })
            if (result) {
                for (const element of result) {
                    myProjectStatusDict[element.CurrentCProjectStatusId].push({
                        ...element,
                        order: element.order,
                        originalContainer: element.CurrentCProjectStatusId
                    })
                }
                setProjects(myProjectStatusDict)
            }
        } else {
            setProjects({})
        }
    }, [setProjects, myProjectTypeId, searchValue, customerProjectTypes, selectedEmployeeId])

    const handleModalEditOpened = useCallback((project: CustomerProjectModelType) => {
        setOpenModal({
            type: 'edit',
            allRowData: { ...project }
        })
    }, [setOpenModal])

    useEffect(() => {
        listenSocket('projectStatusFlow-' + myProjectTypeId, getProjects, true)
        return () => {
            unlistenSocket('projectStatusFlow-' + myProjectTypeId)
        }
    }, [getProjects, myProjectTypeId])

    useEffect(() => {
        getProjects()
    }, [getProjects])

    return { sensors, projects, getProjects, handleOnDragEnd, onHandleDragOver, searchValue, setSearchValue, myProjectTypeId, openModal, setOpenModal, handleModalEditOpened, setSelectedEmployeeId, selectedEmployeeId }
}

interface DroppableStatusColumnProps {
    id: string
    status: CustomerProjectStatusModelType
    projects?: any
    handleModalEditOpened: (project: CustomerProjectModelType) => void
}

const DroppableStatusColumn: React.FC<DroppableStatusColumnProps> = ({ id, status, projects, handleModalEditOpened }) => {
    const { setNodeRef } = useDroppable({
        id,
        data: {
            columnId: id
        }
    })
    const renderThis: JSX.Element[] = []
    for (const key in projects) {
        const item = projects[key]
        renderThis.push(
            <DraggableProject project={item} id={item.id} index={item.id} key={item.id} originalContainer={item.originalContainer} handleModalEditOpened={handleModalEditOpened} />
        )
    }
    const statusColor = status.color
    return (
        <SortableContext items={projects} strategy={rectSortingStrategy} id={id}>
            <div className='flex flex-col mr-15'>
                <div className='py-12 px-20 bg-gray-F7 border-t-4' style={{ borderColor: statusColor }}>
                    <h2 className='text-gray-51 text-18'>{status.name}</h2>
                </div>
                <div className='flex flex-col flex-auto bg-gray-F7 mt-10 px-20 pt-5 pb-15'>
                    <div ref={setNodeRef} style={{minHeight: '500px'}}>
                        {renderThis}
                    </div>
                </div>
            </div>
        </SortableContext>
    )
}


interface DraggableProjectProps {
    id: string
    project: CustomerProjectModelType
    index: string
    originalContainer: number
    handleModalEditOpened: (project: CustomerProjectModelType) => void
}

const DraggableProject: React.FC<DraggableProjectProps> = ({ id, project, index, originalContainer, handleModalEditOpened }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id,
        data: {
            projectId: id,
            index,
            project,
            originalContainer
        },
    });
    const style = {
        transform: CSS.Translate.toString(transform),
    };
    const renderThese: JSX.Element[] = []
    if (project.Employees) {
        for (const employee of project?.Employees) {
            renderThese.push(
                <CustomerProjectRenderImageTooltip id={employee.id} img={employee?.profile_image ?? '/user-png.png'} name={employee?.fullname_short} tooltipName={'customerProjectEmployee'} />
            )
        }
    }
    if (project.Groups) {
        for (const group of project?.Groups) {
            renderThese.push(
                <CustomerProjectRenderImageTooltip id={group.id} img={group?.photo ?? '/group.png'} name={group.name} tooltipName={'customerProjectGroup'} />
            )
        }
    }

    return (
        <div ref={setNodeRef} onClick={() => handleModalEditOpened(project)} style={style} className='mt-12' {...listeners} {...attributes}>
            <div className='flex flex-col gap-8 rounded-lg bg-white px-17 py-10 customer_project_status_flow_card_content'>
                <span className='text-gray-51'>{project.name}</span>
                <span className='text-gray-51 font-light'>{project?.Customer?.name}</span>
                <div className='flex gap-15 items-center flex-wrap'>
                    <div className='flex flex-wrap'>
                        {renderThese}
                    </div>
                    <div className='flex flex-col justify-center'>
                        {
                            project.limit_date ?
                                <span className='flex items-center justify-center gap-5'>
                                    <TwinIcon className='text-gray-63' icon={faCalendarDays} />
                                    <span className='text-gray-8F text-12'>{displayDate(new Date(project?.limit_date || ''))}</span>
                                </span>
                                :
                                null
                        }
                    </div>
                    {
                        project.CustomerProjectComments?.length ?
                            <span className='flex items-center justify-center gap-5'>
                                <TwinIcon className='text-gray-63' icon={faComment} />
                                <span className='text-gray-8F'>{project.CustomerProjectComments?.length}</span>
                            </span>
                            :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

interface CustomerProjectFlowSelectorProps extends CustomerProjectFlowSelectorLogicProps { }

const CustomerProjectFlowSelector: React.FC<CustomerProjectFlowSelectorProps> = ({ customerProjectTypes, ProjectTypeId, ...rest }) => {
    const dictCustomerProjectTypes = dictionaryComplexFromJsonArr(customerProjectTypes || [])
    const { linkNextCustomerProjectType, linkPrevCustomerProjectType, onSelectCustomerProjectType } = useCustomerProjectFlowSelectorLogic({ customerProjectTypes: dictCustomerProjectTypes, ProjectTypeId, ...rest })
    return (
        <div className='flex items-center'>
            <Link className='cursor-pointer hover:text-green-21 flex items-center' to={linkPrevCustomerProjectType}><TwinIcon className='h-20' icon={faChevronLeft} /></Link>
            <Link className='cursor-pointer hover:text-green-21 ml-10 flex items-center' to={linkNextCustomerProjectType}><TwinIcon className='h-20' icon={faChevronRight} /></Link>
            <div className='toolbar-label  text-left text-gray-51 capitalize mx-10 mt-1'>
                <h1 className='text-gray-51 text-18'>
                    <TwinTrans transKey={'board'}>Tablero</TwinTrans>:  {dictCustomerProjectTypes?.[ProjectTypeId]?.name}
                </h1>
            </div>
            <CustomSelectWithSearchBar className='customer_project_flow_selector mr-24 ' label='' items={dictCustomerProjectTypes} onChange={onSelectCustomerProjectType} icon={faSearch} fieldName='name'/>
        </div>
    )
}
interface CustomerProjectFlowSelectorLogicProps extends ReduxCustomerProjectTypes {
    customerProjectTypes: TwinDictionary
    ProjectTypeId: number
}

const useCustomerProjectFlowSelectorLogic = ({ customerProjectTypes, ProjectTypeId }: CustomerProjectFlowSelectorLogicProps) => {
    const navigate = useNavigate()
    const customerProjectTypesStr = JSON.stringify(customerProjectTypes)
    const positionsCPT = useMemo(() => {
        const myCustomerProjectTypes = JSON.parse(customerProjectTypesStr)
        let result: TwinDictionary = {
            byPos: {},
            byId: {},
            lastPos: 0
        }
        let pos = 0
        for (const key in myCustomerProjectTypes) {
            result.byPos[pos] = key
            result.byId[key] = pos
            pos++
        }
        result.lastPos = pos-1
        return result
    }, [customerProjectTypesStr])

    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'projectStatusFlow')
    const nextCustomerProjectType = useCallback(() => {
        const posId = positionsCPT.byId[ProjectTypeId]
        const parsed = positionsCPT.byPos[posId + 1] || positionsCPT.byPos[0]
        return `${basePath}/${parsed}/main`
    }, [positionsCPT, ProjectTypeId, basePath])

    const prevCustomerProjectTyp = useCallback(() => {
        const posId = positionsCPT.byId[ProjectTypeId]
        const parsed = positionsCPT.byPos[posId - 1] || positionsCPT.byPos[positionsCPT.lastPos]
        return `${basePath}/${parsed}/main`
    }, [positionsCPT, ProjectTypeId, basePath])

    const onSelectCustomerProjectType = useCallback((id: number) => {
        navigate(`${basePath}/${id}/main`)
    }, [navigate, basePath])

    return { linkNextCustomerProjectType: nextCustomerProjectType(), linkPrevCustomerProjectType: prevCustomerProjectTyp(), onSelectCustomerProjectType }
}


const customerProjectTypesDispatch = {
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
    setEmployees: (payload: EmployeesPayload) =>({ type: 'CHANGE_EMPLOYEE', payload })
}

export type ReduxCustomerProjectTypes = ConnectedProps<typeof customerProjectTypesConnect>
const mapCustomerProjectTypesConnector = (state: AllReduxPayloads) => ({ customerProjectTypes: state.customerProjectTypes, employees: state.employees })
const customerProjectTypesConnect = connect(mapCustomerProjectTypesConnector, customerProjectTypesDispatch)

const customerProjectTypesConnectLoading = withLoading(CustomerProjectFlow, [{ fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }, {fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const CustomerProjectFlowConnect = customerProjectTypesConnect(customerProjectTypesConnectLoading)

export default CustomerProjectFlowConnect