interface TwinMapRenderProps {
    cords: string
    width: number
    height: number
}

const TwinMapRender: React.FC<TwinMapRenderProps> = ({cords, width, height}) => {
    const urlMaps = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCYn-9593GlKUOB72pkbPpNaoTy_qkhKQA&q="
    return (
        <iframe width={width} height={height} loading="lazy" title={cords} src={urlMaps + cords}></iframe>
    )
}

export default TwinMapRender