import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { QRCodeSVG } from 'qrcode.react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ButtonPrimary } from '../../../baseComponents/Button';
import LoadingSpinner from '../../../baseComponents/LoaderDecider/LoadingSpinner';
import TwinIcon from '../../../baseComponents/TwinIcon';
import TwinTrans from '../../../baseComponents/TwinTrans';
import { twinFetchPostText } from '../../../utils/globals/data';
import { listenSocket, unlistenSocket } from '../../../utils/globals/socket';
import useInterval from '../../../utils/hooks/useInterval';
import useIsLoading from '../../../utils/hooks/useIsLoading';
import { getConfigParam } from '../../../utils/reducers/getters';
import './generateTimeControlQr.sass'

const GenerateTimeControlQr: React.FC = () => {
    const { getMyQr, myQr, remainingTime, loading, usedQr } = useGenerateTimeControlQrLogic()
    const requireQrOnPunchin = getConfigParam('requireQrOnPunchIn')

    if(!requireQrOnPunchin || requireQrOnPunchin === 'off'){
        return <MessageNotActivated /> 
    }

    return (
        <div className='flex flex-col items-center justify-center generate_time_control_qr  flex-auto h-1'>
            <div className='overflow-auto text-center'>
                <ExplanationMessage />
                {loading ?
                    <div className='mt-100'><LoadingSpinner /></div>
                    : myQr && remainingTime ?     
                        <GeneratedQR myQr={myQr} usedQr={usedQr} remainingTime={remainingTime} />
                        : <ButtonPrimary onClick={getMyQr} className='mt-50 mx-auto'><TwinTrans transKey={'generate'}>Generar</TwinTrans></ButtonPrimary>
                    }
            </div>
        </div>)
}

const useGenerateTimeControlQrLogic = () => {
    const [myQr, setMyQr] = useState<string | null>(null)
    const [remainingTime, setRemainingTime] = useState<number | null>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const [usedQr, setUsedQr] = useState<boolean>(false)

    useInterval(() => {
        setRemainingTime((old) => { 
            if (old) { 
                return old - 1 
            } 
            return null 
        })
    }, remainingTime ? 1000 : false)


    const getMyQr = useCallback(async () => {
        startLoading()
        const qr = await twinFetchPostText('/api/app/qrTimeControl/createQrToken', {})
        if (qr) {
            setMyQr(qr)
            setRemainingTime(60)
            endLoading()
        }

    }, [setMyQr, startLoading, endLoading, setRemainingTime])

    const listenSocketQr = useCallback((value?: string) => {
        if (value && value === myQr){
            setUsedQr(true)
            setTimeout(() => {
                setUsedQr(false)
                setRemainingTime(0)
            }, 3000)
        }
    }, [setUsedQr, myQr])

    useEffect(() => {
        listenSocket('qrCode', listenSocketQr, true)
        return () => {
            unlistenSocket('qrCode')
        }
    }, [listenSocketQr])

    return { getMyQr, myQr, remainingTime, loading, usedQr }
}

const MessageNotActivated: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-center mt-100 generate_time_control_qr_enabled'>
            <h3><TwinTrans transKey='optionEnabled'>No tienes esta opción habilitada</TwinTrans></h3>
            <span className='regular16 max-w-600 mt-50'><TwinTrans transKey='informationAboutThisOptionAllows'>Esta opción permitirá fichar a tus empleados únicamente con un código QR que deberá generar un usuario administrador en esta pantalla. Esto es útil para empresas que quieran que sus trabajadores fichen únicamente en el sitio de trabajo. Si deseas habilitar esta opción, puedes hacerlo desde los parámetros, en la opción "Qr para fichar" </TwinTrans></span>
        </div>
    )
}

const ExplanationMessage: React.FC = () => {
    return (
        <Fragment>
            <h4 className='title_message_qr'><TwinTrans transKey='GenerateQrToClockInInApp'>Genera el código QR que te permitirá fichar en la App móvil</TwinTrans></h4>
            <span className='explanation_message_qr max-w-600 regular16 mt-50 block'><TwinTrans transKey='howToPunchIn'>Para fichar, el empleado deberá iniciar sesión en la App móvil y pulsar sobre el botón de fichar. En este punto, se abrirá la cámara para escanear el código QR que puedes generar a continuación:</TwinTrans></span>
        </Fragment>
    )
}

interface GeneratedQRProps {
    myQr: string
    usedQr: boolean
    remainingTime: number
}

const GeneratedQR: React.FC<GeneratedQRProps> = ({ myQr , remainingTime, usedQr}) => {
    return (
        <div className='qr_container mt-50'>
            <div className='relative inline-block'>
                <QRCodeSVG value={myQr}
                    size={300}
                    className='qr_for_punchin'
                    imageSettings={{
                        src: "/BIUUN_ISOTIPO.svg",
                        height: 45,
                        width: 40,
                        opacity: 1,
                        excavate: true,
                    }} />
                {usedQr ? <div className='top-0 left-0 right-0 bottom-0 absolute flex items-center justify-center bg-opacity-50 bg-black'><TwinIcon icon={faCheckCircle} className='text-green-43 bg-white rounded-full' fontSize={75} /></div> : null}
            </div>
            <div className='mt-10'><TwinTrans transKey='QrWillExpireIn'>Este QR caducará en</TwinTrans> {remainingTime} <TwinTrans transKey='seconds'>segundos</TwinTrans></div>
        </div>
    )
}


export default GenerateTimeControlQr
