import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinCalendar from '../../../../../../baseComponents/TwinCalendar'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import { useBaseScreenTableLogic } from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import TableControlMonthWork from '../../../../../../specificComponents/WCal/TableControlMonthWork'
import { getTotalMonthData } from '../../../../../../specificComponents/WCal/TableControlMonthWork/functions'
import TableControlYearWork from '../../../../../../specificComponents/WCal/TableControlMonthWork/TableControlYearWork'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import useDictMonths from '../../../../../../utils/hooks/useDictMonths'
import useOnClickOutside from '../../../../../../utils/hooks/useOnClickOut'
import useUpdateTableInstance from '../../../../../../utils/hooks/useUpdateTableInstance'
import { EmployeeJourneyTyExtended } from '../../../Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { MonthYearTy } from '../types'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../baseComponents/Button'
import { TimeControlEmployeeJourneyData } from '../../../../../../specificComponents/WCal/TableControlMonthWork/types'
import LoadingSpinner from '../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import { timeControlEmployeePDFParseDates } from '../../../Staff/WorkingCalendarParent/TimeControlEmployeeMonth/functions'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'

interface MyMonthTimeControlProps extends ComponentWithPermissions { }
const MyMonthTimeControl: React.FC<MyMonthTimeControlProps> = ({ userPermissions }) => {
    const { setMonthYear, monthYear, changeShowYear, showYears, data, loading, handleDownloadPdf } = useMyMonthControlLogic()
    const { setTableInstance, tableInstance, setOpenModal, } = useBaseScreenTableLogic()
    const { updateTableInstance } = useUpdateTableInstance({ tableInstance })

    const textButton = showYears ? <TwinTrans transKey='displayByMonth'>Mostrar por mes</TwinTrans> : <TwinTrans transKey='displayByYear'>Mostrar por año</TwinTrans>
    return (
        <WhiteBox className='w-full h-full mt-40 p-24 flex flex-col control_employee_working_month'>
            <div className='flex items-center calendar_toolbar'>
                {showYears ?
                    <TimeControlYearSelector {...{ setMonthYear, year: monthYear.year }} /> :
                    <TimeControlMonthSelector {...{ setMonthYear, monthYear }} />}
                <div className='ml-auto flex gap-10'>
                    <ButtonSecondary onClick={changeShowYear}>{textButton}</ButtonSecondary>
                    {loading ?
                        <div className='w-160'><LoadingSpinner className='h-24' /></div> :
                        <ButtonPrimary className='w-160' onClick={handleDownloadPdf}>
                            <TwinTrans transKey='downloadPdf'>Descargar PDF</TwinTrans>
                            <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                        </ButtonPrimary>
                    }
                </div>
            </div>
            {showYears && data?.type === 'year' ?
                <TableControlYearWork setOpened={setOpenModal} {...{ userPermissions, updateTableInstance, setTableInstance, tableInstance, year: monthYear.year, data: data.data, haveOvertimeHours: data?.haveOvertimeHours || false }} /> :
                data?.type === 'month' ?
                <TableControlMonthWork setOpened={setOpenModal} {...{ userPermissions, updateTableInstance, setTableInstance, tableInstance, data: data.data, haveOvertimeHours: data?.haveOvertimeHours || false }} /> : null}
        </WhiteBox>
    )
}

const useMyMonthControlLogic = () => {
    const { t } = useTwinTranslation()
    const [data, setData] = useState<TimeControlEmployeeJourneyData>(null)
    const now = new Date()
    const [monthYear, setMonthYear] = useState<MonthYearTy>({ month: now.getMonth() + 1, year: now.getFullYear() })
    const [showYears, setShowYears] = useState<boolean>(false)
    const { startLoading, endLoading, loading } = useIsLoading()
    const { dictMonths } = useDictMonths()
    const timeControlString = t('timeControlHyphen', 'Control-horario')

    const getData = useCallback(async () => {
        if (showYears) {
            const result = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getMyJourneyByYear', { year: monthYear.year })
            let haveOvertimeHours = false
            for (const element of result) {
                if (element.totalOvertimeMins) {
                    haveOvertimeHours = true
                    break
                }
            }
            if (result) {
                setData({
                    type: 'year',
                    data: result,
                    haveOvertimeHours
                })
            }
        } else {
            const dateStart = new Date(monthYear.year, monthYear.month - 1, 1).toDateString()
            const dateEnd = new Date(monthYear.year, monthYear.month, 0).toDateString()
            const result = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getMyJourneyByDates', { dateStart, dateEnd })
            if (result) {
                const arrResult: EmployeeJourneyTyExtended[] = []
                for (const key in result) {
                    const journey = result[key]
                    arrResult.push({ ...journey, date: key })
                }
                const { totalMinsWorked, totalMinsToDo, balanceMinsToDo, totalOvertimeMins } = getTotalMonthData(arrResult || [])
                arrResult?.push({ type: 'totals', date: '', totalMinsWorked, totalMinsToDo, balance: balanceMinsToDo, totalOvertimeMins })
                setData({
                    type: 'month',
                    data: arrResult,
                    haveOvertimeHours: totalOvertimeMins ? true : false
                })
             }
        }
    }, [monthYear.month, monthYear.year, showYears, setData])

    const changeShowYear = useCallback(() => {
        setShowYears((old) => !old)
    }, [setShowYears])

    useEffect(() => {
        getData()
    }, [getData])

    const handleDownloadPdf = useCallback(async (e: React.MouseEvent) => {
        startLoading?.()
        const { dateStart, dateEnd } = timeControlEmployeePDFParseDates(monthYear.month, monthYear.year, showYears)
        const current = e.currentTarget

        const result = await twinFetchPost('/api/app/employee/employeeTimetable/getMyTimeControlPDF', { dateStart, dateEnd })
        if (result) {
            const pdf = await result.blob()
            const fileURL = URL.createObjectURL(pdf)
            const a = current.querySelector('a')
            const fileName = timeControlString + '-' + (showYears ? monthYear.year : dictMonths[monthYear.month] + '-' + monthYear.year)
            if (a) {
                a.href = fileURL
                a.download = fileName + '.pdf'
                a.click()
            }
            endLoading?.()
        }
        return result
    }, [startLoading, endLoading, monthYear, showYears, dictMonths, timeControlString])

    return { setMonthYear, monthYear, changeShowYear, showYears, data, loading, handleDownloadPdf }
}


interface TimeControlMonthSelectorProps extends TimeControlMonthSelectorLogicProps {
    monthYear: MonthYearTy
}

const TimeControlMonthSelector: React.FC<TimeControlMonthSelectorProps> = ({ monthYear, ...rest }) => {
    const { ref, showModalCalendar, handleShowModalCalendar, onSelectMonth, changeNextMonth, changePrevMonth } = useTimeControlMonthSelectorLogic({ ...rest })
    const { dictMonths } = useDictMonths()
    const { month, year } = monthYear
    return (
        <div className='flex items-center relative'>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={changePrevMonth}><TwinIcon className='h-24' icon={faChevronLeft} /></div>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={changeNextMonth}><TwinIcon className='h-24' icon={faChevronRight} /></div>
            <div className='flex items-center' ref={ref}>
                <div className='toolbar-label  text-left text-gray-51 capitalize cursor-pointer' onClick={handleShowModalCalendar}>
                    {dictMonths[month]} - {year}
                </div>
                <div className='cursor-pointer ml-24 hover:text-green-21' >
                    <TwinIcon className='h-24 mt-5' icon={showModalCalendar ? faChevronUp : faChevronDown} onClick={handleShowModalCalendar} />
                </div>
                {showModalCalendar ? <TwinCalendar defaultActiveStartDate={new Date(year + '-' + month + '-1')} defaultView='year' className='absolute time_selector_month_calendar' onChange={() => null} onClickMonth={onSelectMonth} /> : null}
            </div>
        </div>
    )
}

interface TimeControlMonthSelectorLogicProps {
    setMonthYear: React.Dispatch<React.SetStateAction<MonthYearTy>>
}
const useTimeControlMonthSelectorLogic = ({ setMonthYear }: TimeControlMonthSelectorLogicProps) => {
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false)

    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setShowModalCalendar(false))

    const handleShowModalCalendar = useCallback(() => {
        setShowModalCalendar((old) => !old)
    }, [setShowModalCalendar])

    const changeNextMonth = useCallback(() => {
        setMonthYear((old) => {
            if (old.month === 12) {
                return { month: 1, year: old.year + 1 }
            } else {
                return { ...old, month: old.month + 1 }
            }
        })
    }, [setMonthYear])
    const changePrevMonth = useCallback(() => {
        setMonthYear((old) => {
            if (old.month === 1) {
                return { month: 12, year: old.year - 1 }
            } else {
                return { ...old, month: old.month - 1 }
            }
        })
    }, [setMonthYear])


    const onSelectMonth = useCallback((date: Date) => {
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        setMonthYear({ month, year })
        handleShowModalCalendar()
    }, [handleShowModalCalendar, setMonthYear])


    return { ref, showModalCalendar, handleShowModalCalendar, onSelectMonth, changeNextMonth, changePrevMonth }
}

interface TimeControlYearSelectorProps extends TimeControlYearSelectorLogicProps {
    year: number
}

const TimeControlYearSelector: React.FC<TimeControlYearSelectorProps> = ({year, ...rest }) => {
    const { changeNextYear, changePrevYear } = useTimeControlYearSelectorLogic({ ...rest })
    return (
        <div className='flex items-center relative'>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={changePrevYear}><TwinIcon className='h-24' icon={faChevronLeft} /></div>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={changeNextYear}><TwinIcon className='h-24' icon={faChevronRight} /></div>
            <div className='toolbar-label  text-left text-gray-51 capitalize'>{year}</div>
        </div>
    )
}

interface TimeControlYearSelectorLogicProps {
    setMonthYear: React.Dispatch<React.SetStateAction<MonthYearTy>>
}
const useTimeControlYearSelectorLogic = ({ setMonthYear }: TimeControlYearSelectorLogicProps) => {
    const changeNextYear = useCallback(() => {
        setMonthYear((old) => { return { month: old.month, year: old.year+1} })
    }, [setMonthYear])
    const changePrevYear = useCallback(() => {
        setMonthYear((old) => { return { month: old.month, year: old.year - 1 } })
    }, [setMonthYear])
    
    return { changeNextYear, changePrevYear }
}


export default MyMonthTimeControl