import { PermissionThreeKeys } from '@teinor/erp/permissions'
import { TwinParameters, TwinParametersKeys } from '@teinor/erp/types/company/config/parameter'
import store, { appStore } from '.'
import { getMaxPermissionByGroupPermissions } from '../../baseComponents/CreateRoutering/functions'
import { UserPermissions } from '../../baseComponents/CreateRoutering/types'
import { CustomerPayload } from './customers/customer'
import { DictionaryEmployee } from './company/employees'


export const getAllParams = () => {
    return appStore.getState().config?.parameters || {}
}

export const getActiveLangs = () => {
    return appStore.getState().config?.langs.used || {}
}

export const getAllLangs = () => {
    return appStore.getState().config?.langs.all || {}
}

export const getCategoriesDictionary = () => {
    return store.getState().categories?.dictionary || {}
}

export const getConfigParam = <T extends TwinParametersKeys>(param: T): TwinParameters[T] | undefined => {
    return appStore.getState().config?.parameters?.[param]
}

export const getPriceZoneGroups = () => {
    return store.getState().priceZoneGroups
}

export const getCustomerGroups = () => {
    return store.getState().customerGroups
}

export const getAllSuppliers = () => {
    return store.getState().suppliers?.all
}

export const getSuppliers = () => {
    return store.getState().suppliers?.suppliers
}

export const getCreditors = () => {
    return store.getState().suppliers?.creditors
}

export const getWarehouses = () => {
    return store.getState().warehouses
}

export const getModulesBuyed = () => {
    return appStore.getState().company?.modulesBuyed
}

export const getVariants = () => {
    return store.getState().variants?.variants
}

export const getVariantsValues = () => {
    return store.getState().variants?.values
}

export const getAllWorkingCentersTypes = () => {
    return store.getState().workingCenterTypes
}

export const getAllWorkingCenters = () => {
    return store.getState().workingCenters
}

export const getAllTaskTypes = () => {
    return store.getState().taskTypes
}

export const getAllTaskHelpers = () => {
    return store.getState().taskHelpers
}

export const getBillingGroups = () => {
    return store.getState().billingGroups
}

export const getSelectedWorkingCenter = () => {
    const userWorkingCenters = appStore.getState().user?.extraData?.WorkingCenters || {}
    const currentWorkingCenter = appStore.getState().user?.extraData?.WorkingCenterId || 0
    return userWorkingCenters[currentWorkingCenter]
}

export const getAllGroupVats = () => {
    return store.getState().groupVats
}
export const getAllCustomerProjectTypes = () => {
    return store.getState().customerProjectTypes
}

export const getAllGroupPermissions = () => {
    return appStore.getState().user?.extraData.groupPermissions
}

export const getUserPermissions = (requestedPermissions: PermissionThreeKeys | PermissionThreeKeys[]): UserPermissions | false => {
    const permissions = getAllGroupPermissions()
    if (permissions) {
        if (Array.isArray(requestedPermissions)) {
            let result: UserPermissions | false = false
            for (const permission of requestedPermissions) {
                const myPermission = getMaxPermissionByGroupPermissions(permission, permissions)
                if (myPermission.n > 0 && (!result || result.n < myPermission.n)) {
                    result = myPermission
                }
            }
            return result
        }
        return getMaxPermissionByGroupPermissions(requestedPermissions, permissions)
    }
    return false
}

export const getAntiUnsave = () => {
    return appStore.getState().antiUnsave
}

export const getEmployeeParams = () => {
    return appStore.getState().user?.extraData.Employee?.EmployeeParameters
}

export const getCostBuys = () => {
    return store.getState().costBuys
}

export const getCostSells = () => {
    return store.getState().costSells
}

export const getCompany = () => {
    return appStore.getState().company
}

export const getServiceTypes = () => {
    return store.getState().serviceTypes
}

export const getBillingRetentions = () => {
    return store.getState().billingRetentions
}

export const getPreparePricesConfigParam = ()=> {
    return { 
        decimals: appStore.getState().config?.parameters?.['decimals'],
        rounding: appStore.getState().config?.parameters?.['rounding'],
        roundingPriceType: appStore.getState().config?.parameters?.['roundingPriceType'],
    }
}

export const getConfigPaymentTypes = () => {
    return store.getState().configPaymentTypes
}

export const getConfigPaymentMethods = () => {
    return store.getState().configPaymentMethods
}

export const getConfigChargingMethods = () => {
    return store.getState().configChargingMethods
}

export const getCustomers = (): CustomerPayload => {
    return store.getState().customers
}

export const getEmployees = () => {
    return store.getState().employees
}

export const getAllClientManagerEmployees = () => {
    const employees = store.getState().employees
    const result: DictionaryEmployee = {}
    for (const key in employees) {
        const element = employees[parseInt(key)]
        if (element.clientManager) {
            result[parseInt(key)] = element
        }
    }
    return result
}

export const getEmployeeAbsenceTypes = () => {
    return store.getState().employeeAbsenceTypes
}

export const getGroups = () => {
    return store.getState().groups
}

export const getShippingZoneGroups = () => {
    return store.getState().shippingZoneGroups
}
export const getShippingCompanies = () => {
    return store.getState().shippingCompanies
}
export const getShippingServices = () => {
    return store.getState().shippingServices
}
export const getAllSpecificPriceRules = () => {
    return store.getState().specificPriceRules
}
export const getSellShippingStatus = () => {
    return store.getState().sellShippingStatus
}
export const getDictionaryCountries = () => {
    return appStore.getState().countries
}
export const getUserInfo = () => {
    return appStore.getState().user?.extraData
}
export const getBottomNavApp = () => {
    return appStore.getState().bottomNavApp
}
export const getTaskCFields = () => {
    return store.getState().taskCFields
}

export const getAllConversions = () => {
    return appStore.getState().config?.currencies.conversions || {}
}

export const getActiveCurrencies = () => {
    return appStore.getState().config?.currencies.used || {}
}

export const getActiveCurrenciesDictionary = () => {
    return appStore.getState().config?.currencies.usedDictionary || {}
}
export const getAllCurrencies = () => {
    return appStore.getState().config?.currencies.all || {}
}

export const getISOCurrencyMain = () => {
    return appStore.getState().config?.currencies.main || ''
}