import { useCallback, useState } from 'react'
import './dropdownVerticalSort.sass'
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TwinIcon from '../../TwinIcon';
import { faChevronDown, faChevronUp, faGripDotsVertical } from '@fortawesome/pro-light-svg-icons';
import { DropdownVerticalSortItem } from './types';


interface DropdownVerticalSortListProps extends DropdownVerticalSortListLogicProps {
    items: DropdownVerticalSortItem[]
}

const DropdownVerticalSortList: React.FC<DropdownVerticalSortListProps> = ({ items, setItems }) => {
    const { sensors, onDragStart, onDragEnd, scrollable } = useDropdownVerticalSortListLogic({ setItems })
    const renderThis: JSX.Element[] = []
    const ids = []
    for (const item of items) {
        renderThis.push(<SortableItem key={item.id} id={item.id} item={item} />)
        ids.push(item.id)
    }
    return (
        <div className='flex-auto h-full overflow-auto'>
            <div className={'verticaldropdown_sort_container overflow-hidden' + (!scrollable ? ' h-full' : '')}>
                <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} collisionDetection={closestCenter} >
                    <SortableContext items={ids} strategy={verticalListSortingStrategy} >
                        {renderThis}
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    )
}

interface DropdownVerticalSortListLogicProps {
    setItems: React.Dispatch<any>
}

const useDropdownVerticalSortListLogic = ({ setItems }: DropdownVerticalSortListLogicProps) => {
    const [scrollable, setScrollable] = useState<boolean>(true)

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )
    const onDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event
        if (active.id !== over?.id) {
            let oldIndex = 0
            let newIndex = 0
            setItems((oldItems: DropdownVerticalSortItem[]) => {
                for (const key in oldItems) {
                    const item = oldItems[key]
                    if (active.id === item.id) {
                        oldIndex = parseInt(key)
                    }
                    if (over?.id === item.id) {
                        newIndex = parseInt(key)
                    }
                }
                const items: DropdownVerticalSortItem[] = arrayMove(oldItems, oldIndex, newIndex)
                for (const key in items) {
                    items[key].order = parseInt(key) + 1
                }
                return items
            })
            setScrollable(true)
        }
    }, [setItems])
    const onDragStart = useCallback(() => {
        const container: any = document.querySelector('.verticaldropdown_sort_container')
        const containerParent: any = container?.parentElement
        if (containerParent.scrollHeight > container.offsetHeight) {
            setScrollable(false)
        } else {
            setScrollable(true)
        }
    }, [setScrollable])


    return { onDragStart, onDragEnd, sensors, scrollable }
}

interface SortableItemProps {
    id: string
    item: DropdownVerticalSortItem
}

const SortableItem: React.FC<SortableItemProps> = ({ id, item }) => {
    const [opened, setOpened] = useState(false)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id })
    let myTransform = transform
    if (myTransform) {
        myTransform.scaleX = 1
        myTransform.scaleY = 1
    }
    const style = {
        transform: CSS.Transform.toString(myTransform),
        transition,
    }

    return (
        <div className='verticaldropdown_sort_list_row' ref={setNodeRef} style={style} {...attributes} >
            <div className='verticaldropdown_sort_list_header' {...listeners}>
                <span>{item?.name}</span>
                <TwinIcon icon={opened ? faChevronUp:  faChevronDown} className='ml-auto dropdown_icon cursor-pointer' fontSize={18} onClick={(e) => { e.stopPropagation(); e.preventDefault();  setOpened((old) => !old)}} />
                <TwinIcon icon={faGripDotsVertical} className='ml-20 move_icon' fontSize={18}  />
            </div>
            <div className='verticaldropdown_sort_component'>
                {opened ? item.component : null}
            </div>
        </div>
    )
}

export default DropdownVerticalSortList