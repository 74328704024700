import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { TimeControlEventsAndHolidayBags } from './types'
import { displayDate, displayFormatedHourFromMins } from '../../../../../../utils/globals/date'
import { TwinBigCalendarEvents } from '../../../../../../baseComponents/TwinBigCalendar/types'

export const getOrderedColorHolidayBags = (currentIndex: number) => {
    let index = 0
    const colors = [
        '#AECB6C',
        '#90BDDA',
        '#F8A7C1',
        '#F88754',
        '#6299E3',
        '#D891EE',
    ]

    if (currentIndex === 0) {
        index = 0
    } else {
        index = (currentIndex) % colors.length
    }
    return colors[index]
}

export const timeControlParseFreeDaysToEvents = (data: EmployeeModelType, freeDaysTitle: string) => {
    const events: TimeControlEventsAndHolidayBags['events'] = []
    if (data.WCals) {
        for (const wCal of data.WCals) {
            if (wCal.WCalsFree) {
                for (const calendarFree of wCal.WCalsFree) {
                    if (calendarFree.WCalFreeDays?.length) {
                        for (const calendarFreeDays of calendarFree.WCalFreeDays) {
                            const date = new Date(calendarFreeDays.day)
                            events?.push({
                                id: calendarFreeDays.id,
                                allDay: false,
                                start: date,
                                title: calendarFreeDays.name || freeDaysTitle,
                                subtitle: displayDate(date),
                                color: calendarFreeDays.color,
                            })
                        }
                    }
                }
            }
        }
    }
    return events
}

export const timeControlParseHolidayBagToEvents =(data: EmployeeModelType, pendingText: string) => {
    const result: TimeControlEventsAndHolidayBags = {
        events: [],
        holidayBag: []
    }
    const wCalHolidayBags = data.WCalHolidayBags
    let currentIndex = 0
    if (wCalHolidayBags?.length) {
        for (const element of wCalHolidayBags) {
            const color = getOrderedColorHolidayBags(currentIndex)
            result.holidayBag?.push({
                id: element.id,
                EmployeeId: element.EmployeeId,
                name: element.name,
                originalDays: element.originalDays,
                leftDays: element.leftDays,
                from: displayDate(new Date(element.from)),
                to: displayDate(new Date(element.to)),
                color
            })
            if (element.WCalHolidayUsedDays?.length) {
                for (const usedDay of element.WCalHolidayUsedDays) {
                    const from = new Date(usedDay.from)
                    const to = new Date(usedDay.to)
                    const fromParsed = displayDate(from)
                    const toParsed = displayDate(to)
                    const subtitleExtra = usedDay.approved === 0 ? `\n(${pendingText})`: ''
                    result.events?.push({
                        id: usedDay.id,
                        allDay: true,
                        title: element.name,
                        subtitle: (usedDay.to ? fromParsed + ' - ' + toParsed : fromParsed) + subtitleExtra ,
                        start: from,
                        end: to,
                        color,
                    })
                }
            }
            currentIndex++
        }
    }
    return result
}

export const employeeAbsencesToEvents = (data: EmployeeModelType, pendingText: string) => {
    const events: TwinBigCalendarEvents = []
    if (data.EmployeeAbsences?.length){
        for (const absence of data.EmployeeAbsences) {
            const start = new Date(absence.dateStart)
            const end = new Date(absence.dateEnd)
            const fromParsed = displayDate(start)
            const toParsed = displayDate(end)
            const subtitleExtra = absence.approved === 0 ? `\n(${pendingText})` : ''
            const parsedSchedule = JSON.parse(absence?.schedule || '[]')
            let subtitle = ''
            if(start < end){
                subtitle = fromParsed + ' - ' + toParsed
            } else if( parsedSchedule?.length) {
                let scheduleArr = []
                for (const mySchedule of parsedSchedule) {
                    const parsedStartHours = displayFormatedHourFromMins(mySchedule[0])
                    const parsedEndHours = displayFormatedHourFromMins(mySchedule[1])
                    scheduleArr.push(parsedStartHours + ' - ' + parsedEndHours)
                }
                subtitle = scheduleArr.join("\n")
            } else {
                subtitle = fromParsed
            }
            events?.push({
                id: absence.id,
                allDay: false,
                start,
                end,
                title: absence?.EmployeeAbsenceType?.name,
                subtitle: subtitle + subtitleExtra,
                color: '#227C9D'
            })
        }
    }
    return events
}
export const overtimeToEvents = (data: EmployeeModelType, pendingText: string, overtimeText: string) => {
    const events: TwinBigCalendarEvents = []
    if (data.WCalOvertimes?.length){
        for (const overtime of data.WCalOvertimes) {
            const day = new Date(overtime.day)
            const fromParsed = displayDate(day)
            const subtitleExtra = overtime.status === 0 ? `\n(${pendingText})` : ''
            const parsedSchedule = JSON.parse(overtime?.schedule || '[]')
            let subtitle = ''
            if( parsedSchedule?.length) {
                let scheduleArr = []
                for (const mySchedule of parsedSchedule) {
                    const parsedStartHours = displayFormatedHourFromMins(mySchedule[0])
                    const parsedEndHours = displayFormatedHourFromMins(mySchedule[1])
                    scheduleArr.push(parsedStartHours + ' - ' + parsedEndHours)
                }
                subtitle = scheduleArr.join("\n")
            } else {
                subtitle = fromParsed
            }
            events?.push({
                id: overtime.id,
                allDay: false,
                start: day,
                end: day,
                title: overtimeText,
                subtitle: subtitle + subtitleExtra,
                color: '#f88754'
            })
        }
    }
    return events
}