import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerAddressModelType } from '@teinor/erp/types/company/customer/customerAddress'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderStateName, CustomRenderCountryName, CustomRenderBoolean, EditRow, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { getDictionaryCountries } from '../../../../../../../../../utils/reducers/getters'
import ModalCEContactAddress from './ModalCEContactB2BAddress'

interface ContactB2BAddressesProps extends ComponentWithPermissions {
    basicData: CustomerModelType
}

const ContactB2BAddresses: React.FC<ContactB2BAddressesProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('addresses', 'Direcciones'),
        newText: t('addAddress', 'Nueva dirección'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableContactB2BAddresses} CreateModalComponent={ModalCEContactAddress} EditModalComponent={ModalCEContactAddress} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id }} />
    )
}
interface TableContactB2BAddressesProps extends TableComponentBaseScreenInnerProps { }

const TableContactB2BAddresses: React.FC<TableContactB2BAddressesProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()
    const countries = getDictionaryCountries()


    const columns: ColumnTableSchema<CustomerAddressModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'address',
            dataKey: 'address',
            label: t('address', 'Dirección'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('address', 'Dirección'), }
            }
        },
        {
            id: 'additional_address',
            dataKey: 'additional_address',
            label: t('additional_address_2', 'Dirección 2'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('additional_address', 'Dirección adicional'), }
            }
        },
        {
            id: 'city',
            dataKey: 'city',
            label: t('city', 'Ciudad'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('city', 'Ciudad'), }
            }
        },
        {
            id: 'state',
            dataKey: 'state',
            label: t('state', 'Provincia'),
            customRender: (parameterValue, allRowData) => <CustomRenderStateName stateId={parameterValue} allRowData={allRowData} />,
        },
        {
            id: 'country',
            dataKey: 'country',
            label: t('country', 'País'),
            customRender: (parameterValue) => <CustomRenderCountryName countryId={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: countries, label: t('country', 'País') }
            },
            generalSearchable: false
        },
        {
            id: 'fiscal_address',
            dataKey: 'fiscal_address',
            label: t('fiscalAddress', 'Dirección Fiscal'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={102} />,
            width: 120
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable columns={columns} name='contactB2BAddresses' getDataFrom='/api/app/customer/customerAddress/getAllCustomerAddresses'
             onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
        })}  setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default ContactB2BAddresses