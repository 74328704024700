import { Fragment, useCallback, useEffect, useState } from 'react'
import LoadingSpinner from '../../../LoaderDecider/LoadingSpinner'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { faWindowMinimize } from '@fortawesome/pro-regular-svg-icons'
import { faArrowUpRightAndArrowDownLeftFromCenter, faXmark } from '@fortawesome/pro-light-svg-icons'
import { addBottomNavAppElement, changeBottomNavAppElement, changeErrorMessage, changeSuccessMessage, deleteBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'
import { checkIfAnimated } from '../../../Modal/functions'
import { useSingleTaskLogic, SingleTaskInner, SingleTaskInnerProps } from '../../../../specificComponents/Task/SingleTask'
import HeaderTask, { HeaderElementTask, HeaderTaskProps } from '../../../../specificComponents/Task/SingleTask/HeaderTask'
import withLoading from '../../../../utils/hoc/withLoading'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../utils/reducers'
import { TaskCFieldPayload } from '../../../../utils/reducers/company/taskCFields'
import { GroupPayload } from '../../../../utils/reducers/groups/groups'
import { EmployeesPayload } from '../../../../utils/reducers/company/employees'
import { TwinIconToolTip } from '../../../TwinIcon'
import useEditDeleteModal from '../../../../utils/hooks/useEditDeleteModal'
import { TaskTypePayload } from '../../../../utils/reducers/company/taskTypes'
import '../modalBottomNavApp.sass'

type ModalTaskProps = ModalTaskLogicProps & ReduxModalTask &  {
}

const ModalTask: React.FC<ModalTaskProps> = ({ extraData, ...rest }) => {
    const { handleOpenModal, animatedClassName, onInvalidPermissions, onDeletedTask, ...editDeleteProps } = useModalTaskLogic({ extraData })
    const { data, ...extraRest } = useSingleTaskLogic({ id: extraData?.id || '', onInvalidPermissions, onDeletedTask })
    return (
        <div className={'mr-20 ' + (extraData?.opened? 'min-w-750': 'min-w-300')}>
            <div className={'modal_task_container fixed bottom-0  ' + (extraData?.opened ? ' opened min-w-750 ' + animatedClassName : ' rounded-t-lg min-w-300' ) + (extraData?.skipAnimation? ' skip_animation' : '' )}>
                {extraData?.opened ? 
                    <div className='modal_task single_task flex flex-col notFlexAutoFormRender w-750 opened'>
                        {!data ?
                            <LoadingSpinner />
                            : 
                            <ModalTaskOpened isFloating={true} {...{ ...rest, ...extraRest, ...editDeleteProps, data, handleOpenModal, onDeletedTask }} />
                        }
                    </div>
                : 
                    <ModalTaskMinimized id={String(data?.id || '')} name={data?.name || ''} {...{handleOpenModal}} />
                }
            </div>
        </div>
    )
}



interface ModalTaskLogicProps {
    extraData: any
}
const useModalTaskLogic = ({ extraData }: ModalTaskLogicProps) => {
    const [animated, setAnimated] = useState(false)
    const { ...editDeleteProps } = useEditDeleteModal()

    const {t} = useTwinTranslation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')
    const animatedClassName = checkIfAnimated(animated)
    
    useEffect(() => {
        if (extraData.opened) {
            setTimeout(() => {
                setAnimated(true)
            }, 10)
        }
        return () => {
            if (extraData.opened) {
                setAnimated(false)
            }
        }
    }, [extraData.opened])

    const handleOpenModal = useCallback(async(opened: boolean) => {
        if(opened){
            await deleteBottomNavAppElement('taskModal-' + extraData?.id)
            await addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + extraData?.id, extraData: { ...extraData, opened } })
        } else {
            await changeBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + extraData?.id, extraData: { ...extraData, opened } })
        }
    }, [extraData])

    const onInvalidPermissions = useCallback(async() => {
        changeErrorMessage(taskNotAvailable)
        await deleteBottomNavAppElement('taskModal-' + extraData?.id)
    }, [taskNotAvailable, extraData?.id])

    const onDeletedTask = useCallback(async(otherPerson?: boolean) => {
        if (otherPerson) {
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
        await deleteBottomNavAppElement('taskModal-' + extraData?.id)
    }, [taskDeleted, extraData?.id, taskHasBeenDeleted])

    return { animatedClassName, handleOpenModal, onInvalidPermissions, onDeletedTask, ...editDeleteProps }
}

type ModalTaskOpenedProps = ReduxModalTask & HeaderTaskProps & SingleTaskInnerProps & {
    handleOpenModal: (opened: boolean) => Promise<void>
}

const ModalTaskOpened: React.FC<ModalTaskOpenedProps> = ({handleOpenModal, data, ...rest}) => {
    const {t} = useTwinTranslation()
    return (
        <Fragment>
            <HeaderTask className='modal_task_header' {...{ data, ...rest }}>
                <Fragment>
                    <HeaderElementTask className='task_minimize_icon' icon={faWindowMinimize} message={t('minimize', 'Minimizar')} id={'minimize_taskModal-' + data?.id} onClick={() => handleOpenModal(false)} />
                    <HeaderElementTask icon={faXmark} message={t('close', 'Cerrar')} id={'close'} onClick={() => deleteBottomNavAppElement('taskModal-' + data?.id)} />
                </Fragment>
            </HeaderTask>
            <SingleTaskInner {...{ data, ...rest }} />
        </Fragment>
    )
}



interface ModalTaskMinimizedProps {
    id: string
    name: string
    handleOpenModal: (opened: boolean) => Promise<void>
}

const ModalTaskMinimized: React.FC<ModalTaskMinimizedProps> = ({ id, name, handleOpenModal}) => {
    const {t} = useTwinTranslation()
    return (
        <div className='modal_task bg-gray-E1 flex items-center justify-between p-15 h-50'>
            <div className='twin_elipsis w-200'>{id} - {name}</div>
            <div className='flex items-center'>
                <TwinIconToolTip classNameIcon='ml-20 h-20' icon={faArrowUpRightAndArrowDownLeftFromCenter} message={t('maximize', 'Maximizar')} id='maximize' onClick={() => handleOpenModal(true)} />
                <TwinIconToolTip classNameIcon='ml-20 h-20' icon={faXmark} message={t('close', 'Cerrar')} id={'close'} onClick={() => deleteBottomNavAppElement('taskModal-' + id)} />
            </div>
        </div>
    )
}



const modalTaskDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
}

type ReduxModalTask = ConnectedProps<typeof modalTaskConnect>
const mapModalTaskConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskCFields: state.taskCFields, taskTypes: state.taskTypes })
const modalTaskConnect = connect(mapModalTaskConnector, modalTaskDispatch)
const taskConnectLoading = withLoading(ModalTask, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }, { fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])
const ModalTaskConnect = modalTaskConnect(taskConnectLoading)

export default ModalTaskConnect