import Chart from 'react-apexcharts'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { ButtonPrimary } from '../../../../../../../baseComponents/Button'
import { TimeControlHolidayBags } from '../types'
import { WhiteBox } from '../../../../../../../baseComponents/AppLayout/WhiteBox'
import { useCallback } from 'react'
import ModalHolidayBagDetail from './ModalHolidayBagDetail'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'

interface TCEmployeeHolidaysBagProps {
    holidayBag: TimeControlHolidayBags
    getData: () => Promise<void>
}

const TCEmployeeHolidaysBag: React.FC<TCEmployeeHolidaysBagProps> = ({ holidayBag, getData }) => {
    const { handleModalOpened, openModal, setOpenModal } = useTCEmployeeHolidaysBagLogic()
    return (
        <WhiteBox className='p-24 tce-holiday-bag'>
            <BagHeader holidayBag={holidayBag} handleModalOpened={handleModalOpened} />
            <BagCircle holidayBag={holidayBag} />
            <ButtonPrimary onClick={() => handleModalOpened(true)} className='w-full tce_bag_button'><TwinTrans transKey={'requestHolidays'}>Solicitar vacaciones</TwinTrans></ButtonPrimary>
            {openModal?.type === 'edit' ? <ModalHolidayBagDetail holidayBag={holidayBag} setOpened={() => setOpenModal(null)} allRowData={openModal.allRowData} getData={getData} /> : null}
        </WhiteBox>
    )
}

const useTCEmployeeHolidaysBagLogic = () => {
    const {openModal, setOpenModal}= useEditDeleteModal()

    const handleModalOpened = useCallback((requestingHolidays: boolean) => {
        setOpenModal({
            type: 'edit',
            allRowData: {requestingHolidays}
        })
    }, [setOpenModal])
    return { handleModalOpened, openModal, setOpenModal }
}

interface BagHeaderProps {
    holidayBag: TimeControlHolidayBags
    handleModalOpened: (requestingHolidays: boolean) => void
}

const BagHeader: React.FC<BagHeaderProps> = ({ holidayBag, handleModalOpened}) => {
    const { t, tVars } = useTwinTranslation()
    return (
        <div className='tce_bag_header'>
            <div className='flex items-center mb-10'>
                <h3>{holidayBag.name}</h3>
                <div className='color_dot' style={{ background: holidayBag.color }}></div>
                <span className='text-16 text-green-21 ml-auto cursor-pointer tce_bag_header_detail' onClick={() => handleModalOpened(false)}><TwinTrans transKey='viewDetail'>Ver detalle</TwinTrans></span>
            </div>
            <div className='flex tce_bag_header_side'>
                <span>{`${holidayBag.from} - ${holidayBag.to !== '1/1/1970' ? holidayBag.to : t('noExpires', 'No caduca')}`}</span>
                <span className='ml-auto'>{t('totalDays', 'Días totales') + ' ' + holidayBag.originalDays}</span>
            </div>
            <div className='hidden employee_holiday_bag_info'>
                <div>
                    {tVars('haveHolidaysLeft', 'Te quedan {{days}} días', { days: holidayBag.leftDays })}
                </div>
            </div>
        </div>
    )
}

interface BagCircleProps {
    holidayBag: TimeControlHolidayBags
}

const BagCircle: React.FC<BagCircleProps> = ({ holidayBag }) => {
    const { t } = useTwinTranslation()
    const labelStatus = {
        usedDays: {
            color: '#FFB946',
            label: t('usedDays', `Días utilizados`)
        },
        availableDays: {
            color: '#43BAA5',
            label: t('availableDays', 'Días disponibles')
        },
    }
    const usedDays = parseFloat(String(holidayBag.originalDays)) - parseFloat(String(holidayBag.leftDays))
    const series = [usedDays, parseFloat(String(holidayBag.leftDays))]
    const labels = [labelStatus.usedDays.label + ' ' + (usedDays), labelStatus.availableDays.label + ' ' + holidayBag.leftDays]
    return (
        <div className='flex align-center my-24 employee_holiday_bag_chart'>
            <Chart options={{
                chart: {
                    id: 'sue-informed-chart',
                    events: {
                        mounted: (chart) => {
                            chart.windowResizeHandler()
                        },
                        click: undefined,
                        legendClick: undefined,
                        mouseLeave: undefined,
                    }
                },
                fill: {
                    colors: [labelStatus.usedDays.color, labelStatus.availableDays.color],
                },
                labels,
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            size: "89%",
                            labels: {
                                show: true,
                                total: {
                                    label: t('haveLeft', 'Te quedan'),
                                    show: true,
                                    showAlways: true,
                                    formatter: () => {
                                        if (parseInt(String(holidayBag.leftDays)) === 1) {
                                            return holidayBag.leftDays + ' ' + t('dayLowerCase', 'día')
                                        }
                                        return holidayBag.leftDays + ' ' + t('daysLowerCase', 'días')
                                    }
                                },
                                name: {
                                    show: true,
                                    offsetY: -10,
                                },
                            }
                        }
                    }
                },
                colors: [labelStatus.usedDays.color, labelStatus.availableDays.color],
                legend: {
                    width: 200,
                    offsetY: 30,
                    offsetX: 0,
                    onItemClick: {
                        toggleDataSeries: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: "100%"
                            },
                            legend: {
                                show: false
                            }
                        }
                    }
                ],
            }}
                series={series}
                type='donut'
                width={375}
            />
        </div>
    )
}

export default TCEmployeeHolidaysBag