import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { connect, ConnectedProps } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../../../utils/globals/countries'
import { returnClassOfSpanTemplate } from '../../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useCountryState from '../../../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { WorkingCenterPayload } from '../../../../../../../../../utils/reducers/company/workingCenters'
import { getDictionaryCountries, getModulesBuyed, getUserPermissions } from '../../../../../../../../../utils/reducers/getters'
import { GroupPayload } from '../../../../../../../../../utils/reducers/groups/groups'
import { arrayFromArrayDictionaryAttribute } from '../../../../../../../../../utils/globals/array'
import { haveModuleBuyed } from '../../../../../../../../../utils/globals'
import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'

type MainEmployeeProps = ComponentWithPermissions & ReduxMainEmployee & {
    basicData: EmployeeModelType | null
    getData: () => Promise<void>
}
export type EmployeeModelExtended = EmployeeModelType & {
    'groups': string
    'wCalSpecials': string
}
const MainEmployee: React.FC<MainEmployeeProps> = ({ basicData, userPermissions, getData, workingCenters, groups }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { country, setCountry } = useCountryState({ defaultCountry: basicData?.country })
    const selectedDataParsed = arrayFromArrayDictionaryAttribute(basicData?.Groups || [])
    const { wCalSpecials } = useMainEmployeeLogic()
    const myWCals = basicData?.WCalSpecials?.map((item) => item.id)
    const fields: FormRender<EmployeeModelExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname2',
                    label: t('surname2', 'Segundo apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('employeeCode', 'Código de Empleado'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'defaultWorkingCenterId',
                    label: t('usualWorkingCenter', 'Centro de trabajo habitual'),
                    component: 'CustomSelectWithSearchBar',
                    items: workingCenters || {}
                },
                {
                    name: 'clientManager',
                    label: t('clientManager', 'Gestor de clientes'),
                    component: 'CheckboxMargin',
                },
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postalCode', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: basicData?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: basicData?.state
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
            ]
        }
    ]
    if (getUserPermissions('employee.group')) {
        fields.push({
            cols: 2,
            title: t('userGroups', 'Grupos del usuario'),
            elements: [
                {
                    name: 'groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: {
                        subtitleOptions: t('group', 'Grupos'),
                        subtitleSelectedOptions: t('groupsSelected', 'Grupos seleccionados')
                    },
                    items: groups || {},
                    defaultValue: selectedDataParsed,
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        })
    }
    if (haveModuleBuyed(["working_calendar", "working_calendar_medium", "working_calendar_full"], getModulesBuyed())) {
        fields.push({
            cols: 2,
            title: t('workingCalendarSpecialDays', 'Calendarios de días especiales'),
            elements: [
                {
                    name: 'wCalSpecials',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('specialDays', 'Días especiales'), subtitleSelectedOptions: t('specialDaysAssociated', 'Días especiales asociados') },
                    items: wCalSpecials || {},
                    defaultValue: myWCals || [],
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        })
    }
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)
    return (
        <SingleFormLayout action='/api/app/employee/updateEmployee' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} />
    )
}


const useMainEmployeeLogic = () => {
    const [wCalSpecials, setWCalSpecials] = useState<TwinDictionary | null>(null)

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarSpecial/getAllWorkingCalendarSpecials', {})
        if (result) {
            setWCalSpecials(dictionaryComplexFromJsonArr(result || []))
        }
    }, [])
    useEffect(() => {
        getData()
    }, [getData])
    return { wCalSpecials }
}


const mainEmployeeDispatch = {
    setWorkingCenters: (payload: WorkingCenterPayload) => ({ type: 'CHANGE_WORKINGCENTER', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
}

export type ReduxMainEmployee = ConnectedProps<typeof mainEmployeeConnect>
const mapMainEmployeeConnector = (state: AllReduxPayloads) => ({ workingCenters: state.workingCenters, groups: state.groups })
const mainEmployeeConnect = connect(mapMainEmployeeConnector, mainEmployeeDispatch)

const mainEmployeeConnectLoading = withLoading(MainEmployee, [{ fetchUrl: '/api/app/workingCenter/getAllWorkingCentersComplete', propName: 'workingCenters', setFunctionName: 'setWorkingCenters' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }])

const MainEmployeeConnect = mainEmployeeConnect(mainEmployeeConnectLoading)

export default MainEmployeeConnect