import Chart from 'react-apexcharts'
import { getConfigParam } from '../../../../../../utils/reducers/getters'
import useEditDeleteModal from '../../../../../../utils/hooks/useEditDeleteModal'
import { ButtonPrimary } from '../../../../../../baseComponents/Button'
import { useCallback, useEffect, useState } from 'react'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { displayFormatedHourFromMins } from '../../../../../../utils/globals/date'
import { WCalOvertimeModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalOvertime'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import ModalOvertimeDetail from './ModalOvertimeDetail'

interface TimeControlEmployeeOvertimeProps extends TimeControlEmployeeOvertimeLogicProps {}

const TimeControlEmployeeOvertime: React.FC<TimeControlEmployeeOvertimeProps> = ({ year }) => {
    const { handleModalOpened, openModal, setOpenModal, totalHours, totalOvertimeMins, getTotalOvertime } = useTimeControlEmployeeOvertimeLogic({ year })
    return (
        <WhiteBox className='p-24 tce-holiday-bag'>
            <BagHeader handleModalOpened={handleModalOpened} totalHours={totalHours} totalOvertimeMins={totalOvertimeMins} year={year} />
            <BagCircle totalOvertimeMins={totalOvertimeMins}  totalHours={totalHours}/>
            <ButtonPrimary onClick={() => handleModalOpened(true)} className='w-full tce_bag_button'><TwinTrans transKey={'requestOvertime'}>Solicitar horas extra</TwinTrans></ButtonPrimary>
            {openModal?.type === 'edit' ? <ModalOvertimeDetail year={year} totalOvertimeMins={totalOvertimeMins} totalHours={totalHours} setOpened={() => setOpenModal(null)} allRowData={openModal.allRowData} onSubmit={getTotalOvertime} /> : null}
        </WhiteBox>
    )
}

interface TimeControlEmployeeOvertimeLogicProps {
    year: number
}
export interface WCalOvertimeModelTypeExtended extends WCalOvertimeModelType {
    totalOvertimeMins?: number
}

const useTimeControlEmployeeOvertimeLogic = ({ year }: TimeControlEmployeeOvertimeLogicProps) => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const totalHours = getConfigParam('maxAnualOvertimeHours') 
    const [totalOvertimeMins, setTotalOvertimeMins] = useState<number>(0)

    const handleModalOpened = useCallback((requestingOvertime: boolean) => {
        setOpenModal({
            type: 'edit',
            allRowData: { requestingOvertime }
        })
    }, [setOpenModal])

    const getTotalOvertime = useCallback(async () => {
        const totalOvertimeMins = await twinFetchPostJSON('/api/app/employee/employeeOvertime/getTotalOvertimeMins', { year })
        setTotalOvertimeMins(totalOvertimeMins)
    }, [setTotalOvertimeMins, year])

    useEffect(() => {
        getTotalOvertime()
    }, [getTotalOvertime])

    return { handleModalOpened, openModal, setOpenModal, totalHours, totalOvertimeMins, getTotalOvertime }
}

interface BagHeaderProps {
    handleModalOpened: (requestingHolidays: boolean) => void
    totalHours?: number
    year: number
    totalOvertimeMins?: number
}

const BagHeader: React.FC<BagHeaderProps> = ({ handleModalOpened, totalHours, year, totalOvertimeMins }) => {
    const { t, tVars } = useTwinTranslation()
    let firstDayOfTheYear = new Date(year, 0, 1).toLocaleDateString();
    let lastDayOfTheYear = new Date(year, 11, 31).toLocaleDateString()
    return (
        <div className='tce_bag_header'>
            <div className='flex items-center mb-10'>
                <h3><TwinTrans transKey='overtime'>Horas extra</TwinTrans></h3>
                <span className='text-16 text-green-21 ml-auto cursor-pointer tce_bag_header_detail' onClick={() => handleModalOpened(false)}><TwinTrans transKey='viewDetail'>Ver detalle</TwinTrans></span>
            </div>
            <div className='flex tce_bag_header_side'>
                <span>{`${firstDayOfTheYear} - ${lastDayOfTheYear}`}</span>
                <span className='ml-auto'>{t('totalOvertimeHours', 'Horas totales') + ' ' + displayFormatedHourFromMins(totalHours || 0)}</span>
            </div>
            <div className='hidden employee_holiday_bag_info'>
                <div>
                    {tVars('haveHolidaysLeft', 'Te quedan {{hours}} horas', { hours: displayFormatedHourFromMins(totalOvertimeMins || 0) })}
                </div>
            </div>
        </div>
    )
}

interface BagCircleProps {
    totalHours?: number
    totalOvertimeMins?: number
}

const BagCircle: React.FC<BagCircleProps> = ({ totalHours, totalOvertimeMins }) => {
    const { t } = useTwinTranslation()
    const labelStatus = {
        usedHours: {
            color: '#FFB946',
            label: t('used', `Utilizadas`)
        },
        availableHours: {
            color: '#43BAA5',
            label: t('available', 'Disponibles')
        },
    }
    const hoursLeft = parseFloat(String(totalHours || '0')) - parseFloat(String(totalOvertimeMins || '0'))
    const series = [(totalOvertimeMins || 0), (hoursLeft || 0)]
    const labels = [labelStatus.usedHours.label + ' ' + (displayFormatedHourFromMins(totalOvertimeMins || 0)) + ' ' + t('hLowerCase', 'h'), labelStatus.availableHours.label + ' ' + displayFormatedHourFromMins(hoursLeft) + ' ' + t('hLowerCase', 'h')]
    return (
        <div className='flex align-center my-24 employee_holiday_bag_chart'>
            <Chart options={{
                chart: {
                    id: 'sue-informed-chart',
                    events: {
                        mounted: (chart) => {
                            chart.windowResizeHandler()
                        },
                        click: undefined,
                        legendClick: undefined,
                        mouseLeave: undefined,
                    }
                },
                fill: {
                    colors: [labelStatus.usedHours.color, labelStatus.availableHours.color],
                },
                labels,
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            size: "89%",
                            labels: {
                                show: true,
                                total: {
                                    label: t('haveLeft', 'Te quedan'),
                                    show: true,
                                    showAlways: true,
                                    formatter: () => {
                                        return displayFormatedHourFromMins(hoursLeft) + ' ' + t('hLowerCase', 'h')
                                    }
                                },
                                name: {
                                    show: true,
                                    offsetY: -10,
                                },
                            }
                        }
                    }
                },
                colors: [labelStatus.usedHours.color, labelStatus.availableHours.color],
                legend: {
                    width: 200,
                    offsetY: 30,
                    offsetX: 0,
                    onItemClick: {
                        toggleDataSeries: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: "100%"
                            },
                            legend: {
                                show: false
                            }
                        }
                    }
                ],
            }}
                series={series}
                type='donut'
                width={375}
            />
        </div>
    )
}

export default TimeControlEmployeeOvertime