import { useParams } from 'react-router'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { CustomRenderDateHours, CustomRenderString, CustomRenderStringAndColorNull, EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import ModalEditCampaignCustomer from '../../../../../../../../../specificComponents/ModalEditCampaignCustomer'
import ModalCreateCustomerB2BCampaign from './ModalCreateCustomerB2BCampaign'
import { CampaignCustomerModelTypeExtForCustomerProfile } from '../../../../../../../../../specificComponents/Customers/types'

interface CustomerB2BCampaignsProps extends ComponentWithPermissions {}

const CustomerB2BCampaigns: React.FC<CustomerB2BCampaignsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('campaigns', 'Campañas'),
        newText: t('addCampaign', 'Añadir campaña'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerB2BCampaigns} extraWhereParams={{ 'CustomerId': id }} pageData={pageData} CreateModalComponent={ModalCreateCustomerB2BCampaign} EditModalComponent={ModalEditCampaignCustomer} userPermissions={userPermissions} />
    )
}

interface TableCustomerB2BCampaignsProps extends TableComponentBaseScreenInnerProps {}

const TableCustomerB2BCampaigns: React.FC<TableCustomerB2BCampaignsProps> = ({ setTableInstance, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CampaignCustomerModelTypeExtForCustomerProfile> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('campaign', 'Campaña'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.Campaign?.name} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Campaign.name'
        },
        {
            id: 'CurrentCampaignStatusId',
            dataKey: 'CurrentCampaignStatusId',
            label: t('status', 'Estado'),
            customRender: (_parameterValue, allRowData) => <CustomRenderStringAndColorNull value={allRowData?.CurrentCampaignStatus?.name} color={allRowData?.CurrentCampaignStatus?.color} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('status', 'Estado') }
            },
            searchKey: 'CampaignCustomers.CurrentCampaignStatus.name',
            sortable: true,
            sortKey: 'CurrentCampaignCustomers.CurrentCampaignStatus.order'
        },
        {
            id: 'date',
            dataKey: 'date',
            label: t('limitDate', 'Fecha límite'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
        },
        {
            id: 'dateLastAction',
            dataKey: 'dateLastAction',
            label: t('dateLastAction', 'Fecha última acción'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
            sortable: true,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CustomerB2BCampaignCustomers' columns={columns} getDataFrom='/api/app/customer/campaign/customer/getAllCampaignCustomers'
            onRowClick={(_id, rowData) =>setOpened({
                type: 'edit',
                allRowData: { id: rowData?.id }
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default CustomerB2BCampaigns