import { useCallback } from 'react'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { TaskRepositoryAnswerModelTypeExt } from './types'
import TwinComment, { CreateTwinComment } from '../../../../../../../../baseComponents/TwinComment'
import PermissionChecker from '../../../../../../../../baseComponents/PermissionChecker'
import { ComponentWithPermissions } from '../../../../../../../../baseComponents/CreateRoutering/types'


interface TaskRepositoryAnswersProps extends ComponentWithPermissions{
    getTaskRepository: () => void
    TaskRepositoryId: string
    answers: TaskRepositoryAnswerModelTypeExt[]
}

const TaskRepositoryAnswers: React.FC<TaskRepositoryAnswersProps> = ({ userPermissions, TaskRepositoryId, answers, getTaskRepository }) => {
    const { deleteTaskRespositoryAnswer, updateTaskRespositoryAnswer} = useTaskRepositoryAnswersLogic({getTaskRepository})
    const renderThis: JSX.Element[] = []
    for (const key in answers) {
        const answer = answers[key]
        const parseDate = new Date(answer?.createdAt).toLocaleString()
        renderThis.push(<TwinComment className='pt-20' key={key} deleteComment={deleteTaskRespositoryAnswer} updateComment={updateTaskRespositoryAnswer} comment={answer.content || ''} posArr={parseInt(key)} fieldComment='message' title={answer?.Employee?.fullname_short || ''} subtitle={parseDate} photo={answer?.Employee?.profile_image} downloadFileURL='/api/app/task/repository/answer/getTaskRepositoryAnswerInstanceFile'{...answer} />)
    }

    return (
        <div className='task_repository_answers my-20'>
            <div className='bg-gray-F7 px-16 p-10'>
                <span className='regular18'>
                    <TwinTrans transKey='comments'>Comentarios</TwinTrans>
                </span>
            </div>
            <div className='answers_box'>
                {renderThis}
            </div>
            <div>
                <PermissionChecker userPermissions={userPermissions} permission={'update'}>
                    <CreateTwinComment createURL={'/api/app/task/repository/answer/createTaskRepositoryAnswer'} imagePath={['tasks', 'repository', TaskRepositoryId]} idParent={TaskRepositoryId || ''} fieldIdParent='TaskRepositoryId' myOnSubmit={getTaskRepository} fieldName='content' />
                </ PermissionChecker>
            </div>
        </div>
    )
}

interface TaskRepositoryAnswersLogicProps {
    getTaskRepository: () => void
}

const useTaskRepositoryAnswersLogic = ({ getTaskRepository }: TaskRepositoryAnswersLogicProps) => {

    const deleteTaskRespositoryAnswer = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/task/repository/answer/deleteTaskRepositoryAnswer', { id })
        if(result){
            getTaskRepository()
        }
    }, [getTaskRepository])

    const updateTaskRespositoryAnswer = useCallback(async (id: number, content: string) => {
        const result = await twinFetchPostJSON('/api/app/task/repository/answer/updateTaskRepositoryAnswer', { id, content })
        if(result){
            getTaskRepository()
        }
    }, [getTaskRepository])
    return { deleteTaskRespositoryAnswer, updateTaskRespositoryAnswer }
}

export default TaskRepositoryAnswers