import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { RowData } from '../../../../../../../../../../baseComponents/TwinTable/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'

interface CustomerContactPersonModelTypeExtends extends CustomerContactPersonModelType {
    customerContactPersonAccesses: string[]
}

interface ModalEditContactB2BContactPersonProps extends ModalEditComponentProps {}

const ModalEditContactB2BContactPerson: React.FC<ModalEditContactB2BContactPersonProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { id } = useParams()
    const { t } = useTwinTranslation()
    const { data } = useModalEditContactB2BContactPersonLogic({ allRowData })
    const accesses: TwinDictionary = { 'task_bag_of_hours': { value: 'task_bag_of_hours', name: t('bagOfHours', 'Bolsa de horas') }}

    const fields: FormRender<CustomerContactPersonModelTypeExtends> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'position',
                    label: t('position', 'Cargo'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
            ],
        },
        {
            cols: 2,
            title: t('contatInformation', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
            ],
        },
    ]
    if (allRowData && userPermissions) {
        if (!data) {
            return null
        }
        const userPermissionsSingle = getUserPermissions('sharingTaskBagOfHours')
        if (permissionCheck(userPermissionsSingle, 'read' )){
            fields.push(
                {
                    cols: 1,
                    elements: [
                        {
                            name: 'customerContactPersonAccesses',
                            component: 'MultipleCheckboxSelector',
                            subtitles: {
                                subtitleOptions: t('accesses', 'Accesos'),
                                subtitleSelectedOptions: t('selectedAccesses', 'Accesos seleccionados')
                            },
                            items: accesses || {},
                            defaultValue: data?.customerContactPersonAccesses || [],
                        },
                    ]
                }
            )
        }
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<MediumModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerContactPerson/updateCustomerContactPerson'} translations={{ title: t('editContactPerson', 'Editar Persona de contacto'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />)
    }

    return null
}

interface ModalEditCustomerContactPersonLogicProps {
    allRowData?: RowData
}

const useModalEditContactB2BContactPersonLogic = ({allRowData }: ModalEditCustomerContactPersonLogicProps) => {
    const [data, setData] = useState<CustomerContactPersonModelTypeExtends | null>(null)
    
    const getContactContactPersonData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerContactPerson/getCustomerContactPersonForDetail', { id: allRowData?.id })
        if (result) {
            const accessesParsed: string[] = []
            if (result.CustomerContactPersonAccesses) {
                for (const element of result.CustomerContactPersonAccesses) {
                    accessesParsed.push(element.access_key)
                }
            }
            setData({ ...result, customerContactPersonAccesses: accessesParsed})
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getContactContactPersonData()
    }, [getContactContactPersonData])

    return {data}

}

export default ModalEditContactB2BContactPerson