import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { WeekDays } from '@teinor/erp/types/company/WCalParent/WCal'
import { useCallback } from 'react'
import TwinIcon from '../../../../../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../../../../../baseComponents/TwinTrans'
import Checkbox from '../../../../../../../../../../../forms/Checkbox'
import { RectangularInputHourUnlimited } from '../../../../../../../../../../../forms/Input/InputHour/InputHourUnlimited'
import TableSchedule, { ScheduleTimeTy } from '../../../../../../../../../../../specificComponents/WCal/schedule/TableSchedule'
import useTwinTranslation from '../../../../../../../../../../../utils/hooks/useTwinTranslation'
import { ScheduleForModalEdit, WCalScheduleTyAndError } from '../types'
import { TwinDictionary } from '../../../../../../../../../../../utils/globals/dictionary'

interface ScheduleDayBasicProps extends MEScheduleWCSingleDayLogicProps {
    scheduleDay: WCalScheduleTyAndError
}

interface ScheduleDayProps extends ScheduleDayBasicProps { }

const MEScheduleWCSingleDay: React.FC<ScheduleDayProps> = ({ scheduleDay, ...rest }) => {
    const workingTime = scheduleDay?.workingTime
    const active = workingTime?.length ? true : false
    const {onChangeScheduleTime} = useMEScheduleWCSingleDayLogic({...rest})
    return (
        <div className='schedule_day'>
            <HeaderScheduleDay {...{ active, scheduleDay, ...rest }} />
            {active ?
                <TableSchedule errors={scheduleDay.errors} onChange={onChangeScheduleTime} scheduleTime={scheduleDay.workingTime} />
                :
                <NoWorkingDayMessage />
            }
        </div>
    )
}

interface MEScheduleWCSingleDayLogicProps {
    setMySchedules: React.Dispatch<React.SetStateAction<ScheduleForModalEdit>>
    day: WeekDays
}

const useMEScheduleWCSingleDayLogic = ({setMySchedules, day}: MEScheduleWCSingleDayLogicProps) => {
    const onChangeScheduleTime = useCallback((scheduleTime: ScheduleTimeTy) => {
        setMySchedules((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld[day].workingTime = scheduleTime
            if (!scheduleTime.length) {
                copyOld[day].flexible = false
            }
            return { ...copyOld }
        })
    }, [day, setMySchedules])
    return { onChangeScheduleTime }
}


const NoWorkingDayMessage: React.FC = () => {
    return (
        <div className='h-40 border border-gray-D6 rounded-8 text-red-BA flex items-center'>
            <TwinIcon className='mx-10 h-20 w-20' icon={faCircleExclamation}></TwinIcon>
            <div className='regular16'>
                <TwinTrans transKey='notWorkingDay'>Sin jornada laboral</TwinTrans>
            </div>
        </div>
    )
}


interface HeaderScheduleDayProps extends HeaderScheduleDayLogicProps {
    active: boolean
}

const HeaderScheduleDay: React.FC<HeaderScheduleDayProps> = ({ day, active, scheduleDay, setMySchedules }) => {
    const { days, handleActiveDay, onChangeFlexible } = useHeaderScheduleDayLogic({ day, scheduleDay, setMySchedules })
    return (
        <div className='header_schedule_day'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center mb-15'>
                    <Checkbox label='' id={"week_day_" + day} checked={active} onClick={handleActiveDay} /><label className='regular24 cursor-pointer' htmlFor={"week_day_" + day} >{days[day]}</label>
                </div>
                {active ?
                    <div className='flex items-center justify-end'>
                        <Checkbox label='' id={"flexible_" + day} className='my-10' checked={scheduleDay?.flexible} onChange={onChangeFlexible} />
                        <label className='medium14 cursor-pointer' htmlFor={"flexible_" + day}>
                            <TwinTrans transKey='flexibleTimetable'>Horario flexible</TwinTrans>
                        </label>
                    </div>
                    : null}
            </div>
            {scheduleDay?.flexible && active ?
                <DayFlexibleHours {...{ setMySchedules, day, scheduleDay }} />
                : null}
        </div>
    )
}

interface HeaderScheduleDayLogicProps extends ScheduleDayBasicProps {
}

const useHeaderScheduleDayLogic = ({ day, setMySchedules }: HeaderScheduleDayLogicProps) => {
    const { t } = useTwinTranslation()
    const days: TwinDictionary = {
        'monday': t('monday', 'Lunes'),
        'tuesday': t('tuesday', 'Martes'),
        'wednesday': t('wednesday', 'Miércoles'),
        'thursday': t('thursday', 'Jueves'),
        'friday': t('friday', 'Viernes'),
        'saturday': t('saturday', 'Sábado'),
        'sunday': t('sunday', 'Domingo'),
    }
    const handleActiveDay = useCallback(() => {
        setMySchedules((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if(!copyOld[day]){
                copyOld[day]= {
                    workingTime: [[540, 600]]
                }
            } else {
                if (copyOld[day]?.workingTime.length) {
                    copyOld[day] = {
                        workingTime: []
                    }
                } else {
                    copyOld[day].workingTime = [[540, 600]]
                }
            }
            return { ...copyOld }
        })
    }, [day, setMySchedules])

    const onChangeFlexible = useCallback((value: boolean) => {
        setMySchedules((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld[day] = {
                ...copyOld[day],
                flexible: value,
                maxDayMins: 0,
                minDayMins: 0,
                dailyHours: value ? copyOld[day].dailyHours : 0
            }
            return { ...copyOld }
        })
    }, [setMySchedules, day])



    return { days, onChangeFlexible, handleActiveDay }
}



interface DayFlexibleHoursProps extends DayFlexibleHoursLogicProps { }

const DayFlexibleHours: React.FC<DayFlexibleHoursProps> = ({ day, scheduleDay, setMySchedules }) => {
    const { onChangeDailyHours } = useDayFlexibleHoursLogic({ setMySchedules, scheduleDay, day })
    return (
        <div className='items-center justify-end flex'>
            <div className='flex items-center  justify-end my-5'>
                <span>
                    <TwinTrans transKey='dailyHours'>Horas diarias</TwinTrans>
                </span>
                <div className='ml-10 day_flexible_hours'>
                    <RectangularInputHourUnlimited className='to_right' label='' onFinalChange={(value) => onChangeDailyHours(value)} value={String(scheduleDay.dailyHours || 0)} />
                </div>
            </div>
        </div>
    )
}

interface DayFlexibleHoursLogicProps extends ScheduleDayBasicProps {
}

const useDayFlexibleHoursLogic = ({ setMySchedules, day }: DayFlexibleHoursLogicProps) => {
    const onChangeDailyHours = useCallback((mins: number) => {
        setMySchedules((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld[day]['dailyHours'] = mins
            return { ...copyOld }
        })
    }, [setMySchedules, day])

    return { onChangeDailyHours }
}

export default MEScheduleWCSingleDay