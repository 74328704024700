import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer'
import { DropDownWithSearchBar } from '../../../forms/CustomSelect/DropDown'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { ButtonSecondary } from '../../../baseComponents/Button'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import TwinTrans from '../../../baseComponents/TwinTrans'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { CustomSelectSectionSearchbarDropDown } from '../../../forms/CustomSelect/CustomSelectSections'
import { parseSearchCustomerProjectToSections } from './functions'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import './searcher_customer_project.sass'

export interface SearcherCustomerProjectProps extends SearcherCustomerProjectLogicProps {
    className?: string
}

const SearcherCustomerProject: React.FC<SearcherCustomerProjectProps> = ({ className, customers, handleAddCustomerProject, preSelectedCustomer, OldProjectId }) => {
    const { showSelectors, handleShowSelector, handleSelectedCustomer, selectedCustomer, customerProjects, handleSelectCustomerProject, setSelectedCustomer, ref, customersSectionItems, indexCustomers } = useSearcherCustomerProjectLogic({ handleAddCustomerProject, customers, preSelectedCustomer, OldProjectId })
    return (
        <div className='flex items-center w-full mr-50 '>
            <ButtonSecondary onClick={handleShowSelector}>
                <TwinIcon icon={faPlus} className='mr-5' />
                <TwinTrans transKey='add'>Añadir</TwinTrans>
            </ButtonSecondary>
            {showSelectors ?
                <div ref={ref} className={'searcher_customer_project w-full ' + (className || '')}>
                    {!selectedCustomer ?
                        <CustomSelectSectionSearchbarDropDown items={customersSectionItems} onChange={handleSelectedCustomer} value={''} />
                        :
                        <div>
                            <div className='flex relative searcher_project_container'>
                                <div className='h-34 bg-gray-F7 px-12 flex items-center cursor-pointer w-fit max-w-1/2 twin_elipsis ' onClick={()=>setSelectedCustomer(null)}>{indexCustomers?.[selectedCustomer]?.text}</div>
                                <div className='relative mr-20'>
                                    <div className='arrow_right'></div>
                                </div>
                                {Object.keys(customerProjects).length? <DropDownWithSearchBar items={customerProjects} onChange={handleSelectCustomerProject} value={''} /> : <div className='flex items-center'><TwinTrans transKey='noProjects'>No tiene proyectos</TwinTrans></div> }
                            </div>
                        </div>
                    }
                </div>
            : null
            }
        </div>
    )
}

interface SearcherCustomerProjectLogicProps {
    handleAddCustomerProject: (CustomerProject: CustomerProjectModelType) => Promise<boolean>
    customers: CustomerPayload
    preSelectedCustomer?: number
    OldProjectId?: number
}

const useSearcherCustomerProjectLogic = ({ handleAddCustomerProject, customers, preSelectedCustomer, OldProjectId }: SearcherCustomerProjectLogicProps) => {
    const [showSelectors, setShowSelectors] = useState<boolean>(preSelectedCustomer? true : false)
    const [selectedCustomer, setSelectedCustomer] = useState<number | null>(preSelectedCustomer || null)
    const [customerProjects, setCustomerProjects] = useState<TwinDictionary>({})
    const {t} = useTwinTranslation()
    const customersTrans = t('customers', 'Clientes')
    const customersB2BTrans = t('customersB2B', 'Clientes B2B')
    const contactB2BTrans = t('leadsB2B', 'Potenciales B2B')
    const contactB2CTrans = t('leads', 'Potenciales')
    const { customersSectionItems, indexCustomers } = useMemo(() => {
        const types: Record<ReduxCustomerTypes, string> = {
            'customers': customersTrans,
            'customersB2B': customersB2BTrans,
            'contactsB2B': contactB2BTrans,
            'contactsB2C': contactB2CTrans,
        };
        return parseSearchCustomerProjectToSections(customers, types)
    }, [customers, customersTrans, customersB2BTrans, contactB2BTrans, contactB2CTrans])

    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => handleShowSelector())
    
    const handleSelectCustomerProject = useCallback(async (CustomerProjectId: number) => {
        setShowSelectors(false)
        setSelectedCustomer(null)
        setCustomerProjects({})
        await handleAddCustomerProject(customerProjects[CustomerProjectId])
    }, [setCustomerProjects, handleAddCustomerProject, customerProjects])

    const handleShowSelector = useCallback(() => {
        if(!preSelectedCustomer){
            if (showSelectors){
                setSelectedCustomer(null)
                setCustomerProjects({})
            }
            setShowSelectors((old)=>!old)
        }else if(OldProjectId){
            handleSelectCustomerProject(OldProjectId)
        }
    }, [setShowSelectors, showSelectors, preSelectedCustomer, handleSelectCustomerProject, OldProjectId])


    const getCustomerProjects = useCallback(async(CustomerId?: number) => {
        if(CustomerId){
            const result = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjects', {
                where: {
                    CustomerId,
                    'CustomerProject.active': true
                }
            })
            if(result){
                setCustomerProjects( dictionaryComplexFromJsonArr(result))
            }
        }
    }, [setCustomerProjects])

    const handleSelectedCustomer = useCallback(async(id: number) => {
        setSelectedCustomer(id)
        await getCustomerProjects(id)
    }, [setSelectedCustomer, getCustomerProjects])

    useEffect(() => {
        if (showSelectors) {
            if (ref && ref.current) {
                const inputParentRef = ref?.current?.querySelector('.input_with_icon')
                if (inputParentRef) {
                    inputParentRef.children[0].focus()
                }
            }
        }
    }, [showSelectors, ref])

    useEffect(() => {
        getCustomerProjects(preSelectedCustomer)
    }, [getCustomerProjects, preSelectedCustomer])
    
    return { handleShowSelector, showSelectors, handleSelectedCustomer, handleSelectCustomerProject, selectedCustomer, customerProjects, setSelectedCustomer, ref, customersSectionItems, indexCustomers }
}


export default SearcherCustomerProject