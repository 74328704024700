import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { useAddDoubleTableGreenRowRender } from '../../../baseComponents/DoubleTable/functions'
import withLoading from '../../../utils/hoc/withLoading'
import useTaskColumns from '../../../utils/hooks/useTaskColumns'
import { AllReduxPayloads } from '../../../utils/reducers'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import { TaskCFieldPayload } from '../../../utils/reducers/company/taskCFields'
import { getEmployeeParams } from '../../../utils/reducers/getters'
import { GroupPayload } from '../../../utils/reducers/groups/groups'
import SingleTask from '../SingleTask'
import TaskDoubleTable from '../TaskDoubleTable'
import TaskScreenTable, { TaskScreenTableWhiteBox } from '../TaskScreenTable'
import { TaskTableBaseCompletedProps, TaskTableBaseProps } from './types'
import { TwinDictionary } from '../../../utils/globals/dictionary'

interface TasksTableDefaultProps extends TaskTableBaseProps {}

const TasksTableDefault: React.FC<TasksTableDefaultProps> = ({...rest }) => {
    const {id} = useParams<{ id: string }>()
    const myId = id || ''
    const {columns} = useTaskColumns({ id: myId})
    return (<TaskDoubleTableDefault {...{ columns, id: myId }} {...rest} />)
}


type TaskDoubleTableDefaultProps = TaskTableBaseCompletedProps & {
    id: string
}

export const TaskDoubleTableDefault: React.FC<TaskDoubleTableDefaultProps> = ({ id, ...rest }) => {
    return (<TaskDoubleTable FirstChild={TasksInner} SecondChild={SingleTask} displaySecond={id !== 'main'} {...rest} />)
}

type TasksInnerProps = TaskTableBaseCompletedProps & {
    limitPath: string
    extraCreateTaskParams?: TwinDictionary
}

export const TasksInner: React.FC<TasksInnerProps> = ({ extraWhereParams, ...rest  }) => {
    const { id } = useParams()
    const parameters = getEmployeeParams()
    const floatingTasks = parameters?.['floatingTasks'] === 'on' ? true : false
    const { rowRenderer } = useAddDoubleTableGreenRowRender(id || '')
    return (
        <TaskScreenTableWhiteBox floatingTasks={floatingTasks} rowRenderer={rowRenderer} extraWhereParams={{ ...extraWhereParams }} {...rest} />
    )
}
export const TasksInnerTab: React.FC<TasksInnerProps> = ({ extraWhereParams, ...rest }) => {
    const { id } = useParams()
    const { rowRenderer } = useAddDoubleTableGreenRowRender(id || '')
    return (
        <TaskScreenTable floatingTasks={true} rowRenderer={rowRenderer} extraWhereParams={{ ...extraWhereParams }} {...rest} />
    )
}


const tasksTableDefaultDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

const mapTasksTableDefaultConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskCFields: state.taskCFields })
const tasksTableDefaultConnect = connect(mapTasksTableDefaultConnector, tasksTableDefaultDispatch)
const taskConnectLoading = withLoading(TasksTableDefault, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const TasksTableDefaultConnect = tasksTableDefaultConnect(taskConnectLoading)


export default TasksTableDefaultConnect