import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { ButtonPrimary } from '../../../../baseComponents/Button'
import { permissionCheck } from '../../../../baseComponents/PermissionChecker/function'
import TwinIcon, { TwinIconToolTip, TwinIconToolTipProps } from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { SetEditDeleteModalTy } from '../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../utils/reducers/getters'
import { SetTaskDataExtended, TaskExtendedTy, UpdateTaskTy } from '../types'
import HeaderTaskAddSubTask from './HeaderTaskAddSubTask'
import HeaderTaskDelete from './HeaderTaskDelete'
import HeaderTaskInvertedTime from './HeaderTaskInvertedTime'
import HeaderTaskNotShowUntil from './HeaderTaskNotShowUntil'
import HeaderTaskTransformSubtask from './HeaderTaskTransformSubtask'
import HeaderTaskUnfinish from './HeaderTaskUnfinish'
import HeaderTaskUploadFile from './HeaderTaskUploadFile'
import { HeaderTaskArrComponents } from './types'
import HeaderTaskSubscriber from './HeaderTaskSubscriber'


export interface HeaderTaskProps  {
    data: TaskExtendedTy
    updateTask: UpdateTaskTy
    setOpenModalInvertedTime: SetEditDeleteModalTy
    setData: SetTaskDataExtended
    refSubTask: React.MutableRefObject<any>
    onDeletedTask: () => void
    children?: React.ReactNode
    className?: string
}

const HeaderTask: React.FC<HeaderTaskProps> = ({ updateTask, onDeletedTask, data, setData, refSubTask, setOpenModalInvertedTime, children, className }) => {
    const {t} = useTwinTranslation()

    const headerTaskArr: HeaderTaskArrComponents[] = [
        {
            message: t('unfinishTask', 'Reabrir tarea'),
            component: HeaderTaskUnfinish,
            extraProps: { updateTask, finished: data.finished }
        },
        {
            message: data.is_subscribed?t('unfollowTask', 'Dejar de seguir la tarea') : t('subscribeTask', 'Suscribirse a la Tarea'),
            component: HeaderTaskSubscriber,
            extraProps: { setData, is_subscribed: data.is_subscribed }
        },
        {
            message: t('uploadFile', 'Subir archivo'),
            component: HeaderTaskUploadFile,
            extraProps: { setData }
        },
        {
            message: t('addInvertedTime', 'Añadir tiempo invertido'),
            component: HeaderTaskInvertedTime,
            extraProps: { setOpenModalInvertedTime }
        },
        {
            message: t('notShowUntil', 'No mostrar hasta...'),
            component: HeaderTaskNotShowUntil,
            extraProps: { updateTask, remember_date: data?.remember_date }
        },
        {
            message: t('transformSubTask', 'Transformar en subtarea'),
            component: HeaderTaskTransformSubtask,
            extraProps: { updateTask }
        },
        {
            message: t('addSubTask', 'Añadir subtarea'),
            component: HeaderTaskAddSubTask,
            extraProps: { setData, refSubTask, id: data.id, CustomerProjectId: data?.CustomerProjectId }
        },
        
    ]
    const userPermission = getUserPermissions('task.main')

    if (permissionCheck(userPermission, 'delete')) {
        headerTaskArr.push({
            message: t('delete', 'Eliminar'),
            component: HeaderTaskDelete,
            extraProps: { updateTask, onDeletedTask }
        },)
    }

    const renderThis: JSX.Element[] = []
    for (const key in headerTaskArr) {
        const element = headerTaskArr[key]
        renderThis.push(<element.component key={key} id={data?.id} message={element.message} {...element.extraProps}/>)
    }
    return (
        <div className={'py-15 px-40 bg-gray-F7 flex items-center single_task_header'  + (className || '')} >
            {
                data?.finished ?
                    <div className='mr-auto flex items-center task_finished_message' >
                        <TwinIcon icon={faCheckCircle} className='mr-10 h-20' />
                        <TwinTrans transKey='finishedTask'>Tarea finalizada</TwinTrans>
                    </div>
                    :
                    <ButtonPrimary className='mr-auto' onClick={() => updateTask('finished', true)}>
                        <TwinTrans transKey='endTask'>Finalizar tarea</TwinTrans>
                    </ButtonPrimary>
            }
            <div className='flex items-center header_task_icons_container'>
               {renderThis}
               {children}
            </div>
        </div>
    )
}



interface HeaderElementTaskProps extends TwinIconToolTipProps  {
    className?: string
    subRef?: any
}

export const HeaderElementTask: React.FC<HeaderElementTaskProps> = ({ className, classNameIcon, ...rest }) => {
    return (
        <TwinIconToolTip className={'header_task_icon_container ' + (className || '')} classNameIcon={'h-20 ' + (classNameIcon || '')} {...rest} />
    )
}


export default HeaderTask