import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { useParams } from 'react-router'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'

interface CustomerContactPersonModelTypeExtends extends CustomerContactPersonModelType {
    customerContactPersonAccesses: string[]
}

interface ModalCreateContactB2CContactPersonProps extends ModalEditComponentProps { }

const ModalCreateContactB2CContactPerson: React.FC<ModalCreateContactB2CContactPersonProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { id } = useParams()
    const { t } = useTwinTranslation()
    const accesses: TwinDictionary = { 'task_bag_of_hours': { value: 'task_bag_of_hours', name: t('bagOfHours', 'Bolsa de horas') } }

    const fields: FormRender<CustomerContactPersonModelTypeExtends> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'position',
                    label: t('position', 'Cargo'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
            ],
        },
        {
            cols: 2,
            title: t('contatInformation', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
            ],
        },
    ]
    const userPermissionsSingle = getUserPermissions('sharingTaskBagOfHours')
    if (permissionCheck(userPermissionsSingle, 'read')) {
        fields.push(
            {
                cols: 1,
                elements: [
                    {
                        name: 'customerContactPersonAccesses',
                        component: 'MultipleCheckboxSelector',
                        subtitles: {
                            subtitleOptions: t('accesses', 'Accesos'),
                            subtitleSelectedOptions: t('selectedAccesses', 'Accesos seleccionados')
                        },
                        items: accesses || {},
                    },
                ]
            }
        )
    }
    return (<MediumModalCreateEdit fields={fields} url={'/api/app/customer/customerContactPerson/createCustomerContactPerson'} translations={{
        title: t('createContactPerson', 'Crear Persona de contacto'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCreateContactB2CContactPerson