import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { useLocation, useNavigate } from 'react-router'
import { getBasePath } from '../../../../../../../../../../utils/globals/link'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import ModalSmallFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'


interface ModalContactB2CChangeTypeProps extends ModalContactB2CChangeTypeLogicProps { }

const ModalContactB2CChangeType: React.FC<ModalContactB2CChangeTypeProps> = ({ ...rest}) => {
    const {t} = useTwinTranslation()
    const {handleOnSubmitContactType} = useModalContactB2CChangeTypeLogic({ ...rest })
    return (
        <ModalSmallFilled opened={true} setOpened={rest.setOpened} onAccept={handleOnSubmitContactType} translations={{
                title: t('transformToCustomer', 'Transformar a cliente'),
                subtitle: t('sureTransformContactToCustomer', '¿Seguro que quiere transformar este Cliente Potencial a Cliente?'),
                buttonAccept: t('transform', 'Transformar')
            }} >
        </ModalSmallFilled>
    )
}

interface ModalContactB2CChangeTypeLogicProps extends ModalEditComponentProps {}

const useModalContactB2CChangeTypeLogic = ({ allRowData }: ModalContactB2CChangeTypeLogicProps) => {
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'customer')
    const navigate = useNavigate()
    
    const handleOnSubmitContactType = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/updateCustomer', {
            id: allRowData?.id,
            customer_type: customerTypes['B2C']
        })
        if (result) {
            navigate(`${basePath}/customers/${allRowData?.id}/main`)
        }
    }, [allRowData?.id, navigate, basePath])

    return { handleOnSubmitContactType }
}


export default ModalContactB2CChangeType