import { Fragment, useCallback } from 'react'
import { WCalScheduleTyAndError } from '../../../../WorkingCalendars/SingleWorkingCalendar/Tabs/ScheduleWorkingCalendar/ModalEditScheduleWorkingCalendar/types'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import Checkbox from '../../../../../../../../../forms/Checkbox'
import { RectangularInputHourUnlimited } from '../../../../../../../../../forms/Input/InputHour/InputHourUnlimited'
import TableSchedule, { ScheduleTimeTy } from '../../../../../../../../../specificComponents/WCal/schedule/TableSchedule'

interface ScheduleNewSpecialDayProps extends ScheduleNewSpecialDayLogicProps {
    scheduleSpecialDay: WCalScheduleTyAndError
}

const ScheduleNewSpecialDay: React.FC<ScheduleNewSpecialDayProps> = ({ scheduleSpecialDay, setScheduleSpecialDay}) => {
    const {onChange} = useScheduleNewSpecialDayLogic({setScheduleSpecialDay})
    return (
        <Fragment>
            <HeaderScheduleDaySpecial {...{ scheduleSpecialDay, setScheduleSpecialDay }} />
            <TableSchedule scheduleTime={scheduleSpecialDay.workingTime} errors={scheduleSpecialDay.errors} onChange={onChange}/>
        </Fragment>
    )
}

interface ScheduleNewSpecialDayLogicProps {
    setScheduleSpecialDay: React.Dispatch<React.SetStateAction<WCalScheduleTyAndError>>
}

const useScheduleNewSpecialDayLogic = ({setScheduleSpecialDay}: ScheduleNewSpecialDayLogicProps) => {
    const onChange = useCallback((scheduleTime: ScheduleTimeTy) => {
        setScheduleSpecialDay((old) => { 
            const copy = JSON.parse(JSON.stringify(old))
            if(!scheduleTime.length){
                copy.flexible = false
            }
            return { ...copy, workingTime: scheduleTime }
         })
    }, [setScheduleSpecialDay])
    return {onChange}
}



interface HeaderScheduleDaySpecialProps extends HeaderScheduleDaySpecialLogicProps {
}

const HeaderScheduleDaySpecial: React.FC<HeaderScheduleDaySpecialProps> = ({ scheduleSpecialDay, setScheduleSpecialDay }) => {
    const { onChangeFlexible } = useHeaderScheduleDaySpecialLogic({ scheduleSpecialDay, setScheduleSpecialDay })
    return (
        <div className='header_schedule_day'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center justify-end'>
                    <Checkbox label='' id='flexible' className='my-10' checked={scheduleSpecialDay?.flexible} onChange={onChangeFlexible} />
                    <label className='medium14 cursor-pointer' htmlFor='flexible'>
                        <TwinTrans transKey='flexibleTimetable'>Horario flexible</TwinTrans>
                    </label>
                </div>
            </div>
            {scheduleSpecialDay?.flexible ?
                <DaySpecialFlexibleHours {...{ setScheduleSpecialDay, scheduleSpecialDay }} />
                : null}
        </div>
    )
}

interface HeaderScheduleDaySpecialLogicProps extends ScheduleNewSpecialDayProps {
}

const useHeaderScheduleDaySpecialLogic = ({ setScheduleSpecialDay }: HeaderScheduleDaySpecialLogicProps) => {
    const onChangeFlexible = useCallback((value: boolean) => {
        setScheduleSpecialDay((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            return { ...copyOld, flexible: value, maxDayMins: 0, minDayMins: 0, dailyHours: value ? copyOld.dailyHours : 0 }
        })
    }, [setScheduleSpecialDay])

    return { onChangeFlexible }
}



interface DaySpecialFlexibleHoursProps extends DayFlexibleHoursLogicProps { }

const DaySpecialFlexibleHours: React.FC<DaySpecialFlexibleHoursProps> = ({ scheduleSpecialDay, setScheduleSpecialDay }) => {
    const { onChangeDailyHours } = useDayFlexibleHoursLogic({ setScheduleSpecialDay, scheduleSpecialDay })
    return (
        <div className='items-center justify-end flex'>
            <div className='flex items-center  justify-end my-5'>
                <span>
                    <TwinTrans transKey='dailyHours'>Horas diarias</TwinTrans>
                </span>
                <div className='ml-10 day_special_flexible_hours'>
                    <RectangularInputHourUnlimited className='to_right' label='' onFinalChange={(value) => onChangeDailyHours(value)} value={String(scheduleSpecialDay.dailyHours || 0)} />
                </div>
            </div>
        </div>
    )
}

interface DayFlexibleHoursLogicProps extends ScheduleNewSpecialDayProps {
}

const useDayFlexibleHoursLogic = ({ setScheduleSpecialDay }: DayFlexibleHoursLogicProps) => {
    const onChangeDailyHours = useCallback((mins: number) => {
        setScheduleSpecialDay((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld['dailyHours'] = mins
            return { ...copyOld }
        })
    }, [setScheduleSpecialDay])

    return { onChangeDailyHours }
}



export default ScheduleNewSpecialDay