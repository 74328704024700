import { CustomerBankModelType } from '@teinor/erp/types/company/customer/customerBank'
import { useParams } from 'react-router'
import ModalCreateEdit from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalCEContactB2CBankProps extends ModalEditComponentProps { }

const ModalCEContactB2CBank: React.FC<ModalCEContactB2CBankProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { id } = useParams()
    const { t } = useTwinTranslation()
    const fields: FormRender<CustomerBankModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'iban',
                    label: t('iban', 'IBAN'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'entity',
                    label: t('entity', 'Entidad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'agency',
                    label: t('agency', 'Agencia'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'account',
                    label: t('accountBank', 'Cuenta Bancaria'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('code', 'Código'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'protocol',
                    label: t('protocol', 'Prótocolo'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'numberauth',
                    label: t('numberauth', 'Número de autenticación'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'date',
                    label: t('dateExpired', 'Fecha caducidad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'subaccount',
                    label: t('subaccount', 'Subcuenta'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'credit_code',
                    label: t('creditCode', 'Código de crédito'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'risk',
                    label: t('risk', 'Riesgo'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'datecredit',
                    label: t('dateCredit', 'Fecha de crédito'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'authorized',
                    label: t('authorized', 'Autorizado'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'showadvertise',
                    label: t('showadvertise', 'Mostrar anuncio'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
            ]
        },
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'defaultBank',
            label: t('defaultBank', 'Por defecto'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<ModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerBank/updateCustomerBank'} translations={{ title: t('editBank', 'Editar banco'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />)
    }

    return (<ModalCreateEdit fields={fields} url={'/api/app/customer/customerBank/createCustomerBank'} translations={{
        title: t('createBank', 'Crear banco'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCEContactB2CBank