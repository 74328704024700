import { WeekDays } from '@teinor/erp/types/company/WCalParent/WCal'
import { SetStateAction, useState, useCallback } from 'react'
import { flushSync } from 'react-dom'
import { ButtonPrimary } from '../../../../../../../../../../baseComponents/Button'
import CustomError from '../../../../../../../../../../baseComponents/CustomError'
import { ModalSmallMedium } from '../../../../../../../../../../baseComponents/Modal'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import { checkIfDailyHoursIsSmallerOrEqualArr, checkSchedulesAllOk } from '../../../../../../../../../../utils/globals/schedule'
import useEditDeleteModal from '../../../../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateNewVersionOrUpdate from '../../../subcomponents/ModalCreateNewVersionOrUpdate'
import { WCalParentExtendedTy } from '../../../types'
import MEScheduleWCSingleDay from './MEScheduleWCSingleDay'
import { parseEmployeeWCalsFreeWCalSpecials } from './functions'
import { ScheduleForModalEdit } from './types'

interface ModalEditScheduleWorkingCalendarProps extends ModalEditScheduleWorkingCalendarLogicProps {
    setOpenedModalSchedule: React.Dispatch<SetStateAction<true | null>>
    getData: () => Promise<void>
}

const weekDays: WeekDays[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const ModalEditScheduleWorkingCalendar: React.FC<ModalEditScheduleWorkingCalendarProps> = ({ setOpenedModalSchedule, basicData, schedules, getData}) => {
    const { mySchedules, error, checkAllOkAndSubmit, setMySchedules, openModal, setOpenModal } = useModalEditScheduleWorkingCalendarLogic({ basicData, schedules})
    const renderThis: JSX.Element[] = []
    for (const day of weekDays) {
        const scheduleDay = mySchedules[day]
        renderThis.push(<MEScheduleWCSingleDay key={day} {...{day, setMySchedules, scheduleDay}} />)
    }
    return (
        <ModalSmallMedium className='flex flex-col' opened={true} setOpened={setOpenedModalSchedule} onClickOut={false}>
            <div className=' modal_negative_margin flex flex-auto flex-col'>
                <div className='modal_schedule_container flex-auto overflow-auto h-1'>
                    <h2>
                        <TwinTrans transKey='timetable'>Horario</TwinTrans>
                    </h2>
                    {error ? <CustomError textError={error} className='my-20' /> : null}
                    {renderThis}
                    <div className='flex justify-end my-20 mr-10'>
                        <ButtonPrimary onClick={checkAllOkAndSubmit}>
                            <TwinTrans transKey='save'>Guardar</TwinTrans>    
                        </ButtonPrimary>
                    </div>
                </div>
            </div>
            {openModal?.type === 'edit' ? <ModalCreateNewVersionOrUpdate onSubmit={() => setOpenedModalSchedule(null)} urlTabString='schedule' {...{ openModal, setOpenModal, getData }} /> : null}
       </ModalSmallMedium>
    )
}


interface ModalEditScheduleWorkingCalendarLogicProps {
    schedules: ScheduleForModalEdit
    basicData: WCalParentExtendedTy | null
}

const useModalEditScheduleWorkingCalendarLogic = ({ schedules, basicData }: ModalEditScheduleWorkingCalendarLogicProps) => {
    const [mySchedules, setMySchedules] = useState<ScheduleForModalEdit>(schedules)
    const [error, setError] = useState<string | null>(null)
    const {t} = useTwinTranslation()
    const { openModal, setOpenModal } = useEditDeleteModal()
    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const errorDailyHours = t('conflictInDailyHoursCheckNotExceedTotalDay', 'Hay un conflicto en las horas diarias. Revisa que no superen el total de cada día.')

    const checkAllOkAndSubmit = useCallback(async () => {
        await flushSync(async () => {
            let hasError = false
            let checkedSchedules: ScheduleForModalEdit | false = false
            await setMySchedules((old)=>{
                const copyOld = JSON.parse(JSON.stringify(old))
                for (const day in copyOld) {
                    const myDaySchedule = copyOld[day] 
                    const response = checkSchedulesAllOk(myDaySchedule)
                    if(response.hasError){
                        hasError = true
                    }
                }
                if(!hasError){
                    checkedSchedules = { ...copyOld }
                }
                return {...copyOld}
            })
            if(hasError){
                const modal = document.querySelector('.modal_schedule_container ');
                if (modal) {
                    modal.scrollTop = 0
                }
                setError(errorHours)
                return false
            }
            if (checkedSchedules){
                const checkDailyHours = checkIfDailyHoursIsSmallerOrEqualArr(mySchedules)
                if (checkDailyHours.hasError) {
                    const modal = document.querySelector('.modal_schedule_container ');
                    if (modal) {
                        modal.scrollTop = 0
                    }
                    setError(errorDailyHours)
                    return false
                } else {
                    setError(null)
                    const parsedBasicData = parseEmployeeWCalsFreeWCalSpecials(basicData)
                    setOpenModal({ type: 'edit', allRowData: { ...parsedBasicData, WCalId: basicData?.WCalId, WCalParentId: basicData?.WCalParentId, schedule: JSON.stringify(checkedSchedules) }})
                }
            }
        })
    }, [setMySchedules, setError, errorHours, basicData, setOpenModal, mySchedules, errorDailyHours])


    return { mySchedules, error, checkAllOkAndSubmit, setMySchedules, openModal, setOpenModal }
}




export default ModalEditScheduleWorkingCalendar