import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { CheckboxRounded } from '../../../forms/Checkbox'
import { RectangularTextAreaDebounce } from '../../../forms/Input/TextArea'
import TwinIcon from '../../TwinIcon'
import PermissionChecker from '../../PermissionChecker'
import { UserPermissionsSingle } from '../../CreateRoutering/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { permissionCheck } from '../../PermissionChecker/function'
import { ChangeChecklistTaskTy } from '../types'

interface ChecklistTaskProps {
    task: TwinDictionary
    userPermission: UserPermissionsSingle | false
    onChangeTask: ChangeChecklistTaskTy
    onDeleteTask: ()=>void
    addTask: ()=>void
 }

const ChecklistTask: React.FC<ChecklistTaskProps> = ({ userPermission, task, onChangeTask, onDeleteTask, addTask  }) => {
    const { t } = useTwinTranslation()
    return (
        <div className='checklist_task flex items-center border-b border-gray-EE'>
            <CheckboxRounded className='mr-10' value={task.checked} onChange={(value) => onChangeTask('checked', value)} readOnly={!permissionCheck(userPermission, 'update')}/>
            <RectangularTextAreaDebounce label='' placeholder={t('titleTask', 'Título de la tarea')} readOnly={!permissionCheck(userPermission, 'update')}  className='flex-auto input_change_checklist_task_name mr-20 rounded-md' value={task?.name || ''} onChange={(value) => onChangeTask('name', value)} onBlur={(e) => {
                const val = e.currentTarget.value
                if (val !== task?.name) {
                    onChangeTask('name', e.currentTarget.value)
                }
            }} onShiftEnter={permissionCheck(userPermission, 'update') ? addTask : undefined } />
            <PermissionChecker userPermissions={userPermission} permission={'delete'}>
                <TwinIcon className='cursor-pointer hover:text-red-BA ml-10' icon={faXmark} onClick={onDeleteTask} />
            </PermissionChecker>
        </div>
    )
}

export default ChecklistTask