import { Fragment, useState } from 'react';
import { HeaderAuth, LoginSubHeaderBase, TitleAuth } from '../Login/Components';
import TwinTrans from '../../../../baseComponents/TwinTrans';
import { LoginAuthForm, LoginBaseForm, LoginERP } from '../Login';
import { UserFingerprintMessageData } from '../Login/types';
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation';

export interface LoginForQrProps { }

const LoginForQr: React.FC<LoginForQrProps> = () => {
    const {t} = useTwinTranslation()
    const { fingerprintAuth, setFingerprintAuth } = useLoginForQrLogic()
    return (
        <Fragment>
            <LoginForQrHeader/>
            {fingerprintAuth ?
                <LoginAuthForm fingerprintAuth={fingerprintAuth} />
                :
                <LoginBaseForm active={2} setFingerprintAuth={setFingerprintAuth}>
                    <LoginSubHeaderBase title={t('loginForGenerateQr', 'Login para generar QR')} subtitle={''} />
                    <LoginERP />
                </LoginBaseForm>
            }
        </Fragment>
    );
}

const useLoginForQrLogic = () => {
    const [fingerprintAuth, setFingerprintAuth] = useState<UserFingerprintMessageData | null>(null)
    return { fingerprintAuth, setFingerprintAuth }
}

const LoginForQrHeader: React.FC = () => {
    return (
        <Fragment>
            <HeaderAuth />
            <TitleAuth>
                <TwinTrans transKey='loginPageSubtitleNew'>
                    Bienvenido a la mejor forma de gestionar tu empresa
                </TwinTrans>
            </TitleAuth>
        </Fragment>
    )
}

export default LoginForQr;