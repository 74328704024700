import ModalDeleteFilled from '../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'


interface ModalDeleteCampaignCustomerProps extends ModalEditComponentProps {
    id?: number
 }

const ModalDeleteCampaignCustomer: React.FC<ModalDeleteCampaignCustomerProps> = ({ setOpened, onSubmit, id }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/campaign/customer/deleteCampaignCustomer',
        data: {
            id,
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteThisCampaignCustomer', '¿Seguro que quieres eliminar este cliente de esta campaña?'),
            subtitle: t('onceDeletedCampaignCustomerNotRecover', 'Una vez eliminado no lo podrás recuperar'),
        }} />
    )
}

export default ModalDeleteCampaignCustomer