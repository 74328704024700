import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'

export interface CustomersAction {
    type: 'CHANGE_CUSTOMER'
    payload: CustomerModelType[]
}

export type ReduxCustomerTypes = 'customers' | 'customersB2B' | 'contactsB2B' | 'contactsB2C'

export type CustomersResult = { customers: DictionaryCustomer, customersB2B: DictionaryCustomer, contactsB2B: DictionaryCustomer, contactsB2C: DictionaryCustomer, all: DictionaryCustomer }
export type DictionaryCustomer = { [id: number]: CustomerModelType }
export type CustomerPayload = null | CustomersResult
export default function customer(state: CustomerPayload = null, action: CustomersAction): DictionaryCustomer | null {
    const customerTypesDict: Record<CustomerTypesInversedKeys, ReduxCustomerTypes> = {
        0: 'customers',
        1: 'customersB2B',
        2: 'contactsB2B',
        3: 'contactsB2C'
    }
    switch (action.type) {
        case 'CHANGE_CUSTOMER':
            const result: CustomersResult = { customers: {}, customersB2B: {}, contactsB2B: {}, contactsB2C: {}, all: {} }
            for (const customer of action.payload) {
                const id = customer.id
                const type = customer.customer_type
                const element = customerTypesDict[type]
                if (element) {
                    result[element][id] = customer
                }
                result.all[id] = customer
            }
            return state = result
        default:
            return state
    }
}