import { TwinDictionary } from '../globals/dictionary'
import useTwinTranslation from './useTwinTranslation'

const useDictRequestOvertime = () => {
    const { t } = useTwinTranslation()
    const requestStatusOvertimeTypesDic: TwinDictionary = {
        0: { name: t('pendingApprovedShort', 'Por aprobar'), colorText: 'text-yellow-B9' },
        1: { name: t('accepted', 'Aceptadas'), colorText: 'text-green-21' },
        2: { name: t('canceled', 'Canceladas'), colorText: 'text-red-BA' },
        3: { name: t('denied', 'Denegadas'), colorText: 'text-red-BA' },
        4: { name: t('paid', 'Pagadas'), colorText: 'text-green-21' },
        5: { name: t('createdHolidaysShort', 'En vacaciones'), colorText: 'text-green-21' },
    }

    const overtimePreferences: TwinDictionary = {
        0: { name: t('hoursPaid', 'Horas pagadas'), reason: t('paidOvertimePreference', 'Preferencia horas extra pagadas') },
        1: { name: t('intoHolidays', 'En vacaciones'), reason: t('holidaysPreference', 'Preferencia vacaciones') }
    }

    return {requestStatusOvertimeTypesDic, overtimePreferences}
}

export default useDictRequestOvertime