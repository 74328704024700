import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons'
import { useCallback } from 'react'
import { TwinIconToolTip } from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import useEditDeleteModal from '../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { SetTaskDataExtended } from '../types'
import { TaskCommentModelTypeExt } from './types'
import { changeSuccessMessage } from '../../../../utils/reducers/reduxDispatch'
import { ModalSmallFilledOutside } from '../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import TwinComment, { CreateTwinComment } from '../../../../baseComponents/TwinComment'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import twinFetchPost from '../../../../utils/globals/data'
import { getUserPermissions } from '../../../../utils/reducers/getters'
import PermissionChecker from '../../../../baseComponents/PermissionChecker'

interface TaskCommentsProps extends TaskCommentsLogicProps {
    TaskId: string
    comments: TaskCommentModelTypeExt[]
    CustomerProjectId?: number
}

const TaskComments: React.FC<TaskCommentsProps> = ({ TaskId, comments, setData, CustomerProjectId }) => {
    const { handleCreate, deleteComment, updateComment, copyComment, openModal, setOpenModal } = useTaskCommentsLogic({ setData, TaskId, CustomerProjectId })
    const userPermissions = getUserPermissions('task.main')
    const renderThis: JSX.Element[] = []
    const {t} = useTwinTranslation()
    for (const key in comments) {
        const comment = comments[key]
        const parseDate = new Date(comment?.createdAt).toLocaleString()
        const ableToCopy = CustomerProjectId && !comment.already_copied
        renderThis.push(<TwinComment className='pt-20' key={key} comment={comment.content || ''} posArr={parseInt(key)} fieldComment='message' title={comment.Employee?.fullname_short} subtitle={parseDate} photo={comment?.Employee?.profile_image} downloadFileURL='/api/app/task/comment/getTaskCommentInstanceFile' {...comment} {...{ deleteComment, updateComment}}>
            {ableToCopy ?
                <TwinIconToolTip message={t('copyCommentToProject', 'Copiar comentario al Proyecto')} classNameIcon='mr-15 h-20 hover:text-green-43' icon={faCloudArrowUp} onClick={() => setOpenModal({ type: 'edit', allRowData: { CommentId: comment.id, posArr: parseInt(key) } })} id={comment.id + '-copyComment'} /> 
            : null}
        </TwinComment>
        )
    }

    return (
        <div className='task_comments'>
            <div className='bg-gray-F7 px-16 p-10'>
                <span className='regular18'>
                    <TwinTrans transKey='comments'>Comentarios</TwinTrans>
                </span>
            </div>
            <div className='comments_box'>
                {renderThis}
            </div>
            <div>
                <PermissionChecker userPermissions={userPermissions} permission={'update'}>
                    <CreateTwinComment createURL={'/api/app/task/comment/createTaskComment'} imagePath={['tasks', TaskId]} idParent={TaskId || ''} fieldIdParent='TaskId' myOnSubmit={handleCreate} fieldName='content' extraProps={{ myExtraHeaders: { 'type-update': 'singleTask' } }} />
                </ PermissionChecker>
            </div>
            {openModal?.type === 'edit' ? <ModalSmallFilledOutside translations={{
                title: t('copyCommentToProject', 'Copiar comentario en el proyecto'),
                subtitle: t('sureCopyToProject', '¿Estás seguro de querer copiar este comentario en el proyecto?'),
                buttonAccept: t('copy', 'Copiar'),
            }} opened={true} setOpened={() => setOpenModal(null)} onAccept={() => copyComment?.(openModal?.allRowData?.CommentId, openModal?.allRowData?.posArr)}/> : null}
        </div>
    )
}
interface TaskCommentsLogicProps {
    TaskId: string
    setData: SetTaskDataExtended
    CustomerProjectId?: number
}

const useTaskCommentsLogic = ({ setData, TaskId, CustomerProjectId }: TaskCommentsLogicProps) => {
    const { t } = useTwinTranslation()
    const { openModal, setOpenModal } = useEditDeleteModal()

    const messageCopy = t('copiedSuccessfully', 'Copiado con éxito')
    const handleCreate = useCallback((val: TwinDictionary) => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.TaskComments.push(val)
            return { ...copyOld }
        })
    }, [setData])

    const deleteComment = useCallback(async (id: number, posArr: number) => {
        if (id) {
            await twinFetchPost('/api/app/task/comment/deleteTaskComment', { id, TaskId }, { myExtraHeaders: { 'type-update': 'singleTask' } })
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.TaskComments.splice(posArr, 1)
                return { ...copyOld }
            })
        }
    }, [setData, TaskId])

    const updateComment = useCallback(async (id: number, content: string, posArr: number) => {
        if (id) {
            await twinFetchPost('/api/app/task/comment/updateTaskComment', { id, TaskId, content }, { myExtraHeaders: { 'type-update': 'singleTask' } })
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.TaskComments[posArr].content = content
                return { ...copyOld }
            })
        }
    }, [setData, TaskId])

    const copyComment = useCallback(async (id: number, posArr: number) => {
        if (id) {
            const res = await twinFetchPost('/api/app/customer/customerProject/customerProjectComment/copyTaskCommentToCustomerProject', { id, CustomerProjectId })
            if (res.status === 200) {
                changeSuccessMessage(messageCopy)
                setData((old) => {
                    if (!old) {
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    copyOld.TaskComments[posArr].already_copied = true
                    return { ...copyOld }
                })
            }
        }
    }, [CustomerProjectId, setData, messageCopy])

    return {
        handleCreate, deleteComment, updateComment, copyComment, openModal, setOpenModal }
}

export default TaskComments