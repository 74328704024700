import ModalCreateEdit from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs, getAllClientManagerEmployees, getConfigParam } from '../../../../../../../utils/reducers/getters'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { TwinDictionary, addDictionaryExtraLine, dictionaryComplexFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import { CustomerModelTypeShopAndAddress } from '../../../../../../../specificComponents/Customers/types'
import useCreateCustomerB2BWCAndAddress from '../../../../../../../utils/hooks/customer/useCreateCustomerB2BWCAndAddress'
import { EmployeesPayload } from '../../../../../../../utils/reducers/company/employees'
import { CustomerGroupPayload } from '../../../../../../../utils/reducers/customers/customerGroups'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import withLoading from '../../../../../../../utils/hoc/withLoading'

type ModalCreateCustomerB2BProps = ModalComponentProps & ReduxModalCreateCustomerB2B & {}

const ModalCreateCustomerB2B: React.FC<ModalCreateCustomerB2BProps> = ({ employees, customerGroups, ...rest }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { dictCustomerOrigins, createWorkingCenter, handleShowCreateWorkingCenter, myEmployees } = useModalCreateCustomerB2BLogic({ employees})
    const { workingCenterFields } = useCreateCustomerB2BWCAndAddress({ createWorkingCenter})
    const fields: FormRender<CustomerModelTypeShopAndAddress> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'Nombre fiscal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'responsable_name',
                    label: t('responsableName', 'Nombre del responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'ResponsibleEmployeeId',
                    label: t('ourManager', 'Nuestro gestor'),
                    component: 'CustomSelectWithSearchBar',
                    items: myEmployees || {},
                    fieldName: 'fullname_short',
                    sortItems: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'CustomerOriginId',
                    label: t('origin', 'Origen'),
                    component: 'CustomSelectWithSearchBar',
                    items: dictCustomerOrigins || {},
                },
                {
                    name: 'customer_type',
                    component: 'InputHidden',
                    value: 1
                },
                {
                    name: 'createWorkingCenter',
                    component: 'CheckboxMargin',
                    label: t('createWorkingcenter', 'Crear centro de trabajo'),
                    value: String(createWorkingCenter),
                    onChange: handleShowCreateWorkingCenter
                }
            ]
        },
        ...workingCenterFields,
        {
            cols: 1,
            title: t('customerType', 'Tipo de cliente'),
            elements: [
                {
                    name: 'customersForParse',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: dictionaryComplexFromJsonArr(customerGroups?.B2BCustomerGroups || []),
                },
            ]
        }
    ]
    return (
        <ModalCreateEdit fields={fields} url={'/api/app/customer/createCustomer'} translations={{
        title: t('createCustomerB2B', 'Crear cliente B2B'),
        button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}
interface ModalCreateCustomerB2BLogicProps {
    employees: EmployeesPayload
}
const useModalCreateCustomerB2BLogic = ({ employees }: ModalCreateCustomerB2BLogicProps) => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)
    const [createWorkingCenter, setCreateWorkingCenter] = useState<boolean>(false)
    
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getConfigParam('showOnlyClientManagerEmployees') === 'on' ? getAllClientManagerEmployees() || {} : employees || {}))), [employees])

    const handleShowCreateWorkingCenter = useCallback(() => {
        setCreateWorkingCenter((old)=>!old)
    }, [setCreateWorkingCenter])
    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins, createWorkingCenter, handleShowCreateWorkingCenter, myEmployees }
}

const modalCreateCustomerB2BDispatch = {
    setCustomerGroups: (payload: CustomerGroupPayload) => ({ type: 'CHANGE_CUSTOMER_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxModalCreateCustomerB2B = ConnectedProps<typeof ModalCreateCustomerB2BConnect>
const mapModalCreateCustomerB2BConnector = (state: AllReduxPayloads) => ({ customerGroups: state.customerGroups, employees: state.employees })
const ModalCreateCustomerB2BConnect = connect(mapModalCreateCustomerB2BConnector, modalCreateCustomerB2BDispatch)

const modalCreateCustomerB2BConnectLoading = withLoading(ModalCreateCustomerB2B, [{ fetchUrl: '/api/app/customer/customerGroup/getAllCustomersGroup', propName: 'customerGroups', setFunctionName: 'setCustomerGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

export default ModalCreateCustomerB2BConnect(modalCreateCustomerB2BConnectLoading);