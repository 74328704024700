import { faKey, faLockKeyhole, faUserLarge } from '@fortawesome/pro-light-svg-icons';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CustomError from '../../../../baseComponents/CustomError';
import { useTabLogic } from '../../../../utils/hooks/useTabLogic';
import { BeforeSubmitHandler, OnSubmit } from '../../../../forms/TwinForm/types';
import FormRenderer from '../../../../forms/FormRenderer';
import { FormRender } from '../../../../forms/FormRenderer/types';
import twinFetchPost, { ApiError } from '../../../../utils/globals/data';
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation';
import { FooterOfAuthNumberForm, FooterOfLoginForm, FormAuthentication, LoginAuthNumberSubHeader, LoginHeader, LoginSubHeader } from './Components';
import { classNameError, deciderByActive, handleLoginSubmit, handleStatus422 } from './functions';
import { UserModelType } from '@teinor/erp/types/environment/user';
import useIsLoading from '../../../../utils/hooks/useIsLoading';
import { TwinDictionary } from '../../../../utils/globals/dictionary';
import useInterval from '../../../../utils/hooks/useInterval';
import { UserFingerprintMessageData, UserLoginModelType } from './types';
import '../../components/AuthComposition/auth.sass'

export interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
    const { active, ComponentFromTab, onChangeTabs, fingerprintAuth, setFingerprintAuth } = useLoginLogic()

    return (
        <Fragment>
            <LoginHeader active={active} onChangeTabs={onChangeTabs} />
            {fingerprintAuth ?
                <LoginAuthForm fingerprintAuth={fingerprintAuth} />
                :
                <LoginForm {...{ active, setFingerprintAuth, ComponentFromTab }} />
            }
            {/* <FooterLink textLink={t('createAccount', 'Crear una cuenta')} link={'/register'} /> */}
        </Fragment>
    );
}

const useLoginLogic = () => {
    const [fingerprintAuth, setFingerprintAuth] = useState<UserFingerprintMessageData | null>(null)

    const { active, ComponentFromTab, onChangeTabs } = useTabLogic([
        {
            component: LoginERP
        },
        {
            component: LoginAdmin
        }
    ])

    return { active, ComponentFromTab, onChangeTabs, fingerprintAuth, setFingerprintAuth }
}

interface LoginFormProps {
    active: number
    setFingerprintAuth: React.Dispatch<React.SetStateAction<UserFingerprintMessageData | null>>
    ComponentFromTab: React.FC<any>
}

const LoginForm: React.FC<LoginFormProps> = ({ active, setFingerprintAuth, ComponentFromTab }) => {
    return (
        <Fragment>
            <LoginSubHeader />
            <LoginBaseForm active={active} setFingerprintAuth={setFingerprintAuth}>
                <ComponentFromTab />
            </LoginBaseForm>
        </Fragment>
    )
}

interface LoginAuthFormProps {
    fingerprintAuth: TwinDictionary
}

export const LoginAuthForm: React.FC<LoginAuthFormProps> = ({ fingerprintAuth }) => {
    return (
        <Fragment>
            <LoginAuthNumberSubHeader email={fingerprintAuth?.email} />
            <AuthNumberForm fingerprintAuth={fingerprintAuth} />
        </Fragment>
    )
}


interface LoginBaseFormProps extends LoginBaseFormLogicProps {
    children?: React.ReactNode
}

export const LoginBaseForm: React.FC<LoginBaseFormProps> = ({ children, ...props }) => {
    const { t } = useTwinTranslation()
    const { errors, onSubmit, loading, handleOnPreSubmit } = useLoginBaseFormLogic(props)
    let error: string | false = false
    if (errors) {
            error = t('loginIncorrect', 'Datos de acceso incorrectos')
    }
    return (
        <div className={classNameError(errors) + ' login_base_form'}>
            <CustomError textError={error} className='my-10'  />
            <FormAuthentication buttonText={t('enter', 'Entrar')} action='/api/tryLogin' onSubmit={onSubmit} beforeSubmitHandler={handleOnPreSubmit} loading={loading}>
                {children}
                <FooterOfLoginForm />
            </FormAuthentication>
        </div>
    )
}
interface LoginBaseFormLogicProps {
    active: number
    setFingerprintAuth: React.Dispatch<React.SetStateAction<UserFingerprintMessageData | null>>
}
const useLoginBaseFormLogic = ({ active, setFingerprintAuth }: LoginBaseFormLogicProps) => {
    const [errors, setErrors] = useState<false | ApiError>(false)
    const { startLoading, loading, endLoading } = useIsLoading()

    const handleErrors = useCallback((errors: ApiError, values: TwinDictionary) => {
        if (errors.msg === 'New device detected'){
            setFingerprintAuth({ email: values.email, password: values.password, token: values.token, remember: values.remember, active,  })
        }else{
            setErrors(errors)
        }
    }, [setErrors, setFingerprintAuth, active])

    const onSubmit: OnSubmit = useCallback(async (res, values) => {
        await handleLoginSubmit(res, deciderByActive(active), (err) => handleErrors(err, values), values.remember)
        endLoading()
    }, [handleErrors, endLoading, active])

    const handleOnPreSubmit: BeforeSubmitHandler = useCallback((values, form) => {
        startLoading()
        return true
    }, [startLoading])

    useEffect(() => {
        setErrors(false)
    }, [active])

    return {errors, onSubmit, loading, handleOnPreSubmit}
}


interface LoginERPProps { }

export const LoginERP: React.FC<LoginERPProps> = () => {
    const { t } = useTwinTranslation()
    const fields: FormRender<UserModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'token',
                    placeholder: t('code', 'Código'),
                    component: 'InputWithLeftIcon',
                    required: true,
                    icon: faKey
                },
                {
                    name: 'email',
                    placeholder: t('email', 'Email'),
                    component: 'InputWithLeftIcon',
                    type: 'email',
                    required: true,
                    icon: faUserLarge
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole
                }
            ]
        }
    ]
    return <FormRenderer sections={fields} />
}

interface LoginAdminProps { }

export const LoginAdmin: React.FC<LoginAdminProps> = () => {
    const { t } = useTwinTranslation()
    const fieldsAdmin: FormRender<UserModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'email',
                    placeholder: t('email', 'Email'),
                    component: 'InputWithLeftIcon',
                    type: 'email',
                    required: true,
                    icon: faUserLarge
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole
                }
            ]
        }
    ]
    return <FormRenderer sections={fieldsAdmin} />
}


interface AuthNumberFormProps extends AuthNumberFormLogicProps { }

const AuthNumberForm: React.FC<AuthNumberFormProps> = ({ fingerprintAuth }) => {
    const { errors, onSubmit, loading, handleOnPreSubmit, resendCodeMail, verifiedDevice, timer, code, setCode } = useAuthNumberFormLogic({ fingerprintAuth })
    const { t } = useTwinTranslation()
    const fields: FormRender<UserLoginModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'auth_number',
                    placeholder: t('sixDigitCode', 'Código de seis dígitos'),
                    component: 'InputWithLeftIcon',
                    required: true,
                    icon: faKey,
                    value: code,
                    onChange: setCode
                },
                {
                    name: 'email',
                    component: 'InputHidden',
                    value: fingerprintAuth?.email,
                },
            ]
        }
    ]
    let error: string | false = false
    if (errors) {
        if (errors.msg === 'New device detected') {
            error = t('deviceNotActivated', 'Este dispositivo no está activado')
        } else if (errors.msg === 'Valid number required') {
            error = t('invalidCode', 'Código incorrecto')
        } else {
            error = t('loginIncorrect', 'Datos de acceso incorrectos')
        }
    }
    return (
        <div className={'authcode_form ' + classNameError(errors)}>
            <CustomError textError={error} className='my-10' />
            <FormAuthentication buttonText={t('send', 'Enviar')} action='/api/activateFingerprintAuthNumber' onSubmit={onSubmit} beforeSubmitHandler={handleOnPreSubmit} loading={loading}>
                <FormRenderer sections={fields} />
            </FormAuthentication>
            <FooterOfAuthNumberForm resendCodeMail={resendCodeMail} verifiedDevice={verifiedDevice} timer={timer} loading={loading} />
        </div>
    )
}

interface AuthNumberFormLogicProps {
    fingerprintAuth: TwinDictionary
}

const useAuthNumberFormLogic = ({ fingerprintAuth }: AuthNumberFormLogicProps) => {
    const [errors, setErrors] = useState<false | ApiError>(false)
    const [code, setCode] = useState('')
    const { startLoading, loading, endLoading } = useIsLoading()
    const [timer, setTimer] = useState<number | null>(60)

    const onSubmit: OnSubmit = useCallback(async (res) => {
        if (res.status === 200) {
            const resLogin = await twinFetchPost('/api/tryLogin', { ...fingerprintAuth })
            await handleLoginSubmit(resLogin, deciderByActive(fingerprintAuth.active), setErrors, fingerprintAuth.remember)
        } else {
            await handleLoginSubmit(res, deciderByActive(fingerprintAuth.active), setErrors, fingerprintAuth.remember)
        }
        endLoading()
    }, [fingerprintAuth, endLoading])

    const handleOnPreSubmit: BeforeSubmitHandler = useCallback(() => {
        startLoading()
        return true
    }, [startLoading])

    const resendCodeMail = useCallback(async () => {
        startLoading()
        const res = await twinFetchPost('/api/resendMailAuthNumber', { email: fingerprintAuth.email })
        if (res.status === 200) {
            setErrors(false)
            setCode('')
            setTimer(60)
        } else {
            handleStatus422(setErrors, res)
        }
        endLoading()
    }, [fingerprintAuth.email, startLoading, endLoading])
    
    const verifiedDevice = useCallback(async () => {
        startLoading()
        const res = await twinFetchPost('/api/tryLogin', { ...fingerprintAuth })
        handleLoginSubmit(res, deciderByActive(fingerprintAuth.active), setErrors, fingerprintAuth.remember)
        endLoading()
    }, [fingerprintAuth, startLoading, endLoading])

    useInterval(() => {
        setTimer((old) => { if (old) { return old - 1 } return null })
    }, timer ? 1000 : false)

    return { errors, onSubmit, loading, handleOnPreSubmit, resendCodeMail, timer, verifiedDevice, code, setCode }
}

export default Login;