import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateContact from './ModalCreateContactB2B'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import withLoading from '../../../../../../utils/hoc/withLoading'
import { getAllClientManagerEmployees, getConfigParam } from '../../../../../../utils/reducers/getters'
import { useMemo } from 'react'

interface ContactsB2BProps extends ComponentWithPermissions { }

const ContactsB2B: React.FC<ContactsB2BProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('leadsB2BLong', 'Clientes Potenciales B2B'),
        newText: t('newLead', 'Nuevo Cliente Potencial'),
    }

    return (
        <BaseScreenTable TableComponent={TableContactsB2BConnect} CreateModalComponent={ModalCreateContact} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableContactsB2BProps = TableComponentProps & ReduxTableContactsB2B &{}

const TableContactsB2B: React.FC<TableContactsB2BProps> = ({ setTableInstance, employees }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const myEmployees = useMemo(() => getConfigParam('showOnlyClientManagerEmployees') === 'on' ? getAllClientManagerEmployees() || {} : employees || {}, [employees])
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            },
            searchKey: 'Customer.code',
            sortable: true
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('businessName', 'Nombre comercial'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('businessName', 'Nombre comercial') }
            },
            searchKey: 'Customer.name'
        },
        {
            id: 'fiscal_name',
            dataKey: 'fiscal_name',
            label: t('fiscalName', 'Nombre fiscal'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('fiscalName', 'Nombre fiscal') }
            }
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.email'
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            },
            searchKey: 'Customer.phone'
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('mobile', 'Móvil') }
            },
            searchKey: 'Customer.mobile'
        },
        {
            id: 'CustomerOriginId',
            dataKey: 'CustomerOriginId',
            label: t('origin', 'Origen'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerOrigin?.name} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('origin', 'Origen') }
            },
            searchKey: 'CustomerOrigin.name',
            hideColumnByDefault: true
        },
        {
            id: 'ResponsibleEmployeeId',
            dataKey: 'ResponsibleEmployeeId',
            label: t('ourManager', 'Nuestro gestor'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.ResponsibleEmployee?.fullname_short} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: myEmployees, label: t('ourManager', 'Nuestro gestor'), fieldName:'fullname_short' }
            },
            hideColumnByDefault: true
        },
        {
            id: 'responsable_name',
            dataKey: 'responsable_name',
            label: t('responsibleNameShort', 'N. del responsable'),
            customRender: (parameterValue) => <CustomRenderString value={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('responsibleNameShort', 'N. del responsable') }
            },
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTableLink createRowDataLink={[location, ['$id', 'main']]} name='contactsB2B' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{ 'customer_type': customerTypes['contactB2B'] }} setTableInstance={setTableInstance} />
    )
}
const tableContactsB2BDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

type ReduxTableContactsB2B = ConnectedProps<typeof tableContactsB2BConnect>
const mapTableContactsB2BConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const tableContactsB2BConnect = connect(mapTableContactsB2BConnector, tableContactsB2BDispatch)
const tableContactsB2BLoading = withLoading(TableContactsB2B, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])
const TableContactsB2BConnect = tableContactsB2BConnect(tableContactsB2BLoading)
export default ContactsB2B