import { useCallback, useMemo, useState } from 'react'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { SwitchLightInversed } from '../../../../../../../../../forms/SwitchLight'
import { SingleSelectedFilterTy } from '../../../../../../../../../utils/hooks/useConfigColumns'
import { CustomerPayload } from '../../../../../../../../../utils/reducers/customers/customer'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons'
import { getCustomers } from '../../../../../../../../../utils/reducers/getters'
import { InputWithLabel } from '../../../../../../../../../forms/Input'
import useCustomerTypesDict from '../../../../../../../../../utils/hooks/customer/useCustomerTypesDict'
import { parseSearchCustomerProjectToSections } from '../../../../../../../../../specificComponents/Customers/SearcherCustomerProject/functions'
import { filterCustomSelectSectionsItems } from '../../../../../../../../../forms/CustomSelect/CustomSelectSections/functions'
import { CustomSelectSectionDropDown } from '../../../../../../../../../forms/CustomSelect/CustomSelectSections'

interface SearchComponentProps extends SearchableCustomerNameLogicProps {
    label: string
}

export const SearchableCustomerName: React.FC<SearchComponentProps> = ({ label, ...rest }) => {
    const { onChange, changeExcluded } = useSearchableCustomerNameLogic({ ...rest })
    const { t } = useTwinTranslation()
    const customers = getCustomers()
    return (
        <div className='py-15'>
            <div className='mb-20 flex justify-end'>
                <SwitchLightInversed active={rest.value.excluded || false} label={t('excludeSelected', 'Excluir seleccionadas')} onChange={changeExcluded} />
            </div>
            <ListCustomerAdder customers={customers} onChange={onChange} value={rest.value.value || []}/>
        </div>
    )
}

interface SearchableCustomerNameLogicProps {
    id: string
    dataKey: any
    value: SingleSelectedFilterTy
    changeSingleField: (id: string, value: SingleSelectedFilterTy) => void
    extraComponentData?: any
    isLast?: boolean
 }

const useSearchableCustomerNameLogic = ({ id, value, changeSingleField }: SearchableCustomerNameLogicProps) => {
    const changeExcluded = useCallback(() => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (copy['excluded']) {
            delete copy['excluded']
        } else {
            copy['excluded'] = true
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    const onChange = useCallback((values: string[]) => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (!values.length) {
            delete copy.value
        } else {
            copy.value = values
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    return { changeExcluded, onChange }
}

interface ListCustomerAdderProps extends ListCustomerAdderLogicProps {}

const ListCustomerAdder: React.FC<ListCustomerAdderProps> = ({ customers, onChange, value }) => {
    const {t} = useTwinTranslation()
    const { selectedCustomers, deletePreselectedCustomer, addPreselectedCustomer, searchCustomer, setSearchCustomer, filteredItems } = useListCustomerAdderLogic({ onChange, customers, value})
    const renderThis: JSX.Element[] = []
    for (const key in selectedCustomers) {
        const id = parseInt(selectedCustomers[key])
        renderThis.push(<ListCustomerItem key={key} id={id} name={customers?.all[id]?.name || ''} onDelete={() => deletePreselectedCustomer(parseInt(key))} />)
    }
    return (
        <div className='list_adder_parent w-full'>
            <div className='flex my-20'>
                <InputWithLabel className='w-310 relative' label={t('addCustomer', 'Añadir Cliente')} value={searchCustomer} onChange={setSearchCustomer} />
                <div className='absolute z-9 w-310 mt-34'>
                    <CustomSelectSectionDropDown items={searchCustomer !== '' ? filteredItems : []} onChange={addPreselectedCustomer} value={''} />
                </div>
            </div>
            {selectedCustomers.length !== 0 ?
                <div className={'border flex flex-wrap content-start list_adder'}>
                    {renderThis}
                </div>
                : null
            }
        </div>
    )
}

interface ListCustomerAdderLogicProps {
    onChange: (values: string[]) => void
    customers: CustomerPayload
    value: string[]
}

const useListCustomerAdderLogic = ({ onChange, customers, value }: ListCustomerAdderLogicProps) => {
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>(value)
    const [searchCustomer, setSearchCustomer] = useState<string>('')
    const { dictCustomerTypeTrans } = useCustomerTypesDict()
    const customersSectionItems = useMemo(() => {
        return parseSearchCustomerProjectToSections(customers, dictCustomerTypeTrans)
    }, [customers, dictCustomerTypeTrans])
    const filteredItems = useMemo(() => filterCustomSelectSectionsItems(searchCustomer, customersSectionItems.customersSectionItems), [searchCustomer, customersSectionItems])
      
    const deletePreselectedCustomer = useCallback((pos: number) => {
        setSelectedCustomers((old)=>{
            const copyOld=JSON.parse(JSON.stringify(old))
            copyOld.splice(pos, 1)
            return [...copyOld]
        })
        onChange(selectedCustomers)
    }, [setSelectedCustomers, onChange, selectedCustomers])

    const addPreselectedCustomer = useCallback((id: number) => {
        setSelectedCustomers((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.push(id)
            return [...copyOld]
        })
        onChange([...selectedCustomers, String(id)])
        setSearchCustomer('')
    }, [setSelectedCustomers, setSearchCustomer, onChange, selectedCustomers])

    return { selectedCustomers, deletePreselectedCustomer, addPreselectedCustomer, searchCustomer, setSearchCustomer, filteredItems }
}

interface ListCustomerItemsProps {
    id: number
    name: string
    onDelete: () => void
}

const ListCustomerItem: React.FC<ListCustomerItemsProps> = ({ id, name, onDelete }) => {
    return (
        <div className='list_adder_item flex justify-around items-center bg-gray-F7 mt-15 ml-15'>
            <span>{name}</span>
            <TwinIcon icon={faTimesSquare} className=' ml-20 cursor-pointer hover:text-red-BA' onClick={onDelete} />
        </div>
    )
}
