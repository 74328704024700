import { ApiError } from '../../../../utils/globals/data'
import { setUserByLogin } from '../../../../utils/reducers/reduxDispatch'
import { UserLoginType } from '../../../../utils/reducers/globals/user'

export const classNameError = (errors: false | ApiError) => {
    if (errors) {
        return ''
    }
    return 'mt-38'
}

type SetErrorsFnc = (value: ApiError) => void

export const handleLoginSubmit = async (res: Response, type: UserLoginType, setErrors: SetErrorsFnc, remember?: boolean) => {
    if (res.status === 422) {
        handleStatus422(setErrors, res)
    } else if (res.status === 200) {
        handleStatus200(res, type, remember)
    }
}

export const handleStatus422 = async (setErrors: SetErrorsFnc, res: Response) => {
    const body = await res.json()
    const errors = body.errors
    setErrors(errors[0])
}

export const handleStatus200 = async (res: Response, type: UserLoginType, remember?: boolean) => {
    const token = await res.text()
    await setUserByLogin({ token, type }, remember)
}

export const deciderByActive = (active: number) : UserLoginType=> {
    if (active === 1) {
        return 'admin'
    } else if (active === 2) {
        return 'forQr'
    }
    return 'erp'
}