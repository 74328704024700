import { faArrowLeft, faBriefcase, faEnvelope, faEye, faMobile, faNotes, faPhone, faUser, faXmark } from '@fortawesome/pro-light-svg-icons'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { useCallback } from 'react'
import { SetTaskDataExtended } from '../types'
import { TwinIconToolTip } from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../utils/globals/data'
import { CustomerPayload } from '../../../../utils/reducers/customers/customer'
import SearcherCustomerProject, { SearcherCustomerProjectProps } from '../../../Customers/SearcherCustomerProject'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import PermissionChecker from '../../../../baseComponents/PermissionChecker'
import { getConfigParam, getUserPermissions } from '../../../../utils/reducers/getters'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../utils/reducers'
import withLoading from '../../../../utils/hoc/withLoading'
import { Modify } from '@teinor/erp'
import TwinToolTip from '../../../../baseComponents/TwinToolTip'
import useEditDeleteModal from '../../../../utils/hooks/useEditDeleteModal'
import { ModalDeleteFilledOutside } from '../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { TASK_PROJECT_DEFAULT_COLOR } from '../../variables'
import { CustomSelectTaskContactInfoRow, CustomSelectTaskContactInfoRowClipboard, CustomSelectTaskContactNotes} from '../../Components/CustomSelectTaskContactInfo'
import { permissionCheck } from '../../../../baseComponents/PermissionChecker/function'
import { Link } from 'react-router-dom'
import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { parsePhoneNumber } from '../../../../utils/globals/phone'
import { CodeCustomerToShowTyInversed } from '@teinor/erp/types/company/config/parameter'
import ModalEditCustomerProject from '../../../Customers/ModalEditCustomerProject'

interface TaskProjectsProps extends TaskProjectsLogicProps {
    selectedCustomerProject?: CustomerProjectModelType
    preSelectedCustomer?: number
    OldProjectId?: number
}

const TaskProjects: React.FC<TaskProjectsProps> = ({ TaskId, selectedCustomerProject, setData, preSelectedCustomer, OldProjectId }) => {
    const { handleAddCustomerProject } = useTaskProjectsLogic({ TaskId, setData}) 
    const userPermission = getUserPermissions('customer.profile.main')
    return (
        <div className='mt-30 mb-20'>
            <div className='flex items-center mb-20'>
                <span className='mr-12 light16'>
                    <TwinTrans transKey='project'>Proyecto</TwinTrans>
                </span>
                <PermissionChecker userPermissions={userPermission} permission={'read'}>
                    <SearcherCustomerProjectTasksConnect className='ml-10 z-10' {...{ handleAddCustomerProject, selectedCustomerProject: selectedCustomerProject, preSelectedCustomer, OldProjectId }} />
                </PermissionChecker>
            </div>
            {selectedCustomerProject ?
                <TaskProject project={selectedCustomerProject} {...{ TaskId, setData }} />
                : null
            }
        </div>
    )
}


interface TaskProjectsLogicProps {
    TaskId: string
    setData: SetTaskDataExtended
}

const useTaskProjectsLogic = ({ TaskId, setData }: TaskProjectLogicProps) => {
    const handleAddCustomerProject = useCallback(async (CustomerProject: CustomerProjectModelType) => {
        const result = await twinFetchPostJSON('/api/app/task/addCustomerProjectToTask', { CustomerProjectId: CustomerProject.id, id: TaskId }, { myExtraHeaders: { 'type-update': 'singleTask' } })
        if (result) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.CustomerProjectId = CustomerProject.id
                copyOld.CustomerProject = result
                copyOld.CustomerId = CustomerProject.CustomerId
                return { ...copyOld }
            })
            return true
        }
        return false
    }, [setData, TaskId])

    return { handleAddCustomerProject }
}


type SearcherCustomerProjectTasksProps = Modify<SearcherCustomerProjectProps, ReduxSearcherCustomerProjectTasks & {
    selectedCustomerProject?: CustomerProjectModelType
    preSelectedCustomer?: number
    OldProjectId?: number
}>

const SearcherCustomerProjectTasks: React.FC<SearcherCustomerProjectTasksProps> = ({ customers, selectedCustomerProject, preSelectedCustomer, OldProjectId, ...rest}) => {
    if (selectedCustomerProject){
        return null
    }
    return (<SearcherCustomerProject className='ml-10 z-10' customers={customers} OldProjectId={OldProjectId} preSelectedCustomer={preSelectedCustomer} {...rest} />)
}

const searcherCustomerProjectTasksDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

type ReduxSearcherCustomerProjectTasks = ConnectedProps<typeof searcherCustomerProjectTasksConnect>
const mapSearcherCustomerProjectTasksConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const searcherCustomerProjectTasksConnect = connect(mapSearcherCustomerProjectTasksConnector, searcherCustomerProjectTasksDispatch)
const searcherCustomerProjectTasksConnectLoading = withLoading(SearcherCustomerProjectTasks, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])
const SearcherCustomerProjectTasksConnect = searcherCustomerProjectTasksConnect(searcherCustomerProjectTasksConnectLoading)

interface TaskProjectProps extends TaskProjectLogicProps {
    project: TwinDictionary
}

const TaskProject: React.FC<TaskProjectProps> = ({ project, setData, TaskId }) => {
    const { removeCustomerProject, removeProjectNotCustomer, openModal, setOpenModal } = useTaskProjectLogic({ setData, TaskId })
    const {t} = useTwinTranslation()
    const codeCustomerToShow = getConfigParam('codeCustomerToShow')
    let fieldId: 'id' | 'code' = 'id'
    if (parseInt(String(codeCustomerToShow)) === parseInt(String(CodeCustomerToShowTyInversed['codeCustomer']))) {
        fieldId = 'code'
    }
    const customer = project?.Customer
    const idCodeParsed = customer?.[fieldId]?(customer[fieldId] + ' - ' ): ''
    return (
        <div>
            <div className='bg-gray-F7 task_project' >
                <div className='flex items-center px-14 py-10'>
                    <TaskProjectLinkDecider customer={customer}>
                        <div className='flex items-center'>
                            <div className='mr-10 min-h-16 min-w-16 rounded-full' style={{ 'background': project.CustomerProjectType?.color || TASK_PROJECT_DEFAULT_COLOR }}></div>
                            <div id={'TaskProject' + TaskId}>
                                <span>{idCodeParsed} {customer?.name} - {project?.name}</span>
                                <TwinToolTip place='top' anchorSelect={'#TaskProject' + TaskId} clickable>
                                    <div>
                                        <div>{idCodeParsed}{customer?.name}</div>
                                        <CustomSelectTaskContactInfoRowClipboard icon={faBriefcase} text={customer?.fiscal_name} />
                                        <CustomSelectTaskContactInfoRowClipboard icon={faPhone} text={parsePhoneNumber(customer?.phone)} />
                                        <CustomSelectTaskContactInfoRowClipboard icon={faMobile} text={parsePhoneNumber(customer?.mobile)} />
                                        <CustomSelectTaskContactInfoRowClipboard icon={faEnvelope} text={customer?.email} />
                                        <CustomSelectTaskContactInfoRow icon={faUser} text={customer?.ResponsibleEmployee?.fullname_short} />
                                        <CustomSelectTaskContactNotes icon={faNotes} notes={customer?.notes} />
                                    </div>
                                </TwinToolTip>
                            </div>
                        </div>
                    </TaskProjectLinkDecider>
                    <TwinIconToolTip message={t('removeProjectNotCustomerMss', 'Quitar proyecto sin desasignar cliente')} id={'Tooltip_RemoveTaskProjectNotCustomer' + String(TaskId)} classNameIcon='mr-15 cursor-pointer hover:text-red-BA' icon={faArrowLeft} onClick={removeProjectNotCustomer}  />
                    <TwinIconToolTip message={t('openProjectMss', 'Abrir el proyecto')} id={'Tooltip_OpenTaskProject' + String(TaskId)} classNameIcon='mr-15 hover:text-green-43' icon={faEye} onClick={() => setOpenModal({ type: 'edit', allRowData: { id: project?.id } })} />
                    <TwinIconToolTip message={t('removeProjectMss', 'Desasignar proyecto')} id={'Tooltip_RemoveTaskProject' + String(TaskId)} classNameIcon='hover:text-red-BA' icon={faXmark} onClick={() => setOpenModal({ type: 'delete', allRowData: {} })} />
                </div>
            </div>
            {openModal?.type === 'delete' ? <ModalDeleteFilledOutside translations={{
                title: t('unassignProject', 'Desasignar proyecto'),
                subtitle: t('sureUnassignProject', '¿Estás seguro de querer desasignar este proyecto?'),
                buttonCancel: t('cancel', 'Cancelar'),
                buttonAccept: t('unassign', 'Desasignar'),
            }} opened={true} setOpened={() => setOpenModal(null)} onAccept={removeCustomerProject} /> : null}
            {openModal?.type === 'edit' ?
                <ModalEditCustomerProject allRowData={{ ...openModal.allRowData }} userPermissions={getUserPermissions('customer.projects.main') || undefined} setOpened={() => setOpenModal(null)} onSubmit={() =>{}} />
                : null
            }
        </div>
    )
}

interface TaskProjectLinkDeciderProps {
    children: React.ReactNode
    customer: CustomerModelType
}

const TaskProjectLinkDecider: React.FC<TaskProjectLinkDeciderProps> = ({children, customer}) => {
    const userPermission = getUserPermissions('customer.profile.tasks')
    if (permissionCheck(userPermission, 'read')) {
        const dict: TwinDictionary = {
            0: '/customer/customers',
            1: '/customer/customersB2B',
            2: '/customer/contacts',
            3: '/customer/contactsB2B'
        }
        return <Link to={dict[customer.customer_type] + '/' + customer.id + '/tasks'} className='flex-auto w-1 pr-10'>{children}</Link>
    }
    return (
        <div className='flex-auto w-1 pr-10'>
            {children}
        </div>
    )
}



interface TaskProjectLogicProps {
    TaskId: string
    setData: SetTaskDataExtended
}

const useTaskProjectLogic = ({ TaskId,  setData}: TaskProjectLogicProps) => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const removeCustomerProject = useCallback(async () => {
        await twinFetchPostText('/api/app/task/removeCustomerProjectOfTask', { id: TaskId }, { myExtraHeaders: { 'type-update': 'singleTask' } })
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            delete copyOld.CustomerProject
            copyOld.CustomerProjectId = null
            copyOld.CustomerContactPersonId = null
            copyOld.CustomerShopId = null
            copyOld.CustomerId = null
            return { ...copyOld }
        })
    }, [TaskId, setData])

    const removeProjectNotCustomer = useCallback(async () => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            delete copyOld.CustomerProject
            return { ...copyOld }
        })
    }, [setData])

    return { removeCustomerProject, openModal, setOpenModal, removeProjectNotCustomer }
}

export default TaskProjects