import { faPaperclip, faPenToSquare, faRotateLeft, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useState } from 'react'
import { TwinDictionary } from '../../utils/globals/dictionary'
import { getUserInfo } from '../../utils/reducers/getters'
import { twinFetchPostText } from '../../utils/globals/data'
import { CustomRenderRoundedImage } from '../TwinTable/VirtualTableListing/CustomRenders'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import useEditDeleteModal, { SetEditDeleteModalTy } from '../../utils/hooks/useEditDeleteModal'
import TwinIcon from '../TwinIcon'
import { parseFileName } from '../../utils/globals/files'
import TextArea from '../../forms/Input/TextArea'
import { ButtonDisabledOrLoader, ButtonPrimary } from '../Button'
import TwinTrans from '../TwinTrans'
import TwinForm from '../../forms/TwinForm'
import { InputHidden } from '../../forms/Input'
import FilesClipInput from '../../forms/FilesInput/FilesClipInput'
import { OnSubmit } from '../../forms/TwinForm/types'
import { ImageInputFile } from '../../forms/ImageInput/types'
import useIsLoading from '../../utils/hooks/useIsLoading'
import { ModalDeleteFilledOutside } from '../ModalsLayouts/ModalDeleteFilled'
import './twinComment.sass'

type TwinCommentProps = MyCommentProps & TwinCommentBoxProps &  {
    comment: string
    createdAt: string
    title: string
    subtitle: string
    photo?: string
    className?: string
    children?: React.ReactNode
}

const TwinComment: React.FC<TwinCommentProps> = ({  photo, title, subtitle, className, ...rest }) => {

    return (
        <div className={'flex flex-col ' + (className || '')}>
            <TwinCommentHeader {...{photo, title, subtitle}} />
            <TwinCommentBox  {...rest} />
        </div>
    )
}
type TwinCommentBoxProps = TwinCommentBoxLogicProps & MyCommentProps & {
    EmployeeId: number
    file_url?: string
}

export const TwinCommentBox: React.FC<TwinCommentBoxProps> = ({ EmployeeId, id, comment, file_url, downloadFileURL,  ...rest}) => {
    const { downloadFile } = useTwinCommentBoxLogic({id, downloadFileURL})
    const userInfo = getUserInfo()
    const MyEmployeeId = userInfo?.Employee?.id
    const isMyComment = MyEmployeeId === EmployeeId
    return (
        <Fragment>
            {
                isMyComment ?
                <MyComment {...{ ...rest, id, comment }} />
                : <OtherComment {...{ comment }} />
            }
            {
                file_url ?
                <div className=' block mt-5 cursor-pointer mr-20' onClick={(e) => downloadFile(e)}>
                    <a className='hidden' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                    <TwinIcon className='mr-10 ' icon={faPaperclip} />
                    <span>{parseFileName({ file_url })}</span>
                </div>
                : null
            }
        </Fragment>
    )
                
}

interface TwinCommentBoxLogicProps {
    id: number
    downloadFileURL: string
}

const useTwinCommentBoxLogic = ({ id, downloadFileURL }: TwinCommentBoxLogicProps) => {
    const downloadFile = useCallback(async (e: React.MouseEvent) => {
        const current = e.currentTarget
        const link = await twinFetchPostText(downloadFileURL, {
            id
        })
        if (link && link !== 'false') {
            const a = current.querySelector('a')
            if (a) {
                a.href = link
                a.click()
            }
        }
    }, [downloadFileURL, id])
    return { downloadFile }
}

interface TwinCommentHeaderProps {
    title: string
    subtitle: string
    photo?: string
 }

export const TwinCommentHeader: React.FC<TwinCommentHeaderProps> = ({ title,subtitle, photo }) => {
    return (
        <div className='flex items-center mb-10'>
            <CustomRenderRoundedImage className='mr-12 h-34 w-34 min-w-34' icon={faUser} img={photo || '/user-png.png'} name={title} />
            <span className='medium14 mr-12'>{title}</span>
            <span className='light14 text-gray-51 twin_comment_subtitle'>{subtitle}</span>
        </div>
    )
}

interface MyCommentProps extends MyCommentLogicProps {
    id: number
    comment: string
    deleteComment: (id: number, pos: number) => void
    children?: React.ReactNode
}

const MyComment: React.FC<MyCommentProps> = ({ id, comment, deleteComment, posArr, children, ...rest }) => {
    const { edit, handleEdit, saveComment, setOpenModal, openModal } = useMyCommentLogic({ posArr, ...rest })
    const {t} = useTwinTranslation()
    return (
        <div className='flex items-start w-full border border-gray-D6 rounded-xl p-10'>
            {edit ?
                <EditComment {...{ id, comment, saveComment, setOpenModal, handleEdit }} />
                :
                <CommentContent {...{ id, comment, handleEdit, setOpenModal }}>{children}</CommentContent>
            }
            {openModal?.type === 'delete' ? <ModalDeleteFilledOutside translations={{
                title: t('deleteComment', 'Eliminar comentario'),
                subtitle: t('sureDeleteComment', '¿Estás seguro de querer eliminar este comentario?'),
                buttonCancel: t('cancel', 'Cancelar'),
                buttonAccept: t('delete', 'Eliminar'),
            }} opened={true} setOpened={() => setOpenModal(null)} onAccept={() => deleteComment(openModal?.allRowData?.CommentId, posArr)}/> : null}
        </div>
    )
}

interface CommentContentProps {
    comment: string
    handleEdit: () => void
    setOpenModal: SetEditDeleteModalTy
    id: number
    children?: React.ReactNode
}

const CommentContent: React.FC<CommentContentProps> = ({ id, comment, handleEdit, setOpenModal, children }) => {
    return (
        <Fragment>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap break-words w-1 flex-auto ' + (comment ? '' : 'italic')}>
                {comment}
            </div>
            {children}
            <TwinIcon className='mr-15 h-20 cursor-pointer hover:text-green-43' icon={faPenToSquare} onClick={handleEdit} />
            <TwinIcon className='mr-10 h-20 cursor-pointer hover:text-red-BA' icon={faTrashCan} onClick={() => setOpenModal({ type: 'delete', allRowData: { CommentId: id } })} />
        </Fragment>
    )
}


interface MyCommentLogicProps {
    posArr: number
    fieldComment: string
    updateComment: (id: number, comment: string, pos: number)=>void
}
const useMyCommentLogic = ({ posArr, updateComment}: MyCommentLogicProps) => {
    const [edit, setEdit] = useState<boolean>(false)
    const {openModal, setOpenModal} = useEditDeleteModal()

    const handleEdit = useCallback(() => {
        setEdit((old)=>!old)
    }, [setEdit])

    const saveComment = useCallback(async (id: number, comment: string) => {
        updateComment(id, comment, posArr)
        setEdit(false)
    }, [updateComment, setEdit, posArr])

    return { edit, handleEdit, saveComment, openModal, setOpenModal }
}

interface OtherCommentProps {
    comment: string
}

const OtherComment: React.FC<OtherCommentProps> = ({comment}) => {
    if(!comment){
        return null
    }
    return (
        <div className='flex items-center w-full border border-gray-D6 rounded-xl p-10'>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap break-words w-1 flex-auto  ' + (comment ? '' : 'italic')}>
                {comment}
            </div>
        </div>
    )
}


interface CreateTwinCommentProps extends CreateTwinCommentLogicProps {
    createURL: string
    imagePath: string[]
    fieldName: string
    idParent: string
    fieldIdParent: string
    extraProps?: TwinDictionary
}

export const CreateTwinComment: React.FC<CreateTwinCommentProps> = ({ createURL, imagePath, fieldName, fieldIdParent, idParent, extraProps, ...logic }) => {
    const { comment, handleSubmit, loading, onChangeComment, startLoading, files, setFiles } = useCreateTwinCommentLogic({ ...logic })
    const { t } = useTwinTranslation()
    const buttonIsDisabled =  comment || files.length ? false : true
    return (
        <TwinForm className='mt-30' action={createURL} onSubmit={handleSubmit} beforeSubmitHandler={startLoading} imagepath={imagePath} extraProps={extraProps}>
            <div className=' flex flex-auto items-center border border-gray-D6 px-8 py-6 rounded-lg twin_create_comment'>
                <div className='flex flex-auto items-center pr-10'>
                    <TextArea className='w-full text-gray-51 twin_comment_textarea ' name={fieldName} value={comment} placeholder={t('writeAComment', 'Escribe un comentario')} onChange={onChangeComment} maxRows={3} />
                    <InputHidden name={fieldIdParent} value={idParent} />
                    {loading === false ? <FilesClipInput name='file_url' className='flex items-center' onChange={setFiles} /> : null}
                    <ButtonDisabledOrLoader buttonIsDisabled={buttonIsDisabled} textButton={t('add', 'Añadir')} {...{ loading }} />
                </div>
            </div>
        </TwinForm>
    )
}

interface CreateTwinCommentLogicProps {
    myOnSubmit: (val: TwinDictionary)=>void
}

const useCreateTwinCommentLogic = ({ myOnSubmit }: CreateTwinCommentLogicProps) => {
    const [comment, setComment] = useState<string>('')
    const [files, setFiles] = useState<ImageInputFile[]>([]);
    const { startLoading, endLoading, loading } = useIsLoading()
    const onChangeComment = useCallback((value: string) => {
        setComment(value)
    }, [setComment])
    const handleSubmit: OnSubmit = useCallback(async (res) => {
        const result = await res.json()
        setComment('')
        setFiles([])
        endLoading()
        myOnSubmit(result)

    }, [endLoading, setComment, myOnSubmit])

    return { startLoading, loading, comment, onChangeComment, handleSubmit, files, setFiles }
}

interface EditCommentProps extends EditCommentLogicProps {
    id: number
    saveComment: (id: number, content: string) => Promise<void>
    setOpenModal: SetEditDeleteModalTy
}

const EditComment: React.FC<EditCommentProps> = ({ id, comment, saveComment, setOpenModal, handleEdit }) => {
    const { editComment, restoreContent, setEditComment } = useEditCommentLogic({ comment, handleEdit })
    return (
        <Fragment>
            <TextArea className='flex-auto light12 twin_comment_textarea edit_twin_comment_textarea' value={editComment} onChange={setEditComment} />
            <div className='flex items-center'>
                <ButtonPrimary className='ml-3 mr-15 twin_comment_edit_button' onClick={() => saveComment(id, editComment)}><TwinTrans transKey='save'>Guardar</TwinTrans></ButtonPrimary>
                <TwinIcon className='mr-15 cursor-pointer hover:text-green-43 twin_comment_icon' icon={faRotateLeft} onClick={restoreContent} />
                <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA twin_comment_icon' onClick={() => setOpenModal({ type: 'delete', allRowData: { CommentId: id } })} />
            </div>
        </Fragment>
    )
}

interface EditCommentLogicProps {
    comment?: string
    handleEdit: ()=>void
}

const useEditCommentLogic = ({ comment, handleEdit}: EditCommentLogicProps) => {
    const [editComment, setEditComment] = useState<string>(comment || '')
    const restoreContent = useCallback(() => {
        setEditComment(comment || '')
        handleEdit()
    }, [comment, handleEdit])

    return { editComment, setEditComment, restoreContent }
}

export default TwinComment