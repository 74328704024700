import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { CustomerShopModelType } from '@teinor/erp/types/company/customer/customerShop'
import { InputWithLabelMargin } from '../../../../forms/Input'
import { RectangularTextAreaDebounce } from '../../../../forms/Input/TextArea'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { CustomSelectTaskInfoCustomerChildsBaseProps } from './types'
import CustomSelectTaskContactInfo from '../../Components/CustomSelectTaskContactInfo'


interface TaskNameProps {
    id: number
    name: string
    changeName: (value: string) => void
}

export const TaskName: React.FC<TaskNameProps> = ({ id, name, changeName }) => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex items-center regular18 flex-auto'>
            <span className='mr-10 text-green-43'>#{id}</span>
            <RectangularTextAreaDebounce label='' placeholder={t('titleTask', 'Título de la tarea')} className='flex-auto input_change_task_name' value={name} onChange={changeName} onBlur={(e)=> {
                const val = e.currentTarget.value
                if (val !== name ){
                    changeName(e.currentTarget.value)
                }
            }} />
        </div >
    )
}

interface CustomSelectTaskContactPersonsProps extends CustomSelectTaskInfoCustomerChildsBaseProps {
    contact?: CustomerContactPersonModelType
    TaskId: number
}

export const CustomSelectTaskContactPersons: React.FC<CustomSelectTaskContactPersonsProps> = ({items, updateTask, value, CustomerId, contact, TaskId}) => {
    const {t} = useTwinTranslation()
    if (contact) {
        items[contact.id] = contact
    }
    const selectedContact = value ? items?.[value] : undefined
    
    if (!CustomerId){
        return <InputWithLabelMargin label={t('customerContact', 'Contacto del cliente')} readOnly={true} value={t('addProject', 'Añadir proyecto')} />
    }
    return <CustomSelectTaskContactInfo id={'contactPersonId' + TaskId} label={t('customerContact', 'Contacto del cliente')} items={items} value={value} selectedContact={selectedContact} onChange={(value) => updateTask('CustomerContactPersonId', value)} /> 
}

interface CustomSelectTaskShopProps extends CustomSelectTaskInfoCustomerChildsBaseProps {
    shop?: CustomerShopModelType
    TaskId: number
}

export const CustomSelectTaskShop: React.FC<CustomSelectTaskShopProps> = ({ items, updateTask, CustomerId, value, shop, TaskId}) => {
    const {t} = useTwinTranslation()
    if(shop){
        items[shop.id]= shop
    }
    const selectedContact = value ? items?.[value] : undefined
    if (!CustomerId ){
        return <InputWithLabelMargin label={t('customerShop', 'Centro del cliente')} readOnly={true} value={t('addProject', 'Añadir proyecto')} />
    }
    return <CustomSelectTaskContactInfo id={'ShopId' + TaskId} label={t('customerShop', 'Centro del cliente')} items={items} value={value} selectedContact={selectedContact} onChange={(value) => updateTask('CustomerShopId', value)} /> 
}
