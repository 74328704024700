import { EmployeeJourneyTyExtended } from '../../../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { ModalMedium } from '../../../../../baseComponents/Modal'
import TwinMapRender from '../../../../../baseComponents/TwinMapRender'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { displayDateByString, parseStringDateHoursToMins, displayFormatedHourFromMins } from '../../../../../utils/globals/date'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../utils/globals/dictionary'
import { EditDeleteModalProps } from '../../../../../utils/hooks/useEditDeleteModal'
import './modalShowTimetables.sass'

interface ModalShowTimetablesLocationProps extends EditDeleteModalProps {
    data: EmployeeJourneyTyExtended[] | null
}

export const ModalShowTimetablesLocation: React.FC<ModalShowTimetablesLocationProps> = ({ openModal, setOpenModal, data }) => {
    const renderThis: JSX.Element[] = []
    if (!data) {
        return null
    }
    const date = openModal?.allRowData.date
    const myDateData: TwinDictionary = dictionaryComplexFromJsonArr(data, 'date')
    if (myDateData[date]) {
        let item = myDateData[date]
        for (const timetable of item.timetables || []) {
            renderThis.push(<SingleTimetableLocation timetable={timetable} />)
        }
    }
    return (
        <ModalMedium className={'h-full modal_medium flex flex-col modal_negative_margin'} opened={true} setOpened={() => setOpenModal(null)}>
            <h2 className='mb-20'><TwinTrans transKey='startAndEndHoursOfDay'>Horas de entrada y salida del día</TwinTrans> {displayDateByString(openModal?.allRowData.date)}</h2>
            <div className='overflow-auto flex flex-col flex-auto pr-30 display_table_control_month_work'>
                {renderThis}
            </div>
        </ModalMedium>
    )
}

interface SingleTimetableLocationProps {
    timetable: any
}

const SingleTimetableLocation: React.FC<SingleTimetableLocationProps> = ({ timetable,}) => {
    const haveLocation = timetable.locationStart || timetable.locationEnd
    return (
        <div className={'grid grid-cols-2 gap-30 mb-20 location_grid ' + (haveLocation ? ' have_location' : '')}>
            <StartOrEndLocation translationMain={{
                title: 'Inicio',
                transKey: 'start'
            }} editedHours={timetable.edited_start_time} myLocation={timetable.locationStart} originalHours={timetable.og_start_time} />
            {timetable.og_end_time || timetable.edited_end_time ?
                <StartOrEndLocation translationMain={{
                    title: 'Salida',
                    transKey: 'clockOut'
                }} editedHours={timetable.edited_end_time} myLocation={timetable.locationEnd} originalHours={timetable.og_end_time} />
                : null
            }
        </div>
    )
}

interface StartOrEndLocationProps {
    translationMain: { title: string, transKey: string }
    originalHours: string | null
    editedHours: string | null
    myLocation?: string
}

const StartOrEndLocation: React.FC<StartOrEndLocationProps> = ({ translationMain, originalHours, editedHours, myLocation }) => {
    const isEdited = editedHours ? true : false
    const isNewRow = originalHours ? false : true
    const displayHours = {
        first: parseInt(parseStringDateHoursToMins((editedHours ? editedHours : originalHours) || '')) ,
        second: originalHours ? parseInt(parseStringDateHoursToMins((originalHours) || '')) : null
    }
    return (
        <div>
            <div className='location_title'>
                <span className='medium14'><TwinTrans transKey={translationMain.transKey}>{translationMain.title}</TwinTrans></span>
                <span className='light14'>
                    : {displayFormatedHourFromMins(displayHours.first || 0)}
                    {isNewRow ? <TwinTrans transKey='addHourParenthesis'> (Añadido)</TwinTrans> : null}
                    {isEdited && !isNewRow ? <TwinTrans transKey='editedParenthesisShort'> (Edit.)</TwinTrans> : null}
                    {isEdited && displayHours.second ?
                        <span> / {displayFormatedHourFromMins(displayHours.second)} <TwinTrans transKey='originalShort'> (Orig.)</TwinTrans></span>
                        : null
                    }
                </span>
            </div>
            {myLocation
                ? <TwinMapRender width={290} height={225} cords={myLocation} />
                : null
            }
        </div>
    )
}