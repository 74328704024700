import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { ModalOpenedSetTy } from '../../../../../../../../../baseComponents/Modal/types'
import { ModalCreateEditBaseCustomLoading } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinCalendarStartEnd from '../../../../../../../../../baseComponents/TwinCalendar/TwinCalendarStartEnd'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { OnSubmit } from '../../../../../../../../../forms/TwinForm/types'
import ScheduleAbsence from '../../../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence'
import { AbsenceScheduleErrorsTy } from '../../../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence/types'
import twinFetchPost from '../../../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'
import { checkSchedulesAllOk } from '../../../../../../../../../utils/globals/schedule'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { changeErrorMessage, changeSuccessMessage, clearAntiUnsaveElement } from '../../../../../../../../../utils/reducers/reduxDispatch'
import { getEmployeeAbsenceTypes } from '../../../../../../../../../utils/reducers/getters'
import useIsLoading from '../../../../../../../../../utils/hooks/useIsLoading'


type MainRequestEmployeeAbsenceProps = ModalEditComponentProps & MainRequestEmployeeAbsenceLogicProps &  {}

const MainRequestEmployeeAbsence: React.FC<MainRequestEmployeeAbsenceProps> = ({ allRowData, setOpened, onSubmit }) => {
    const { t } = useTwinTranslation()
    const employeeAbsenceTypes = getEmployeeAbsenceTypes()
    const { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule, loading } = useMainRequestEmployeeAbsenceLogic({ setOpened, onSubmit })
    const fields: FormRender<EmployeeAbsenceModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'CustomSelect',
                    label: t('absenceType', 'Tipo de ausencia'),
                    items: employeeAbsenceTypes || {},
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('uploadProof', 'Subir justificante'),
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
            ]
        }
    ]
    return (
        <ModalCreateEditBaseCustomLoading url='' onSubmit={() => { }} beforeSubmit={(vals: TwinDictionary) => { checkAllOk(vals); return false }} translations={{
            title: t('createEmployeeAbsence', 'Crear ausencia'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} antiUnsaveTrigger={true} imagePath={['tmp']} className='notFlexAutoFormRender' {...{loading, fields, setOpened}}>
            <TwinCalendarStartEnd start={dateSelected.start} end={dateSelected.end} onChange={handleOnChange} />
            <ScheduleAbsence {...{ schedule, setSchedule }} />
        </ModalCreateEditBaseCustomLoading>
    )
}

interface MainRequestEmployeeAbsenceLogicProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}
const useMainRequestEmployeeAbsenceLogic = ({ setOpened, onSubmit }: MainRequestEmployeeAbsenceLogicProps) => {
    const currentDate = new Date()
    const [schedule, setSchedule] = useState<AbsenceScheduleErrorsTy>({ workingTime: [], errors: [] })
    const [dateSelected, setDateSelected] = useState({ start: currentDate, end: currentDate })
    const { startLoading, endLoading, loading } = useIsLoading()
    const { t } = useTwinTranslation()

    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const messages = JSON.stringify({
        'The day picked must be a working day': t('mustBeWorkingDay', 'La fecha seleccionada debe ser un día laborable'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
    })
    const message = t('successfullyCompleted', 'Realizado correctamente')

    const handleOnChange = useCallback((value: Date, type: 'start' | 'end') => {
        setDateSelected((old) => {
            return { ...old, [type]: value }
        })
    }, [setDateSelected])


    const checkAllOk = useCallback(async (values: TwinDictionary) => {
        startLoading()
        await flushSync(async () => {
            let hasError = false
            values['dateStart'] = dateSelected.start.toDateString()
            values['dateEnd'] = dateSelected.end.toDateString()
            let checkedSchedule: AbsenceScheduleErrorsTy | false = false
            await setSchedule((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                const myDaySchedule = copyOld
                myDaySchedule.errors = []
                const response = checkSchedulesAllOk(myDaySchedule)
                if (response.hasError) {
                    hasError = true
                }
                if (hasError) {
                    changeErrorMessage(errorHours)
                }
                if (!hasError) {
                    checkedSchedule = { ...copyOld }
                }
                return { ...copyOld }
            })
            if (hasError) {
                changeErrorMessage(errorHours)
                endLoading()
                return false
            }
            if (checkedSchedule) {
                if (schedule.workingTime && schedule.workingTime.length) {
                    values['schedule'] = JSON.stringify(schedule?.workingTime)
                }
                const response = await twinFetchPost('/api/app/employee/employeeAbsence/createEmployeeAbsence', values)
                if (response.status === 200) {
                    setOpened(null)
                    onSubmit?.(response, values)
                    changeSuccessMessage(message)
                } else {
                    const messagesParsed = JSON.parse(messages)
                    const responseJSON = await response.json()
                    if (responseJSON.errors[0].msg && messagesParsed[responseJSON.errors[0].msg]) {
                        changeErrorMessage(messagesParsed[responseJSON.errors[0].msg])
                    }
                }
            }
        })
        await clearAntiUnsaveElement()
        endLoading()
    }, [errorHours, setSchedule, setOpened, dateSelected.start, dateSelected.end, onSubmit, schedule.workingTime, messages, endLoading, startLoading, message])

    return { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule, loading, endLoading }
}

export default MainRequestEmployeeAbsence