import { CustomerProjectModelTypeExtended, SetCustomerProjectDataTy } from '../types'
import { CPChecklistModelType } from '@teinor/erp/types/company/customer/customerProject/cPChecklist'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import ChecklistSection from './CPChecklistSection'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../utils/globals/dictionary'
import CustomSelect from '../../../../forms/CustomSelect'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { ButtonPrimary } from '../../../../baseComponents/Button'
import { getUserPermissions } from '../../../../utils/reducers/getters'
import { permissionCheck } from '../../../../baseComponents/PermissionChecker/function'
import PermissionChecker from '../../../../baseComponents/PermissionChecker'
import { ButtonAddChecklistSectionBase } from '../../../../baseComponents/Checklist/ButtonAddChecklistSection'
import { ModalOpenedTy } from '../../../../baseComponents/Modal/types'
import ButtonsAddSectionCopyTChecklist from './ButtonsAddSectionCopyTChecklist'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import './customerProjectChecklistTab.sass'

interface CPChecklistTabProps {
    customerProjectData: CustomerProjectModelTypeExtended
    setCustomerProjectData: SetCustomerProjectDataTy
}

const CPChecklistTab: React.FC<CPChecklistTabProps> = ({customerProjectData, setCustomerProjectData}) => {
    const {t} = useTwinTranslation()
    const renderThis: JSX.Element[] = []
    const { opened, handleOpen } = useCPChecklistTab()
    const checklists = customerProjectData?.CPChecklists
    const haveList = checklists?.length
    if (haveList) {
        for (const key in checklists) {
            const checklist = checklists[key]
            renderThis.push(<Checklist key={checklist.id} posChecklist={parseInt(key)} myChecklist={checklist} {...{ setCustomerProjectData }} />)
        }
    }
    const userPermission = getUserPermissions('customer.projects.checklists')
    return (
        <div className='flex flex-col flex-auto h-1 overflow-auto checklist_negative_margin'>
            {haveList ? 
                renderThis 
            :
                <PermissionChecker userPermissions={userPermission} permission='update'>
                    {opened ?
                        <ButtonCreateCopyChecklist {...{ customerProjectData, setCustomerProjectData }} />
                    :
                        null
                    }
                    <ButtonAddChecklistSectionBase addSection={handleOpen} buttonText={t('createList', 'Crear lista')} icon={faPlus} />
                </PermissionChecker>
            }
        </div>
    )
}

const useCPChecklistTab = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const handleOpen = useCallback(() => {
        setOpened((old)=>{
            if(!old){
                return true
            }
            return null
        })
    }, [setOpened])
    return { opened, handleOpen }
}


interface ButtonCreateCopyChecklistProps extends ButtonCreateCopyChecklistLogicProps {}

const ButtonCreateCopyChecklist: React.FC<ButtonCreateCopyChecklistProps> = ({...logic}) => {
    const {t} = useTwinTranslation()
    const { dictTChecklist, handleCopyTChecklistId, copyTChecklistId, copyCPList } = useButtonCreateCopyChecklistLogic({ ...logic })
    return (
        <div className='flex items-center justify-center mt-20'>
            <CustomSelect className='w-200' label={t('copyListFromTemplate', 'Copiar lista de una plantilla')} items={dictTChecklist || {}} value={copyTChecklistId} onChange={handleCopyTChecklistId} />
            <ButtonPrimary className='ml-10' onClick={copyCPList}>
                <TwinTrans transKey='createList'>Crear lista</TwinTrans>
            </ButtonPrimary>
        </div>
    )
}

interface ButtonCreateCopyChecklistLogicProps {
    customerProjectData: CustomerProjectModelTypeExtended
    setCustomerProjectData: SetCustomerProjectDataTy
}

const useButtonCreateCopyChecklistLogic = ({ customerProjectData, setCustomerProjectData }: ButtonCreateCopyChecklistLogicProps) => {
    const [copyTChecklistId, setCopyTChecklistId] = useState<number>(0)
    const [dictTChecklist, setDictTChecklist] = useState<TwinDictionary | null>(null)
    const { t } = useTwinTranslation()
    const messageCreateNewList = t('createNewList', 'Crear nueva lista')

    const createCPList = useCallback(async () => {
        const res = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/createCPChecklist', { CustomerProjectId: customerProjectData?.id, name: 'Checklist' })
        if (res) {
            setCustomerProjectData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.CPChecklists = [res]
                return { ...copyOld }
            })
        }
    }, [customerProjectData?.id, setCustomerProjectData])

    const copyCPList = useCallback(async () => {
        if (parseInt(String(copyTChecklistId)) === 0) {
            createCPList()
        } else {
            const res = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/copyTemplateToCPChecklist', { CustomerProjectId: customerProjectData?.id, TChecklistId: parseInt(String(copyTChecklistId)) })
            if (res) {
                setCustomerProjectData((old) => {
                    if (!old) {
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    copyOld.CPChecklists = [res]
                    return { ...copyOld }
                })
            }
        }
    }, [customerProjectData?.id, setCustomerProjectData, copyTChecklistId, createCPList])

    const getTChecklists = useCallback(async () => {
        const tChecklists = await twinFetchPostJSON('/api/app/config/templateChecklist/getAllTChecklists', {})
        setDictTChecklist({ 0: { name: messageCreateNewList, value: 0 }, ...dictionaryComplexFromJsonArr(tChecklists) })
    }, [setDictTChecklist, messageCreateNewList])

    const handleCopyTChecklistId = useCallback((value: number) => {
        setCopyTChecklistId(value)
    }, [setCopyTChecklistId])

    useEffect(() => {
        getTChecklists()
    }, [getTChecklists])

    return { createCPList, dictTChecklist, handleCopyTChecklistId, copyTChecklistId, copyCPList }
}



interface ChecklistProps extends ChecklistLogicProps {}

const Checklist: React.FC<ChecklistProps> = ({ myChecklist, setCustomerProjectData,posChecklist }) => {
    const { addSection, refChecklistSection } = useChecklistLogic({ myChecklist, setCustomerProjectData, posChecklist })
    const renderThis: JSX.Element[] = []
    if (myChecklist?.CPChecklistSections){
        for (const key in myChecklist.CPChecklistSections) {
            const section = myChecklist.CPChecklistSections[key]
            renderThis.push(<ChecklistSection key={section.id} posSection={parseInt(key)} {...{ section, setCustomerProjectData, posChecklist, addSection, refChecklistSection }} CustomerProjectId={myChecklist.CustomerProjectId} />)
        }
    }
    const userPermission = getUserPermissions('customer.projects.checklists')
    return (
        <div className='my-10' ref={refChecklistSection}>
            {renderThis}
            <ButtonsAddSectionCopyTChecklist {...{userPermission, setCustomerProjectData, posChecklist, myChecklist, addSection}} />
        </div>
    )
}
interface ChecklistLogicProps {
    posChecklist: number
    myChecklist: CPChecklistModelType
    setCustomerProjectData: SetCustomerProjectDataTy
}

const useChecklistLogic = ({ myChecklist, setCustomerProjectData, posChecklist }: ChecklistLogicProps) => {
    const userPermission = getUserPermissions('customer.projects.checklists')
    const refChecklistSection = useRef<any>(null)

    const addSection = useCallback(async () => {
        if (permissionCheck(userPermission, 'update')){
            const res = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/createCPChecklistSection', { CPChecklistId: myChecklist.id, name: '', CustomerProjectId: myChecklist.CustomerProjectId })
            if(res){
                setCustomerProjectData((old) => {
                    if(!old){
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    const checklist = copyOld.CPChecklists[posChecklist]
                    if (!checklist?.CPChecklistSections){
                        checklist.CPChecklistSections = []
                    }
                    checklist?.CPChecklistSections.push({ ...res, CPChecklistTasks: []})
                    return { ...copyOld }
                })
            }
            setTimeout(() => {
                refChecklistSection.current?.children[myChecklist.CPChecklistSections.length || 0]?.getElementsByClassName('input_change_checklist_section_name')[0]?.getElementsByClassName('rectangular_textarea')[0]?.children[0]?.focus()
            }, 100)
        }
    }, [posChecklist, myChecklist.id, setCustomerProjectData, userPermission, myChecklist.CustomerProjectId, myChecklist.CPChecklistSections.length])

    return { addSection, refChecklistSection }
}


export default CPChecklistTab