import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalCreateEditStructureLayout } from '../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps, ModalEditComponentProps } from '../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../baseComponents/PermissionChecker/function'
import { RowData } from '../../baseComponents/TwinTable/types'
import { FormRender } from '../../forms/FormRenderer/types'
import twinFetchPost, { twinFetchPostJSON } from '../../utils/globals/data'
import { valueOrDefaultValue } from '../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { getAllTaskTypes, getEmployees, getGroups, getTaskCFields, getUserPermissions } from '../../utils/reducers/getters'
import TableTaskWorkLogs from './TaskBagOfHours'
import { parsedDataBagOfHoursDetail } from './TaskBagOfHours/functions'
import { METaskBagOfHoursModelTypeExtended } from './TaskBagOfHours/types'
import { ModalSmallCentered, ModalWithCloseButton } from '../../baseComponents/Modal'
import { ModalOpenedSetTy } from '../../baseComponents/Modal/types'
import FormRenderer from '../../forms/FormRenderer'
import TwinForm from '../../forms/TwinForm'
import { changeErrorMessage, changeSuccessMessage } from '../../utils/reducers/reduxDispatch'
import { useSingleTaskLogic, SingleTaskInner } from '../Task/SingleTask'
import TwinTrans from '../../baseComponents/TwinTrans'
import { UserPermissionsSingle } from '../../baseComponents/CreateRoutering/types'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../utils/reducers'
import { TaskCFieldPayload } from '../../utils/reducers/company/taskCFields'
import { CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'
import { Link } from 'react-router-dom'
import useEditDeleteModal, { SetEditDeleteModalTy, UseEditDeleteModalProps } from '../../utils/hooks/useEditDeleteModal'
import { BeforeSubmitHandler, OnSubmit } from '../../forms/TwinForm/types'
import { ButtonPrimary, ButtonSecondary } from '../../baseComponents/Button'
import { displayFormatedHourFromSecs } from '../../utils/globals/date'
import { TwinDictionary } from '../../utils/globals/dictionary'
import useIsLoading from '../../utils/hooks/useIsLoading'
import LoadingSpinner from '../../baseComponents/LoaderDecider/LoadingSpinner'
import PermissionChecker from '../../baseComponents/PermissionChecker'
import HeaderTask from '../Task/SingleTask/HeaderTask'
import './modaledittaskbagofhours.sass'

type ModalEditTaskBagOfHoursProps = ModalEditComponentProps & ReduxModalEditTaskBagOfHours & {}

const ModalEditTaskBagOfHours: React.FC<ModalEditTaskBagOfHoursProps> = ({ allRowData, userPermissions, setOpened, taskCFields, ...rest }) => {
    const { selectedTaskId, setSelectedTaskId, returnModal } = useModalEditTaskBagOfHoursLogic({ setOpened })

        return (
            <ModalWithCloseButton size='modal_big' opened={true} setOpened={setOpened} setOpenedReturn={returnModal} showCloseButton={selectedTaskId ? true : false} className='text-gray-51 flex flex-col edit_bag_of_hours' onClickOut={false} >
                <div className={'flex flex-auto '}>
                    {selectedTaskId ?
                        <TaskBagOfHoursSingleTask selectedTaskId={selectedTaskId} setSelectedTaskId={setSelectedTaskId} returnModal={returnModal} />
                        :
                        <TaskBagOfHoursFormAndTable setSelectedTaskId={setSelectedTaskId} allRowData={allRowData} userPermissions={userPermissions} {...rest} setOpened={setOpened} />
                    }
                </div>
            </ModalWithCloseButton>)
    }

interface ModalEditTaskBagOfHoursLogicProps {
    setOpened: ModalOpenedSetTy
}

const useModalEditTaskBagOfHoursLogic = ({ setOpened }: ModalEditTaskBagOfHoursLogicProps) => {
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null)
   
    const returnModal = useCallback(() => {
        if (selectedTaskId) {
            setSelectedTaskId(null)
        } else {
            setOpened(null)
        }
    }, [setOpened, selectedTaskId, setSelectedTaskId])

    return { selectedTaskId, setSelectedTaskId, returnModal }
}

interface TaskBagOfHoursSingleTaskProps extends TaskBagOfHoursSingleTaskLogicProps {
    selectedTaskId: number | null
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const TaskBagOfHoursSingleTask: React.FC<TaskBagOfHoursSingleTaskProps> = ({ selectedTaskId, setSelectedTaskId, ...logic }) => {
    const { onInvalidPermissions, onDeletedTask } = useTaskBagOfHoursSingleTaskLogic({ ...logic })
    const { refSubTask, data: dataSingleTask, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime, setOpenModalInvertedTime } = useSingleTaskLogic({ id: String(selectedTaskId), onInvalidPermissions, onDeletedTask })
    const employees = getEmployees()
    const groups = getGroups()
    const taskCFields = getTaskCFields()
    const taskTypes = getAllTaskTypes()
    if (!dataSingleTask) {
        return null
    }
    return (
        <div className={'flex flex-col w-full single_task overflow-auto'}>
            <HeaderTask data={dataSingleTask} {...{ refSubTask, updateTask, setData, onDeletedTask, setOpenModalInvertedTime }} />
            <SingleTaskInner data={dataSingleTask} setSelectedSubtaskId={setSelectedTaskId}{...{ updateTask, setData, changeDescription, changeName, refSubTask, customerDicts, openModalInvertedTime, setOpenModalInvertedTime, employees, groups, taskCFields, taskTypes }} />
        </div>
    )
}

interface TaskBagOfHoursSingleTaskLogicProps {
    returnModal: () => void
}

const useTaskBagOfHoursSingleTaskLogic = ({ returnModal }: TaskBagOfHoursSingleTaskLogicProps) => {
    const { t } = useTwinTranslation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')

    const onInvalidPermissions = useCallback(() => {
        changeErrorMessage(taskNotAvailable)
    }, [taskNotAvailable])

    const onDeletedTask = useCallback((otherPerson?: boolean) => {
        if (otherPerson) {
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
        returnModal()
    }, [taskDeleted, taskHasBeenDeleted, returnModal])

    return { onInvalidPermissions, onDeletedTask }
}

type TaskBagOfHoursFormAndTableProps = TaskBagOfHoursFormAndTableLogicProps & ModalComponentProps & {
    userPermissions?: UserPermissionsSingle
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const TaskBagOfHoursFormAndTable: React.FC<TaskBagOfHoursFormAndTableProps> = ({ allRowData, userPermissions, setSelectedTaskId, setOpened, onSubmit }) => {
    const { t } = useTwinTranslation()
    const { data, handleChangeData, downloadBagOfHoursPdf, loading, parsedLink, handleOnPreSubmit, openModal, setOpenModal } = useTaskBagOfHoursFormAndTableLogic({ allRowData })
    const fields: FormRender<METaskBagOfHoursModelTypeExtended> = [
        {
            cols: 4,
            elements: [
                {
                    name: 'CustomerProjectId',
                    label: t('project', 'Proyecto'),
                    component: 'CustomSelect',
                    required: true,
                    items: data ? { [data?.CustomerProjectId]: data?.CustomerProject } : {},
                    readOnly: true
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'timeLimit',
                    label: t('timeLimit', 'Tiempo límite'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(data?.timeLimit || 0),
                    onFinalChange: (value) => handleChangeData('timeLimit', value)
                },
                {
                    name: 'remainingTime',
                    label: t('remainingTime', 'Tiempo restante'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    value: String(data?.remainingTime || 0),
                    onFinalChange: (value) => handleChangeData('remainingTime', value)
                },
                {
                    name: 'expireDate',
                    label: t('expiration', 'Vencimiento'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]
    if (data && userPermissions) {
        fields[0].elements.push({
            name: 'active',
            label: t('active', 'Activo'),
            component: 'CheckboxMargin',
        })
        fields[0].elements.push({
            name: 'sharing',
            label: t('share', 'Compartida'),
            component: 'CheckboxMargin',
        })
        const headerTitle: JSX.Element = <div className='flex items-center'>
            <h2>{t('editBagOfHours', 'Editar bolsa de horas')}</h2>
            <PermissionChecker userPermissions={getUserPermissions('bagOfHoursDownloadPdf')} permission={'read'}>
                <div className='ml-auto regular16 text-green-43 cursor-pointer' onClick={downloadBagOfHoursPdf}>
                    {loading ? <div className='w-100 m-auto'><LoadingSpinner className='w-30 h-30' /></div> : <TwinTrans transKey='downloadReport'>Descargar informe</TwinTrans>}
                    <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                </div>
            </PermissionChecker>
        </div>
        const parsedFields = valueOrDefaultValue(fields, userPermissions, data)
        return (
            <TwinForm className={'flex flex-auto flex-col small_margin'} action='/api/app/task/bagOfHours/updateTaskBagOfHours' beforeSubmitHandler={handleOnPreSubmit} onSubmit={(res, values) => { onSubmit(res, values); setOpened(null) }}>
                <ModalCreateEditStructureLayout translations={{ title: headerTitle, button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} className='modal_negative_margin flex flex-col' loading={false}>
                    <div className='px-30 flex flex-col'>
                        <Link to={parsedLink}><h2 className='cursor-pointer text-gray-51 hover:text-green-21 mb-20 text-18  font-light '>{data?.CustomerProject?.Customer?.id} - {data?.CustomerProject?.Customer?.name}</h2></Link>
                        {fields.length ?
                            <FormRenderer className='flex flex-wrap' sections={parsedFields} />
                            : null}
                    </div>
                    {data ? <div className='flex flex-auto mx-30 mt-50'><TableTaskWorkLogs data={data.Tasks} setSelectedTaskId={setSelectedTaskId} /></div> : null}
                </ModalCreateEditStructureLayout>
                {openModal ?
                    <ModalModifiedTimeLimit {...{ openModal, setOpenModal, setOpened, onSubmit, data }}/>
                    : null
                }
            </TwinForm>
        )
    }
    return null
}

interface TaskBagOfHoursFormAndTableLogicProps {

    allRowData?: RowData
}

const useTaskBagOfHoursFormAndTableLogic = ({ allRowData }: TaskBagOfHoursFormAndTableLogicProps) => {
    const [data, setData] = useState<METaskBagOfHoursModelTypeExtended | null>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const { openModal, setOpenModal } = useEditDeleteModal()
    const { t } = useTwinTranslation()
    const bagOfHoursString = t('bagOfHours', 'Bolsa de horas')
    const typeCustomer: CustomerTypesInversedKeys = data?.CustomerProject?.Customer?.customer_type || 0
    const parsedLink = useMemo(() => {
        const dictTypeCustomerLink: Record<CustomerTypesInversedKeys, string> = {
            0: 'customers',
            1: 'customersB2B',
            2: 'contactsB2B',
            3: 'contactsB2C',
        }
        return '/customer/' + dictTypeCustomerLink[typeCustomer] + '/' + data?.CustomerProject?.Customer?.id + '/main'
    }, [typeCustomer, data?.CustomerProject?.Customer?.id])

    const handleOnPreSubmit: BeforeSubmitHandler = useCallback((vals) => {
        const differenceTimeLimit = (vals?.timeLimit || 0) - (data?.preTimeLimit || 0)
        if(differenceTimeLimit !== 0){
            const newRemaining = parseInt(String(vals?.remainingTime || '0')) + differenceTimeLimit
            setOpenModal({ type: 'edit', allRowData: { differenceTimeLimit, newRemaining } })
            return false
        }
        return true
    }, [setOpenModal, data?.preTimeLimit])

    const getData = useCallback(async () => {
        const bagOfHours = await twinFetchPostJSON('/api/app/task/bagOfHours/getTaskBagOfHoursForDetail', { id: allRowData?.id })
        if (bagOfHours) {
            const result = parsedDataBagOfHoursDetail(bagOfHours)
            setData({ ...result, preTimeLimit: result.timeLimit })
        }
    }, [setData, allRowData?.id])

    const handleChangeData = useCallback((field: keyof METaskBagOfHoursModelTypeExtended, value: number) => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            const parseTimeLimit = parseInt(String(copyOld.timeLimit))
            const parseRemainingTime = parseInt(String(copyOld.remainingTime))
            if (field === 'timeLimit') {
                if (value < parseRemainingTime) {
                    copyOld.remainingTime = value
                }
            } else if (field === 'remainingTime') {
                if (value > parseTimeLimit) {
                    copyOld.timeLimit = value
                }
            }
            return { ...copyOld, [field]: value }
        })
    }, [setData])

    const downloadBagOfHoursPdf = useCallback(async (e: React.MouseEvent) => {
        startLoading()
        const current = e.currentTarget
        const result = await twinFetchPost('/api/app/task/bagOfHours/getBagOfHoursPdf', { id: allRowData?.id })
        if (result) {
            const pdf = await result.blob()
            const fileURL = URL.createObjectURL(pdf)
            const a = current.querySelector('a')
            const fileName = `${bagOfHoursString}-${allRowData?.id}`
            if (a) {
                a.href = fileURL
                a.download = fileName + '.pdf'
                a.click()
            }
            endLoading()
        }
    }, [allRowData, bagOfHoursString, startLoading, endLoading])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleChangeData, downloadBagOfHoursPdf, loading, parsedLink, handleOnPreSubmit, openModal, setOpenModal }
}

interface ModalModifiedTimeLimitProps extends ModalNotifiedTimeLimitLogicProps {
    openModal: UseEditDeleteModalProps
}

const ModalModifiedTimeLimit: React.FC<ModalModifiedTimeLimitProps> = ({ openModal, setOpenModal, ...logic }) => {
    const options = { differenceTime: displayFormatedHourFromSecs(openModal?.allRowData.differenceTimeLimit) }
    const {tVars} = useTwinTranslation()
    const { saveTaskBagOfHours } = useModalNotifiedTimeLimitLogic({ setOpenModal, ...logic})
    if(!openModal){
        return null
    }
    return (
        <ModalSmallCentered opened={true} setOpened={() => setOpenModal(null)} onClickOut={false} >
            <div className='flex flex-col justify-between'>
                <h2><TwinTrans transKey='modifiedTaskBagOfHoursTimeLimit'>Has modificado el tiempo límite</TwinTrans></h2>
                <span className='mt-30'>
                    {tVars('wouldYouLikeToModifyRemainingTime', 'Has añadido {{differenceTime}} al tiempo límite ¿Deseas añadir el mismo tiempo al tiempo restante?', options)}
                </span>
            </div>
            <div className='flex justify-center gap-x-33'>
                <ButtonSecondary onClick={() => saveTaskBagOfHours({})}>
                    <TwinTrans transKey={'saveAndNotAdd'}>Guardar sin añadir</TwinTrans>
                </ButtonSecondary>
                <ButtonPrimary onClick={() => saveTaskBagOfHours({ remainingTime: openModal.allRowData.newRemaining || 0 })}>
                    <TwinTrans transKey='addAndSave'>Añadir y guardar</TwinTrans>
                </ButtonPrimary>
            </div>
        </ModalSmallCentered>
    )
}

interface ModalNotifiedTimeLimitLogicProps {
    data: METaskBagOfHoursModelTypeExtended | null
    setOpenModal: SetEditDeleteModalTy
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}

const useModalNotifiedTimeLimitLogic = ({ setOpenModal , setOpened, data, onSubmit}: ModalNotifiedTimeLimitLogicProps) => {
    const {t} = useTwinTranslation()
    const message = t('successfullyCompleted', 'Realizado correctamente')

    const saveTaskBagOfHours = useCallback(async (vals: TwinDictionary) => {
        const res = await twinFetchPostJSON('/api/app/task/bagOfHours/updateTaskBagOfHours', { ...data, ...vals })
        if (res) {
            changeSuccessMessage(message)
            setOpenModal(null)
            setOpened(null)
            onSubmit?.(res, {})
        }
    }, [setOpenModal, setOpened, data, message, onSubmit])

    return { saveTaskBagOfHours }
}


const modalEditTaskBagOfHoursDispatch = {
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

type ReduxModalEditTaskBagOfHours = ConnectedProps<typeof modalEditTaskBagOfHoursConnect>
const mapModalEditTaskBagOfHoursConnector = (state: AllReduxPayloads) => ({ taskCFields: state.taskCFields })
const modalEditTaskBagOfHoursConnect = connect(mapModalEditTaskBagOfHoursConnector, modalEditTaskBagOfHoursDispatch)

const taskConnectLoading = withLoading(ModalEditTaskBagOfHours, [{ fetchUrl: '/api/app/task/customField/getAllTaskCFieldsComplete', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const ModalEditTaskBagOfHoursConnect = modalEditTaskBagOfHoursConnect(taskConnectLoading)

export default ModalEditTaskBagOfHoursConnect