import { useCallback, useEffect, useState } from 'react'
import { checkIfAnimated } from '../../../baseComponents/Modal/functions'
import { ModalOpenedTy } from '../../../baseComponents/Modal/types'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import TaskNotificationModalPopup from './TaskNotificationModalPopup'
import './tasknotificationmodal.sass'
import { TaskNotificationModalTasksTy } from './types'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { getUserPermissions } from '../../../utils/reducers/getters'
import { permissionCheck } from '../../../baseComponents/PermissionChecker/function'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { CampaignCustomerModelType } from '@teinor/erp/types/company/customer/campaign/campaignCustomer'


interface TaskNotificationModalProps { }

const TaskNotificationModal: React.FC<TaskNotificationModalProps> = () => {
    const { displayModal, tasks, handleOnClosePopUpTask, setTasks } = useTaskNotificationModalLogic()
    if (!displayModal || (!tasks.taskToShow.length && !tasks.projectToShow.length && !tasks.campaignToShow.length)) {
        return null
    }
    return (
        <TaskNotificationModalPopup tasks={tasks} setTasks={setTasks} handleOnClosePopUpTask={handleOnClosePopUpTask} animatedClass={checkIfAnimated(displayModal)} />
    )
}

const useTaskNotificationModalLogic = () => {
    const [displayModal, setDisplayModal] = useState<ModalOpenedTy>(null)
    const [tasks, setTasks] = useState<TaskNotificationModalTasksTy>({
        hiddenTasks: {},
        taskToShow: [],
        projectToShow: [],
        campaignToShow: []
    })

    const getMyTasks = useCallback(async () => {
        const start_date_start = new Date()
        const start_date_end = new Date(start_date_start)
        start_date_end.setMinutes(start_date_start.getMinutes() + 10)
        start_date_start.setMinutes(start_date_start.getMinutes() - 10)
        const result = await twinFetchPostJSON('/api/app/task/getMyTasksForNotification', {
            start_date_between: [start_date_start, start_date_end]
        })
        if (result?.taskToShow?.length || result?.projectToShow?.length || result?.campaignToShow?.length) {
            setTasks((old) => {
                const copy = JSON.parse(JSON.stringify(old))
                const taskToShow: TaskModelType[] = []
                const hiddenTasks: TwinDictionary = {}
                const projectToShow: CustomerProjectModelType[]= []
                const campaignToShow: CampaignCustomerModelType[]= []
                const ids: string[] = []
                const currentDate = new Date()
                currentDate.setMinutes(currentDate.getMinutes() - 20)
                for (const key in copy.hiddenTasks) {
                    const element = new Date(copy.hiddenTasks[key])
                    if (element >= currentDate) {
                        hiddenTasks[key] = element
                    }
                    ids.push(key)
                }
                for (const element of result.taskToShow || []) {
                    if (!ids.includes('task-' + element.id)) {
                        ids.push('task-' + element.id)
                        taskToShow.push(element)
                    }
                }
                for (const element of result.projectToShow || []) {
                    if (!ids.includes('project-' + element.id)) {
                        ids.push('project-' + element.id)
                        projectToShow.push(element)
                    }
                }
                for (const element of result.campaignToShow || []) {
                    if (!ids.includes('campaign-' + element.id)) {
                        ids.push('campaign-' + element.id)
                        campaignToShow.push(element)
                    }
                }
                return { ...old, taskToShow, hiddenTasks, projectToShow, campaignToShow }
            })
            setDisplayModal(true)
        }
    }, [setDisplayModal, setTasks])

    const handleOnClosePopUpTask = useCallback(() => {
        setTasks((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            const hiddenTasks: TwinDictionary = copy.hiddenTasks
            for (const element of copy.taskToShow) {
                hiddenTasks['task-' + element.id] = element.start_date
            }
            for (const element of copy.projectToShow) {
                hiddenTasks['project-' + element.id] = element.limit_date
            }
            for (const element of copy.campaignToShow) {
                hiddenTasks['campaign-' + element.id] = element.date
            }
            return { ...old, taskToShow: [], projectToShow: [], campaignToShow: [], hiddenTasks}
        })
        setDisplayModal(null)
    }, [setDisplayModal])


    useEffect(() => {
        const userPermission = getUserPermissions('task.main')
        if (permissionCheck(userPermission, 'read')) {
            getMyTasks()
            const timer = setInterval(() => {
                getMyTasks()
            }, 1000 * 60 * 10)
    
            return () => clearInterval(timer)
        }

    }, [getMyTasks])

    return { displayModal, setDisplayModal, tasks, handleOnClosePopUpTask, setTasks }
}

export default TaskNotificationModal