import ModalSmallFilled from '../../../../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import { ModalEditComponentProps } from '../../../../../../../../../baseComponents/ModalsLayouts/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'



interface ModalConfirmCreateCampaignCustomersProps extends ModalEditComponentProps {
    createCampaignCustomers: () => Promise<void>
    numCustomers: number
}

const ModalConfirmCreateCampaignCustomers: React.FC<ModalConfirmCreateCampaignCustomersProps> = ({ createCampaignCustomers, numCustomers, ...rest }) => {
    const { t, tVars } = useTwinTranslation()
    return (
        <ModalSmallFilled opened={true} setOpened={rest.setOpened} onAccept={createCampaignCustomers} translations={{
            title: t('addCustomersToCampaign', 'Añadir clientes a la campaña'),
            subtitle: tVars('sureAddNumberCustomersToCampaign', '¿Seguro que quieres añadir {{numCustomers}} clientes a esta campaña?', { numCustomers }),
            buttonAccept: t('add', 'Añadir')
        }} />
    )
}

export default ModalConfirmCreateCampaignCustomers