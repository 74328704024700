import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderDate, CustomRenderBoolean, EditRow, CustomRenderString, CustomRenderDisplayHoursMinsSecsBySecs } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomerBagOfHours from './ModalCreateCustomerBagOfHours'
import ModalEditTaskBagOfHours from '../../../../../../../../../specificComponents/ModalEditTaskBagOfHours'

interface CustomerBagsOfHoursProps extends ComponentWithPermissions {
}

const CustomerBagsOfHours: React.FC<CustomerBagsOfHoursProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('bagsOfHours', 'Bolsas de horas'),
        newText: t('addBagOfHours', 'Añadir bolsa de horas'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerBagsOfHours} extraWhereParams={{ 'CustomerId': id }} pageData={pageData} CreateModalComponent={ModalCreateCustomerBagOfHours} EditModalComponent={ModalEditTaskBagOfHours} userPermissions={userPermissions} />
    )
}

type TableCustomerBagsOfHoursProps = TableComponentBaseScreenInnerProps & {
}

const TableCustomerBagsOfHours: React.FC<TableCustomerBagsOfHoursProps> = ({ setTableInstance, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<TaskBagOfHoursModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'TaskBagOfHours.name'
        },
        {
            id: 'CustomerProject',
            dataKey: 'CustomerProjectId',
            label: t('project', 'Proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProject?.name} />
        },
        {
            id: 'timeLimit',
            dataKey: 'timeLimit',
            label: t('timeLimit', 'Tiempo límite'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs value={parseInt(parameterValue)} />,
            sortable: true
        },
        {
            id: 'remainingTime',
            dataKey: 'remainingTime',
            label: t('remainingTime', 'Tiempo restante'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs className={parseInt(parameterValue) <= 0 ? 'text-red-BA' : ''} value={parseInt(parameterValue)} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelect',
                extraComponentData: {
                    label: t('timeFilter', 'Filtro de tiempo'), items: {
                        'exh': { name: t('exhausteds', 'Agotadas') },
                        '20less': { name: t('less20Remaining', 'Menos 20% restante') },
                        'all': { name: t('all', 'Todos') }
                    }
                }
            },
            generalSearchable: false,
            sortable: true
        },
        {
            id: 'expireDate',
            dataKey: 'expireDate',
            label: t('expireDate', 'Fecha de vencimiento'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'active',
            dataKey: 'active',
            label: t('active', 'Activo'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
            hideColumnByDefault: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: {
                    items: {
                        'true': { name: t('actived', 'Activados') },
                        'false': { name: t('disabled', 'Desactivados') },
                        'all': { name: t('all', 'Todos') }
                    }, label: t('active', 'Activo')
                }
            },
            searchKey: 'TaskBagOfHours.active',
            generalSearchable: false
        },
        {
            id: 'sharing',
            dataKey: 'sharing',
            label: t('share', 'Compartida'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CustomerBagOfHours' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} getDataFrom='/api/app/task/bagOfHours/getAllTaskBagsOfHours'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default CustomerBagsOfHours