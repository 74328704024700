import { useParams } from 'react-router'
import { useMemo } from 'react'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, CustomRenderColor, DeleteRow, EditRowNotLink } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import CustomerProjectStatusSortButton from './CustomerProjectStatusSortButton'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import ModalCECustomProjectStatus from './ModalCECustomerProjectStatus'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import ModalDeleteCustomerProjectStatus from './ModalDeleteCustomerProjectStatus'

interface CustomerProjectStatusProps extends ComponentWithPermissions { }

const CustomerProjectStatus: React.FC<CustomerProjectStatusProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('customerProjectStatus', 'Estados del tipo de proyecto'),
        newText: t('newValue', 'Nuevo valor'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomerProjectStatus} CreateModalComponent={ModalCECustomProjectStatus} EditModalComponent={ModalCECustomProjectStatus} DeleteModalComponent={ModalDeleteCustomerProjectStatus} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomerProjectStatusProps extends TableComponentProps { }

const TableCustomerProjectStatus: React.FC<TableCustomerProjectStatusProps> = ({ tableInstance, setTableInstance, userPermissions, setOpened }) => {
    const { t } = useTwinTranslation()
    const { CustomerProjectTypeId } = useParams()
    const extraWhereParams = { 'CustomerProjectTypeId': CustomerProjectTypeId ? parseInt(CustomerProjectTypeId) : null }
    const columns: ColumnTableSchema<CustomerProjectStatusModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'color',
            dataKey: 'color',
            label: t('color', 'Color'),
            customRender: (parameterValue) => <CustomRenderColor value={parameterValue} />,
        },
        {
            id: 'order',
            dataKey: 'order',
            label: t('order', 'Orden'),
            sortable: true,
        },
        {
            id: 'listing',
            dataKey: 'listing',
            label: t('listing', 'Listado'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    const children = useMemo(() => <CustomerProjectStatusSortButton tableInstance={tableInstance} userPermissions={userPermissions} />, [tableInstance, userPermissions])
    return (
        <TwinTable columns={columns} name='CustomerProjectStatus' extraWhereParams={extraWhereParams} getDataFrom='/api/app/customer/customerProject/customerProjectStatus/getAllCustomerProjectStatus' setTableInstance={setTableInstance} defaultOrder={{ defaultOrderField: 'order', order: 'asc' }} hideLoadFiltersButton={true}>
            {children}
        </TwinTable>
    )

}

export default CustomerProjectStatus