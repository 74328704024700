import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'


interface ModalDeleteContactB2BGdprFileProps extends ModalEditComponentProps { }

const ModalDeleteContactB2BGdprFile: React.FC<ModalDeleteContactB2BGdprFileProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()

    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/updateCustomer',
        data: {
            id: allRowData?.id,
            gdpr_file: '',
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteGdprFile', '¿Seguro que quieres eliminar este fichero?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteContactB2BGdprFile