import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import MainContactB2BProfile from './Tabs/MainContactB2BProfile'
import ContactAddresses from './Tabs/ContactB2BAddresses'
import ContactB2BOtherInformation from './Tabs/ContactB2BOtherInformation'
import ContactB2BBanks from './Tabs/ContactB2BBanks'
import ContactB2BContactsPersons from './Tabs/ContactB2BContactsPersons'
import ContactB2BProjects from './Tabs/ContactB2BProjects'
import ContactB2BShops from './Tabs/ContactB2BShops'
import ContactB2BGdpr from './Tabs/ContactB2BGdpr'
import ContactB2BFiles from './Tabs/ContactB2BFiles'
import ContactB2BBagOfHours from './Tabs/ContactB2BBagOfHours'
import ContactB2BTasks from './Tabs/ContactB2BTasks'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import ContactB2BNotes from './Tabs/ContactB2BNotes'
import ContactB2BCampaigns from './Tabs/ContactB2BCampaigns'

interface SingleContactB2BProps extends  ComponentWithPermissions {}

const SingleContactB2B: React.FC<SingleContactB2BProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: { id: params.id }, url: '/api/app/customer/getCustomerInstance', variableToCurrentPage: 'name' })
    const { dictCustomerOrigins } = useSingleContactB2BLogic()

    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainContactB2BProfile, text: t('general', 'General'), permission: 'customer.profile.main', extraProps: { dictCustomerOrigins}},
        'other-information': { component: ContactB2BOtherInformation, text: t('otherInformation', 'Otra información'), permission: 'customer.profile.main' },
        'address': { component: ContactAddresses, text: t('addresses', 'Direcciones'), permission: 'customer.profile.address' },
        'bank': { component: ContactB2BBanks, text: t('banks', 'Bancos'), permission: 'customer.profile.bank' },
        'contacts': { component: ContactB2BContactsPersons, text: t('contacts', 'Contactos'), permission: 'customer.profile.contactPerson' },
        'shops': { component: ContactB2BShops, text: t('workingCenters', 'Centros de trabajo'), permission: 'customer.profile.shops' },
        'projects': { component: ContactB2BProjects, text: t('projects', 'Proyectos'), permission: 'customer.profile.projects' },
        'bagsOfHours': { component: ContactB2BBagOfHours, text: t('bagsOfHours', 'Bolsas de horas'), permission: 'customer.profile.bagOfHours', modules: ['tasks'] },
        'tasks': { component: ContactB2BTasks, text: t('tasks', 'Tareas'), permission: 'customer.profile.tasks', modules: ['tasks'] },
        'campaigns': { component: ContactB2BCampaigns, text: t('campaigns', 'Campañas'), permission: 'customer.profile.campaign', modules: ['tasks'] },
        'notes': { component: ContactB2BNotes, text: t('notes', 'Notas'), permission: 'customer.profile.notes' },
        'files': { component: ContactB2BFiles, text: t('files', 'Ficheros'), permission: 'customer.profile.files' },
        'gdpr': { component: ContactB2BGdpr, text: t('gdpr', 'RGPD'), permission: 'customer.profile.gdpr' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}



const useSingleContactB2BLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)

    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins }
}

export default SingleContactB2B