import { HolidayTypesKeys } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import { TwinDictionary } from '../../globals/dictionary'
import useTwinTranslation from '../useTwinTranslation'

const useDictHolidayTypes = () => {
    const { t } = useTwinTranslation()
    const dictHolidayTypesDays: Record<HolidayTypesKeys, TwinDictionary> = {
        0: { name: t('workingDays', 'Días laborables') },
        1: { name: t('naturalDays', 'Días naturales') },
    }
    const dictHolidayTypes: Record<HolidayTypesKeys, TwinDictionary> = {
        0: { name: t('workable', 'Laborables') },
        1: { name: t('naturals', 'Naturales') },
    }
    return { dictHolidayTypesDays, dictHolidayTypes }
}

export default useDictHolidayTypes