import { faChevronLeft, faXmark } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../TwinIcon'
import { ButtonPrimary, ButtonRed, ButtonSecondary } from '../Button'
import { checkIfAnimated } from './functions'
import { useCallback, useEffect, useRef, useState } from 'react'
import useOnClickOutside from '../../utils/hooks/useOnClickOut'
import TwinTrans from '../TwinTrans'
import { Modify } from '../../utils/globals/types'
import { ModalOpenedSetTy, ModalOpenedTy } from './types'
import ReactDOM from 'react-dom'
import './modal.sass'

interface ModalExtraBigProps extends ModalProps{
}

export const ModalExtraBig: React.FC<ModalExtraBigProps> = ({ opened, setOpened, onCancel, children, onClickOut, className}) => {
    return (
        <Modal className={'h-full modal_extrabig ' + (className || '') } {...{opened,setOpened, onClickOut}}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}
interface ModalBigProps extends ModalProps{
}

export const ModalBig: React.FC<ModalBigProps> = ({ opened, setOpened, onCancel, children, onClickOut, className}) => {
    return (
        <Modal className={'h-full modal_big ' + (className || '') } {...{opened,setOpened, onClickOut}}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}

interface ModalMediumProps extends ModalProps{ }

export const ModalMedium: React.FC<ModalMediumProps> = ({ className, opened, setOpened, onCancel, children, onClickOut}) => {
    return (
        <Modal className={'h-full modal_medium ' + (className || '')} {...{opened,setOpened, onClickOut}}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}

interface ModalSmallMediumProps extends ModalProps{ }

export const ModalSmallMedium: React.FC<ModalSmallMediumProps> = ({ className, opened, setOpened, onCancel, children, onClickOut}) => {
    return (
        <Modal className={'h-full modal_small_medium ' + (className || '')} {...{opened,setOpened, onClickOut}}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}

interface ModalSmallMediumOutsideProps extends ModalSmallMediumProps {}

export const ModalSmallMediumOutside: React.FC<ModalSmallMediumOutsideProps> = ({...rest}) => {
    return (
        ReactDOM.createPortal(<ModalSmallMedium {...rest} />, document.body)
    )
}

export interface ModalFlexibleProps extends ModalProps {
    type: 'modal_little' | 'modal_medium' | 'modal_big' | 'modal_extrabig'
}

export const ModalFlexible: React.FC<ModalFlexibleProps> = ({ type, className, opened, setOpened, onCancel, children, onClickOut }) => {
    return (
        <Modal className={'h-full  ' + (type) + ' ' + (className || '')} {...{opened,setOpened, onClickOut}}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}
export interface ModalWithCloseButtonProps extends ModalProps {
    size: 'modal_little' | 'modal_medium' | 'modal_big' | 'modal_extrabig'
    showCloseButton: boolean
    setOpenedReturn: ModalOpenedSetTy
}

export const ModalWithCloseButton: React.FC<ModalWithCloseButtonProps> = ({ size, className, opened, setOpened, setOpenedReturn, onCancel, children, onClickOut, showCloseButton }) => {
    return (
        <Modal className={'h-full  ' + (size) + ' ' + (className || '')} {...{opened, setOpened, onClickOut}}>
            <div className='flex gap-33'>
                <CloseLateralModal setOpened={setOpenedReturn} onCancel={onCancel} />
                {showCloseButton ? <ButtonCloseModal setOpened={setOpened} onCancel={onCancel} /> : null}
            </div>
            {children}
        </Modal>
    )
}

type ModalLittleUnclosableProps = Modify<ModalProps, {
    setOpened?: never
}>

export const ModalLittleUnclosable: React.FC<ModalLittleUnclosableProps> = ({ opened, children}) => {
    return (
        <Modal className='h-full modal_little' {...{opened, setOpened: () => undefined}}>
            {children}
        </Modal>
    )
}

interface ModalLittleProps extends ModalProps {}

export const ModalLittle: React.FC<ModalLittleProps> = ({ children, className, setOpened, onCancel, ...rest}) => {
    return (
        <Modal className={'h-full modal_little ' + (className || '')} {...rest} {...{ setOpened, onCancel }}>
            <CloseLateralModal {...{ setOpened, onCancel }} />
            {children}
        </Modal>
    )
}


export interface ModalSmallCenteredProps extends ModalCenteredProps {}

export const ModalSmallCentered: React.FC<ModalSmallCenteredProps> = ({opened, setOpened, children, onClickOut, className}) => {
    return (
        <ModalCentered className={'modal_smallcentered flex flex-col justify-between ' + (className|| '')} {...{opened,setOpened, onClickOut}}>
            {children}
        </ModalCentered>
    )
}
export interface ModalMediumCenteredProps extends ModalCenteredProps {}

export const ModalMediumCentered: React.FC<ModalMediumCenteredProps> = ({opened, setOpened, children, onClickOut, className}) => {
    return (
        <ModalCentered className={'modal_mediumcentered flex flex-col justify-between ' + (className|| '')} {...{opened,setOpened, onClickOut}}>
            {children}
        </ModalCentered>
    )
}
interface ButtonsModalSmallCenteredProps extends ConfirmationButtonsProps{
    confirmText: string
    disabled?: boolean
}

export const ButtonsModalSmallCentered: React.FC<ButtonsModalSmallCenteredProps> = ({ onAccept, onCancel, setOpened, confirmText, disabled }) => {
    const { myOnClose, myOnAccept } = useButtonsModalSmallCenteredLogic(setOpened, onAccept, onCancel)
    return (
        <div className='flex justify-center gap-x-33'>
            <ButtonSecondary onClick={myOnClose}>
                <TwinTrans transKey={'cancel'}>Cancelar</TwinTrans>
            </ButtonSecondary>
            <ButtonPrimary onClick={myOnAccept} disabled={disabled}>
                {confirmText}
            </ButtonPrimary>
        </div>
    )
}

const useButtonsModalSmallCenteredLogic = (setOpened: (value: React.SetStateAction<any>) => void, onAccept?: (() => void), onCancel?: (() => void)) => {
    const myOnClose = useCallback(async () => {
        await onCancel?.()
        setOpened(null)
    }, [onCancel, setOpened])
    const myOnAccept = useCallback(async () => {
        await onAccept?.()
        setOpened(null)
    }, [onAccept, setOpened])
    return { myOnClose, myOnAccept }
}


export interface ModalDeleteProps extends ModalProps{
    onAccept?(): void
    translations?: {
        buttonCancel?: string
        buttonAccept?: string
    }
}

export const ModalDelete: React.FC<ModalDeleteProps> = ({ onAccept, opened, setOpened, onCancel, children, onClickOut, className, translations}) => {
    return (
        <ModalSmallCentered {...{opened,setOpened, onClickOut, className}}>
            {children}
            <DeleteButtons {...{ onAccept, onCancel, setOpened, translations }}/>
        </ModalSmallCentered>
    )
}

interface ConfirmationButtonsProps {
    onAccept?(): void
    onCancel?(): void
    setOpened: ModalOpenedSetTy
    translations?: {
        buttonCancel?: string
        buttonAccept?: string
    }
}

const DeleteButtons: React.FC<ConfirmationButtonsProps> = ({ onAccept, onCancel, setOpened, translations }) => {
    const { myOnClose, myOnAccept } = useDeleteButtonsLogic(setOpened, onAccept, onCancel)
    return (
        <ModalButtons>
            <ButtonSecondary onClick={myOnClose} className='cancel_delete' type='button'>
                {translations?.buttonCancel ? translations.buttonCancel : <TwinTrans transKey={'cancel'}>Cancelar</TwinTrans>}
            </ButtonSecondary>
            <ButtonRed onClick={myOnAccept} className='confirm_delete' type='button'>
                {translations?.buttonAccept ? translations.buttonAccept : <TwinTrans transKey={'delete'}>Eliminar</TwinTrans>}
            </ButtonRed>
        </ModalButtons>
    )
}
interface ModalButtonsProps {
    children?: React.ReactNode
}

export const ModalButtons: React.FC<ModalButtonsProps> = ({ children }) => {
    return (
        <div className='flex justify-center gap-x-33'>
            {children}
        </div>
    )
}

const useDeleteButtonsLogic = (setOpened: ModalOpenedSetTy, onAccept?: (() => void), onCancel?: (() => void)) => {
    const myOnClose = useCallback(async () => {
        await onCancel?.()
        setOpened(null)
    }, [onCancel, setOpened])
    const myOnAccept = useCallback(async () => {
        await onAccept?.()
        setOpened(null)
    }, [onAccept, setOpened])
    return { myOnClose, myOnAccept }
}

interface CloseLateralModalProps {
    onCancel?(): void
    setOpened: ModalOpenedSetTy
}

export const CloseLateralModal: React.FC<CloseLateralModalProps> = ({ setOpened, onCancel }) => {
    const { myOnClose } = useCloseLateralModalLogic(setOpened, onCancel)
    return (
        <CloseLateralModalSimple onClose={myOnClose} />
    )
}
interface CloseLateralModalSimpleProps {
    onClose(): void
}

export const CloseLateralModalSimple: React.FC<CloseLateralModalSimpleProps> = ({ onClose }) => {
    return (
        <div onClick={onClose} className='cursor-pointer hover:text-green-21 close_modal'>
            <TwinIcon icon={faChevronLeft} className='icon_close_modal' />
            <span>
                <TwinTrans transKey='return'>
                    Volver
                </TwinTrans>
            </span>
        </div>
    )
}

const useCloseLateralModalLogic = (setOpened: ModalOpenedSetTy, onCancel?: (() => void)) => {
    const myOnClose = useCallback(async () => {
        await onCancel?.()
        setOpened(null)
    }, [onCancel, setOpened])
    return { myOnClose }
}
interface ButtonCloseModalProps {
    onCancel?(): void
    setOpened: ModalOpenedSetTy
}

export const ButtonCloseModal: React.FC<ButtonCloseModalProps> = ({ setOpened, onCancel }) => {
    const { myOnClose } = useButtonCloseModalLogic(setOpened, onCancel)
    return (
        <div onClick={myOnClose} className='cursor-pointer hover:text-green-21 close_modal flex align-center'>
            <TwinIcon icon={faXmark} className='icon_close_modal w-18 h-18' />
            <span>
                <TwinTrans transKey='close'>
                    Cerrar
                </TwinTrans>
            </span>
        </div>
    )
}

const useButtonCloseModalLogic = (setOpened: ModalOpenedSetTy, onCancel?: (() => void)) => {
    const myOnClose = useCallback(async () => {
        await onCancel?.()
        setOpened(null)
    }, [onCancel, setOpened])
    return { myOnClose }
}

interface ModalProps extends ModalLogicProps {
    className?: string
    onCancel?(): void
    children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ opened, className, setOpened, children, onClickOut=true}) => {
    const { animatedClassName, ref } = useModalLogic({ opened, setOpened, onClickOut })
    if (opened) {
        return (
            <div className='fixed inset-0 w-full h-full bg-black bg-opacity-50 z-10' >
                <div className={'modal fixed  z-10 bg-white ' + animatedClassName + ' ' + (className || '')} ref={ref}>
                    {children}
                </div>
            </div>
        )
    }
    return null
}

interface ModalCenteredProps extends ModalProps {
}
const ModalCentered: React.FC<ModalCenteredProps> = ({ opened, className, setOpened, children, onClickOut=true}) => {
    const { animatedClassName, ref } = useModalLogic({ opened, setOpened, onClickOut })
    if (opened) {
        return (
            <div className='fixed inset-0 w-full h-full bg-black bg-opacity-50 z-10 flex justify-center items-center' >
                <div className={'modal fixed  z-10 bg-white ' + animatedClassName + ' ' + (className || '')} ref={ref}>
                    {children}
                </div>
            </div>
        )
    }
    return null
}

interface ModalLogicProps {
    opened: ModalOpenedTy
    setOpened: ModalOpenedSetTy
    onClickOut?: boolean
}


const useModalLogic = ({opened, setOpened, onClickOut}: ModalLogicProps) => {
    const [animated, setAnimated] = useState(false)
    const animatedClassName = checkIfAnimated(animated)
    useEffect(() => {
        if (opened) {
            setTimeout(() => {
                setAnimated(true)
            }, 10)
        }
        return () => {
            if (opened) {
                setAnimated(false)
            }
        }
    }, [opened])
    const ref = useRef<any>(null)
    useOnClickOutside(ref, () => onClickOut ? setOpened(null) : {})
    
    return { animatedClassName, ref }
}


export default Modal