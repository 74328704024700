import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { useLocation, useNavigate } from 'react-router'
import { getBasePath } from '../../../../../../../../../../utils/globals/link'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import ModalSmallFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'


interface ModalContactB2BChangeTypeProps extends ModalContactB2BChangeTypeLogicProps { }

const ModalContactB2BChangeType: React.FC<ModalContactB2BChangeTypeProps> = ({ ...rest}) => {
    const {t} = useTwinTranslation()
    const {handleOnSubmitContactType} = useModalContactB2BChangeTypeLogic({ ...rest })
    return (
        <ModalSmallFilled opened={true} setOpened={rest.setOpened} onAccept={handleOnSubmitContactType} translations={{
            title: t('transformToCustomerB2B', 'Transformar a cliente B2B'),
            subtitle: t('sureTransformContactToCustomerB2B', '¿Seguro que quiere transformar este Cliente Potencial a Cliente B2B?'),
            buttonAccept: t('transform', 'Transformar')
        }} />
    )
}

interface ModalContactB2BChangeTypeLogicProps extends ModalEditComponentProps {}

const useModalContactB2BChangeTypeLogic = ({ allRowData }: ModalContactB2BChangeTypeLogicProps) => {
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'customer')
    const navigate = useNavigate()
    
    const handleOnSubmitContactType = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/updateCustomer', {
            id: allRowData?.id,
            customer_type: customerTypes['B2B']
        })
        if (result) {
            navigate(`${basePath}/customersB2B/${allRowData?.id}/main`)
        }
    }, [allRowData?.id, navigate, basePath])

    return { handleOnSubmitContactType }
}


export default ModalContactB2BChangeType